// Modals
.#{$vudClassPrefix}modal {
    display: none;
    overflow: hidden;
    position: fixed;
    content: '';
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    outline: 0;
    background-color: var(--#{$vudTokenPrefix}modal-overlay-bg);

    justify-content: center;
    align-items: center;
    z-index: $zindex-modal;

    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;

    &.fade {
        opacity: 0;
        animation: hide .35s;

        &.in {
            display: flex;
            opacity: 1;
            animation: show .35s;
        }
    }

    &.#{$vudClassPrefix}modal-help,
    &.#{$vudClassPrefix}modal-info,
    &.#{$vudClassPrefix}modal-error,
    &.#{$vudClassPrefix}modal-warning,
    &.#{$vudClassPrefix}modal-success {

        .#{$vudClassPrefix}modal-dialog {
            padding-left: rem(144px);

            @media(min-width: $screen-sm-max) {
                &.#{$vudClassPrefix}modal-sm {
                    padding-left: rem(60px);
                    padding-top: rem(152px);

                    .#{$vudClassPrefix}modal-body {
                        &::before {
                            top: rem(46px);
                            left: calc(#{'50% - ' rem(36px)});
                        }
                    }
                }
            }

            @media(max-width: $screen-sm-max) {
                padding-top: rem(152px);
                padding-left: rem(60px);
            }

            @media(max-width: $screen-xs-max) {
                padding-left: rem(36px);
            }
        }

        &.#{$vudClassPrefix}modal-center { 
            text-align: center;

            .#{$vudClassPrefix}modal-dialog {
                padding-left: rem(48px);
                padding-top: rem(168px);
            }
        }

        .#{$vudClassPrefix}modal-content {
            &::before {
                display: block;
                position: absolute; 
                content: '';
                width: rem(64px);
                height: rem(64px);
                top: rem(72px);
                left: rem(48px);

                mask-image: url('#{$image-path}/informative-icons/64_informative_icons_dynamic.svg');
                -webkit-mask-image: url('#{$image-path}/informative-icons/64_informative_icons_dynamic.svg');

                mask-size: auto 64px;
                -webkit-mask-size: auto 64px;
                background-color: transparent;
                mask-repeat: no-repeat;
                -webkit-mask-repeat: no-repeat;

                @media(max-width: $screen-sm-max) {
                    top: rem(60px);
                    left: calc(#{'50% - ' rem(36px)}); 
                }
            }
        }

        &.#{$vudClassPrefix}modal-center {
            .#{$vudClassPrefix}modal-content {
                &::before {
                    top: rem(72px);
                    left: calc(50% - 3.2rem);
                }
            }

            .#{$vudClassPrefix}modal-header .#{$vudClassPrefix}close {
                top: rem(-134px);
            }
        }

        @media(max-width: $screen-sm-max) {
            .#{$vudClassPrefix}close {
                top: rem(-130px);
            }
        }
    }

    &.#{$vudClassPrefix}modal-help {
        .#{$vudClassPrefix}modal-content {
            &::before {
                mask-position: rem(-282px) 0;
                -webkit-mask-position: rem(-282px) 0;
                background-color: var(--#{$vudTokenPrefix}icon-informative-help-bg);
            }
        }
    }

    &.#{$vudClassPrefix}modal-info {
        .#{$vudClassPrefix}modal-content {
            &::before {
                mask-position: rem(-212px) 0;
                -webkit-mask-position: rem(-212px) 0;
                background-color: var(--#{$vudTokenPrefix}icon-informative-info-bg);
            }
        }
    }

    &.#{$vudClassPrefix}modal-error {
        .#{$vudClassPrefix}modal-content {
            &::before {
                mask-position: rem(-70px) 0;
                -webkit-mask-position: rem(-70px) 0;
                background-color: var(--#{$vudTokenPrefix}icon-informative-error-bg);
            }
        }
    }

    &.#{$vudClassPrefix}modal-warning {
        .#{$vudClassPrefix}modal-content {
            &::before {
                mask-position: rem(-141px) 0;
                -webkit-mask-position: rem(-141px) 0;
                background-color: var(--#{$vudTokenPrefix}icon-informative-warning-bg);
            }
        }
    }

    &.#{$vudClassPrefix}modal-success {
        .#{$vudClassPrefix}modal-content {
            &::before {
                mask-position: 0 0;
                -webkit-mask-position: 0 0;
                background-color: var(--#{$vudTokenPrefix}icon-informative-success-bg);
            }
        }
    }

    .#{$vudClassPrefix}modal-content {
        height: 100%;

        display: flex;
        flex-direction: column;
        clear: both;
        width: 100%;
    }
}

.#{$vudClassPrefix}modal-dialog {
    position: relative;
    width: rem(600px);
    max-width: calc(#{'100% - ' rem(60px)});
    max-height: calc(#{'100% - ' rem(60px)});
    min-height: rem(300px);
    padding: rem(72px) rem(48px) rem(40px) rem(48px);

    border-radius: $border-radius-24;
    background-color: var(--#{$vudTokenPrefix}modal-bg);
    box-shadow: var(--#{$vudTokenPrefix}modal-shadow);

    @media(max-width: $screen-xs-min) {
        padding: rem(56px) rem(24px);
    }

    &.#{$vudClassPrefix}modal-xl {
        max-width: calc(#{'100% - ' rem(60px)});

        @media(min-width: $screen-lg-min) {
            width: rem(1200px);
        }

        @media(max-width: $screen-lg-max) {
            width: calc(#{'100% - ' rem(240px)});
        }

        @media(max-width: $screen-md-max) {
            width: calc(#{'100% - ' rem(60px)});
        }

        @media(max-width: $screen-xs-max) {
            width: calc(#{'100% - ' rem(30px)});
        }
    }

    &.#{$vudClassPrefix}modal-full {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        max-height: 100%;
        height: 100vh;
    }

    &.#{$vudClassPrefix}modal-lg {
        width: rem(720px);
    }

    &.#{$vudClassPrefix}modal-sm {
        width: rem(480px);
    }
}

// .modal-header,
.#{$vudClassPrefix}modal-header {
    position: relative;

    .#{$vudClassPrefix}close {
        position: absolute;
        top: rem(-40px);
        right: rem(-18px);

        @media(max-width: $screen-xs-max) {
            right: 0;
        }
    }
}

.#{$vudClassPrefix}modal-title {
    padding: 0;
    margin: 0 0 $spacing-16 0;

    color: var(--#{$vudTokenPrefix}titles-font-color);
    line-height: 1.5;
    font: {
        // size: rem(16px);
        weight: $bold;
    }

    h1, h2, h3, h4, h5, h6, p { margin-bottom: 0; }
}

.#{$vudClassPrefix}modal-body {
    margin-bottom: rem(56px);

    //max-height: calc(#{'72vh - ' rem(110px)});
    height: 100%;
    overflow-y: auto;

    /* fix for required fields in a form */
    padding-left: rem(7px);
    margin-left: rem(-7px);

    p {
        margin-bottom: $spacing-16;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.#{$vudClassPrefix}modal-footer {
    @include clearfix();
    // padding: 0 0 rem(30px);
    padding: 0;
    flex-wrap: wrap;

    .float-right {
        text-align: right;
    }
}


// Transition
@keyframes hide {
    from{
        opacity: 1;
    } to {
        opacity: 0;
    }
}

@keyframes show {
    from{
        opacity: 0;
    } to {
        opacity: 1;
    }
}
