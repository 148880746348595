
//*** scss local variable ***
$focusWidth: rem(2px);

//*** element/component code ***
// Make terms and descriptions in description list/block tag line up side-by-side.
.dl-horizontal {
    @media (min-width: $screen-sm-min) {
        dt {
            overflow: hidden;
            float: left;
            clear: left;
            width: rem(162px);

            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        dd {
            margin-left: rem(180px);
        }
    }
}

// unstyled list, removing the default list-style and left margin.
.list-unstyled,
.list-inline {
    padding-left: 0;

    list-style: none;
}

// place all list on a single line / paragraph.
.list-inline {
    margin-left: rem(-6px);

    > li {
        display: inline-block;
        padding-right: rem(6px);
        padding-left: rem(6px);
    }
}

// Basic list group
.#{$vudClassPrefix}list-group {
    margin: 0;
    padding: 0 rem(30px);
    overflow: hidden;

    color: var(--#{$vudTokenPrefix}body-font-color);
    list-style: none;

    border-radius: $border-radius-24;
    background-color: var(--#{$vudTokenPrefix}module-container-bg-color);
    box-shadow: var(--module-container-shadow);

    // List group components
    > .#{$vudClassPrefix}list-group-header,
    > .#{$vudClassPrefix}list-group-item,
    > .#{$vudClassPrefix}list-group-title,
    > .#{$vudClassPrefix}list-group-footer {
        // overflow: hidden;
        display: block;
        clear: both;
        cursor: default;
        position: relative;
        margin: 0;
        padding: rem(15px) 0;

        font: {
            size: rem(14px);
        }
        line-height: rem(18px);

        &:not(:first-child) {
            box-shadow: var(--#{$vudTokenPrefix}list-group-divider);
        }

        .#{$vudClassPrefix}badge {
            position: relative;
            float: right;
            min-width: rem(30px);
            height: rem(20px);
            top: 0;
            padding: rem(1px) rem(6px);
            margin-right: rem(1px);

            color: var(--#{$vudTokenPrefix}list-group-badge-text);
            text-align: center;
            font-size: rem(14px);
            font-weight: normal;

            box-shadow: none;
            background: transparent;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    // header block of the list group
    > .#{$vudClassPrefix}list-group-header {
        margin-right: rem(-30px);
        margin-left: rem(-30px);
        padding: rem(15px) rem(30px);

        border-top-right-radius: $border-radius-24;
        border-top-left-radius: $border-radius-24;
        background-color: var(--#{$vudTokenPrefix}list-group-header-bg);

        &.#{$vudClassPrefix}list-group-title {
            color: $neutral-05;

            .#{$vudClassPrefix}badge {
                background-color: $neutral-05;
                border-color: $white;
            }
        }
    }

    // list item default state
    > .#{$vudClassPrefix}list-group-item {
        &:last-child {
            border-bottom-right-radius: $border-radius-24;
            border-bottom-left-radius: $border-radius-24;
        }

        // active state for a list item
        &.active {
            margin-right: rem(-30px);
            margin-left: rem(-30px);
            padding: rem(15px) rem(30px);

            background-color: var(--module-container-active-bg-color);
            box-shadow: none;

            &::before {
                display: block;
                position: absolute;
                content: '';
                width: rem(5px);
                height: 100%;
                top: 0;
                left: 0;

                background-color: var(--module-container-active-before-color);
            }

            + .#{$vudClassPrefix}list-group-item { box-shadow: none; }
        }

        // disabled state for a list item
        &.disabled {
            &, &:hover, &:active, &:focus {
                cursor: default;
                pointer-events: none;
                outline: none;

                color: var(--#{$vudTokenPrefix}button-disabled-text-color);
            }

            .#{$vudClassPrefix}badge { color: var(--#{$vudTokenPrefix}button-disabled-text-color); }
        }

        // title element in the list item of the list group
        .#{$vudClassPrefix}list-group-item-heading,
        .#{$vudClassPrefix}list-group-item-text {
            margin-bottom: rem(12px);

            color: var(--#{$vudTokenPrefix}body-font-color);
            font: {
                size: rem(14px);
                weight: $normal;
            }
            line-height: rem(18px);
        }

        //
        .#{$vudClassPrefix}list-group-item-text.mutted { color: var(--#{$vudTokenPrefix}neutral-80); }
    }

    // define default state for a clickable list item
    > a.#{$vudClassPrefix}list-group-item {
        cursor: pointer;

        color: var(--#{$vudTokenPrefix}body-font-color);
        text-decoration: none;

        border: 0;
        background-color: transparent;

        // full width of list item for active focus and hover state
        &:not(.disabled):hover,
        &:not(.disabled):focus,
        &.active {
            margin-right: rem(-30px);
            margin-left: rem(-30px);
            padding: rem(15px) rem(30px);
        }

        // hover state for list item
        &:not(.disabled):not(.active):hover {
            background-color: var(--module-container-hover-bg-color);
            box-shadow: none;

            + .list-group-item { box-shadow: none; }
        }

        // focus state for list item
        &:not(.disabled):focus {
            outline: 0;

            &, &:hover {
                //box-shadow: inset 0 0 0 $focusWidth $primary-blue;
                box-shadow: inset 0 0 0 $focusWidth var(--#{$vudTokenPrefix}anchor-focus-outline);
            }
        }

        // active state for list item
        &.active {
            &:focus::before {
                box-shadow: inset $focusWidth $focusWidth 0 0 var(--#{$vudTokenPrefix}anchor-focus-outline), inset 0 rem(-1px) 0 0 var(--#{$vudTokenPrefix}anchor-focus-outline);
            }
        }
    }

    // title element group
    > .#{$vudClassPrefix}list-group-title {
        & {
            color: var(--#{$vudTokenPrefix}titles-font-color);

            font: {
                size: rem(16px);
                weight: $bold;
            }

            line-height: rem(24px);
        }

        .#{$vudClassPrefix}badge {
            top: rem(4px);
            height: rem(20px);
            min-width: rem(30px);
            padding-top: rem(2px);

            color: var(--#{$vudTokenPrefix}primary-neutral);
            font-size: rem(12px);
            line-height: rem(14px);
            font-weight: bold;

            box-shadow: none;
            border: rem(1px) solid var(--#{$vudTokenPrefix}list-group-badge-border);
            background-color: var(--#{$vudTokenPrefix}list-group-badge-bg);
        }
    }

    // alternative style for the list group
    &.#{$vudClassPrefix}list-group-primary {
        background-color: var(--#{$vudTokenPrefix}list-group-primary-bg);

        // default state for list items in the alternative list group
        > .#{$vudClassPrefix}list-group-item,
        > .#{$vudClassPrefix}list-group-title {
            &:not(:first-child):not(.#{$vudClassPrefix}list-group-footer):not(.active) {
                box-shadow: var(--list-group-primary-divider);
            }

            .#{$vudClassPrefix}badge {
                color: $neutral-05;
                font-size: rem(12px);
                font-weight: bold;

                border: rem(1px) solid $blue-30;
                box-shadow: none;
            }
        }

        > .#{$vudClassPrefix}list-group-title.#{$vudClassPrefix}list-group-item { color: $neutral-05; }

        > .#{$vudClassPrefix}list-group-item {
            color: var(--#{$vudTokenPrefix}list-group-primary-text-color);

            // active state for the alternative list item
            &.active {
                background-color: var(--list-group-primary-active-bg);
                box-shadow: none;

                &::before { display: none; }

                + .#{$vudClassPrefix}list-group-item:not(:first-child):not(.#{$vudClassPrefix}list-group-footer):not(.active) { box-shadow: none; }
            }

            // disabled state for the alternative list item
            &.disabled {
                &, .#{$vudClassPrefix}badge {
                    color: $blue-30;
                }
            }

            // badge element for the alternative list group
            .#{$vudClassPrefix}badge {
                &.#{$vudClassPrefix}badge-nopill {
                    padding: 0;

                    font-size: rem(14px);
                    font-weight: normal;
                    text-align: right;

                    box-shadow: none;
                    border: 0;

                    b, strong { font-weight: bold; }
                }
            }

            // title element in the list item of the list group
            .#{$vudClassPrefix}list-group-item-heading,
            .#{$vudClassPrefix}list-group-item-text {
                color: $neutral-05;
            }
        }

        // clickable list item for alternative list group
        > a.#{$vudClassPrefix}list-group-item {
            // hoverble state
            &:not(.disabled):not(.active):hover {
                background-color: var(--list-group-primary-hover-bg);
                box-shadow: none;
            }

            // focus state
            &:not(.disabled):not(:first-child):not(.#{$vudClassPrefix}list-group-footer):focus,
            &:not(.disabled):not(:first-child):not(.#{$vudClassPrefix}list-group-footer):not(.active):focus {
                &, &:hover {
                    box-shadow: inset 0 0 0 $focusWidth $neutral-05;
                }
            }
        }

        > .#{$vudClassPrefix}list-group-title {
            .#{$vudClassPrefix}badge {
                color: var(--#{$vudTokenPrefix}badge-text-color);

                background-color: var(--#{$vudTokenPrefix}badge-bg);
                box-shadow: var(--#{$vudTokenPrefix}badge-shadow);
                border: rem(1px) solid var(--#{$vudTokenPrefix}badge-bg);
            }
        }

        // footer block of the alternative list group
        > .#{$vudClassPrefix}list-group-footer,
        > a.#{$vudClassPrefix}list-group-footer {
            cursor: default;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &, &:not(.disabled):hover,
            &:not(.disabled):focus,
            &:active, &.active,
            &.disabled {
                padding: rem(24px) 0;

                border-radius: 0 0 $border-radius-24 $border-radius-24;
                box-shadow: var(--list-group-primary-divider);
            }

            // default badge block in the footer block
            .#{$vudClassPrefix}badge {
                padding: 0;

                font-size: rem(14px);
                line-height: rem(30px);
                text-align: right;
                vertical-align: baseline;

                box-shadow: none;
                border: 0;
                height: auto;
                
                order: 4;
                
                b, strong {
                    font-size: rem(24px);
                }
            }
        }
    }
}
