// Cards list - .card-list-primary

//*** scss local variable ***
$card-list-primary-item-shadow: var(--module-container-shadow);


//*** element/component code ***
.#{$vudClassPrefix}card-list {
    &.#{$vudClassPrefix}card-list-primary {
        .#{$vudClassPrefix}card-list-item {
            border-radius: $border-radius-12;
            box-shadow: $card-list-primary-item-shadow;

            .description {
                flex-direction: column;
                align-items: initial;
            }
        }
    }
}