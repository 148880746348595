
// Top navigation - secondary styles - WHITE VERSION

.#{$vudClassPrefix}navbar {
    &.#{$vudClassPrefix}navbar-secondary {
        background: var(--#{$vudTokenPrefix}navigation-secondary-bg);
        box-shadow: var(--#{$vudTokenPrefix}navigation-secondary-shadow);

        .#{$vudClassPrefix}dropdown {
            > .#{$vudClassPrefix}dropdown-toggle {
                .#{$vudClassPrefix}caret { background-color: var(--#{$vudTokenPrefix}caret-default-bg); }

                &:hover { background-color: var(--#{$vudTokenPrefix}navigation-secondary-item-hover-bg); }
            }

            &.open {
                > .#{$vudClassPrefix}dropdown-toggle {
                    .#{$vudClassPrefix}caret { background-color: var(--#{$vudTokenPrefix}caret-default-bg); }
                }
            }  
        }
    }
}


/* Product dropdown area */
.#{$vudClassPrefix}navbar-secondary {
    .#{$vudClassPrefix}navbar-header {
        .#{$vudClassPrefix}navbar-brand {
            > a,
            > .#{$vudClassPrefix}dropdown-toggle {
                color: var(--#{$vudTokenPrefix}navigation-secondary-item-text-color);

                &:hover { background-color: var(--#{$vudTokenPrefix}navigation-secondary-item-hover-bg); }

                &:focus {
                    outline: rem(2px) solid var(--#{$vudTokenPrefix}navigation-secondary-item-focus-border-color);
                    outline-offset: rem(-2px);
                }
            }

            &.open {
                > a {
                    &, &:focus { box-shadow: var(--#{$vudTokenPrefix}navigation-default-menudrop-shadow); }
                }
            }
        }
    }
}


/* navbar */
.#{$vudClassPrefix}navbar-secondary {
    .#{$vudClassPrefix}navbar-collapse {
        .nav {
            li {
                a {
                    &:hover{ background: var(--#{$vudTokenPrefix}navigation-secondary-item-hover-bg); }

                    &:focus { outline: rem(1px) solid var(--#{$vudTokenPrefix}anchor-focus-outline); }
                }
            }
        }

        .first-level.#{$vudClassPrefix}navbar-right {
            > .icon {
                & > a {
                    &:hover {
                        &:after { background-color: var(--#{$vudTokenPrefix}navigation-secondary-item-hover-bg); }
                    }

                    &:focus {
                        &:before { background-color: var(--#{$vudTokenPrefix}anchor-focus-outline); }
                    }

                    .#{$vudClassPrefix}badge {
                        color:  var(--#{$vudTokenPrefix}badge-success-text);
                        background-color: var(--#{$vudTokenPrefix}badge-success-bg);
                        box-shadow: var(--#{$vudTokenPrefix}badge-success-shadow);
                    }

                    .vismaicon::before { background-color: var(--#{$vudTokenPrefix}navigation-secondary-hamburger-bg); }
                }

                &:not(.#{$vudClassPrefix}dropdown) > a {
                    border-radius: rem(14px);

                    &:hover { background: none; }
                }

                &.active {
                    > a {
                        background-color: transparent;
                        box-shadow: none;

                        &:before {
                            display: block;
                            background-color: $neutral-light-60;
                            -webkit-mask-image: url(#{$image-path}/squerkle-border-thin.svg);
                            mask-image: url(#{$image-path}/squerkle-border-thin.svg);
                        }

                        &:focus {
                            &:before {
                                -webkit-mask-image: url(#{$image-path}/squerkle-border-thick.svg);
                                mask-image: url(#{$image-path}/squerkle-border-thick.svg);
                                z-index: 0;
                                background-color: var(--#{$vudTokenPrefix}anchor-focus-outline);
                            }
                        }
                    }

                    .vismaicon:not(.vismaicon-filled)::before { background-color: var(--#{$vudTokenPrefix}navigation-secondary-hamburger-bg); }
                }

                &.#{$vudClassPrefix}dropdown {
                    &.open, &.show {
                        .#{$vudClassPrefix}dropdown-toggle {
                            &:focus { outline: rem(2px) solid var(--#{$vudTokenPrefix}navigation-secondary-item-focus-border-color); }
                        }
                    }
                }
            }

            .#{$vudClassPrefix}dropdown {
                .#{$vudClassPrefix}dropdown-toggle {
                    .vismaicon {
                        &::before { background-color: var(--#{$vudTokenPrefix}navigation-secondary-hamburger-bg); }
                    }

                    .#{$vudClassPrefix}badge {
                        color: var(--#{$vudTokenPrefix}badge-success-text);
                        background: var(--#{$vudTokenPrefix}badge-success-bg);
                    }
                }
            }
            
            .user-dropdown {
                > .#{$vudClassPrefix}dropdown-toggle {
                    > small { color: var(--#{$vudTokenPrefix}navigation-secondary-item-dropdown-small-text-color); }
                }

                &.icon {
                    &.open {
                        .#{$vudClassPrefix}dropdown-toggle {
                            .vismaicon::before { background-color: var(--#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg); }
                        }
                    }
                }
            }
        }

        .first-level {
            > li > a {
                color: var(--#{$vudTokenPrefix}navigation-secondary-item-text-color);

                &:focus {
                    outline: rem(2px) solid var(--#{$vudTokenPrefix}navigation-secondary-item-focus-border-color);
                    outline-offset: rem(-2px);
                }
            }

            > .active {
                > a {
                    color: var(--#{$vudTokenPrefix}navigation-secondary-item-active-text-color);
                    font-weight: var(--#{$vudTokenPrefix}navigation-secondary-item-active-font-weight);
                    background-color: var(--#{$vudTokenPrefix}navigation-secondary-item-active-bg);

                    &, &:hover, &:focus, &:active { box-shadow: var(--#{$vudTokenPrefix}navigation-secondary-item-active-shadow); }
                }
            }

            > .#{$vudClassPrefix}dropdown.open {
                > a {
                    &, &:focus { box-shadow: var(--#{$vudTokenPrefix}navigation-default-menudrop-shadow); }
                }
            }

            > .disabled {
                > a { color: var(--neutral-50); }

                .search-group {
                    &, &.search-group-primary {
                        input {
                            &.#{$vudClassPrefix}form-control {
                                &[type=text], &[type=search] {
                                    border-color: var(--#{$vudTokenPrefix}input-disabled-border-color);
                                }
                            }
                        }
                    }
                }
            }

            .menudrop {
                > .#{$vudClassPrefix}dropdown-toggle {
                    .icon-align-justify {
                        &, &::before, &::after { background: var(--#{$vudTokenPrefix}navigation-secondary-menudrop-icon-bg); }
                    }
                }
            }

            .search-group {
                &, &.search-group-primary {
                    input {
                        &.#{$vudClassPrefix}form-control {
                            &[type=text], &[type=search] {
                                border-color: var(--#{$vudTokenPrefix}input-border-color);
                                box-shadow: none;

                                &:hover, &:focus { border-color: var(--#{$vudTokenPrefix}input-hover-border-color); }

                                &:focus { box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}input-hover-border-color); }

                                &:-webkit-autofill  {
                                    &, &:hover, &:focus { box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}input-hover-border-color), 0 0 0 rem(1000px) var(--#{$vudTokenPrefix}input-bg) inset !important; }
                                }
                            }
                        }
                    }
                }
            }
        }

        ul.second-level {
            border-top: rem(1px) solid var(--#{$vudTokenPrefix}navigation-secondary-second-level-divider);
            box-shadow: var(--#{$vudTokenPrefix}navigation-secondary-second-level-shadow); 
        }
    }
}

.#{$vudClassPrefix}navbar-secondary {
    &.#{$vudClassPrefix}navbar.is-mobile {
        .#{$vudClassPrefix}navbar-collapse{
            .first-level {
                .menudrop {
                    .icon-align-justify {
                        &,
                        &::before,
                        &::after { background: var(--#{$vudTokenPrefix}navigation-secondary-menudrop-icon-bg); }
                    }
                }
            }
        }
    }
}
