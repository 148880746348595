//== Carets

//=== Variables
$caret-large: $arrow-icon-large;
$caret-medium: $arrow-icon-large; // this is deprecated - will be removed in time, until then is replaced with arrow-icon-large
$caret-small: $arrow-icon-small;

$caret-small-height: rem(5px);
$caret-small-width: rem(8px);

$caret-large-height: rem(6px);
$caret-large-width: rem(10px);

$caret-default-bg: var(--caret-default-bg);
$caret-primary-bg: var(--caret-primary-bg);
$caret-secondary-bg: var(--caret-secondary-bg);
$caret-disabled-bg: var(--caret-disabled-bg);

//=== Mixins
// 
@mixin caret-mask-img($size: 'sm') {
  $height: $caret-small-height !default;
  $width: $caret-small-width !default;
  $mask-image: $caret-small !default;

  @if $size == 'sm' {
    $height: $caret-small-height;
    $width: $caret-small-width;
    $mask-image: $caret-small;
  } @else if $size == 'lg' {
    $height: $caret-large-height;
    $width: $caret-large-width;
    $mask-image: $caret-large;
  }

  height: $height;
  width: $width;

  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: $width $height;
  mask-size: $width $height;
  -webkit-mask-image: $mask-image;
  mask-image: $mask-image;
}

// 
@mixin caret-mask-color($theme: 'default') {
  $background-color: $caret-default-bg !default;

  @if $theme == 'default' {
    $background-color: $caret-default-bg;
  } @else if $theme == 'primary' {
    $background-color: $caret-primary-bg;
  } @else if $theme == 'secondary' {
    $background-color: $caret-secondary-bg;
  } @else if $theme == 'disabled' {
    $background-color: $caret-disabled-bg;
  }

  background-color: $background-color;
}

// attribute values:
//  - size: 'sm' or 'lg'
//  - &theme: 'default', 'primary', 'secondary' or 'disabled'
@mixin caret($size: 'sm', $theme: 'default') {
    @include caret-mask-img($size);
    @include caret-mask-color($theme);
}

// Size
@mixin caret-large() {
  @include caret-mask-img('lg');
}

@mixin caret-medium() { // this is deprecated - will be removed in time, until then is replaced with caret-large
  @include caret-mask-img('lg');
}

@mixin caret-small() {
  @include caret-mask-img('sm');
}


// Large
//black
@mixin caret-large-default() {
  @include caret('lg', 'default');
}

//blue
@mixin caret-large-primary() {
  @include caret('lg', 'primary');
}

//white
@mixin caret-large-secondary() {
  @include caret('lg', 'secondary');
}

//grey
@mixin caret-large-disabled() {
  @include caret('lg', 'disabled');
}


// Medium --- this is deprecated - will be removed in time
//black
@mixin caret-medium-default() {
  @include caret('lg', 'default');
}

//blue
@mixin caret-medium-primary() {
  @include caret('lg', 'primary');
}

//white
@mixin caret-medium-secondary() {
  @include caret('lg', 'secondary');
}

//grey
@mixin caret-medium-disabled() {
  @include caret('lg', 'disabled');
}


// Small
//black
@mixin caret-small-default() {
  @include caret('sm', 'default');
}

//blue
@mixin caret-small-primary() {
  @include caret('sm', 'primary');
}

//white
@mixin caret-small-secondary() {
  @include caret('sm', 'secondary');
}

//grey
@mixin caret-small-disabled() {
  @include caret('sm', 'disabled');
  }
