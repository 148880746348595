
//# Toasts
.#{$vudClassPrefix}toast {
    border-width: rem(1px);
    border-style: solid;
    border-radius: $border-radius-8;
    border-color: var(--#{$vudTokenPrefix}alert-info-border);
    box-shadow: var(--#{$vudTokenPrefix}alert-info-shadow);
    color: var(--#{$vudTokenPrefix}alert-info-text);
    background: var(--#{$vudTokenPrefix}alert-info-bg);
    padding: $spacing-16 $spacing-40;
    margin-bottom: $spacing-16;
    line-height: $line-height-sm;
    max-width: rem(450px);
    display: block;
    position: absolute; 
    top: $spacing-40;
    z-index: 1001; 
    left: calc(#{'50% - ' rem(225px)});
    //position: relative;

    &:before {
        content:'';
        width: rem(16px);
        height: rem(16px);
        display: block;
        position: absolute;
        top: rem(17px);
        left: $spacing-16;
        mask: url('#{$image-path}/informative-icons/16_informative_icons_dynamic.svg');
        -webkit-mask: url('#{$image-path}/informative-icons/16_informative_icons_dynamic.svg');
        mask-position: rem(-63px) 0;
        -webkit-mask-position: rem(-63px) 0;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: auto;
        -webkit-mask-size: auto;
        background-color: var(--#{$vudTokenPrefix}icon-informative-info-bg);
        }

    .vismaicon-filled { display: none; }

    &.#{$vudClassPrefix}toast-success {
        border-color: var(--#{$vudTokenPrefix}alert-success-border);
        box-shadow: var(--#{$vudTokenPrefix}alert-success-shadow);
        background-color: var(--#{$vudTokenPrefix}alert-success-bg);
        color: var(--#{$vudTokenPrefix}alert-success-text);

        &:before {
            mask-position: 0 0;
            -webkit-mask-position: 0 0;
            background-color: var(--#{$vudTokenPrefix}icon-informative-success-bg);
        }
    }

    &.#{$vudClassPrefix}toast-error,
    &.#{$vudClassPrefix}toast-danger {
        border-color: var(--alert-danger-border);
        box-shadow: var(--#{$vudTokenPrefix}alert-danger-shadow);
        background-color: var(--#{$vudTokenPrefix}alert-danger-bg);
        color: var(--#{$vudTokenPrefix}alert-danger-text);

        &:before {
            mask-position: rem(-21px) 0;
            -webkit-mask-position: rem(-21px) 0;
            background-color: var(--#{$vudTokenPrefix}icon-informative-error-bg);
        }
    }

    &.#{$vudClassPrefix}toast-warning {
        border-color: var(--#{$vudTokenPrefix}alert-warning-border);
        box-shadow: var(--#{$vudTokenPrefix}alert-warning-shadow);
        background-color: var(--#{$vudTokenPrefix}alert-warning-bg);
        color: var(--#{$vudTokenPrefix}alert-warning-text);

        &:before {
            mask-position: rem(-42px) 0;
            -webkit-mask-position: rem(-42px) 0;
            background-color: var(--#{$vudTokenPrefix}icon-informative-warning-bg);
        }
    }

    //Close button
    .#{$vudClassPrefix}close {
        position: absolute;
        top: calc(#{'50% - ' rem(6px)});
        right: rem(17px);
    }
}
