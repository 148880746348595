//=== Welcome card

.#{$vudClassPrefix}hero-card {
    display: flex;
    flex-wrap: wrap-reverse;
    width: #{'calc(100% - ' rem(32px) ')' };
    margin:  rem(16px) rem(16px) rem(48px);

    border-radius: rem(16px);
    background-color: var(--#{$vudTokenPrefix}hero-card-bg);
    box-shadow: 0 0 0 rem(16px) var(--hero-card-border-color), 0px 20px 60px rgba(37, 38, 38, 0.1);

    [class^="col-"] {
        display: flex;
    }

    p {
        margin-bottom: rem(16px);

        font: {
            size: rem(14px);
        }
        line-height: 1.35714em;
    }

    .#{$vudClassPrefix}hero-card-content {
        display: flex;
        flex-direction: column;
        max-width: rem(564px);
        padding: rem(48px) rem(32px);

        @media (max-width: $screen-sm-min) { padding: rem(32px) rem(16px); }

        .#{$vudClassPrefix}btn-block {
            margin-top: rem(32px);
            margin-bottom: rem(32px);

            &:last-child {
                margin-bottom: 0;
            }
        }

        p + .#{$vudClassPrefix}btn-block {
            margin-top: rem(16px);
        }

        .#{$vudClassPrefix}h1 {
            font: {
                size: rem(32px);
            }
            line-height: 1.15625em;
        }
    }

    .#{$vudClassPrefix}hero-card-illustration {
        display: flex;
        position: relative;
        align-self: flex-start;
        max-width: rem(327px);
        top: rem(-32px);

        &.#{$vudClassPrefix}hero-card-illustration-lg {
            max-width: rem(465px);
        }

        img, svg, .#{$vudClassPrefix}hero-card-image {
            max-width: 100%;
        }
    }

    .#{$vudClassPrefix}card-title {
        margin-bottom: rem(16px);

        font: {
            family: $font-family-secondary;
            size: rem(32px);
            weight: $normal;
        }
        line-height: 1.15625em;
    }
}





