
// Top navigation - Product dropdown area

.#{$vudClassPrefix}navbar-default,
.#{$vudClassPrefix}navbar-primary,
.#{$vudClassPrefix}navbar-secondary {
    .#{$vudClassPrefix}navbar-header {
        .#{$vudClassPrefix}navbar-brand {
            > .#{$vudClassPrefix}dropdown-toggle { padding: $spacing-8 $spacing-24 $spacing-8 $spacing-16; }

            .#{$vudClassPrefix}dropdown-menu {
                top: 100%;
                padding: 0;
                margin-top: 0;
                z-index: $zindex-navbar-dropdown;

                /* show scroll if menu items do not fit into the screen */
                max-height: calc(100vh - rem(64px));
                overflow-y: auto;

                > li {
                    overflow: visible;
                    padding: 0;
                    box-shadow: rem(1px) 0 0 0 var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong);

                    &:hover {
                        box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}navbar-brand-item-focus-border-color);

                        > a { border-color: transparent; }
                    }

                    > a {
                        display: inline-flex;
                        align-items: center;
                        min-height: rem(64px);
                        width: 100%;
                        padding: $spacing-8 $spacing-16 $spacing-8 $spacing-16;

                        color: var(--#{$vudTokenPrefix}navigation-default-dropdown-text-color);
                        font: $font-md;

                        border-radius: 0;
                        border-top: rem(1px) solid var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong);

                        img {
                            margin-right: $spacing-16;
                            height: rem(48px);
                            width: rem(48px);
                        }

                        > span {
                            display: block;
                            width: auto;
                            white-space: normal;
                        }

                        &.active {
                            border: 0;
                            background-color: var(--module-container-active-bg-color);
                            box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}button-active-border-color);
                            position: relative;
                            overflow: visible;

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                width: rem(6px);
                                height: 100%;
                                left: 0;
                                top: 0;
                                background-color: var(--module-container-active-before-color);
                            }
                        }

                        &:focus {
                            box-shadow: none;
                            outline: rem(2px) solid var(--#{$vudTokenPrefix}navigation-secondary-item-focus-border-color);
                            outline-offset: rem(-2px);
                        }
                    }

                    .#{$vudClassPrefix}badge {
                        position: absolute;
                        right: rem(26px);
                        top:rem(20px);
                    }
                }

                > .disabled {
                    > a {
                        color: var(--#{$vudTokenPrefix}button-disabled-text-color);

                        img {
                            opacity: 0.55;
                        }
                    }
                }
            }
            
            &.open {
                .#{$vudClassPrefix}dropdown-toggle {
                    background-color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg);
                    color: var(--#{$vudTokenPrefix}titles-font-color);

                    &:hover {
                        background-color: var(--#{$vudTokenPrefix}dropdown-menu-hover-bg);
                        box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}button-hover-border-color);
                    }

                    .#{$vudClassPrefix}caret {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

.#{$vudClassPrefix}navbar-default,
.#{$vudClassPrefix}navbar-primary,
.#{$vudClassPrefix}navbar-secondary {
    &.#{$vudClassPrefix}navbar.is-mobile {
        .#{$vudClassPrefix}navbar-collapse{
            .first-level {
                .menudrop {
                    &.open {
                        > .#{$vudClassPrefix}dropdown-menu {
                            .#{$vudClassPrefix}navbar-brand {
                                .dropped-apps-toggle {
                                    display: block;
                                    color: var(--#{$vudTokenPrefix}anchor-color);
                                    padding-left: rem(50px);
                                    position: relative;
                                    box-shadow: none;

                                    > .#{$vudClassPrefix}caret {
                                        background-color: var(--#{$vudTokenPrefix}caret-primary-bg);
                                        left: rem(27px);
                                        top: rem(17px);
                                        transform: rotate(90deg);
                                    }
                                }
                                
                                &.is-open {
                                    .dropped-apps-toggle {
                                        padding-left: rem(30px);

                                        > .#{$vudClassPrefix}caret {
                                            transform: rotate(270deg);
                                            position: relative;
                                            left: auto;
                                            top: rem(-2px);
                                            right: rem(-15px);
                                        }
                                    }
                                }

                                .#{$vudClassPrefix}dropdown-menu {
                                    li.active { display: none; }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}
