
//# Alert
.#{$vudClassPrefix}alert {
    position: relative;
    min-height: rem(67px);
    padding: $spacing-24 rem(72px) $spacing-24 rem(52px);
    margin-bottom: $spacing-16;

    line-height: $line-height-sm;
    color: var(--#{$vudTokenPrefix}alert-info-text);

    border-radius: $border-radius;
    border-width: rem(1px);
    border-style: solid;
    border-color: var(--#{$vudTokenPrefix}alert-info-border);
    background: var(--#{$vudTokenPrefix}alert-info-bg);
    box-shadow: var(--#{$vudTokenPrefix}alert-info-shadow);


    @media(max-width: $screen-md-min) {
        padding-right: $spacing-48;
    }

    &:before {
        content:'';
        width: rem(16px);
        height: rem(16px);
        display: block;
        position: absolute;
        top: rem(26px);
        left: $spacing-24;
        background-color: var(--#{$vudTokenPrefix}icon-informative-info-bg);

        mask: url('#{$image-path}/informative-icons/16_informative_icons_dynamic.svg');
        -webkit-mask: url('#{$image-path}/informative-icons/16_informative_icons_dynamic.svg');
        mask-position: rem(-63px) 0;
        -webkit-mask-position: rem(-63px) 0;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: auto rem(16px);
        -webkit-mask-size: auto rem(16px);
        }

    .vismaicon-filled { display: none; }

    .#{$vudClassPrefix}btn,
    > p:last-child { margin-bottom: 0; }

    a {
        color: var(--#{$vudTokenPrefix}anchor-color);
    }

    h1, h2, h3, h4, h5, h6 {
        font: $font-sm;
        font-weight: $bold;
        line-height: 1.6;

        &:last-child{
            margin-bottom: 0;
        }
    }

    // a, i, em, b, strong {
    //     display: contents;
    // }

    // &.alert-success { padding-right: $spacing-128; }

    &.#{$vudClassPrefix}alert-success {
        border-color: var(--#{$vudTokenPrefix}alert-success-border);
        background: var(--#{$vudTokenPrefix}alert-success-bg);
        color: var(--#{$vudTokenPrefix}alert-success-text);
        box-shadow: var(--#{$vudTokenPrefix}alert-success-shadow);

        &:before {
            mask-position: 0 0;
            -webkit-mask-position: 0 0;
            background-color: var(--#{$vudTokenPrefix}icon-informative-success-bg);
        }
    }

    &.#{$vudClassPrefix}alert-warning { 
        border-color: var(--#{$vudTokenPrefix}alert-warning-border);
        background: var(--#{$vudTokenPrefix}alert-warning-bg);
        color: var(--#{$vudTokenPrefix}alert-warning-text);
        box-shadow: var(--#{$vudTokenPrefix}alert-warning-shadow);

        &:before { 
            mask-position: rem(-42px) 0;
            -webkit-mask-position: rem(-42px) 0;
            background-color: var(--#{$vudTokenPrefix}icon-informative-warning-bg);
        }
    }

    &.#{$vudClassPrefix}alert-error,
    &.#{$vudClassPrefix}alert-danger {
        border-color: var(--alert-danger-border);
        background: var(--#{$vudTokenPrefix}alert-danger-bg);
        color: var(--#{$vudTokenPrefix}alert-danger-text);
        box-shadow: var(--#{$vudTokenPrefix}alert-danger-shadow);

        &::before {
            mask-position: rem(-21px) 0;
            -webkit-mask-position: rem(-21px) 0;
            background-color: var(--#{$vudTokenPrefix}icon-informative-error-bg);
        }
    }


    //Close button
    .#{$vudClassPrefix}close {
        position: absolute;
        top: rem(26px);
        right: $spacing-24;
    }


    // Small
    &.#{$vudClassPrefix}alert-sm {
        min-height: rem(52px);
        padding-top: $spacing-16;
        padding-bottom: $spacing-16;

        &::before,
        .#{$vudClassPrefix}close { top: rem(17px); }
    }

    // Medium size icon
    &.#{$vudClassPrefix}alert-icon-md {
        min-height: rem(84px);
        padding: $spacing-24 rem(88px) $spacing-24 rem(72px);

        &::before {
            width: rem(32px);
            height: rem(32px);
            left: $spacing-24;

            mask-size: auto rem(32px);
            -webkit-mask-size: auto rem(32px);
        }

        &.#{$vudClassPrefix}alert-info::before {
            mask-position: rem(-126px) 0;
            -webkit-mask-position: rem(-126px) 0;
        }

        &.#{$vudClassPrefix}alert-warning::before {
            mask-position: rem(-84px) 0;
            -webkit-mask-position: rem(-84px) 0;
        }

        &.#{$vudClassPrefix}alert-danger::before,
        &.#{$vudClassPrefix}alert-error::before {
            mask-position: rem(-42px) 0;
            -webkit-mask-position: rem(-42px) 0;
        }
    }

    // Large size icon
    &.#{$vudClassPrefix}alert-icon-lg {
        min-height: rem(100px);
        padding: $spacing-24 rem(88px) $spacing-24 rem(96px);

        &::before {
            width: rem(48px);
            height: rem(48px);
            left: $spacing-24;

            mask-size: auto rem(48px);
            -webkit-mask-size: auto rem(48px);
        }

        &.#{$vudClassPrefix}alert-info::before {
            mask-position: rem(-189px) 0;
            -webkit-mask-position: rem(-189px) 0;
        }

        &.#{$vudClassPrefix}alert-warning::before {
            mask-position: rem(-126px) 0;
            -webkit-mask-position: rem(-126px) 0;
        }

        &.#{$vudClassPrefix}alert-danger::before,
        &.#{$vudClassPrefix}alert-error::before {
            mask-position: rem(-63px) 0;
            -webkit-mask-position: rem(-63px) 0;
        }
    }

}
