// Cards list - .card-list-default

//*** scss local variable ***
$card-list-default-item-hover-border-color: var(--#{$vudTokenPrefix}button-hover-border-color);
$card-list-default-item-selected-border-color: var(--#{$vudTokenPrefix}button-active-border-color);
$card-list-default-item-active-border-color: var(--module-container-active-before-color);

$card-list-default-item-divider-bg: var(--module-container-divider);

$card-list-default-item-shadow: var(--module-container-shadow);

//*** element/component code ***
.#{$vudClassPrefix}card-list {
    &.#{$vudClassPrefix}card-list-default {
        &.#{$vudClassPrefix}card-list-hover {
            .#{$vudClassPrefix}card-list-item {
                &:hover, &.hover {
                    box-shadow: inset 0 0 0 rem(1px) $card-list-default-item-hover-border-color, $card-list-default-item-shadow;
                }
            }
        }

        .#{$vudClassPrefix}card-list-item {
            padding: $spacing-8 $spacing-16;

            box-shadow: $card-list-default-item-shadow;
            overflow: hidden;

            &.selected, &.active {
                box-shadow: inset 0 0 0 rem(1px) $card-list-default-item-selected-border-color, $card-list-default-item-shadow;
            }

            &:before {
                display: block;
                content:'';
                position: absolute;
                width: rem(5px);
                height: 100%;
                left: 0;
                top: 0;

                background-color: $card-list-default-item-active-border-color;
            }
        }
    }
}
