
// Button pills/facets

//*** scss local variable ***
// pills/facets default theme
$facet-gap:                              $spacing-16;
$facet-lg-gap:                           $spacing-16;

$facet-inner-space:                      $spacing-4 $spacing-16;
$facet-lg-inner-space:                   ($spacing-12 - rem(1px)) $spacing-24;

// $facet-line-height:                      $spacing-16;
$facet-lg-line-height:                   $spacing-24;

$facet-text-color:                       var(--#{$vudTokenPrefix}button-text-color);
$facet-disabled-text-color:              var(--#{$vudTokenPrefix}button-disabled-text-color);

$facet-border-color:                     var(--#{$vudTokenPrefix}button-border-color);
$facet-hover-border-color:               var(--#{$vudTokenPrefix}button-hover-border-color);
$facet-focus-border-color:               var(--#{$vudTokenPrefix}button-focus-border-color);
$facet-pressed-border-color:             var(--#{$vudTokenPrefix}button-pressed-border-color);
$facet-active-border-color:              var(--#{$vudTokenPrefix}button-active-border-color);
$facet-disabled-border-color:            var(--#{$vudTokenPrefix}button-disabled-border-color);

$facet-bg:                               var(--#{$vudTokenPrefix}button-bg);
$facet-hover-bg:                         var(--#{$vudTokenPrefix}button-hover-bg);
$facet-pressed-bg:                       var(--#{$vudTokenPrefix}button-pressed-bg);
$facet-active-bg:                        var(--#{$vudTokenPrefix}button-active-bg);
$facet-disabled-bg:                      var(--#{$vudTokenPrefix}button-disabled-bg);

$facet-shadow-color:                     var(--button-shadow-color);
$facet-hover-shadow-color:               var(--button-hover-shadow-color);
$facet-focus-shadow-color:               var(--button-focus-shadow-color);

// pills/facets primary/alternative theme
$facet-primary-text-color:               var(--#{$vudTokenPrefix}button-primary-text-color);

$facet-primary-border-color:             var(--#{$vudTokenPrefix}facet-primary-bg);
$facet-primary-hover-border-color:       var(--#{$vudTokenPrefix}facet-primary-bg);
$facet-primary-focus-border-color:       var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$facet-primary-focus-inner-border-color: var(--button-primary-focus-inner-shadow-color);

$facet-primary-bg:                       var(--#{$vudTokenPrefix}facet-primary-bg);
$facet-primary-hover-bg:                 var(--#{$vudTokenPrefix}facet-primary-bg);
$facet-primary-tint-hover-bg:            linear-gradient(0deg, rgba($black, 0.12), rgba($black, 0.12));

//*** element/component code ***
.#{$vudClassPrefix}nav {
    //
    &.#{$vudClassPrefix}nav-pills {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $facet-gap;
        margin: 0;
        padding: 0;

        list-style: none;

        li {
            display: flex;
        }

        //## Base style for a button pills
        a, button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: $input-btn-height;
            padding: $facet-inner-space;
            margin: 0;
            min-width: rem(128px);
            text-align: center;
            color: $facet-text-color;
            font: {
                size: $font-size-sm;
                weight: $normal;
            }
            text-decoration: none;

            outline: 0;
            border: {
                radius: $border-radius-16;
                width: rem(1px);
                style: solid;
                color: $facet-border-color;
            }
            background-color: $facet-bg;
            // box-shadow: $shadow-20 $facet-shadow-color;
            box-shadow: var(--#{$vudTokenPrefix}shadow-20);

            //### pills hover state
            &:hover {
                border-color: $facet-hover-border-color;
                background-color: $facet-hover-bg;
                // box-shadow: $shadow-20 $facet-hover-shadow-color;
                box-shadow: var(--#{$vudTokenPrefix}shadow-20);
            }

            //### pills focus state
            &:focus {
                border-color: $facet-focus-border-color;
                // box-shadow: inset 0 0 0 rem(1px) $facet-focus-border-color, $shadow-20 $facet-focus-shadow-color;
                box-shadow: inset 0 0 0 rem(1px) $facet-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);
            }

            //### pills active/pressed state
            // &:active {
            //     &, &.active {
            //         border-color: $facet-active-border-color;
            //         background-color: $facet-active-bg;
            //         box-shadow: none;
            //     }
            // }

            //### pills pressed state
            &:active, &.active:active, &.pressed, &.pressed:active {
                border-color: $facet-pressed-border-color;
                background-color: $facet-pressed-bg;
                box-shadow: none;
            }
        }

        //### pills active/selected state
        .active a, a.active,
        .active button,
        button.active {
            border-color: $facet-active-border-color;
            background-color: $facet-active-bg;
            box-shadow: none;

            //### pills active/selected and hover state
            &:hover {
                border-color: $facet-hover-border-color;
                background-color: $facet-hover-bg;
            }

            //### pills active/selected and pressed state
            &:active {
                border-color: $facet-pressed-border-color;
                background-color: $facet-pressed-bg;
                box-shadow: none;
            }

            //### pills active/selected focus and not pressed state
            &:focus:not(:active) {
                border-color: $facet-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $facet-focus-border-color;
            }
        }

        //### pills disabled state
        .disabled a,
        a.disabled,
        .disabled button,
        button.disabled,
        button:disabled {
            cursor: default;
            pointer-events: none;

            color: $facet-disabled-text-color;

            border-color: $facet-disabled-border-color;
            background-color: $facet-disabled-bg;
            box-shadow: none;
        }


        //# Alternative styling for pill/facet buttons
        &.#{$vudClassPrefix}nav-pills-primary {
            .active a,
            a.active,
            .active button,
            button.active {
                color: $facet-primary-text-color;

                border-color: $facet-primary-border-color;
                background-color: $facet-primary-bg;

                //### pills active/selected and hover state
                &:hover {
                    border-color: $facet-primary-hover-border-color;
                    background-color: $facet-primary-hover-bg;
                    background-image: $facet-primary-tint-hover-bg;
                }

                //### pills active/selected and pressed state
                &:active {
                    border-color: $facet-pressed-border-color;
                    background-color: $facet-pressed-bg;
                    background-image: none;
                    box-shadow: none;
                    color: $facet-text-color;
                }

                //### pills active/selected focus and not pressed state
                &:focus:not(:active) {
                    border-color: $facet-primary-focus-border-color;
                    box-shadow: inset 0 0 0 rem(1px) $facet-primary-focus-border-color, inset 0 0 0 rem(2px) $facet-primary-focus-inner-border-color, var(--#{$vudTokenPrefix}shadow-20);

                    //### pills active/selected and hover state
                    &:hover {
                        box-shadow: inset 0 0 0 .1rem $facet-primary-focus-border-color, inset 0 0 0 .2rem $facet-primary-focus-inner-border-color, 0 .2rem .4rem 0 $facet-focus-shadow-color;
                    }
                }

                &:disabled, &.disabled {
                    color: $facet-disabled-text-color;

                    border-color: $facet-disabled-border-color;
                    background-color: $facet-disabled-bg;
                }
            }
        }


        //# Larger styling for pill/facet buttons
        &.#{$vudClassPrefix}nav-pills-lg {
            gap: $facet-lg-gap;

            a, button {
                padding: $facet-lg-inner-space;
                border-radius: $border-radius-24;
                line-height: $facet-lg-line-height;
                height: rem(48px);
            }
        }


        //# Disabled state for all pills in wrapper ('.nav.nav-pills')
        :disabled &, .disabled &,
        &:disabled, &.disabled {
            a, button {
                cursor: default;
                pointer-events: none;

                color: $facet-disabled-text-color;

                border-color: $facet-disabled-border-color;
                background-color: $facet-disabled-bg;
                box-shadow: none;
            }

            //# Alternative styling for pill/facet buttons
            &.#{$vudClassPrefix}nav-pills-primary {
                .active a,
                a.active,
                .active button,
                button.active {
                    color: $facet-disabled-text-color;

                    border-color: $facet-disabled-border-color;
                    background-color: $facet-disabled-bg;
                }
            }
        }
    }
}
