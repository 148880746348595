
// Top navigation - items from the top nav with icons intead of text (right side of the nav) 
.vismaicon.vismaicon-menu {
    &:before {
        background-color: var(--#{$vudTokenPrefix}navigation-default-icon-bg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-size: cover;
    }
}

.#{$vudClassPrefix}navbar-secondary {
    .vismaicon {
        &::before { background-color: var(--#{$vudTokenPrefix}navigation-secondary-icon-bg) }
    }
}
