// VUD variables
//
//=== Table of Contents:
//
//  1. Imports
//  2. Color and shadow maps
//  3. Opacity
//  4. Path variables
//  5. Typography
//  6. Responsivness
//  7. Grids
//  8. Z-index variables
//  9. Icons/svg mask/paterns
// 10. Spacings - Margins and paddings



//=== 1. Imports 

//# Functions and Mixins
@use "sass:list";

@import '../utilities/functions';
@import '../utilities/mixins';

// @import "../../tokens/scss/variables-light";
// @import "../../tokens/scss/variables-dark";

//=== VUD prefix variables
$vudPrefix: '' !default;
$vudClassPrefix: $vudPrefix !default;
$vudTokenPrefix: $vudPrefix !default;
$backupVudTokenPrefix: $vudPrefix !default;

//=== 2. Color palette

//# Maps
//### Color map
$color-map: (
    white: #ffffff,
    black: #000000,
    purple: (
        palette: (
            90: #403380,
            80: #4d3d99,
            70: #6154a5,
            60: #7365b8,
            50: #8a80bd,
            40: #9e96c8,
            30: #b2abd3,
            20: #c6c1df,
            10: #dad7ea,
            05: #edecf5
        ),
        primary: #4d3d99 // $purple-80
    ),
    blue: (
        palette: (
            90: #1b384c,
            80: #14476a,
            70: #0d5788,
            60: #116fae,
            50: #1482cc,
            40: #419bd7,
            30: #6cb2e1,
            20: #99cbec,
            10: #d2eafa,
            05: #e7f2f9
        ),
        primary: #116fae // $blue-60
    ),          
    green: (
        palette: (
            90: #254934,
            80: #316245,
            70: #2d7048,
            60: #4e9c54,
            50: #67a870,
            40: #7faf7f,
            30: #9bc29b,
            20: #afd4af,
            10: #cfe7cf,
            05: #ebf7eb
        ),
        primary: #2d7048 // $green-70
    ),
    red: (
        palette: (
            90: #801f30,
            80: #a12036,
            70: #cc334c,
            60: #d44f64,
            50: #db6b7d,
            40: #e28594,
            30: #eaa1ac,
            20: #f1bdc4,
            10: #fad9de,
            05: #ffedef
        ),
        primary: #cc334c // $red-70
    ),
    orange: (
        palette: (
            90: #803b00,
            80: #b35300,
            70: #c26800,
            60: #CA7900,
            50: #e5a23e,
            40: #edb45f,
            30: #f2c480,
            20: #fad7a2,
            10: #fcdfb1,
            05: #fcf2dc
        ),
        primary: #803b00 // $orange-90
    ),
    neutral: (
        light: (
            palette: (
                90: #252626,
                80: #494a4a,
                70: #6b6b6b,
                60: #8a8a8a,
                50: #a5a5a5,
                40: #c0c0c0,
                30: #dadada,
                20: #f2f2f2,
                10: #f7f7f7,
                05: #ffffff // $white
            ),
            primary: #252626 // $neutral-90
        ),
        dark: (
            palette: (
                90: #000000,
                80: #15181a,
                70: #2b3033,
                60: #4c545b,
                50: #6f7a82,
                40: #86929b,
                30: #9aa5ae,
                20: #afbcc4,
                10: #c3d0d9,
                05: #e4ebf0
            ),
            primary: #2b3033 // $neutral-70
        )
    )
  ) !default;



//### Shadow map
$shadow-map: (
    50: (
        distance: 15px,
        size: 40px
    ),
    40: (
        distance: 10px,
        size: 15px
    ),
    30: (
        distance: 5px,
        size: 10px
    ),
    20: (
        distance: 2px,
        size: 4px
    ),
    10: (
        distance: 1px,
        size: 1px
    )
) !default;



//# Main colors palette
//#
// $white: get-map-value($color-map, white) !default;
// $black: get-map-value($color-map, black) !default;
$white: white !default;
$black: black !default;


//### Purple palette
$purple-90: get-map-value($color-map, purple, palette, 90) !default;
$purple-80: get-map-value($color-map, purple, palette, 80) !default;
$purple-70: get-map-value($color-map, purple, palette, 70) !default;
$purple-60: get-map-value($color-map, purple, palette, 60) !default;
$purple-50: get-map-value($color-map, purple, palette, 50) !default;
$purple-40: get-map-value($color-map, purple, palette, 40) !default;
$purple-30: get-map-value($color-map, purple, palette, 30) !default;
$purple-20: get-map-value($color-map, purple, palette, 20) !default;
$purple-10: get-map-value($color-map, purple, palette, 10) !default;
$purple-05: get-map-value($color-map, purple, palette, 05) !default;

$primary-purple: get-map-value($color-map, purple, primary) !default;
$purple-primary: get-map-value($color-map, purple, primary) !default;


//### Blue palette
$blue-90: get-map-value($color-map, blue, palette, 90) !default;
$blue-80: get-map-value($color-map, blue, palette, 80) !default;
$blue-70: get-map-value($color-map, blue, palette, 70) !default;
$blue-60: get-map-value($color-map, blue, palette, 60) !default;
$blue-50: get-map-value($color-map, blue, palette, 50) !default;
$blue-40: get-map-value($color-map, blue, palette, 40) !default;
$blue-30: get-map-value($color-map, blue, palette, 30) !default;
$blue-20: get-map-value($color-map, blue, palette, 20) !default;
$blue-10: get-map-value($color-map, blue, palette, 10) !default;
$blue-05: get-map-value($color-map, blue, palette, 05) !default;

$primary-blue: get-map-value($color-map, blue, primary) !default;
$blue-primary: get-map-value($color-map, blue, primary) !default;


//### Green palette
$green-90: get-map-value($color-map, green, palette, 90) !default;
$green-80: get-map-value($color-map, green, palette, 80) !default;
$green-70: get-map-value($color-map, green, palette, 70) !default;
$green-60: get-map-value($color-map, green, palette, 60) !default;
$green-50: get-map-value($color-map, green, palette, 50) !default;
$green-40: get-map-value($color-map, green, palette, 40) !default;
$green-30: get-map-value($color-map, green, palette, 30) !default;
$green-20: get-map-value($color-map, green, palette, 20) !default;
$green-10: get-map-value($color-map, green, palette, 10) !default;
$green-05: get-map-value($color-map, green, palette, 05) !default;

$primary-green: get-map-value($color-map, green, primary) !default;


//### Red palette
$red-90: get-map-value($color-map, red, palette, 90) !default;
$red-80: get-map-value($color-map, red, palette, 80) !default;
$red-70: get-map-value($color-map, red, palette, 70) !default;
$red-60: get-map-value($color-map, red, palette, 60) !default;
$red-50: get-map-value($color-map, red, palette, 50) !default;
$red-40: get-map-value($color-map, red, palette, 40) !default;
$red-30: get-map-value($color-map, red, palette, 30) !default;
$red-20: get-map-value($color-map, red, palette, 20) !default;
$red-10: get-map-value($color-map, red, palette, 10) !default;
$red-05: get-map-value($color-map, red, palette, 05) !default;

$primary-red: get-map-value($color-map, red, primary) !default;


//### Orange palette
$orange-90: get-map-value($color-map, orange, palette, 90) !default;
$orange-80: get-map-value($color-map, orange, palette, 80) !default;
$orange-70: get-map-value($color-map, orange, palette, 70) !default;
$orange-60: get-map-value($color-map, orange, palette, 60) !default;
$orange-50: get-map-value($color-map, orange, palette, 50) !default;
$orange-40: get-map-value($color-map, orange, palette, 40) !default;
$orange-30: get-map-value($color-map, orange, palette, 30) !default;
$orange-20: get-map-value($color-map, orange, palette, 20) !default;
$orange-10: get-map-value($color-map, orange, palette, 10) !default;
$orange-05: get-map-value($color-map, orange, palette, 05) !default;

$primary-orange: get-map-value($color-map, orange, primary) !default;


//# Neutral color palette
//### Light mode palette
$neutral-light-90: get-map-value($color-map, neutral, light, palette, 90) !default;
$neutral-light-80: get-map-value($color-map, neutral, light, palette, 80) !default;
$neutral-light-70: get-map-value($color-map, neutral, light, palette, 70) !default;
$neutral-light-60: get-map-value($color-map, neutral, light, palette, 60) !default;
$neutral-light-50: get-map-value($color-map, neutral, light, palette, 50) !default;
$neutral-light-40: get-map-value($color-map, neutral, light, palette, 40) !default;
$neutral-light-30: get-map-value($color-map, neutral, light, palette, 30) !default;
$neutral-light-20: get-map-value($color-map, neutral, light, palette, 20) !default;
$neutral-light-10: get-map-value($color-map, neutral, light, palette, 10) !default;
$neutral-light-05: get-map-value($color-map, neutral, light, palette, 05) !default;

$primary-neutral-light: get-map-value($color-map, neutral, light, primary) !default;


//### Dark mode palette
$neutral-dark-90: get-map-value($color-map, neutral, dark, palette, 90) !default;
$neutral-dark-80: get-map-value($color-map, neutral, dark, palette, 80) !default;
$neutral-dark-70: get-map-value($color-map, neutral, dark, palette, 70) !default;
$neutral-dark-60: get-map-value($color-map, neutral, dark, palette, 60) !default;
$neutral-dark-50: get-map-value($color-map, neutral, dark, palette, 50) !default;
$neutral-dark-40: get-map-value($color-map, neutral, dark, palette, 40) !default;
$neutral-dark-30: get-map-value($color-map, neutral, dark, palette, 30) !default;
$neutral-dark-20: get-map-value($color-map, neutral, dark, palette, 20) !default;
$neutral-dark-10: get-map-value($color-map, neutral, dark, palette, 10) !default;
$neutral-dark-05: get-map-value($color-map, neutral, dark, palette, 05) !default;

$primary-neutral-dark: get-map-value($color-map, neutral, dark, primary) !default;


//### Neutral palette
$neutral-90: $neutral-light-90 !default;
$neutral-80: $neutral-light-80 !default;
$neutral-70: $neutral-light-70 !default;
$neutral-60: $neutral-light-60 !default;
$neutral-50: $neutral-light-50 !default;
$neutral-40: $neutral-light-40 !default;
$neutral-30: $neutral-light-30 !default;
$neutral-20: $neutral-light-20 !default;
$neutral-10: $neutral-light-10 !default;
$neutral-05: $neutral-light-05 !default;

$primary-neutral: $primary-neutral-light !default;



//=== 3. Opacity
$opacity-50: 0 !default;
$opacity-40: 0.50 !default;
$opacity-30: 0.25 !default;
$opacity-20: 0.10 !default;
$opacity-10: 0.05 !default;



//=== 4. Path variables
$prefix-path: '../..' !default;
$image-path: '#{$prefix-path}/img' !default;
$font-path: '#{$prefix-path}/fonts' !default;
$icons-path: '#{$prefix-path}/img/vismaicons' !default; // DEPRECATED - this will be removed, we don't have the icons in the VUD library, more details here: https://ux.visma.com/weblibrary/latest/development/documentation/docs/visma-icons.php
$tabs-graph-path: $image-path + '/tabs' !default;



//=== 5. Typography 
$line-height-base: 1.4 !default;
$font-weight-base: normal !default;
$font-family-base: 'Open Sans', 'OpenSansFallback', sans-serif !default;
$font-family-secondary: 'Ubuntu', 'UbuntuFallback', sans-serif !default;
$font-family-secondary-light: 'Ubuntu', 'UbuntuFallback', sans-serif !default;
$font-family-secondary-semibold: 'Ubuntu', 'UbuntuFallback', sans-serif !default;
$font-family-secondary-bold: 'Ubuntu', 'UbuntuFallback', sans-serif !default;

//### Main font-size
// base/html font size
$nc4-units: 'rem' !default; // DEPRECATED - will be replaced by $vud-units
$vud-units: 'rem' !default;
$html-font-size: 62.5% !default; // --> 1rem = 10px
$body-font-size: rem(14px) !default;
$font-size-base: rem(14px) !default;

// font-weight
$bold: 700 !default;
$semibold: 600 !default;
$normal: 400 !default;
$thin: 200 !default;

// font-size
$font-size-xl: rem(24px) !default;
$font-size-lg: rem(20px) !default;
$font-size-md: rem(16px) !default;
$font-size-sm: rem(14px) !default;
$font-size-xs: rem(13px) !default;

// line-height
$line-height-xl: 1.6 !default;
$line-height-lg: 1.5 !default;
$line-height-md: 1.4 !default;
$line-height-sm: 1.3 !default;
$line-height-xs: 1.2 !default;

// font-size/line-height font-family
$font-xl: list.slash(rem(24px), 1.6) $font-family-base !default;
$font-lg: list.slash(rem(20px), 1.5) $font-family-base !default;
$font-md: list.slash(rem(16px), 1.4) $font-family-base !default;
$font-sm: list.slash(rem(14px), 1.3) $font-family-base !default;
$font-xs: list.slash(rem(13px), 1.2) $font-family-base !default;



//=== 6. Responsivness
// main screen sizes
$screen-xxl-min: 1680px !default;
$screen-xl-min: 1440px !default;
$screen-lg-min: 1280px !default;
$screen-md-min: 992px !default;
$screen-sm-min: 769px !default;
$screen-xs-min: 550px !default;
 
$screen-xxl-max: 1679px !default;
$screen-xl-max: 1439px !default;
$screen-lg-max: 1279px !default;
$screen-md-max: 991px !default;
$screen-sm-max: 768px !default;
$screen-xs-max: 549px !default;



//=== 7. Grids
// Grid breakpoints
$grid-breakpoints: (
    xxs: 0,
    xs: $screen-xs-min,
    sm: $screen-sm-min,
    md: $screen-md-min,
    lg: $screen-lg-min,
    xl: $screen-xl-min,
    xxl: $screen-xxl-min
) !default;

// Grid containers
$container-max-widths: (
    xxs: 100%, 
    xs: 90%,
    sm: 90%,
    md: 90%,
    lg: $screen-lg-min - 32px,
    xl: $screen-xl-min - 32px,
    xxl: $screen-xxl-min - 32px
    ) !default;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns:                12 !default;
$grid-gutter-width:           rem(32px) !default;

// Grid options - Quickly modify global styling by enabling or disabling optional features.
$enable-grid-classes:       true !default;



//=== 8. Z-index variables
$zindex-button-active:        5 !default;
$zindex-button-focus:        10 !default;
$zindex-navbar-header:      900 !default;
$zindex-dropdown-backdrop:  990 !default;
$zindex-dropdown:          1000 !default;
$zindex-navbar:            1000 !default;
$zindex-navbar-dropdown:   1010 !default;
$zindex-fixed:             1030 !default;
$zindex-sticky:            1030 !default;
$zindex-modal-backdrop:    1040 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;



//=== 9. Icons/svg mask/patern
// arrow in css
$arrow-icon-small: url(#{$image-path}/arrow-small.svg);
$arrow-icon-large: url(#{$image-path}/arrow-large.svg);
$arrow-icon-medium: $arrow-icon-large; // this is deprecated - will be removed in time, until then is replaced with arrow-large

// search icon
$search-icon: url(#{$image-path}/search.svg);

// close "X" icon
$close-icon: url(#{$image-path}/close.svg);
$close-icon-lg: url(#{$image-path}/close-large.svg);

// check-mark patern // primary-neutral
$check-mark-light: url(#{$image-path}/stepper-checkmark.svg);
$check-mark-dark: url(#{$image-path}/stepper-checkmark-dark.svg);

// squerkle patern
$squerkle-patern: url(#{$image-path}/squerkle-mask.svg);
$squerkle-sm-patern-border-thick: url(#{$image-path}/squerkle-sm-border-thick.svg);
$squerkle-sm-patern-border-thin: url(#{$image-path}/squerkle-sm-border-thin.svg);
$squerkle-patern-border-thick: url(#{$image-path}/squerkle-border-thick.svg);
$squerkle-patern-border-thin: url(#{$image-path}/squerkle-border-thin.svg);



//=== 10. Spacings - Margins and paddings
$enable-negative-margins:     true !default;

$spacing: rem(10px) !default;
$spacings-map: (
  0: 0,
  2: $spacing * .2,
  4: $spacing * .4,
  6: $spacing * .6,
  8: $spacing * .8,
  10: $spacing * 1,
  12: $spacing * 1.2,
  16: $spacing * 1.6,
  24: $spacing * 2.4,
  32: $spacing * 3.2,
  40: $spacing * 4.0,
  48: $spacing * 4.8,
  64: $spacing * 6.4,
  96: $spacing * 9.6,
  128: $spacing *12.8,
  auto: auto,
) !default;

$negative-spacings: if($enable-negative-margins, negativify-map($spacings-map), null) !default;
