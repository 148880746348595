
// VUD variables
//
//=== Table of Contents:
//
//  1. Core CSS Variables
//    # a. Color palette
//    # b. Icons
//    # c. Typography
//
//  2. Light mode CSS Variables (mixin)
//    # a. Color palette - Neutral palette
//    # b. Base body properties
//    # c. Typography
//    # d. Buttons
//    # e. Icons
//    # f. Badges
//    # g. List groups
//    # h. Navigation
//    # i. Vertical navigation
//    # j. Modals
//    # k. Forms
//         - inputs
//         - switches
//         - radio-checkbox
//    # l. Images
//    # m. Table
//    # n. Stepper
//    # o.
//
//  3. Dark mode CSS Variables (mixin)
//    # a. Color palette - Neutral palette
//    # b. Base body properties
//    # c. Typography
//    # d. Buttons
//    # e. Icons
//    # f. Badges
//    # g. List groups
//    # h. Navigation
//    # i. Vertical navigation
//    # j. Modals
//    # k. Forms
//         - inputs
//         - switches
//         - radio-checkbox
//    # l. Images
//    # m. Table
//    # n. Stepper
//    # o. Progress Bar


//=== Aditional SCSS Variables
@import '../variables';
//== Your custom scss overwrite variables
@import '../custom-variables';

@import "../../../tokens/scss/variables-light";
@import "../../../tokens/scss/variables-dark";

/* Setup a few global scope properties */
:root, :host {
  //=== 1. Core CSS Variables
  //# a. Color palette
  --#{$vudTokenPrefix}white: #{$white};
  --#{$vudTokenPrefix}black: #{$black};


  //### Purple palette
  --#{$vudTokenPrefix}purple-90: #{$light-vud-purple-90};
  --#{$vudTokenPrefix}purple-80: #{$light-vud-purple-80};
  --#{$vudTokenPrefix}purple-70: #{$light-vud-purple-70};
  --#{$vudTokenPrefix}purple-60: #{$light-vud-purple-60};
  --#{$vudTokenPrefix}purple-50: #{$light-vud-purple-50};
  --#{$vudTokenPrefix}purple-40: #{$light-vud-purple-40};
  --#{$vudTokenPrefix}purple-30: #{$light-vud-purple-30};
  --#{$vudTokenPrefix}purple-20: #{$light-vud-purple-20};
  --#{$vudTokenPrefix}purple-10: #{$light-vud-purple-10};
  --#{$vudTokenPrefix}purple-05: #{$light-vud-purple-05};

  --#{$vudTokenPrefix}primary-purple: var(--#{$vudTokenPrefix}purple-80);


  //### Blue palette
  --#{$vudTokenPrefix}blue-90: #{$light-vud-blue-90};
  --#{$vudTokenPrefix}blue-80: #{$light-vud-blue-80};
  --#{$vudTokenPrefix}blue-70: #{$light-vud-blue-70};
  --#{$vudTokenPrefix}blue-60: #{$light-vud-blue-60};
  --#{$vudTokenPrefix}blue-50: #{$light-vud-blue-50};
  --#{$vudTokenPrefix}blue-40: #{$light-vud-blue-40};
  --#{$vudTokenPrefix}blue-30: #{$light-vud-blue-30};
  --#{$vudTokenPrefix}blue-20: #{$light-vud-blue-20};
  --#{$vudTokenPrefix}blue-10: #{$light-vud-blue-10};
  --#{$vudTokenPrefix}blue-05: #{$light-vud-blue-05};

  --#{$vudTokenPrefix}primary-blue: var(--#{$vudTokenPrefix}blue-60);


  //### Green palette
  --#{$vudTokenPrefix}green-90: #{$light-vud-green-90};
  --#{$vudTokenPrefix}green-80: #{$light-vud-green-80};
  --#{$vudTokenPrefix}green-70: #{$light-vud-green-70};
  --#{$vudTokenPrefix}green-60: #{$light-vud-green-60};
  --#{$vudTokenPrefix}green-50: #{$light-vud-green-50};
  --#{$vudTokenPrefix}green-40: #{$light-vud-green-40};
  --#{$vudTokenPrefix}green-30: #{$light-vud-green-30};
  --#{$vudTokenPrefix}green-20: #{$light-vud-green-20};
  --#{$vudTokenPrefix}green-10: #{$light-vud-green-10};
  --#{$vudTokenPrefix}green-05: #{$light-vud-green-05};

  --#{$vudTokenPrefix}primary-green: var(--#{$vudTokenPrefix}green-70);


  //### Red palette
  --#{$vudTokenPrefix}red-90: #{$light-vud-red-90};
  --#{$vudTokenPrefix}red-80: #{$light-vud-red-80};
  --#{$vudTokenPrefix}red-70: #{$light-vud-red-70};
  --#{$vudTokenPrefix}red-60: #{$light-vud-red-60};
  --#{$vudTokenPrefix}red-50: #{$light-vud-red-50};
  --#{$vudTokenPrefix}red-40: #{$light-vud-red-40};
  --#{$vudTokenPrefix}red-30: #{$light-vud-red-30};
  --#{$vudTokenPrefix}red-20: #{$light-vud-red-20};
  --#{$vudTokenPrefix}red-10: #{$light-vud-red-10};
  --#{$vudTokenPrefix}red-05: #{$light-vud-red-05};

  --#{$vudTokenPrefix}primary-red: var(--#{$vudTokenPrefix}red-70);


  //### Orange palette
  --#{$vudTokenPrefix}orange-90: #{$light-vud-orange-90};
  --#{$vudTokenPrefix}orange-80: #{$light-vud-orange-80};
  --#{$vudTokenPrefix}orange-70: #{$light-vud-orange-70};
  --#{$vudTokenPrefix}orange-60: #{$light-vud-orange-60};
  --#{$vudTokenPrefix}orange-50: #{$light-vud-orange-50};
  --#{$vudTokenPrefix}orange-40: #{$light-vud-orange-40};
  --#{$vudTokenPrefix}orange-30: #{$light-vud-orange-30};
  --#{$vudTokenPrefix}orange-20: #{$light-vud-orange-20};
  --#{$vudTokenPrefix}orange-10: #{$light-vud-orange-10};
  --#{$vudTokenPrefix}orange-05: #{$light-vud-orange-05};

  --#{$vudTokenPrefix}primary-orange: var(--#{$vudTokenPrefix}orange-90);


  //### Brand primary palette
  --#{$vudTokenPrefix}brand-primary-90: var(--#{$backupVudTokenPrefix}brand-primary-90, #{$light-brand-primary-90});
  --#{$vudTokenPrefix}brand-primary-80: var(--#{$backupVudTokenPrefix}brand-primary-80, #{$light-brand-primary-80});
  --#{$vudTokenPrefix}brand-primary-70: var(--#{$backupVudTokenPrefix}brand-primary-70, #{$light-brand-primary-70});
  --#{$vudTokenPrefix}brand-primary-60: var(--#{$backupVudTokenPrefix}brand-primary-60, #{$light-brand-primary-60});
  --#{$vudTokenPrefix}brand-primary-50: var(--#{$backupVudTokenPrefix}brand-primary-50, #{$light-brand-primary-50});
  --#{$vudTokenPrefix}brand-primary-40: var(--#{$backupVudTokenPrefix}brand-primary-40, #{$light-brand-primary-40});
  --#{$vudTokenPrefix}brand-primary-30: var(--#{$backupVudTokenPrefix}brand-primary-30, #{$light-brand-primary-30});
  --#{$vudTokenPrefix}brand-primary-20: var(--#{$backupVudTokenPrefix}brand-primary-20, #{$light-brand-primary-20});
  --#{$vudTokenPrefix}brand-primary-10: var(--#{$backupVudTokenPrefix}brand-primary-10, #{$light-brand-primary-10});
  --#{$vudTokenPrefix}brand-primary-05: var(--#{$backupVudTokenPrefix}brand-primary-05, #{$light-brand-primary-05});


  //### Brand secondary palette
  --#{$vudTokenPrefix}brand-secondary-90: var(--#{$backupVudTokenPrefix}brand-secondary-90, #{$light-brand-secondary-90});
  --#{$vudTokenPrefix}brand-secondary-80: var(--#{$backupVudTokenPrefix}brand-secondary-80, #{$light-brand-secondary-80});
  --#{$vudTokenPrefix}brand-secondary-70: var(--#{$backupVudTokenPrefix}brand-secondary-70, #{$light-brand-secondary-70});
  --#{$vudTokenPrefix}brand-secondary-60: var(--#{$backupVudTokenPrefix}brand-secondary-60, #{$light-brand-secondary-60});
  --#{$vudTokenPrefix}brand-secondary-50: var(--#{$backupVudTokenPrefix}brand-secondary-50, #{$light-brand-secondary-50});
  --#{$vudTokenPrefix}brand-secondary-40: var(--#{$backupVudTokenPrefix}brand-secondary-40, #{$light-brand-secondary-40});
  --#{$vudTokenPrefix}brand-secondary-30: var(--#{$backupVudTokenPrefix}brand-secondary-30, #{$light-brand-secondary-30});
  --#{$vudTokenPrefix}brand-secondary-20: var(--#{$backupVudTokenPrefix}brand-secondary-20, #{$light-brand-secondary-20});
  --#{$vudTokenPrefix}brand-secondary-10: var(--#{$backupVudTokenPrefix}brand-secondary-10, #{$light-brand-secondary-10});
  --#{$vudTokenPrefix}brand-secondary-05: var(--#{$backupVudTokenPrefix}brand-secondary-05, #{$light-brand-secondary-05});


  //### Brand tertiary palette
  --#{$vudTokenPrefix}brand-tertiary-90: var(--#{$backupVudTokenPrefix}brand-tertiary-90, #{$light-brand-tertiary-90});
  --#{$vudTokenPrefix}brand-tertiary-80: var(--#{$backupVudTokenPrefix}brand-tertiary-80, #{$light-brand-tertiary-80});
  --#{$vudTokenPrefix}brand-tertiary-70: var(--#{$backupVudTokenPrefix}brand-tertiary-70, #{$light-brand-tertiary-70});
  --#{$vudTokenPrefix}brand-tertiary-60: var(--#{$backupVudTokenPrefix}brand-tertiary-60, #{$light-brand-tertiary-60});
  --#{$vudTokenPrefix}brand-tertiary-50: var(--#{$backupVudTokenPrefix}brand-tertiary-50, #{$light-brand-tertiary-50});
  --#{$vudTokenPrefix}brand-tertiary-40: var(--#{$backupVudTokenPrefix}brand-tertiary-40, #{$light-brand-tertiary-40});
  --#{$vudTokenPrefix}brand-tertiary-30: var(--#{$backupVudTokenPrefix}brand-tertiary-30, #{$light-brand-tertiary-30});
  --#{$vudTokenPrefix}brand-tertiary-20: var(--#{$backupVudTokenPrefix}brand-tertiary-20, #{$light-brand-tertiary-20});
  --#{$vudTokenPrefix}brand-tertiary-10: var(--#{$backupVudTokenPrefix}brand-tertiary-10, #{$light-brand-tertiary-10});
  --#{$vudTokenPrefix}brand-tertiary-05: var(--#{$backupVudTokenPrefix}brand-tertiary-05, #{$light-brand-tertiary-05});


  //### Brand neutral-light palette
  --#{$vudTokenPrefix}brand-neutral-light-90: var(--#{$backupVudTokenPrefix}brand-neutral-light-90, var(--#{$vudTokenPrefix}neutral-90, #{$light-brand-neutral-light-90}));
  --#{$vudTokenPrefix}brand-neutral-light-80: var(--#{$backupVudTokenPrefix}brand-neutral-light-80, var(--#{$vudTokenPrefix}neutral-80, #{$light-brand-neutral-light-80}));
  --#{$vudTokenPrefix}brand-neutral-light-70: var(--#{$backupVudTokenPrefix}brand-neutral-light-70, var(--#{$vudTokenPrefix}neutral-70, #{$light-brand-neutral-light-70}));
  --#{$vudTokenPrefix}brand-neutral-light-60: var(--#{$backupVudTokenPrefix}brand-neutral-light-60, var(--#{$vudTokenPrefix}neutral-60, #{$light-brand-neutral-light-60}));
  --#{$vudTokenPrefix}brand-neutral-light-50: var(--#{$backupVudTokenPrefix}brand-neutral-light-50, var(--#{$vudTokenPrefix}neutral-50, #{$light-brand-neutral-light-50}));
  --#{$vudTokenPrefix}brand-neutral-light-40: var(--#{$backupVudTokenPrefix}brand-neutral-light-40, var(--#{$vudTokenPrefix}neutral-40, #{$light-brand-neutral-light-40}));
  --#{$vudTokenPrefix}brand-neutral-light-30: var(--#{$backupVudTokenPrefix}brand-neutral-light-30, var(--#{$vudTokenPrefix}neutral-30, #{$light-brand-neutral-light-30}));
  --#{$vudTokenPrefix}brand-neutral-light-20: var(--#{$backupVudTokenPrefix}brand-neutral-light-20, var(--#{$vudTokenPrefix}neutral-20, #{$light-brand-neutral-light-20}));
  --#{$vudTokenPrefix}brand-neutral-light-10: var(--#{$backupVudTokenPrefix}brand-neutral-light-10, var(--#{$vudTokenPrefix}neutral-10, #{$light-brand-neutral-light-10}));
  --#{$vudTokenPrefix}brand-neutral-light-05: var(--#{$backupVudTokenPrefix}brand-neutral-light-05, var(--#{$vudTokenPrefix}neutral-05, #{$light-brand-neutral-light-05}));


  //### Brand neutral-dark palette
  --#{$vudTokenPrefix}brand-neutral-dark-90: var(--#{$backupVudTokenPrefix}brand-neutral-dark-90, var(--#{$vudTokenPrefix}neutral-90, #{$dark-brand-neutral-dark-90}));
  --#{$vudTokenPrefix}brand-neutral-dark-80: var(--#{$backupVudTokenPrefix}brand-neutral-dark-80, var(--#{$vudTokenPrefix}neutral-80, #{$dark-brand-neutral-dark-80}));
  --#{$vudTokenPrefix}brand-neutral-dark-70: var(--#{$backupVudTokenPrefix}brand-neutral-dark-70, var(--#{$vudTokenPrefix}neutral-70, #{$dark-brand-neutral-dark-70}));
  --#{$vudTokenPrefix}brand-neutral-dark-60: var(--#{$backupVudTokenPrefix}brand-neutral-dark-60, var(--#{$vudTokenPrefix}neutral-60, #{$dark-brand-neutral-dark-60}));
  --#{$vudTokenPrefix}brand-neutral-dark-50: var(--#{$backupVudTokenPrefix}brand-neutral-dark-50, var(--#{$vudTokenPrefix}neutral-50, #{$dark-brand-neutral-dark-50}));
  --#{$vudTokenPrefix}brand-neutral-dark-40: var(--#{$backupVudTokenPrefix}brand-neutral-dark-40, var(--#{$vudTokenPrefix}neutral-40, #{$dark-brand-neutral-dark-40}));
  --#{$vudTokenPrefix}brand-neutral-dark-30: var(--#{$backupVudTokenPrefix}brand-neutral-dark-30, var(--#{$vudTokenPrefix}neutral-30, #{$dark-brand-neutral-dark-30}));
  --#{$vudTokenPrefix}brand-neutral-dark-20: var(--#{$backupVudTokenPrefix}brand-neutral-dark-20, var(--#{$vudTokenPrefix}neutral-20, #{$dark-brand-neutral-dark-20}));
  --#{$vudTokenPrefix}brand-neutral-dark-10: var(--#{$backupVudTokenPrefix}brand-neutral-dark-10, var(--#{$vudTokenPrefix}neutral-10, #{$dark-brand-neutral-dark-10}));
  --#{$vudTokenPrefix}brand-neutral-dark-05: var(--#{$backupVudTokenPrefix}brand-neutral-dark-05, var(--#{$vudTokenPrefix}neutral-05, #{$dark-brand-neutral-dark-05}));

    //# b. Icons
  --stepper-visited-icon-light: #{$check-mark-light};
  --stepper-visited-icon-dark: #{$check-mark-dark};


  //# c. Typography
  // base/html font size
  --html-font-size: #{$html-font-size};
  --body-font-size: #{$body-font-size};
}

// --alias-token: var(--brand-alias-token, var(--vud-alias-token, back-up value));


// Light and Dark CSS Variables
//
// 2. Light mode CSS Variables
@mixin light-mode {
  //=== a. Color palette
  //# Neutral palette
  --#{$vudTokenPrefix}neutral-90: #{$light-vud-neutral-light-90};
  --#{$vudTokenPrefix}neutral-80: #{$light-vud-neutral-light-80};
  --#{$vudTokenPrefix}neutral-70: #{$light-vud-neutral-light-70};
  --#{$vudTokenPrefix}neutral-60: #{$light-vud-neutral-light-60};
  --#{$vudTokenPrefix}neutral-50: #{$light-vud-neutral-light-50};
  --#{$vudTokenPrefix}neutral-40: #{$light-vud-neutral-light-40};
  --#{$vudTokenPrefix}neutral-30: #{$light-vud-neutral-light-30};
  --#{$vudTokenPrefix}neutral-20: #{$light-vud-neutral-light-20};
  --#{$vudTokenPrefix}neutral-10: #{$light-vud-neutral-light-10};
  --#{$vudTokenPrefix}neutral-05: #{$light-vud-neutral-light-05};

  --#{$vudTokenPrefix}primary-neutral: var(--#{$vudTokenPrefix}neutral-90);


  //=== b. Base body properties
  // Set site background color
  --#{$vudTokenPrefix}root-background-color: var(--#{$backupVudTokenPrefix}ref-fill-on-screen-default, var(--custom-root-background-color, #{$light-ref-fill-on-screen-default}));

  --#{$vudTokenPrefix}scrollbar-track-bg: var(--#{$backupVudTokenPrefix}comp-content-surface-muted, var(--#{$vudTokenPrefix}root-background-color, #{$light-comp-content-surface-muted}));
  --#{$vudTokenPrefix}scrollbar-thumb-bg: var(--#{$backupVudTokenPrefix}comp-content-icon-disabled, #{$light-comp-content-icon-disabled});
  --#{$vudTokenPrefix}scrollbar-thumb-drag-bg: var(--#{$backupVudTokenPrefix}comp-content-icon-default, #{$light-comp-content-icon-default});

  // body properties
  --#{$vudTokenPrefix}body-font-color: var(--#{$backupVudTokenPrefix}comp-content-text-default, var(--custom-body-font-color, #{$light-comp-content-text-default}));
  --#{$vudTokenPrefix}titles-font-color: var(--custom-titles-font-color, var(--#{$vudTokenPrefix}primary-neutral));

  // Components background color 
  --#{$vudTokenPrefix}module-container-bg-color: var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-module-container-bg-color, #{$light-comp-content-surface-enabled}));
  --module-container-shadow: var(--custom-module-container-shadow, #{shadow(10)} rgba(#{hex-to-rgb($blue-90)}, 0.1));
  --module-container-divider: var(--custom-module-container-divider, var(--#{$vudTokenPrefix}neutral-30));
  --module-container-active-bg-color: var(--custom-module-container-active-bg-color, var(--#{$vudTokenPrefix}neutral-20));
  --module-container-active-before-color: var(--custom-module-container-active-before-color, var(--#{$vudTokenPrefix}green-60));
  --module-container-hover-bg-color: var(--custom-module-container-hover-bg-color, var(--#{$vudTokenPrefix}blue-10));


  //=== c. Typography
  // body text
  --#{$vudTokenPrefix}text-default: var(--#{$backupVudTokenPrefix}ref-text-default, var(--custom-text-default, #{$light-ref-text-default}));
  --#{$vudTokenPrefix}text-alt: var(--#{$backupVudTokenPrefix}ref-text-inverse, var(--custom-text-alt, #{$light-ref-text-inverse}));
  --#{$vudTokenPrefix}text-primary: var(--#{$backupVudTokenPrefix}ref-text-emphasis, var(--custom-text-primary, #{$light-ref-text-emphasis}));
  --#{$vudTokenPrefix}text-secondary: var(--#{$backupVudTokenPrefix}ref-text-secondary, var(--custom-text-secondary, #{$light-ref-text-secondary}));
  --#{$vudTokenPrefix}text-disabled: var(--#{$backupVudTokenPrefix}ref-text-disabled, var(--custom-text-disabled, #{$light-ref-text-disabled}));
  --#{$vudTokenPrefix}text-error: var(--#{$backupVudTokenPrefix}ref-text-error-moderate, var(--custom-text-error, #{$light-ref-text-error-moderate}));
  --#{$vudTokenPrefix}text-warning: var(--#{$backupVudTokenPrefix}ref-text-warning-moderate, var(--custom-text-warning, #{$light-ref-text-warning-moderate}));
  --#{$vudTokenPrefix}text-success: var(--#{$backupVudTokenPrefix}ref-text-success-moderate, var(--custom-text-success, #{$light-ref-text-success-moderate}));


  // backgrounds
  --#{$vudTokenPrefix}bg-default: var(--#{$backupVudTokenPrefix}ref-fill-on-surface-default, var(--custom-bg-default, #{$light-ref-fill-on-surface-default}));
  --#{$vudTokenPrefix}bg-primary: var(--#{$backupVudTokenPrefix}ref-fill-on-screen-default, var(--custom-bg-primary, #{$light-ref-fill-on-screen-default}));
  --#{$vudTokenPrefix}bg-secondary: var(--#{$backupVudTokenPrefix}ref-fill-on-secondary-enabled, var(--custom-bg-secondary, #{$light-ref-fill-on-secondary-enabled}));


  // shadows
  --#{$vudTokenPrefix}shadow-40: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-4, #{$light-ref-shadow-default-elevation-4});
  --#{$vudTokenPrefix}shadow-30: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-3, #{$light-ref-shadow-default-elevation-3});
  --#{$vudTokenPrefix}shadow-20: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-2, #{$light-ref-shadow-default-elevation-2});
  --#{$vudTokenPrefix}shadow-10: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-1, #{$light-ref-shadow-default-elevation-1});

  --#{$vudTokenPrefix}shadow-50: var(--#{$vudTokenPrefix}shadow-40);


  // anchor/breadcrumbs properties
  --#{$vudTokenPrefix}anchor-color: var(--#{$backupVudTokenPrefix}ref-text-links, var(--custom-anchor-color, #{$light-ref-text-links}));
  --#{$vudTokenPrefix}anchor-hover-color: var(--custom-anchor-hover-color, var(--#{$vudTokenPrefix}blue-70));
  --#{$vudTokenPrefix}anchor-visited-color: var(--#{$backupVudTokenPrefix}ref-text-visited, #{$light-ref-text-visited});
  --#{$vudTokenPrefix}anchor-focus-outline: var(--custom-anchor-focus-outline, var(--#{$vudTokenPrefix}blue-50));
  --#{$vudTokenPrefix}anchor-focus-outline-alt: var(--custom-anchor-focus-outline-alt, var(--#{$vudTokenPrefix}blue-10));
  --#{$vudTokenPrefix}anchor-disabled-color: var(--#{$backupVudTokenPrefix}ref-text-disabled, var(--custom-anchor-disabled-color, #{$light-ref-text-disabled}));


  // anchor/breadcrumbs - active/selected state
  --#{$vudTokenPrefix}anchor-selected-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-anchor-selected-color, #{$light-comp-nav-hor-default-text-default}));


  // 'mark' tag/element (highlighted text)
  --#{$vudTokenPrefix}highlighted-text-bg-color: var(--#{$backupVudTokenPrefix}ref-fill-on-mark-default, var(--custom-highlighted-text-bg-color, #{$light-ref-fill-on-mark-default}));   // use --ref-fill-on-mark-default: var(--vud-orange-10); /* Suitable for highligting text. */
  --#{$vudTokenPrefix}highlighted-text-color: var(--#{$backupVudTokenPrefix}comp-list-text-inverse, var(--custom-highlighted-text-color, #{$light-comp-list-text-inverse}));


  // 'hr' tag/element
  --#{$vudTokenPrefix}divider-color: var(--#{$backupVudTokenPrefix}ref-border-strong, var(--custom-divider-color, #{$light-ref-border-strong}));
  --#{$vudTokenPrefix}divider-default-color: var(--#{$backupVudTokenPrefix}ref-border-moderate, var(--custom-divider-default-color, #{$light-ref-border-moderate}));
  --#{$vudTokenPrefix}divider-primary-color: var(--#{$backupVudTokenPrefix}ref-border-extreme, var(--custom-divider-primary-color, #{$light-ref-border-extreme}));
  --#{$vudTokenPrefix}divider-disabled-color: var(--#{$backupVudTokenPrefix}ref-border-weak, var(--custom-divider-disabled-color, #{$light-ref-border-weak}));
  --#{$vudTokenPrefix}divider-error-color: var(--#{$backupVudTokenPrefix}ref-border-on-funct-warning-weak, var(--custom-divider-error-color, #{$light-ref-border-on-funct-warning-weak}));
  --#{$vudTokenPrefix}fancy-divider-color: var(--#{$vudTokenPrefix}neutral-90);


  //=== d. Buttons
  //## Default button
  --#{$vudTokenPrefix}button-text-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-text-default, var(--custom-button-text-color, #{$light-comp-btn-default-on-confimatory-text-default}));
  --#{$vudTokenPrefix}button-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-enabled, var(--custom-button-border-color, #{$light-comp-btn-default-on-confimatory-border-enabled}));
  --button-inner-border-color: var(--custom-button-inner-border-color, var(--#{$vudTokenPrefix}neutral-40));
  --#{$vudTokenPrefix}button-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-enabled, var(--custom-button-bg, #{$light-comp-btn-default-on-confimatory-surface-enabled}));
  --button-shadow-color: var(--custom-button-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.12));
  --button-icon: var(--custom-button-icon, var(--#{$vudTokenPrefix}blue-50));
  --#{$vudTokenPrefix}button-icon-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-default, var(--custom-button-icon-color, #{$light-comp-btn-default-on-confimatory-icon-default}));

  // Default button - hover state
  --#{$vudTokenPrefix}button-hover-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-hover, var(--custom-button-hover-border-color, #{$light-comp-btn-default-on-confimatory-border-hover}));
  --#{$vudTokenPrefix}button-hover-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-hover, var(--custom-button-hover-bg, #{$light-comp-btn-default-on-confimatory-surface-hover}));
  --button-hover-shadow-color: var(--custom-button-hover-shadow-color, rgba(#{hex-to-rgb($blue-50)}, 0.15));

  // Default button - focus state
  --#{$vudTokenPrefix}button-focus-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-focused, var(--custom-button-focus-border-color, #{$light-comp-btn-default-on-confimatory-border-focused}));
  --button-focus-shadow-color: var(--custom-button-focus-shadow-color, rgba(#{hex-to-rgb($blue-50)}, 0.15));

  // Default button - pressed state
  --#{$vudTokenPrefix}button-pressed-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-pressed, var(--custom-button-pressed-border-color, #{$light-comp-btn-default-on-confimatory-border-pressed}));
  --#{$vudTokenPrefix}button-pressed-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-pressed, var(--custom-button-pressed-bg, #{$light-comp-btn-default-on-confimatory-surface-pressed}));

  // Default button - active state
  --#{$vudTokenPrefix}button-active-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-moderate, var(--custom-button-active-border-color, #{$light-comp-btn-default-on-confimatory-border-selected-moderate}));
  --#{$vudTokenPrefix}button-active-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-button-active-bg, #{$light-comp-btn-default-on-confimatory-surface-selected-moderate}));

  // Default button - active/selected state
  --#{$vudTokenPrefix}button-selected-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-button-selected-bg, #{$light-comp-btn-default-on-confimatory-surface-selected-moderate}));

  // Default button - disabled state
  --#{$vudTokenPrefix}button-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-text-disabled, var(--custom-button-disabled-text-color, #{$light-comp-btn-default-on-confimatory-text-disabled}));
  --#{$vudTokenPrefix}button-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-disabled, var(--custom-button-disabled-border-color, #{$light-comp-btn-default-on-confimatory-border-disabled}));
  --button-disabled-inner-border-color: var(--custom-button-disabled-inner-border-color, var(--#{$vudTokenPrefix}neutral-50));
  --#{$vudTokenPrefix}button-disabled-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-disabled, var(--custom-button-disabled-bg, #{$light-comp-btn-default-on-confimatory-surface-disabled}));


  // Default Destructive button
  --#{$vudTokenPrefix}button-destructive-text-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-text-default, var(--custom-button-destructive-text-color, #{$light-comp-btn-default-on-destructive-text-default}));
  --#{$vudTokenPrefix}button-destructive-hover-text-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-text-inverse, #{$light-comp-btn-default-on-destructive-text-inverse});
  --#{$vudTokenPrefix}button-destructive-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-border-enabled, var(--custom-button-destructive-border-color, #{$light-comp-btn-default-on-destructive-border-enabled}));
  --#{$vudTokenPrefix}button-destructive-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-enabled, var(--custom-button-destructive-bg, #{$light-comp-btn-default-on-destructive-surface-enabled}));
  --#{$vudTokenPrefix}button-destructive-hover-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-hover, var(--custom-button-destructive-hover-bg, #{$light-comp-btn-default-on-destructive-surface-hover}));
  --#{$vudTokenPrefix}button-destructive-pressed-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-pressed, var(--custom-button-destructive-pressed-bg, #{$light-comp-btn-default-on-destructive-surface-pressed}));

  //## Primary button
  --#{$vudTokenPrefix}button-primary-text-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-text-default, var(--custom-button-primary-text-color, #{$light-comp-btn-primary-on-confimatory-text-default}));
  --#{$vudTokenPrefix}button-primary-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-enabled, var(--custom-button-primary-border-color, #{$light-comp-btn-primary-on-confimatory-border-enabled}));
  --button-primary-inner-border-color: var(--custom-button-primary-inner-border-color, var(--#{$vudTokenPrefix}green-80));
  --#{$vudTokenPrefix}button-primary-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-enabled, var(--custom-button-primary-bg, #{$light-comp-btn-primary-on-confimatory-surface-enabled}));
  --button-primary-shadow-color: var(--custom-button-primary-shadow-color, rgba(#{hex-to-rgb($green-80)}, 0.2));
  --#{$vudTokenPrefix}button-primary-icon: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-icon-default, var(--custom-button-primary-icon, #{$light-comp-btn-primary-on-confimatory-icon-default}));
  --#{$vudTokenPrefix}facet-primary-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-strong, var(--custom-facet-primary-bg, #{$light-comp-btn-default-on-confimatory-surface-selected-strong}));

  // Primary button - hover state
  --#{$vudTokenPrefix}button-primary-hover-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-hover, var(--custom-button-primary-hover-border-color, #{$light-comp-btn-primary-on-confimatory-border-hover}));
  --#{$vudTokenPrefix}button-primary-hover-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-hover, var(--custom-button-primary-hover-bg, #{$light-comp-btn-primary-on-confimatory-surface-hover}));

  // Primary button - focus state
  --#{$vudTokenPrefix}button-primary-focus-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-focused, var(--custom-button-primary-focus-border-color, #{$light-comp-btn-primary-on-confimatory-border-focused}));
  --button-primary-focus-inner-shadow-color: var(--custom-button-primary-focus-inner-shadow-color, var(--#{$vudTokenPrefix}neutral-05));
  --button-primary-focus-shadow-color: var(--custom-button-primary-focus-shadow-color, var(--#{$vudTokenPrefix}button-primary-focus-border-color));

  // Primary button - active/pressed state
  --#{$vudTokenPrefix}button-primary-active-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-pressed, var(--custom-button-primary-active-border-color, #{$light-comp-btn-primary-on-confimatory-border-pressed}));
  --#{$vudTokenPrefix}button-primary-active-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-pressed, var(--custom-button-primary-active-bg, #{$light-comp-btn-primary-on-confimatory-surface-pressed}));

  // Primary button - active/selected state
  --#{$vudTokenPrefix}button-primary-selected-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-strong, var(--custom-button-primary-selected-border-color, #{$light-comp-btn-default-on-confimatory-border-selected-strong}));
  --#{$vudTokenPrefix}button-primary-selected-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-strong, var(--custom-button-primary-selected-bg, #{$light-comp-btn-default-on-confimatory-surface-selected-strong}));

  // Primary Destructive button
  --#{$vudTokenPrefix}button-primary-destructive-active-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-border-pressed, var(--custom-button-primary-destructive-active-border-color, #{$light-comp-btn-primary-on-destructive-border-pressed}));
  --#{$vudTokenPrefix}button-primary-destructive-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-surface-enabled, var(--custom-button-primary-destructive-bg, #{$light-comp-btn-primary-on-destructive-surface-enabled}));
  --#{$vudTokenPrefix}button-primary-destructive-active-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-surface-pressed, var(--custom-button-primary-destructive-active-bg, #{$light-comp-btn-primary-on-destructive-surface-pressed}));


  //# dropdown button
  --#{$vudTokenPrefix}dropdown-menu-text-color: var(--#{$backupVudTokenPrefix}comp-list-text-default, var(--custom-dropdown-menu-text-color, #{$light-comp-list-text-default}));
  --#{$vudTokenPrefix}dropdown-menu-bg: var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dropdown-menu-bg, #{$light-comp-content-surface-enabled}));
  --#{$vudTokenPrefix}dropdown-menu-shadow-color: var(--#{$backupVudTokenPrefix}ref-box-shadow-default-elevation-01, var(--#{$vudTokenPrefix}shadow-10)); // check Narchie

  // dropdown button - hover state
  --#{$vudTokenPrefix}dropdown-menu-hover-bg: var(--#{$backupVudTokenPrefix}comp-list-surface-hover, var(--custom-dropdown-menu-hover-bg, #{$light-comp-list-surface-hover}));

  // dropdown button - active/selected state
  --#{$vudTokenPrefix}dropdown-menu-selected-text-color: var(--#{$backupVudTokenPrefix}comp-list-text-emphasis, var(--custom-dropdown-menu-selected-text-color, #{$light-comp-list-text-emphasis}));

  // dropdown arrow
  --#{$vudTokenPrefix}dropdown-arrow-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-default, var(--custom-dropdown-arrow-bg, #{$light-comp-btn-default-on-confimatory-icon-default}));
  --#{$vudTokenPrefix}dropdown-arrow-disabled-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-disabled, var(--custom-dropdown-arrow-disabled-bg, #{$light-comp-btn-default-on-confimatory-icon-disabled}));

  --#{$vudTokenPrefix}dropdown-primary-arrow-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-icon-default, var(--custom-dropdown-primary-arrow-bg, #{$light-comp-btn-primary-on-confimatory-icon-default}));


  //# wizard
  --#{$vudTokenPrefix}wizard-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-enabled, var(--custom-wizard-border-color, #{$light-comp-nav-stepper-border-enabled}));
  --#{$vudTokenPrefix}wizard-inner-border-color: var(--custom-wizard-inner-border-color, var(--#{$vudTokenPrefix}wizard-border-color));

  // wizard hover
  --#{$vudTokenPrefix}wizard-hover-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-hover, var(--custom-wizard-hover-border-color, #{$light-comp-nav-stepper-border-hover}));

  // wizard selected
  --#{$vudTokenPrefix}wizard-selected-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-selected-strong, var(--custom-wizard-selected-border-color, #{$light-comp-nav-stepper-border-selected-strong}));
  --#{$vudTokenPrefix}wizard-checkmark-icon: var(--custom-wizard-checkmark-icon, url(#{$image-path}/wizard-checkmark.svg));

  // wizard tabdrop
  --#{$vudTokenPrefix}wizard-tabdrop-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-icon-default, var(--custom-wizard-tabdrop-color, #{$light-comp-nav-stepper-icon-default}));


  //=== e. Icons
  // worksourface, action and social media icons
  --#{$vudTokenPrefix}icon-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-icon-bg, #{$light-ref-icon-default}));
  --#{$vudTokenPrefix}icon-hover-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, var(--custom-icon-hover-bg, #{$light-ref-icon-emphasis}));
  --#{$vudTokenPrefix}icon-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, var(--custom-icon-disabled-bg, #{$light-ref-icon-disabled}));

  --visma-icon-color: var(--#{$vudTokenPrefix}icon-bg);


  // pictograms
  --#{$vudTokenPrefix}pictogram-primary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-stroke-default, #{$light-ref-pictogram-on-primary-stroke-default});
  --#{$vudTokenPrefix}pictogram-secondary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-on-secondary-stroke-default, #{$light-ref-pictogram-on-secondary-stroke-default});
  --#{$vudTokenPrefix}pictogram-tertiary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-on-tertiary-stroke-default, #{$light-ref-pictogram-on-tertiary-stroke-default});

  --#{$vudTokenPrefix}pictogram-fill: var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-fill-default, #{$light-ref-pictogram-on-primary-fill-default});
  --#{$vudTokenPrefix}pictogram-stroke: var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-stroke-default, #{$light-ref-pictogram-on-primary-stroke-default});

  --#{$vudTokenPrefix}pictogram-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, #{$light-ref-icon-disabled});

  --visma-pictogram-color: var(--#{$vudTokenPrefix}pictogram-primary-bg);
  --visma-pictogram-fill: var(--#{$vudTokenPrefix}pictogram-fill);
  --visma-pictogram-stroke: var(--#{$vudTokenPrefix}pictogram-stroke);


  // Informative icons
  --#{$vudTokenPrefix}icon-informative-success-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-success, var(--custom-icon-informative-success-bg, #{$light-comp-situation-funct-icon-success}));
  --#{$vudTokenPrefix}icon-informative-error-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-error, var(--custom-icon-informative-error-bg, #{$light-comp-situation-funct-icon-error}));
  --#{$vudTokenPrefix}icon-informative-warning-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-icon-informative-warning-bg, #{$light-comp-situation-funct-icon-warning}));
  --#{$vudTokenPrefix}icon-informative-info-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-icon-informative-info-bg, #{$light-comp-situation-funct-icon-emphasis}));
  --#{$vudTokenPrefix}icon-informative-help-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-icon-informative-help-bg, #{$light-comp-situation-funct-icon-emphasis}));

  --visma-informative-success-color: var(--#{$vudTokenPrefix}icon-informative-success-bg);
  --visma-informative-error-color: var(--#{$vudTokenPrefix}icon-informative-error-bg);
  --visma-informative-warning-color: var(--#{$vudTokenPrefix}icon-informative-warning-bg);
  --visma-informative-info-color: var(--#{$vudTokenPrefix}icon-informative-info-bg);
  --visma-informative-help-color: var(--#{$vudTokenPrefix}icon-informative-help-bg);

  // Carets and close icons
  --#{$vudTokenPrefix}caret-default-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-caret-default-bg, #{$light-ref-icon-default}));
  --#{$vudTokenPrefix}caret-primary-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, var(--custom-caret-primary-bg, #{$light-ref-icon-emphasis}));
  --#{$vudTokenPrefix}caret-secondary-bg: var(--#{$backupVudTokenPrefix}ref-icon-inverse, var(--custom-caret-secondary-bg, #{$light-ref-icon-inverse}));
  --#{$vudTokenPrefix}caret-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, var(--custom-caret-disabled-bg, #{$light-ref-icon-disabled}));
  --caret-navigation-bg: var(--custom-caret-navigation-bg, var(--#{$vudTokenPrefix}neutral-05));
  --#{$vudTokenPrefix}close-icon-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-close-icon-bg, #{$light-ref-icon-default}));
  --close-focus-outline: var(--custom-close-focus-outline, var(--#{$vudTokenPrefix}blue-50));


  //=== f. Badges
  --#{$vudTokenPrefix}badge-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-badge-bg, #{$light-comp-situation-funct-surface-default}));
  --#{$vudTokenPrefix}badge-text-color: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-default, var(--custom-badge-text-color, #{$light-comp-situation-funct-text-default}));
  --#{$vudTokenPrefix}badge-shadow: var(--custom-badge-shadow, 0 0 0 rem(1px) #{$light-comp-situation-funct-border-default});

  --#{$vudTokenPrefix}badge-success-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-success-moderate, var(--custom-badge-success-bg, #{$light-comp-situation-funct-surface-success-moderate}));
  --#{$vudTokenPrefix}badge-success-sm-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-success, var(--custom-badge-success-sm-bg, #{$light-comp-situation-funct-icon-success}));
  --#{$vudTokenPrefix}badge-success-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-success-weak, var(--custom-badge-success-text, #{$light-comp-situation-funct-text-success-weak}));
  --#{$vudTokenPrefix}badge-success-shadow: var(--custom-badge-success-shadow, 0 0 0 rem(1px) #{$light-comp-situation-funct-border-success-moderate});

  --#{$vudTokenPrefix}badge-warning-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-warning-moderate, var(--custom-badge-warning-bg, #{$light-comp-situation-funct-surface-warning-moderate}));
  --#{$vudTokenPrefix}badge-warning-sm-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-badge-warning-sm-bg, #{$light-comp-situation-funct-icon-warning}));

  --#{$vudTokenPrefix}badge-danger-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-error-moderate, var(--custom-badge-danger-bg, #{$light-comp-situation-funct-surface-error-moderate}));
  --#{$vudTokenPrefix}badge-danger-sm-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-error, var(--custom-badge-danger-sm-bg, #{$light-comp-situation-funct-icon-error}));
  --#{$vudTokenPrefix}badge-danger-shadow: var(--custom-badge-danger-shadow, 0 0 0 rem(1px) #{$light-comp-situation-funct-border-error-moderate});

  --#{$vudTokenPrefix}badge-info-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-info-moderate, var(--custom-badge-info-bg, #{$light-comp-situation-funct-surface-info-moderate}));
  --#{$vudTokenPrefix}badge-info-sm-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-badge-info-sm-bg, #{$light-comp-situation-funct-icon-emphasis}));
  --badge-info-alt-bg: var(--custom-badge-info-alt-bg, var(--#{$vudTokenPrefix}neutral-05));
  --#{$vudTokenPrefix}badge-info-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-info-weak, var(--custom-badge-info-text, #{$light-comp-situation-funct-text-info-weak}));
  --badge-info-alt-text: var(--custom-badge-info-alt-text, var(--#{$vudTokenPrefix}blue-50));


  //=== g. List groups
  --#{$vudTokenPrefix}list-group-divider: var(--custom-list-group-divider, inset 0 2px 0 -1px #{$light-comp-separator-moderate});
  --#{$vudTokenPrefix}list-group-header-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-list-group-header-bg, #{$light-comp-nav-hor-brand-surface-default}));
  --#{$vudTokenPrefix}list-group-badge-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-list-group-badge-bg, #{$light-comp-situation-funct-surface-default}));
  --#{$vudTokenPrefix}list-group-badge-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-default, var(--custom-list-group-badge-border, #{$light-comp-situation-funct-border-default}));
  --#{$vudTokenPrefix}list-group-badge-text: var(--#{$backupVudTokenPrefix}comp-list-text-secondary, var(--custom-list-group-badge-text, #{$light-comp-list-text-secondary}));

  --#{$vudTokenPrefix}list-group-primary-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-list-group-primary-bg, #{$light-comp-nav-hor-brand-surface-default}));
  --#{$vudTokenPrefix}list-group-primary-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-list-group-primary-text-color, #{$light-comp-nav-hor-brand-text-default}));
  --list-group-primary-divider: var(--custom-list-group-primary-divider, #{inset 0 1px 0 0} var(--#{$vudTokenPrefix}blue-50));
  --list-group-primary-active-bg: var(--custom-list-group-primary-active-bg, rgba(#{hex-to-rgb($neutral-light-90)}, 0.15));
  --list-group-primary-hover-bg: var(--custom-list-group-primary-hover-bg, var(--#{$vudTokenPrefix}blue-70));


  //=== h. Navigation
  --#{$vudTokenPrefix}navbar-brand-item-focus-border-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-focused, var(--custom-navbar-brand-item-focus-border-color, #{$light-comp-nav-hor-brand-surface-focused}));
  --#{$vudTokenPrefix}navigation-default-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-navigation-default-bg, #{$light-comp-nav-hor-brand-surface-default}));
  --#{$vudTokenPrefix}navigation-default-shadow: var(--#{$backupVudTokenPrefix}comp-separator-moderate, #{$light-comp-separator-moderate});

  --#{$vudTokenPrefix}navigation-default-second-level-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-navigation-default-second-level-bg, #{$light-comp-nav-hor-default-surface-default}));
  --#{$vudTokenPrefix}navigation-default-second-level-shadow: var(--custom-navigation-default-second-level-shadow, #{$light-ref-shadow-default-elevation-1});
  --#{$vudTokenPrefix}navigation-default-second-level-divider: var(--custom-navigation-default-second-level-divider, var(--#{$vudTokenPrefix}navigation-default-shadow));

  --#{$vudTokenPrefix}navigation-default-item-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-navigation-default-item-text-color, #{$light-comp-nav-hor-brand-text-default}));
  --#{$vudTokenPrefix}navigation-default-item-hover-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-hover, var(--custom-navigation-default-item-hover-bg, #{$light-comp-nav-hor-brand-surface-hover}));
  --#{$vudTokenPrefix}navigation-default-item-focus-border-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-border-focused, var(--custom-navigation-default-item-focus-border-color, #{$light-comp-nav-hor-brand-border-focused}));
  --#{$vudTokenPrefix}navigation-default-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-selected-moderate, var(--custom-navigation-default-item-active-bg, #{$light-comp-nav-hor-brand-surface-selected-moderate}));

  --#{$vudTokenPrefix}navigation-default-icon-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-default, var(--custom-navigation-default-icon-bg, #{$light-comp-nav-hor-brand-icon-default}));
  --#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-inverse, var(--custom-navigation-default-dropdown-open-icon-bg, #{$light-comp-nav-hor-brand-icon-inverse}));

  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-navigation-default-item-dropdown-open-bg, #{$light-comp-nav-hor-default-surface-default}));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-inverse, var(--custom-navigation-default-item-dropdown-open-text-color, #{$light-comp-nav-hor-brand-text-inverse}));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-small-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-navigation-default-item-dropdown-small-text-color, #{$light-comp-nav-hor-brand-text-default}));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-small-text-color: var(--#{$backupVudTokenPrefix}comp-content-text-secondary, var(--custom-navigation-default-item-dropdown-open-small-text-color, #{$light-comp-content-text-secondary}));

  --#{$vudTokenPrefix}navigation-default-dropdown-shadow: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-1, var(--custom-navigation-default-dropdown-shadow, #{$light-ref-shadow-default-elevation-1}));
  --#{$vudTokenPrefix}navigation-default-dropdown-text-color: var(--#{$backupVudTokenPrefix}comp-content-text-default, var(--custom-navigation-default-dropdown-text-color, #{$light-comp-content-text-default}));
  --#{$vudTokenPrefix}navigation-default-dropdown-divider-color-dotted: var(--#{$backupVudTokenPrefix}comp-separator-strong, var(--custom-navigation-default-dropdown-divider-color-dotted, #{$light-comp-separator-strong}));
  --#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong: var(--#{$backupVudTokenPrefix}comp-separator-strong, var(--custom-navigation-default-dropdown-divider-color-strong, #{$light-comp-separator-strong}));

  --#{$vudTokenPrefix}navigation-default-attention-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-navigation-default-attention-bg, #{$light-comp-situation-funct-icon-warning}));
  --#{$vudTokenPrefix}navigation-default-badge-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-navigation-default-badge-bg, #{$light-comp-situation-funct-surface-default}));
  --#{$vudTokenPrefix}navigation-default-badge-text-color: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-default, var(--custom-navigation-default-badge-text-color, #{$light-comp-situation-funct-text-default}));
  --#{$vudTokenPrefix}navigation-default-badge-shadow: var(--custom-navigation-default-badge-shadow, var(--#{$vudTokenPrefix}badge-shadow));

  --navigation-default-menudrop-bg: var(--custom-navigation-default-menudrop-bg, var(--#{$vudTokenPrefix}neutral-05));
  --#{$vudTokenPrefix}navigation-default-menudrop-shadow: var(--custom-navigation-default-menudrop-shadow, #{$light-ref-shadow-default-elevation-2});

  --#{$vudTokenPrefix}navigation-default-hamburger-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-default, var(--custom-navigation-default-hamburger-bg, #{$light-comp-nav-hor-brand-icon-default}));
  --#{$vudTokenPrefix}navigation-default-hamburger-open-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-inverse, var(--custom-navigation-default-hamburger-open-bg, #{$light-comp-nav-hor-brand-icon-inverse}));


  // Primary navigation
  --#{$vudTokenPrefix}navigation-primary-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-default, var(--custom-navigation-primary-bg, #{$light-comp-nav-hor-inverted-surface-default}));

  --#{$vudTokenPrefix}navigation-primary-item-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-text-default, #{$light-comp-nav-hor-inverted-text-default});
  --#{$vudTokenPrefix}navigation-primary-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-selected-moderate, var(--custom-navigation-primary-item-active-bg, #{$light-comp-nav-hor-inverted-surface-selected-moderate}));
  --#{$vudTokenPrefix}navigation-primary-item-hover-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-hover, var(--custom-navigation-primary-item-hover-bg, #{$light-comp-nav-hor-inverted-surface-hover}));
  --#{$vudTokenPrefix}navigation-primary-item-border-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-border-enabled, #{$light-comp-nav-hor-brand-border-enabled});

  --#{$vudTokenPrefix}navigation-primary-icon-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-icon-default, #{$light-comp-nav-hor-inverted-icon-default});


  // Secondary navigation
  --#{$vudTokenPrefix}navigation-secondary-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-navigation-secondary-bg, #{$light-comp-nav-hor-default-surface-default}));
  --#{$vudTokenPrefix}navigation-secondary-shadow: var(--custom-navigation-secondary-shadow, inset 0 rem(-1px) 0 0 var(--#{$vudTokenPrefix}navigation-default-shadow));

  --#{$vudTokenPrefix}navigation-secondary-second-level-shadow: var(--custom-navigation-secondary-second-level-shadow, var(--#{$vudTokenPrefix}navigation-default-second-level-shadow));
  --#{$vudTokenPrefix}navigation-secondary-second-level-divider: var(--custom-navigation-secondary-second-level-divider, var(--#{$vudTokenPrefix}navigation-default-second-level-divider));

  --#{$vudTokenPrefix}navigation-secondary-item-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-navigation-secondary-item-text-color, #{$light-comp-nav-hor-default-text-default}));
  --#{$vudTokenPrefix}navigation-secondary-item-active-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-emphasis, var(--custom-navigation-secondary-item-active-text-color, #{$light-comp-nav-hor-default-text-emphasis}));
  --#{$vudTokenPrefix}navigation-secondary-item-active-font-weight: var(--custom-navigation-secondary-item-active-font-weight, $normal);
  --#{$vudTokenPrefix}navigation-secondary-item-active-shadow: var(--custom-navigation-secondary-item-active-shadow, #{inset 0 -4px 0px} #{$light-comp-nav-hor-default-border-selected-strong});
  --#{$vudTokenPrefix}navigation-secondary-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-selected-moderate, var(--custom-navigation-secondary-item-active-bg, #{$light-comp-nav-hor-default-surface-selected-moderate}));
  --#{$vudTokenPrefix}navigation-secondary-item-hover-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-hover, var(--custom-navigation-secondary-item-hover-bg, #{$light-comp-nav-hor-default-surface-hover}));
  --#{$vudTokenPrefix}navigation-secondary-item-focus-border-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-border-focused, var(--custom-navigation-secondary-item-focus-border-color, #{$light-comp-nav-hor-default-border-focused}));

  --#{$vudTokenPrefix}navigation-secondary-item-dropdown-small-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-navigation-secondary-item-dropdown-small-text-color, #{$light-comp-nav-hor-default-text-default}));

  --#{$vudTokenPrefix}navigation-secondary-icon-bg: var(--custom-navigation-secondary-icon-bg, --#{$vudTokenPrefix}navigation-default-icon-bg);

  --#{$vudTokenPrefix}navigation-secondary-badge-bg: var(--custom-navigation-secondary-badge-bg, var(--#{$vudTokenPrefix}badge-success-bg));
  --#{$vudTokenPrefix}navigation-secondary-badge-text-color: var(--custom-navigation-secondary-badge-text-color, var(--#{$vudTokenPrefix}badge-success-text));
  --#{$vudTokenPrefix}navigation-secondary-badge-shadow: var(--custom-navigation-secondary-badge-shadow, var(--#{$vudTokenPrefix}badge-success-shadow));

  --#{$vudTokenPrefix}navigation-secondary-menudrop-icon-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-icon-default, var(--custom-navigation-secondary-menudrop-icon-bg, #{$light-comp-nav-hor-default-icon-default}));

  --#{$vudTokenPrefix}navigation-secondary-hamburger-bg: var(--custom-navigation-secondary-hamburger-bg, var(--#{$vudTokenPrefix}neutral-80));
  --#{$vudTokenPrefix}navigation-secondary-hamburger-open-bg: var(--custom-navigation-secondary-hamburger-open-bg, var(--#{$vudTokenPrefix}neutral-80));


  //=== i. Vertical navigation
  --#{$vudTokenPrefix}vertical-nav-default-shadow: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-1, var(--custom-vertical-nav-default-shadow, $light-ref-shadow-default-elevation-1));
  --#{$vudTokenPrefix}vertical-nav-default-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-default, var(--custom-vertical-nav-default-bg, #{$light-comp-nav-vert-default-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-default-item-hover-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-hover, var(--custom-vertical-nav-default-item-hover-bg, #{$light-comp-nav-vert-default-surface-hover}));
  --#{$vudTokenPrefix}vertical-nav-default-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-strong, var(--custom-vertical-nav-default-item-active-bg, #{$light-comp-nav-vert-default-surface-selected-strong}));
  --#{$vudTokenPrefix}vertical-nav-default-item-open-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-weak, var(--custom-vertical-nav-default-item-open-bg, #{$light-comp-nav-vert-default-surface-selected-weak}));
  --#{$vudTokenPrefix}vertical-nav-default-sublvl-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-strong, var(--custom-vertical-nav-default-sublvl-active-bg, #{$light-comp-nav-vert-default-surface-selected-strong}));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-vertical-nav-default-collapse-btn-bg, #{$light-comp-nav-vert-brand-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-hover-bg: var(--custom-vertical-nav-default-collapse-btn-hover-bg, var(--#{$vudTokenPrefix}blue-40));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-shadow: var(--custom-vertical-nav-default-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-50)}, 0.3));
  --#{$vudTokenPrefix}vertical-nav-default-icon-color: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-icon-default, var(--custom-vertical-nav-default-icon-color, #{$light-comp-nav-vert-default-icon-default}));
  --#{$vudTokenPrefix}vertical-nav-default-icon-disabled-color: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-icon-disabled, var(--custom-vertical-nav-default-icon-disabled-color, #{$light-comp-nav-vert-default-icon-disabled}));
  --#{$vudTokenPrefix}vertical-nav-default-divider-bg: var(--custom-vertical-nav-default-divider-bg, var(--#{$vudTokenPrefix}neutral-30));
  --#{$vudTokenPrefix}vertical-nav-default-badge-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-overlay-default, var(--custom-vertical-nav-default-badge-bg, #{$light-comp-situation-funct-surface-overlay-default}));

  --#{$vudTokenPrefix}vertical-nav-primary-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-vertical-nav-primary-bg, #{$light-comp-nav-vert-brand-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-primary-shadow: var(--custom-vertical-nav-primary-shadow, --#{$vudTokenPrefix}vertical-nav-default-shadow);
  --#{$vudTokenPrefix}vertical-nav-primary-item-text: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-text-default, var(--custom-vertical-nav-primary-item-text, #{$light-comp-nav-vert-brand-text-default}));
  --#{$vudTokenPrefix}vertical-nav-primary-item-hover-bg: var(--custom-vertical-nav-primary-item-hover-bg, rgba(255, 255, 255, 0.08));
  --#{$vudTokenPrefix}vertical-nav-primary-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-selected-strong, var(--custom-vertical-nav-primary-item-active-bg, #{$light-comp-nav-vert-brand-surface-selected-strong}));
  --#{$vudTokenPrefix}vertical-nav-primary-item-open-bg: var(--custom-vertical-nav-primary-item-open-bg, rgba(37, 38, 38, 0.08));
  --#{$vudTokenPrefix}vertical-nav-primary-item-disabled: var(--custom-vertical-nav-primary-item-disabled, rgba(#{hex-to-rgb($neutral-05)}, 0.5));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-bg: var(--custom-vertical-nav-primary-collapse-bg, rgba(#{hex-to-rgb($blue-90)}, 0.5));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-icon-default, var(--custom-vertical-nav-primary-collapse-btn-bg, #{$light-comp-nav-vert-brand-icon-default}));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-hover-bg: var(--custom-vertical-nav-primary-collapse-btn-hover-bg, var(--#{$vudTokenPrefix}blue-10));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-arrow: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-vertical-nav-primary-collapse-arrow, #{$light-comp-nav-vert-brand-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-shadow: var(--custom-vertical-nav-primary-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.15));
  --#{$vudTokenPrefix}vertical-nav-primary-divider-bg: var(--custom-vertical-nav-primary-divider-bg, rgba(255, 255, 255, 0.12));

  --#{$vudTokenPrefix}vertical-nav-secondary-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-surface-default, var(--custom-vertical-nav-secondary-bg, #{$light-comp-nav-vert-inverted-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-secondary-border-color: var(--custom-vertical-nav-secondary-border-color, var(--#{$vudTokenPrefix}vertical-nav-secondary-bg));
  --#{$vudTokenPrefix}vertical-nav-secondary-item-text: var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-icon-default, #{$light-comp-nav-vert-inverted-icon-default});
  --#{$vudTokenPrefix}vertical-nav-secondary-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-surface-selected-strong, var(--custom-vertical-nav-secondary-item-active-bg, #{$light-comp-nav-vert-inverted-surface-selected-strong}));
  --#{$vudTokenPrefix}vertical-nav-secondary-item-open-bg: var(--custom-vertical-nav-secondary-item-open-bg, rgba(255, 255, 255, 0.08));
  --#{$vudTokenPrefix}vertical-nav-secondary-item-hover-bg: var(--custom-vertical-nav-secondary-item-hover-bg, rgba(255, 255, 255, 0.12));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapsed-border-color: var(--custom-vertical-nav-secondary-collapsed-border-color, var(--#{$vudTokenPrefix}neutral-80));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-icon-default, var(--custom-vertical-nav-secondary-collapse-btn-bg, #{$light-comp-nav-vert-inverted-icon-default}));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-hover-bg: var(--custom-vertical-nav-secondary-collapse-btn-hover-bg, var(--#{$vudTokenPrefix}neutral-30));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapsed-btn-hover-bg: var(--custom-vertical-nav-secondary-collapsed-btn-hover-bg, var(--#{$vudTokenPrefix}neutral-70));

  //=== j. Modals
  --#{$vudTokenPrefix}modal-bg: var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-modal-bg, #{$light-comp-content-surface-enabled}));
  --#{$vudTokenPrefix}modal-shadow: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-2, var(--custom-modal-shadow, #{$light-ref-shadow-default-elevation-2}));
  --#{$vudTokenPrefix}modal-overlay-bg: var(--custom-modal-overlay-bg, rgba(#{hex-to-rgb($primary-neutral-light)}, 0.5));


  //=== k. Forms
  // inputs/textarea
  --#{$vudTokenPrefix}input-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-default, var(--custom-input-text-color, #{$light-comp-inputs-text-default}));
  --#{$vudTokenPrefix}input-placeholder-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-secondary, var(--custom-input-placeholder-color, #{$light-comp-inputs-text-secondary}));
  --#{$vudTokenPrefix}input-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-enabled, var(--custom-input-border-color, #{$light-comp-inputs-border-enabled}));
  --#{$vudTokenPrefix}input-bg: var(--#{$backupVudTokenPrefix}comp-inputs-surface-enabled, var(--custom-input-bg, #{$light-comp-inputs-surface-enabled}));
  --#{$vudTokenPrefix}input-shadow-color: var(--custom-input-shadow-color, transparent);

  // inputs - hover state
  --#{$vudTokenPrefix}input-hover-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-hover, var(--custom-input-hover-border-color, #{$light-comp-inputs-border-hover}));
  --#{$vudTokenPrefix}input-hover-bg: var(--#{$backupVudTokenPrefix}comp-inputs-surface-hover, var(--custom-input-hover-bg, #{$light-comp-inputs-surface-hover}));
  --#{$vudTokenPrefix}input-hover-shadow-color: var(--custom-input-hover-shadow-color, #{$light-ref-shadow-default-elevation-1});

  // inputs - focus state
  --#{$vudTokenPrefix}input-focus-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-focused, var(--custom-input-focus-border-color, #{$light-comp-inputs-border-focused}));
  --#{$vudTokenPrefix}input-focus-shadow-color: var(--custom-input-focus-shadow-color, #{$light-ref-shadow-default-elevation-1});

  // inputs - disabled state
  --#{$vudTokenPrefix}input-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-disabled, var(--custom-input-disabled-text-color, #{$light-comp-inputs-text-disabled}));
  --#{$vudTokenPrefix}input-disabled-bg: var(--#{$backupVudTokenPrefix}comp-inputs-surface-disabled, var(--custom-input-disabled-bg, #{$light-comp-inputs-surface-disabled}));
  --#{$vudTokenPrefix}input-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-disabled, var(--custom-input-disabled-border-color, #{$light-comp-inputs-border-disabled}));

  // error inputs
  --#{$vudTokenPrefix}input-error-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-error, var(--custom-input-error-text-color, #{$light-comp-inputs-text-error}));
  --#{$vudTokenPrefix}input-error-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-error, var(--custom-input-error-border-color, #{$light-comp-inputs-border-error}));

  // labels for inputs
  --#{$vudTokenPrefix}input-label-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-default, var(--custom-input-label-text-color, #{$light-comp-inputs-text-default}));
  --#{$vudTokenPrefix}input-label-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-disabled, var(--custom-input-label-disabled-text-color, #{$light-comp-inputs-text-disabled}));

  // inputs - primary
  --#{$vudTokenPrefix}input-primary-border-color: var(--#{$backupVudTokenPrefix}comp-search-brand-border-enabled, var(--custom-input-primary-border-color, #{$light-comp-search-brand-border-enabled}));
  --#{$vudTokenPrefix}input-primary-shadow-color: var(--custom-input-primary-shadow-color, #{$light-ref-shadow-brand-elevation-1});

  // inputs - primary - hover
  --#{$vudTokenPrefix}input-primary-hover-border-color: var(--#{$backupVudTokenPrefix}comp-search-brand-border-hover, var(--custom-input-primary-hover-border-color, #{$light-comp-search-brand-border-hover}));

  // inputs - primary - disabled
  --#{$vudTokenPrefix}search-field-primary-disabled-button: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-disabled, var(--custom-search-field-primary-disabled-button, #{$light-comp-btn-primary-on-confimatory-surface-disabled}));
  --#{$vudTokenPrefix}search-field-primary-disabled-button-border: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-disabled, var(--custom-search-field-primary-disabled-button-border, #{$light-comp-btn-primary-on-confimatory-border-disabled}));


  // switches
  --#{$vudTokenPrefix}switches-text-color: var(--#{$backupVudTokenPrefix}comp-switch-text-default, var(--custom-switches-text-color, #{$light-comp-switch-text-default}));
  --#{$vudTokenPrefix}switches-border-color: var(--#{$backupVudTokenPrefix}comp-switch-border-enabled, var(--custom-switches-border-color, #{$light-comp-switch-border-enabled}));
  --#{$vudTokenPrefix}switches-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-enabled, var(--custom-switches-bg, #{$light-comp-switch-surface-enabled}));
  --#{$vudTokenPrefix}switches-togglemark-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-strong, var(--custom-switches-togglemark-bg, #{$light-comp-switch-surface-strong}));
  --#{$vudTokenPrefix}switches-togglemark-shadow-color: var(--custom-switches-togglemark-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0));

  // switches - hover
  --#{$vudTokenPrefix}switches-hover-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-hover, var(--custom-switches-hover-bg, #{$light-comp-switch-surface-hover}));

  // switches - focus
  --#{$vudTokenPrefix}switches-focus-border-color: var(--#{$backupVudTokenPrefix}comp-switch-border-focused, var(--custom-switches-focus-border-color, #{$light-comp-switch-border-focused}));
  --#{$vudTokenPrefix}switches-focus-inner-border-color: var(--custom-switches-focus-inner-border-color, var(--#{$vudTokenPrefix}neutral-05));

  // switches - disabled
  --#{$vudTokenPrefix}switches-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-switch-text-disabled, var(--custom-switches-disabled-text-color, #{$light-comp-switch-text-disabled}));
  --#{$vudTokenPrefix}switches-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-switch-border-disabled, #{$light-comp-switch-border-disabled});
  --#{$vudTokenPrefix}switches-disabled-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-switches-disabled-bg, #{$light-comp-switch-surface-disabled}));
  --#{$vudTokenPrefix}switches-disabled-togglemark-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-switches-disabled-togglemark-bg, #{$light-comp-switch-surface-disabled}));

  // switches - checked
  --#{$vudTokenPrefix}switches-checked-text-color: var(--#{$backupVudTokenPrefix}comp-switch-text-inverse, var(--custom-switches-checked-text-color, #{$light-comp-switch-text-inverse}));
  --#{$vudTokenPrefix}switches-checked-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-selected-strong, var(--custom-switches-checked-bg, #{$light-comp-switch-surface-selected-strong}));

  // switches - hover - checked
  --#{$vudTokenPrefix}switches-checked-hover-bg: var(--#{$backupVudTokenPrefix}-comp-switch-surface-selected-strong-hover, var(--custom-switches-checked-hover-bg, #{$light-comp-switch-surface-selected-strong-hover}));

  // switches - disabled - checked
  --#{$vudTokenPrefix}switches-checked-disabled-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-switches-checked-disabled-bg, #{$light-comp-switch-surface-disabled}));


  // radio-checkbox
  --#{$vudTokenPrefix}radio-checkbox-text-color: var(--#{$backupVudTokenPrefix}comp-toggle-text-default, var(--custom-radio-checkbox-text-color, #{$light-comp-toggle-text-default}));
  --#{$vudTokenPrefix}radio-checkbox-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-enabled, var(--custom-radio-checkbox-border-color, #{$light-comp-toggle-border-enabled}));
  --#{$vudTokenPrefix}radio-checkbox-bg: var(--#{$backupVudTokenPrefix}comp-toggle-surface-enabled, var(--custom-radio-checkbox-bg, #{$light-comp-toggle-surface-enabled}));
  --#{$vudTokenPrefix}radio-checkbox-checkmark-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-emphasis, var(--custom-radio-checkmark-bg, #{$light-comp-toggle-icon-emphasis}));
  --#{$vudTokenPrefix}radio-checkbox-dot-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-inverse, var(--custom-radio-checkbox-dot-bg, #{$light-comp-toggle-icon-inverse}));

  // radio-checkbox - focus
  --#{$vudTokenPrefix}radio-checkbox-focus-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-focused, var(--custom-radio-checkbox-focus-border-color, #{$light-comp-toggle-border-focused}));
  --#{$vudTokenPrefix}radio-checkbox-error-focus-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-error, var(--custom-radio-checkbox-error-focus-border-color, #{$light-comp-toggle-border-error}));

  // radio-checkbox - disabled
  --#{$vudTokenPrefix}radio-checkbox-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-toggle-text-disabled, var(--custom-radio-checkbox-disabled-text-color, #{$light-comp-toggle-text-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-disabled, var(--custom-radio-checkbox-disabled-border-color, #{$light-comp-toggle-border-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-disabled-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-disabled-bg, #{$light-comp-toggle-icon-disabled}));

  // radio-checkbox - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-emphasis, var(--custom-radio-checkbox-checked-border-color, #{$light-comp-toggle-border-emphasis}));
  --#{$vudTokenPrefix}radio-checkbox-checked-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-emphasis, var(--custom-radio-checkbox-checked-bg, #{$light-comp-toggle-icon-emphasis}));

  // radio-checkbox - disabled - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-disabled, var(--custom-radio-checkbox-checked-disabled-border-color, #{$light-comp-toggle-border-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-bg: var(--#{$backupVudTokenPrefix}comp-toggle-surface-disabled, var(--custom-radio-checkbox-checked-disabled-bg, #{$light-comp-toggle-surface-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-radio-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-checked-disabled-radio-bg, #{$light-comp-toggle-icon-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-checkmark-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-checked-disabled-checkmark-bg, #{$light-comp-toggle-icon-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-dot-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-checked-disabled-dot-bg, #{$light-comp-toggle-icon-disabled}));

  // radio-checkbox - read only - checked
  --#{$vudTokenPrefix}radio-checkbox-read-only-bg: var(--#{$backupVudTokenPrefix}comp-toggle-surface-disabled, var(--custom-radio-checkbox-read-only-bg, #{$light-comp-toggle-surface-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-read-only-checkmark-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-read-only-checkmark-bg, #{$light-comp-toggle-icon-disabled}));









  // selects/dropdowns
  --select-arrow: var(--custom-select-arrow, url(#{$image-path}/arrow-large.svg));
  --select-arrow-disabled: var(--custom-select-arrow-disabled, url(#{$image-path}/arrow-large-disabled.svg));


  // drop area
  --drop-area-bg: var(--custom-drop-area-bg, var(--#{$vudTokenPrefix}neutral-10));
  --drop-area-border-color: var(--custom-drop-area-border-color, var(--#{$vudTokenPrefix}neutral-70));
  --drop-area-highlight-border-color: var(--custom-drop-area-highlight-border-color, var(--#{$vudTokenPrefix}blue-50));
  --drop-area-highlight-bg: var(--custom-drop-area-highlight-bg, var(--#{$vudTokenPrefix}button-hover-bg));
  --drop-area-focus-border-color: var(--custom-drop-area-focus-border-color, var(--#{$vudTokenPrefix}blue-50));
  --drop-area-preview-item-bg: var(--custom-drop-area-preview-item-bg, var(--#{$vudTokenPrefix}neutral-20));
  --drop-area-preview-item-op: var(--custom-drop-area-preview-item-op, 1);
  --drop-area-preview-item-border-color: var(--custom-drop-area-preview-item-border-color, var(--#{$vudTokenPrefix}neutral-60));


  //=== l. Images
  --image-no-alt-border-color: var(--custom-image-no-alt-border-color, var(--#{$vudTokenPrefix}primary-orange));
  --thumbnail-border-color: var(--custom-thumbnail-border-color, var(--#{$vudTokenPrefix}neutral-50));








  //=== m. Table
  --#{$vudTokenPrefix}table-border-color: var(--#{$backupVudTokenPrefix}comp-separator-moderate, var(--custom-table-border-color, #{$light-comp-separator-moderate}));
  --#{$vudTokenPrefix}table-dotted-divider-color: var(--#{$backupVudTokenPrefix}comp-separator-strong, var(--custom-table-dotted-divider-color, #{$light-comp-separator-strong}));
  --#{$vudTokenPrefix}table-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-enabled, var(--custom-table-bg, #{$light-comp-table-surface-enabled}));
  --#{$vudTokenPrefix}table-alt-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-muted, var(--custom-table-alt-bg, #{$light-comp-table-surface-muted}));
  --#{$vudTokenPrefix}table-shadow-color: var(--custom-table-shadow-color, #{$light-ref-shadow-default-elevation-1});

  // table - cell hover
  --#{$vudTokenPrefix}table-hover-border-color: var(--#{$backupVudTokenPrefix}comp-table-border-hover, var(--custom-table-hover-border-color, #{$light-comp-table-border-hover}));
  --#{$vudTokenPrefix}table-hover-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-hover, var(--custom-table-hover-bg, #{$light-comp-table-surface-hover}));

  // table - cell active/pressed state
  --#{$vudTokenPrefix}table-active-border-color: var(--#{$backupVudTokenPrefix}comp-table-border-selected, var(--custom-table-active-border-color, #{$light-comp-table-border-selected}));
  --#{$vudTokenPrefix}table-active-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-selected, var(--custom-table-active-bg, #{$light-comp-table-surface-selected}));

  // table - cell active/selected state
  --#{$vudTokenPrefix}table-selected-border-color: var(--#{$backupVudTokenPrefix}comp-table-border-selected, var(--custom-table-selected-border-color, #{$light-comp-table-border-selected}));
  --#{$vudTokenPrefix}table-selected-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-selected, var(--custom-table-selected-bg, #{$light-comp-table-surface-selected}));

  // table - error row state
  --#{$vudTokenPrefix}table-error-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-error-hover, var(--custom-table-error-bg, #{$light-comp-table-surface-error-hover}));
  --#{$vudTokenPrefix}table-error-text-color: var(--#{$backupVudTokenPrefix}comp-table-text-error-moderate, #{$light-comp-table-text-error-moderate});

  //=== n. Stepper
  --#{$vudTokenPrefix}stepper-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-disabled, var(--custom-stepper-text-color, #{$light-comp-nav-stepper-text-disabled}));
  --#{$vudTokenPrefix}stepper-bg: var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-weak, var(--custom-stepper-bg, #{$light-comp-nav-stepper-surface-weak}));
  --stepper-wrapper-text-color: var(--custom-stepper-wrapper-text-color, var(--#{$vudTokenPrefix}primary-neutral));
  --stepper-wrapper-bg: var(--custom-stepper-wrapper-bg, var(--#{$vudTokenPrefix}neutral-05));

  // stepper - active/selected state
  --#{$vudTokenPrefix}stepper-active-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-emphasis, var(--custom-stepper-active-text-color, #{$light-comp-nav-stepper-text-emphasis}));
  --#{$vudTokenPrefix}stepper-active-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-strong, var(--custom-stepper-active-border-color, #{$light-comp-nav-stepper-surface-strong}));
  --stepper-active-bg: var(--custom-stepper-active-bg, var(--#{$vudTokenPrefix}neutral-05));
  --stepper-active-shadow-color: var(--custom-stepper-active-shadow-color, rgba(#{hex-to-rgb($blue-50)}, 0.25));

  // stepper - visited state
  --#{$vudTokenPrefix}stepper-visited-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-success, var(--custom-stepper-visited-text-color, #{$light-comp-nav-stepper-text-success}));
  --#{$vudTokenPrefix}stepper-visited-bg: var(--#{$backupVudTokenPrefix}comp-nav-stepper-icon-success, var(--custom-stepper-visited-bg, #{$light-comp-nav-stepper-icon-success}));


  //=== o. Progress Bar
  --#{$vudTokenPrefix}progress-bar-label: var(--#{$backupVudTokenPrefix}comp-progress-text-secondary, var(--custom-progress-bar-label, #{$light-comp-progress-text-secondary}));
  --#{$vudTokenPrefix}progress-bar-wrapper-bg: var(--#{$backupVudTokenPrefix}comp-progress-default-border-weak, var(--custom-progress-bar-wrapper-bg, #{$light-comp-progress-default-border-weak}));
  --#{$vudTokenPrefix}progress-bar-bg: var(--#{$backupVudTokenPrefix}comp-progress-secondary-border-strong, var(--custom-progress-bar-bg, #{$light-comp-progress-secondary-border-strong}));
  // --progress-bar-shadow-color: var(--custom-progress-bar-shadow-color, rgba(#{hex-to-rgb($primary-blue)}, 0.15));


  //=== m. Alerts and toasts
  --#{$vudTokenPrefix}alert-success-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-success-weak, var(--custom-alert-success-bg, #{$light-comp-situation-funct-surface-success-weak}));
  --#{$vudTokenPrefix}alert-success-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-success-weak, var(--custom-alert-success-border, #{$light-comp-situation-funct-border-success-weak}));
  --#{$vudTokenPrefix}alert-success-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-success-moderate, var(--custom-alert-success-text, #{$light-comp-situation-funct-text-success-moderate}));
  --#{$vudTokenPrefix}alert-success-shadow: var(--custom-alert-success-shadow, #{$light-ref-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-info-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-info-weak, var(--custom-alert-info-bg, #{$light-comp-situation-funct-surface-info-weak}));
  --#{$vudTokenPrefix}alert-info-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-info-weak, var(--custom-alert-info-border, #{$light-comp-situation-funct-border-info-weak}));
  --#{$vudTokenPrefix}alert-info-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-info-moderate, var(--custom-alert-info-text, #{$light-comp-situation-funct-text-info-moderate}));
  --#{$vudTokenPrefix}alert-info-shadow: var(--custom-alert-info-shadow, #{$light-ref-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-warning-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-warning-weak, var(--custom-alert-warning-bg, #{$light-comp-situation-funct-surface-warning-weak}));
  --#{$vudTokenPrefix}alert-warning-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-warning-weak, var(--custom-alert-warning-border, #{$light-comp-situation-funct-border-warning-weak}));
  --#{$vudTokenPrefix}alert-warning-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-warning-moderate, var(--custom-alert-warning-text, #{$light-comp-situation-funct-text-warning-moderate}));
  --#{$vudTokenPrefix}alert-warning-shadow: var(--custom-alert-warning-shadow, #{$light-ref-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-danger-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-error-weak, var(--custom-alert-danger-bg, #{$light-comp-situation-funct-surface-error-weak}));
  --#{$vudTokenPrefix}alert-danger-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-error-weak, var(--custom-alert-danger-border, #{$light-comp-situation-funct-border-error-weak}));
  --#{$vudTokenPrefix}alert-danger-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-error-moderate, var(--custom-alert-danger-text, #{$light-comp-situation-funct-text-error-moderate}));
  --#{$vudTokenPrefix}alert-danger-shadow: var(--custom-alert-danger-shadow, #{$light-ref-shadow-default-elevation-1});


  //=== n. Popovers and tooltips
  --popover-shadow: var(--custom-popover-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.08));
  --popover-arrow-tip: var(--custom-popover-arrow-tip, url(#{$image-path}/popover-tip.svg));


  //Tooltips
  --tooltip-bg: var(--custom-tooltip-bg, var(--#{$vudTokenPrefix}orange-05));
  --tooltip-shadow: var(--custom-tooltip-shadow, #{shadow(30)} rgba(#{hex-to-rgb($orange-70)}, 0.15));
  --tooltip-border-color: var(--custom-tooltip-border-color, var(--#{$vudTokenPrefix}orange-40));
  --tooltip-arrow-tip: var(--custom-tooltip-arrow-tip, url(#{$image-path}/tooltip-tip.svg));

  --tooltip-primary-bg: var(--custom-tooltip-primary-bg, var(--#{$vudTokenPrefix}neutral-05));
  --tooltip-primary-shadow: var(--custom-tooltip-primary-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.08));
  --tooltip-primary-border-color: var(--custom-tooltip-primary-border-color, var(--#{$vudTokenPrefix}neutral-60));
  --tooltip-primary-text: var(--custom-tooltip-primary-text, var(--#{$vudTokenPrefix}neutral-90));
  --tooltip-primary-arrow-tip: var(--custom-tooltip-primary-arrow-tip, url(#{$image-path}/tooltip-tip-primary.svg));

  --tooltip-error-bg: var(--custom-tooltip-error-bg, var(--#{$vudTokenPrefix}red-05));
  --tooltip-error-shadow: var(--custom-tooltip-error-shadow, #{shadow(30)} rgba(#{hex-to-rgb($red-70)}, 0.15));
  --tooltip-error-border-color: var(--custom-tooltip-error-border-color, var(--#{$vudTokenPrefix}red-60));
  --tooltip-error-text: var(--custom-tooltip-error-text, var(--#{$vudTokenPrefix}red-80));
  --tooltip-error-arrow-tip: var(--custom-tooltip-error-arrow-tip, url(#{$image-path}/tooltip-tip-error.svg));

  --tooltip-secondary-bg: var(--custom-tooltip-secondary-bg, var(--#{$vudTokenPrefix}neutral-90));
  --tooltip-secondary-shadow: var(--custom-tooltip-secondary-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.08));
  --tooltip-secondary-border-color: var(--custom-tooltip-secondary-border-color, var(--tooltip-secondary-bg));
  --tooltip-secondary-text: var(--custom-tooltip-secondary-text, var(--#{$vudTokenPrefix}neutral-30));
  --tooltip-secondary-arrow-tip: var(--custom-tooltip-secondary-arrow-tip, url(#{$image-path}/tooltip-tip-secondary.svg));


  //=== o. Datepicker
  --#{$vudTokenPrefix}datepicker-inline-bg: var(--#{$backupVudTokenPrefix}comp-calendar-surface-enabled, var(--custom-datepicker-inline-bg, #{$light-comp-calendar-surface-enabled}));
  --datepicker-popup-bg: var(--custom-datepicker-popup-bg, var(--#{$vudTokenPrefix}module-container-bg-color));

  --datepicker-arrow: var(--custom-datepicker-arrow, url(#{$image-path}/arrow-small-blue.svg));
  --#{$vudTokenPrefix}datepicker-border-color: var(--#{$backupVudTokenPrefix}comp-separator-moderate, var(--custom-datepicker-border-color, #{$light-comp-separator-moderate}));
  --datepicker-nonclickable-text-color: var(--custom-datepicker-nonclickable-text-color, var(--#{$vudTokenPrefix}neutral-80));
  --datepicker-item-hover-bg: var(--custom-datepicker-item-hover-bg, var(--#{$vudTokenPrefix}blue-10));
  --datepicker-item-text: var(--custom-datepicker-item-text, var(--#{$vudTokenPrefix}neutral-90));
  --datepicker-item-today-border: var(--custom-datepicker-item-today-border, var(--#{$vudTokenPrefix}blue-50));
  --datepicker-item-selected-bg: var(--custom-datepicker-item-selected-bg, var(--#{$vudTokenPrefix}blue-60));
  --datepicker-item-selected-focus-border: var(--custom-datepicker-item-selected-focus-border, var(--#{$vudTokenPrefix}neutral-05));
  --datepicker-item-selected-text: var(--custom-datepicker-item-selected-text, var(--#{$vudTokenPrefix}neutral-05));
  --datepicker-item-selected-range-bg: var(--custom-datepicker-item-selected-range-bg, var(--#{$vudTokenPrefix}neutral-20));
  --datepicker-item-hover-range-bg: var(--custom-datepicker-item-hover-range-bg, var(--#{$vudTokenPrefix}blue-10));
  --datepicker-item-range-focus-shadow: var(--custom-datepicker-item-range-focus-shadow, var(--#{$vudTokenPrefix}blue-60));
  --datepicker-item-range-focus-border: var(--custom-datepicker-item-range-focus-border, var(--#{$vudTokenPrefix}neutral-05));

  --datepicker-today-btn-border: var(--custom-datepicker-today-btn-border, var(--#{$vudTokenPrefix}blue-50));
  --datepicker-today-btn-text: var(--custom-datepicker-today-btn-text, var(--#{$vudTokenPrefix}blue-60));

  --datepicker-clear-btn-border: var(--custom-datepicker-clear-btn-border, var(--#{$vudTokenPrefix}red-50));
  --datepicker-clear-btn-text: var(--custom-datepicker-clear-btn-text, var(--#{$vudTokenPrefix}red-70));


  //=== p. Tabs
  --#{$vudTokenPrefix}tabs-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-default, var(--custom-tabs-text-color, #{$light-comp-nav-stepper-text-default}));
  --#{$vudTokenPrefix}tabs-focused-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-default, var(--custom-tabs-focused-text-color, #{$light-comp-nav-stepper-text-default}));
  --#{$vudTokenPrefix}tabs-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-disabled, var(--custom-tabs-disabled-text-color, #{$light-comp-nav-stepper-text-disabled}));
  --#{$vudTokenPrefix}tabs-content-border-color: var(--#{$backupVudTokenPrefix}comp-separator-extreme, var(--custom-tabs-content-border-color, #{$light-comp-separator-extreme}));
  --#{$vudTokenPrefix}tabs-content-secondary-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-pressed, var(--custom-tabs-content-secondary-border-color, #{$light-comp-nav-stepper-surface-pressed}));
  --tabs-content-bg: var(--custom-tabs-content-bg, var(--#{$vudTokenPrefix}neutral-05));
  --tabs-content-shadow-color: var(--custom-tabs-content-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0.1));

  --tabs-normal-Ypoition:              #{rem(1px)};
  --tabs-disabled-Ypoition:            #{rem(-49px)};
  --tabs-hover-Ypoition:               #{rem(-99px)};
  --tabs-selected-Ypoition:            #{rem(-148px)};
  --tabs-focus-Ypoition:               #{rem(-199px)};
  --tabs-focus-hover-Ypoition:         #{rem(-249px)};
  --tabs-selected-focused-Ypoition:    #{rem(-299px)};
  --tabs-pressed-Ypoition:             var(--tabs-selected-Ypoition);

  --tabs-dropdown-Ypoition:               #{rem(8px)};
  --tabs-dropdown-normal-Xpoition:        #{rem(0px)};
  --tabs-dropdown-hover-Xpoition:       #{rem(-50px)};
  --tabs-dropdown-focus-Xpoition:       #{rem(-100px)};
  --tabs-dropdown-pressed-Xpoition:    #{rem(-150px)};
  --tabs-dropdown-disabled-Xpoition:   #{rem(-200px)};


  //=== q. Helper classes
  //Borders
  --border-color: var(--custom-border-color, var(--#{$vudTokenPrefix}neutral-50));


  //=== r. Login page
  --mask-bg: var(--custom-mask-bg, rgba(#{hex-to-rgb($blue-90)}, 0));

  --cookies-alert-bg: var(--custom-cookies-alert-bg, var(--#{$vudTokenPrefix}neutral-05));
  --cookies-alert-secondary-bg: var(--custom-cookies-alert-secondary-bg, var(--#{$vudTokenPrefix}neutral-20));
  --cookies-alert-text: var(--custom-cookies-alert-text, var(--#{$vudTokenPrefix}neutral-90));

  --password-field-icon: var(--custom-password-field-icon, url(#{$image-path}/login/password-icon.svg));

  --visma-logo: var(--custom-visma-logo, url(#{$image-path}/visma-logo.svg));


  //=== s. Error pages
  --error-img-opacity: var(--custom-error-img-opacity, 1);


  //=== t. Spinners
  --spinner-default-blue: var(--custom-spinner-default-blue, url(#{$image-path}/spinners/spinner_doughnut_blue_light.svg));
  --spinner-default-grey: var(--custom-spinner-default-grey, url(#{$image-path}/spinners/spinner_doughnut_grey_light.svg));
  --spinner-default-green: var(--custom-spinner-default-green, url(#{$image-path}/spinners/spinner_doughnut_green_light.svg));

  --spinner-primary-blue: var(--custom-spinner-primary-blue, url(#{$image-path}/spinners/spinner_dotted_blue_light.svg));
  --spinner-primary-grey: var(--custom-spinner-primary-grey, url(#{$image-path}/spinners/spinner_dotted_grey_light.svg));
  --spinner-primary-green: var(--custom-spinner-primary-green, url(#{$image-path}/spinners/spinner_dotted_green_light.svg));

  --spinner-secondary-blue: var(--custom-spinner-secondary-blue, url(#{$image-path}/spinners/spinner_snake_blue_light.svg));
  --spinner-secondary-grey: var(--custom-spinner-secondary-grey, url(#{$image-path}/spinners/spinner_snake_grey_light.svg));
  --spinner-secondary-green: var(--custom-spinner-secondary-green, url(#{$image-path}/spinners/spinner_snake_green_light.svg));


  //=== u. Cards list
  --card-list-text-color: var(--custom-card-list-text-color, var(--#{$vudTokenPrefix}neutral-80));
  --card-list-secondary-bg: var(--custom-card-list-secondary-bg, var(--#{$vudTokenPrefix}neutral-20));

  --#{$vudTokenPrefix}hero-card-bg:  var(--#{$backupVudTokenPrefix}comp-content-surface-muted, var(--custom-hero-card-bg, #{$light-comp-content-surface-muted}));
  --hero-card-border-color:  var(--custom-hero-card-border-color, var(--#{$vudTokenPrefix}neutral-05));

  }

// END Light mode.



//
// ***
//



//
// 3. Dark mode CSS Variables
@mixin dark-mode {
  //=== a. Color palette
  //# Neutral palette
  --#{$vudTokenPrefix}neutral-90: #{$dark-vud-neutral-dark-90};
  --#{$vudTokenPrefix}neutral-80: #{$dark-vud-neutral-dark-80};
  --#{$vudTokenPrefix}neutral-70: #{$dark-vud-neutral-dark-70};
  --#{$vudTokenPrefix}neutral-60: #{$dark-vud-neutral-dark-60};
  --#{$vudTokenPrefix}neutral-50: #{$dark-vud-neutral-dark-50};
  --#{$vudTokenPrefix}neutral-40: #{$dark-vud-neutral-dark-40};
  --#{$vudTokenPrefix}neutral-30: #{$dark-vud-neutral-dark-30};
  --#{$vudTokenPrefix}neutral-20: #{$dark-vud-neutral-dark-20};
  --#{$vudTokenPrefix}neutral-10: #{$dark-vud-neutral-dark-10};
  --#{$vudTokenPrefix}neutral-05: #{$dark-vud-neutral-dark-05};

  --#{$vudTokenPrefix}primary-neutral: var(--#{$vudTokenPrefix}neutral-70);


  //=== b. Base body properties
  // Set site background color
  --#{$vudTokenPrefix}root-background-color: var(--#{$backupVudTokenPrefix}ref-fill-on-screen-default, var(--custom-dark-root-background-color, #{$dark-ref-fill-on-screen-default}));

  --#{$vudTokenPrefix}scrollbar-track-bg: var(--#{$backupVudTokenPrefix}comp-content-surface-muted, var(--#{$vudTokenPrefix}root-background-color, #{$dark-comp-content-surface-muted}));
  --#{$vudTokenPrefix}scrollbar-thumb-bg: var(--#{$backupVudTokenPrefix}comp-content-icon-disabled, #{$dark-comp-content-icon-disabled});
  --#{$vudTokenPrefix}scrollbar-thumb-drag-bg: var(--#{$backupVudTokenPrefix}comp-content-icon-default, #{$dark-comp-content-icon-default});

  // body properties
  --#{$vudTokenPrefix}body-font-color: var(--#{$backupVudTokenPrefix}comp-content-text-default, var(--custom-dark-body-font-color, #{$dark-comp-content-text-default}));
  --#{$vudTokenPrefix}titles-font-color: var(--custom-dark-titles-font-color, var(--#{$vudTokenPrefix}neutral-10));

  // Components background color
  --#{$vudTokenPrefix}module-container-bg-color: var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dark-module-container-bg-color, #{$dark-comp-content-surface-enabled}));
  --module-container-shadow: var(--custom-dark-module-container-shadow, #{shadow(10)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));
  --module-container-divider: var(--custom-dark-module-container-divider, var(--#{$vudTokenPrefix}neutral-80));
  --module-container-active-bg-color: var(--custom-dark-module-container-active-bg-color, var(--#{$vudTokenPrefix}neutral-80));
  --module-container-active-before-color: var(--custom-dark-module-container-active-before-color, var(--#{$vudTokenPrefix}blue-40));
  --module-container-hover-bg-color: var(--custom-dark-module-container-hover-bg-color, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.1));


  //=== c. Typography
  // body text
  --#{$vudTokenPrefix}text-default: var(--#{$backupVudTokenPrefix}ref-text-default, var(--custom-dark-text-default, #{$dark-ref-text-default}));
  --#{$vudTokenPrefix}text-alt: var(--#{$backupVudTokenPrefix}ref-text-inverse, var(--custom-dark-text-alt, #{$dark-ref-text-inverse}));
  --#{$vudTokenPrefix}text-primary: var(--#{$backupVudTokenPrefix}ref-text-emphasis, var(--custom-dark-text-primary, #{$dark-ref-text-emphasis}));
  --#{$vudTokenPrefix}text-secondary: var(--#{$backupVudTokenPrefix}ref-text-secondary, var(--custom-dark-text-secondary, #{$dark-ref-text-secondary}));
  --#{$vudTokenPrefix}text-disabled: var(--#{$backupVudTokenPrefix}ref-text-disabled, var(--custom-dark-text-disabled, #{$dark-ref-text-disabled}));
  --#{$vudTokenPrefix}text-error: var(--#{$backupVudTokenPrefix}ref-text-error-moderate, var(--custom-dark-text-error, #{$dark-ref-text-error-moderate}));
  --#{$vudTokenPrefix}text-warning: var(--#{$backupVudTokenPrefix}ref-text-warning-moderate, var(--custom-dark-text-warning, #{$dark-ref-text-warning-moderate}));
  --#{$vudTokenPrefix}text-success: var(--#{$backupVudTokenPrefix}ref-text-success-moderate, var(--custom-dark-text-success, #{$dark-ref-text-success-moderate}));


  // backgrounds
  --#{$vudTokenPrefix}bg-default: var(--#{$backupVudTokenPrefix}ref-fill-on-surface-default, var(--custom-dark-bg-default, #{$dark-ref-fill-on-surface-default}));
  --#{$vudTokenPrefix}bg-primary: var(--#{$backupVudTokenPrefix}ref-fill-on-screen-default, var(--custom-dark-bg-primary, #{$dark-ref-fill-on-screen-default}));
  --#{$vudTokenPrefix}bg-secondary: var(--#{$backupVudTokenPrefix}ref-fill-on-secondary-enabled, var(--custom-dark-bg-secondary, #{$dark-ref-fill-on-secondary-enabled}));


  // shadows
  --#{$vudTokenPrefix}shadow-40: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-4, var(--custom-dark-shadow-40, #{$dark-ref-shadow-default-elevation-4}));
  --#{$vudTokenPrefix}shadow-30: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-3, var(--custom-dark-shadow-30, #{$dark-ref-shadow-default-elevation-3}));
  --#{$vudTokenPrefix}shadow-20: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-2, var(--custom-dark-shadow-20, #{$dark-ref-shadow-default-elevation-2}));
  --#{$vudTokenPrefix}shadow-10: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-1, var(--custom-dark-shadow-10, #{$dark-ref-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}shadow-50: var(--#{$vudTokenPrefix}shadow-40);


  // anchor/breadcrumbs properties
  --#{$vudTokenPrefix}anchor-color: var(--#{$backupVudTokenPrefix}ref-text-links, var(--custom-dark-anchor-color, #{$dark-ref-text-links}));
  --#{$vudTokenPrefix}anchor-hover-color: var(--custom-dark-anchor-hover-color, var(--#{$vudTokenPrefix}blue-20));
  --#{$vudTokenPrefix}anchor-visited-color: var(--#{$backupVudTokenPrefix}ref-text-visited, #{$dark-ref-text-visited});
  --#{$vudTokenPrefix}anchor-focus-outline: var(--custom-dark-anchor-focus-outline, var(--#{$vudTokenPrefix}neutral-05));
  --#{$vudTokenPrefix}anchor-focus-outline-alt: var(--custom-dark-anchor-focus-outline-alt, var(--#{$vudTokenPrefix}neutral-05));
  --#{$vudTokenPrefix}anchor-disabled-color: var(--#{$backupVudTokenPrefix}ref-text-disabled, var(--custom-dark-anchor-disabled-color, #{$dark-ref-text-disabled}));

  // anchor/breadcrumbs - active/selected state
  --#{$vudTokenPrefix}anchor-selected-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-dark-anchor-selected-color, #{$dark-comp-nav-hor-default-text-default}));

  // 'mark' tag/element (highlighted text)
  --#{$vudTokenPrefix}highlighted-text-bg-color: var(--#{$backupVudTokenPrefix}ref-fill-on-mark-default, var(--custom-dark-highlighted-text-bg-color, #{$dark-ref-fill-on-mark-default}));
  --#{$vudTokenPrefix}highlighted-text-color: var(--#{$backupVudTokenPrefix}comp-list-text-inverse, var(--custom-dark-highlighted-text-color, #{$dark-comp-list-text-inverse}));


  // 'hr' tag/element
  --#{$vudTokenPrefix}divider-color: var(--#{$backupVudTokenPrefix}ref-border-strong, var(--custom-dark-divider-color, #{$dark-ref-border-strong}));
  --#{$vudTokenPrefix}divider-default-color: var(--#{$backupVudTokenPrefix}ref-border-moderate, var(--custom-dark-divider-default-color, #{$dark-ref-border-moderate}));
  --#{$vudTokenPrefix}divider-primary-color: var(--#{$backupVudTokenPrefix}ref-border-extreme, var(--custom-dark-divider-primary-color, #{$dark-ref-border-extreme}));
  --#{$vudTokenPrefix}divider-disabled-color: var(--#{$backupVudTokenPrefix}ref-border-weak, var(--custom-dark-divider-disabled-color, #{$dark-ref-border-weak}));
  --#{$vudTokenPrefix}divider-error-color: var(--#{$backupVudTokenPrefix}ref-border-on-funct-warning-weak, var(--custom-dark-divider-error-color, #{$dark-ref-border-on-funct-warning-weak}));
  --#{$vudTokenPrefix}fancy-divider-color: var(--divider-color);


  //=== d. Buttons
  // default button
  --#{$vudTokenPrefix}button-text-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-text-default, var(--custom-dark-button-text-color, #{$dark-comp-btn-default-on-confimatory-text-default}));
  --#{$vudTokenPrefix}button-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-enabled, var(--custom-dark-button-border-color, #{$dark-comp-btn-default-on-confimatory-border-enabled}));
  --button-inner-border-color: var(--custom-dark-button-inner-border-color, var(--#{$vudTokenPrefix}neutral-50));
  --#{$vudTokenPrefix}button-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-enabled, var(--custom-dark-button-bg, #{$dark-comp-btn-default-on-confimatory-surface-enabled}));
  --button-shadow-color: var(--custom-dark-button-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.12));
  --button-icon: var(--custom-dark-button-icon, var(--#{$vudTokenPrefix}neutral-05));
  --#{$vudTokenPrefix}button-icon-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-default, var(--custom-dark-button-icon-color, #{$dark-comp-btn-default-on-confimatory-icon-default}));

  // default button - hover state
  --#{$vudTokenPrefix}button-hover-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-hover, var(--custom-dark-button-hover-border-color, #{$dark-comp-btn-default-on-confimatory-border-hover}));
  --#{$vudTokenPrefix}button-hover-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-hover, var(--custom-dark-button-hover-bg, #{$dark-comp-btn-default-on-confimatory-surface-hover}));
  --button-hover-shadow-color: var(--custom-dark-button-hover-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));

  // default button - focus state
  --#{$vudTokenPrefix}button-focus-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-focused, var(--custom-dark-button-focus-border-color, #{$dark-comp-btn-default-on-confimatory-border-focused}));
  --button-focus-shadow-color: var(--custom-dark-button-focus-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));

  // default button - pressed state
  --#{$vudTokenPrefix}button-pressed-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-pressed, var(--custom-dark-button-pressed-border-color, #{$dark-comp-btn-default-on-confimatory-border-pressed}));
  --#{$vudTokenPrefix}button-pressed-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-pressed, var(--custom-dark-button-pressed-bg, #{$dark-comp-btn-default-on-confimatory-surface-pressed}));

  // default button - active state
  --#{$vudTokenPrefix}button-active-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-moderate, var(--custom-dark-button-active-border-color, #{$dark-comp-btn-default-on-confimatory-border-selected-moderate}));
  --#{$vudTokenPrefix}button-active-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-dark-button-active-bg, #{$dark-comp-btn-default-on-confimatory-surface-selected-moderate}));

  // default button - active/selected state
  --#{$vudTokenPrefix}button-selected-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-dark-button-selected-bg, #{$dark-comp-btn-default-on-confimatory-surface-selected-moderate}));

  // default button - disabled state
  --#{$vudTokenPrefix}button-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-text-disabled, var(--custom-dark-button-disabled-text-color, #{$dark-comp-btn-default-on-confimatory-text-disabled}));
  --#{$vudTokenPrefix}button-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-disabled, var(--custom-dark-button-disabled-border-color, #{$dark-comp-btn-default-on-confimatory-border-disabled}));
  --button-disabled-inner-border-color: var(--custom-dark-button-disabled-inner-border-color, var(--#{$vudTokenPrefix}neutral-60));
  --#{$vudTokenPrefix}button-disabled-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-disabled, var(--custom-dark-button-disabled-bg, #{$dark-comp-btn-default-on-confimatory-surface-disabled}));

  // Default Destructive button
  --#{$vudTokenPrefix}button-destructive-text-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-text-default, var(--custom-dark-button-destructive-text-color, #{$dark-comp-btn-default-on-destructive-text-default}));
  --#{$vudTokenPrefix}button-destructive-hover-text-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-text-inverse, #{$dark-comp-btn-default-on-destructive-text-inverse});
  --#{$vudTokenPrefix}button-destructive-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-border-enabled, var(--custom-dark-button-destructive-border-color, #{$dark-comp-btn-default-on-destructive-border-enabled}));
  --#{$vudTokenPrefix}button-destructive-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-enabled, var(--custom-dark-button-destructive-bg, #{$dark-comp-btn-default-on-destructive-surface-enabled}));
  --#{$vudTokenPrefix}button-destructive-hover-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-hover, var(--custom-dark-button-destructive-hover-bg, #{$dark-comp-btn-default-on-destructive-surface-hover}));
  --#{$vudTokenPrefix}button-destructive-pressed-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-pressed, var(--custom-dark-button-destructive-pressed-bg, #{$dark-comp-btn-default-on-destructive-surface-pressed}));


  // primary button
  --#{$vudTokenPrefix}button-primary-text-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-text-default, var(--custom-dark-button-primary-text-color, #{$dark-comp-btn-primary-on-confimatory-text-default}));
  --button-primary-border-color: var(--custom-dark-button-primary-border-color, var(--#{$vudTokenPrefix}blue-70));
  --button-primary-inner-border-color: var(--custom-dark-button-primary-inner-border-color, var(--#{$vudTokenPrefix}blue-80));
  --#{$vudTokenPrefix}button-primary-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-enabled, var(--custom-dark-button-primary-bg, #{$dark-comp-btn-primary-on-confimatory-surface-enabled}));
  --button-primary-shadow-color: var(--custom-dark-button-primary-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));
  --#{$vudTokenPrefix}button-primary-icon: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-icon-default, var(--custom-dark-button-primary-icon, #{$dark-comp-btn-primary-on-confimatory-icon-default}));
  --#{$vudTokenPrefix}facet-primary-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-strong, var(--custom-dark-facet-primary-bg, #{$dark-comp-btn-default-on-confimatory-surface-selected-strong}));

  // primary button - hover state
  --#{$vudTokenPrefix}button-primary-hover-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-hover, var(--custom-dark-button-primary-hover-border-color, #{$dark-comp-btn-primary-on-confimatory-border-hover}));
  --#{$vudTokenPrefix}button-primary-hover-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-hover, var(--custom-dark-button-primary-hover-bg, #{$dark-comp-btn-primary-on-confimatory-surface-hover}));

  // primary button - focus state
  --#{$vudTokenPrefix}button-primary-focus-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-focused, var(--custom-dark-button-primary-focus-border-color, #{$dark-comp-btn-primary-on-confimatory-border-focused}));
  --button-primary-focus-inner-shadow-color: var(--custom-dark-button-primary-focus-inner-shadow-color, transparent);
  --button-primary-focus-shadow-color: var(--custom-dark-button-primary-focus-shadow-color, var(--#{$vudTokenPrefix}button-primary-focus-border-color));

  // primary button - active/pressed state
  --#{$vudTokenPrefix}button-primary-active-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-pressed, var(--custom-dark-button-primary-active-border-color, #{$dark-comp-btn-primary-on-confimatory-border-pressed}));
  --#{$vudTokenPrefix}button-primary-active-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-pressed, var(--custom-dark-button-primary-active-bg, #{$dark-comp-btn-primary-on-confimatory-surface-pressed}));

  // primary button - active/selected state
  --#{$vudTokenPrefix}button-primary-selected-border-color: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-strong, var(--custom-dark-button-primary-selected-border-color, #{$dark-comp-btn-default-on-confimatory-border-selected-strong}));
  --#{$vudTokenPrefix}button-primary-selected-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-strong, var(--custom-dark-button-primary-selected-bg, #{$dark-comp-btn-default-on-confimatory-surface-selected-strong}));

  // Primary Destructive button
  --#{$vudTokenPrefix}button-primary-destructive-active-border-color: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-border-pressed, var(--custom-dark-button-primary-destructive-active-border-color, #{$dark-comp-btn-primary-on-destructive-border-pressed}));
  --#{$vudTokenPrefix}button-primary-destructive-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-surface-enabled, var(--custom-dark-button-primary-destructive-bg, #{$dark-comp-btn-primary-on-destructive-surface-enabled}));
  --#{$vudTokenPrefix}button-primary-destructive-active-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-surface-pressed, var(--custom-dark-button-primary-destructive-active-bg, #{$dark-comp-btn-primary-on-destructive-surface-pressed}));


  //# dropdown button
  --#{$vudTokenPrefix}dropdown-menu-text-color: var(--#{$backupVudTokenPrefix}comp-list-text-default, var(--custom-dark-dropdown-menu-text-color, #{$dark-comp-list-text-default}));
  --#{$vudTokenPrefix}dropdown-menu-bg: var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dark-dropdown-menu-bg, #{$dark-comp-content-surface-enabled}));
  --#{$vudTokenPrefix}dropdown-menu-shadow-color: var(--#{$backupVudTokenPrefix}ref-box-shadow-default-elevation-01, var(--#{$vudTokenPrefix}shadow-10)); // check Narchie

  // dropdown button - hover state
  --#{$vudTokenPrefix}dropdown-menu-hover-bg: var(--#{$backupVudTokenPrefix}comp-list-surface-hover, var(--custom-dark-dropdown-menu-hover-bg, #{$dark-comp-list-surface-hover}));

  // dropdown button - active/selected state
  --#{$vudTokenPrefix}dropdown-menu-selected-text-color: var(--#{$backupVudTokenPrefix}comp-list-text-emphasis, var(--custom-dark-dropdown-menu-selected-text-color, #{$dark-comp-list-text-emphasis}));

  // dropdown arrow
  --#{$vudTokenPrefix}dropdown-arrow-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-default, var(--custom-dark-dropdown-arrow-bg, #{$dark-comp-btn-default-on-confimatory-icon-default}));
  --#{$vudTokenPrefix}dropdown-arrow-disabled-bg: var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-disabled, var(--custom-dark-dropdown-arrow-disabled-bg, #{$dark-comp-btn-default-on-confimatory-icon-disabled}));

  --#{$vudTokenPrefix}dropdown-primary-arrow-bg: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-icon-default, var(--custom-dark-dropdown-primary-arrow-bg, #{$dark-comp-btn-primary-on-confimatory-icon-default}));


  //# wizard
  --#{$vudTokenPrefix}wizard-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-enabled, var(--custom-dark-wizard-border-color, #{$dark-comp-nav-stepper-border-enabled}));
  --#{$vudTokenPrefix}wizard-inner-border-color: var(--custom-dark-wizard-inner-border-color, var(--#{$vudTokenPrefix}wizard-border-color));

  // wizard hover
  --#{$vudTokenPrefix}wizard-hover-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-hover, var(--custom-dark-wizard-hover-border-color, #{$dark-comp-nav-stepper-border-hover}));

  // wizard selected
  --#{$vudTokenPrefix}wizard-selected-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-selected-strong, var(--custom-dark-wizard-selected-border-color, #{$dark-comp-nav-stepper-border-selected-strong}));
  --#{$vudTokenPrefix}wizard-checkmark-icon: var(--custom-dark-wizard-checkmark-icon, url(#{$image-path}/wizard-checkmark-dark.svg));

  // wizard tabdrop
  --#{$vudTokenPrefix}wizard-tabdrop-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-icon-default, var(--custom-dark-wizard-tabdrop-color, #{$dark-comp-nav-stepper-icon-default}));


  //=== e. Icons
  // worksourface, action and social media icons
  --#{$vudTokenPrefix}icon-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-dark-icon-bg, #{$dark-ref-icon-default}));
  --#{$vudTokenPrefix}icon-hover-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, var(--custom-dark-icon-hover-bg, #{$dark-ref-icon-emphasis}));
  --#{$vudTokenPrefix}icon-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, var(--custom-dark-icon-disabled-bg, #{$dark-ref-icon-disabled}));

  --visma-icon-color: var(--#{$vudTokenPrefix}icon-bg);


  // pictograms
  --#{$vudTokenPrefix}pictogram-primary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-stroke-default, #{$dark-ref-pictogram-on-primary-stroke-default});
  --#{$vudTokenPrefix}pictogram-secondary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-on-secondary-stroke-default, #{$dark-ref-pictogram-on-secondary-stroke-default});
  --#{$vudTokenPrefix}pictogram-tertiary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-on-tertiary-stroke-default, #{$dark-ref-pictogram-on-tertiary-stroke-default});

  --#{$vudTokenPrefix}pictogram-fill: var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-fill-default, #{$dark-ref-pictogram-on-primary-fill-default});
  --#{$vudTokenPrefix}pictogram-stroke: var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-stroke-default, #{$dark-ref-pictogram-on-primary-stroke-default});

  --#{$vudTokenPrefix}pictogram-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, #{$dark-ref-icon-disabled});

  --visma-pictogram-color: var(--#{$vudTokenPrefix}pictogram-primary-bg);
  --visma-pictogram-fill: var(--#{$vudTokenPrefix}pictogram-fill);
  --visma-pictogram-stroke: var(--#{$vudTokenPrefix}pictogram-stroke);


  // Informative icons
  --#{$vudTokenPrefix}icon-informative-success-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-success, var(--custom-dark-icon-informative-success-bg, #{$dark-comp-situation-funct-icon-success}));
  --#{$vudTokenPrefix}icon-informative-error-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-error, var(--custom-dark-icon-informative-error-bg, #{$dark-comp-situation-funct-icon-error}));
  --#{$vudTokenPrefix}icon-informative-warning-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-dark-icon-informative-warning-bg, #{$dark-comp-situation-funct-icon-warning}));
  --#{$vudTokenPrefix}icon-informative-info-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-dark-icon-informative-info-bg, #{$dark-comp-situation-funct-icon-emphasis}));
  --#{$vudTokenPrefix}icon-informative-help-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-dark-icon-informative-help-bg, #{$dark-comp-situation-funct-icon-emphasis}));

  --visma-informative-success-color: var(--#{$vudTokenPrefix}icon-informative-success-bg);
  --visma-informative-error-color: var(--#{$vudTokenPrefix}icon-informative-error-bg);
  --visma-informative-warning-color: var(--#{$vudTokenPrefix}icon-informative-warning-bg);
  --visma-informative-info-color: var(--#{$vudTokenPrefix}icon-informative-info-bg);
  --visma-informative-help-color: var(--#{$vudTokenPrefix}icon-informative-help-bg);

  // Carets and close icons
  --#{$vudTokenPrefix}caret-default-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-dark-caret-default-bg, #{$dark-ref-icon-default}));
  --#{$vudTokenPrefix}caret-primary-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, var(--custom-dark-caret-primary-bg, #{$dark-ref-icon-emphasis}));
  --#{$vudTokenPrefix}caret-secondary-bg: var(--#{$backupVudTokenPrefix}ref-icon-inverse, var(--custom-dark-caret-secondary-bg, #{$dark-ref-icon-inverse}));
  --#{$vudTokenPrefix}caret-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, var(--custom-dark-caret-disabled-bg, #{$dark-ref-icon-disabled}));
  --caret-navigation-bg: var(--custom-dark-caret-navigation-bg, var(--#{$vudTokenPrefix}neutral-20));
  --#{$vudTokenPrefix}close-icon-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-dark-close-icon-bg, #{$dark-ref-icon-default}));
  --close-focus-outline: var(--custom-dark-close-focus-outline, var(--#{$vudTokenPrefix}blue-50));


  //=== f. Badges
  --#{$vudTokenPrefix}badge-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-dark-badge-bg, #{$dark-comp-situation-funct-surface-default}));
  --#{$vudTokenPrefix}badge-text-color: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-default, var(--custom-dark-badge-text-color, #{$dark-comp-situation-funct-text-default}));
  --#{$vudTokenPrefix}badge-shadow: var(--custom-dark-badge-shadow, 0 0 0 rem(1px) #{$dark-comp-situation-funct-border-default});

  --#{$vudTokenPrefix}badge-success-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-success-moderate, var(--custom-dark-badge-success-bg, #{$dark-comp-situation-funct-surface-success-moderate}));
  --#{$vudTokenPrefix}badge-success-sm-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-success, var(--custom-dark-badge-success-sm-bg, #{$dark-comp-situation-funct-icon-success}));
  --#{$vudTokenPrefix}badge-success-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-success-weak, var(--custom-dark-badge-success-text, #{$dark-comp-situation-funct-text-success-weak}));
  --#{$vudTokenPrefix}badge-success-shadow: var(--custom-dark-badge-success-shadow, 0 0 0 rem(1px) #{$dark-comp-situation-funct-border-success-moderate});

  --#{$vudTokenPrefix}badge-warning-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-warning-moderate, var(--custom-dark-badge-warning-bg, #{$dark-comp-situation-funct-surface-warning-moderate}));
  --#{$vudTokenPrefix}badge-warning-sm-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-dark-badge-warning-sm-bg, #{$dark-comp-situation-funct-icon-warning}));

  --#{$vudTokenPrefix}badge-danger-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-error-moderate, var(--custom-dark-badge-danger-bg, #{$dark-comp-situation-funct-surface-error-moderate}));
  --#{$vudTokenPrefix}badge-danger-sm-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-error, var(--custom-dark-badge-danger-sm-bg, #{$dark-comp-situation-funct-icon-error}));
  --#{$vudTokenPrefix}badge-danger-shadow: var(--custom-dark-badge-danger-shadow, 0 0 0 rem(1px) #{$dark-comp-situation-funct-border-error-moderate});

  --#{$vudTokenPrefix}badge-info-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-info-moderate, var(--custom-dark-badge-info-bg, #{$dark-comp-situation-funct-surface-info-moderate}));
  --#{$vudTokenPrefix}badge-info-sm-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-dark-badge-info-sm-bg, #{$dark-comp-situation-funct-icon-emphasis}));
  --badge-info-alt-bg: var(--custom-dark-badge-info-alt-bg, var(--#{$vudTokenPrefix}blue-70));
  --#{$vudTokenPrefix}badge-info-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-info-weak, var(--custom-dark-badge-info-text, #{$dark-comp-situation-funct-text-info-weak}));
  --badge-info-alt-text: var(--custom-dark-badge-info-alt-text, var(--#{$vudTokenPrefix}neutral-05));


  //=== g. List groups
  --#{$vudTokenPrefix}list-group-divider: var(--custom-dark-list-group-divider, inset 0 2px 0 -1px #{$dark-comp-separator-moderate});
  --#{$vudTokenPrefix}list-group-header-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-dark-list-group-header-bg, #{$dark-comp-nav-hor-brand-surface-default}));
  --#{$vudTokenPrefix}list-group-badge-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-dark-list-group-badge-bg, #{$dark-comp-situation-funct-surface-default}));
  --#{$vudTokenPrefix}list-group-badge-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-default, var(--custom-dark-list-group-badge-border, #{$dark-comp-situation-funct-border-default}));
  --#{$vudTokenPrefix}list-group-badge-text: var(--#{$backupVudTokenPrefix}comp-list-text-secondary, var(--custom-dark-list-group-badge-text, #{$dark-comp-list-text-secondary}));

  --#{$vudTokenPrefix}list-group-primary-bg: var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dark-list-group-primary-bg, #{$dark-comp-nav-hor-brand-surface-default}));
  --#{$vudTokenPrefix}list-group-primary-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-dark-list-group-primary-text-color, #{$dark-comp-nav-hor-brand-text-default}));
  --list-group-primary-divider: var(--custom-dark-list-group-primary-divider, #{inset 0 1px 0 0} rgba(#{hex-to-rgb($neutral-dark-10)}, 0.15));
  --list-group-primary-active-bg: var(--custom-dark-list-group-primary-active-bg, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));
  --list-group-primary-hover-bg: var(--custom-dark-list-group-primary-hover-bg, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.15));


  //=== h. Navigation
  --#{$vudTokenPrefix}navbar-brand-item-focus-border-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-focused, var(--custom-dark-navbar-brand-item-focus-border-color, #{$dark-comp-nav-hor-brand-surface-focused}));
  --#{$vudTokenPrefix}navigation-default-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-dark-navigation-default-bg, #{$dark-comp-nav-hor-brand-surface-default}));
  --#{$vudTokenPrefix}navigation-default-shadow: var(--#{$backupVudTokenPrefix}comp-separator-moderate, #{$dark-comp-separator-moderate});

  --#{$vudTokenPrefix}navigation-default-second-level-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-dark-navigation-default-second-level-bg, #{$dark-comp-nav-hor-default-surface-default}));
  --#{$vudTokenPrefix}navigation-default-second-level-shadow: var(--custom-dark-navigation-default-second-level-shadow, #{$dark-ref-shadow-default-elevation-1});
  --#{$vudTokenPrefix}navigation-default-second-level-divider: var(--custom-dark-navigation-default-second-level-divider, var(--#{$vudTokenPrefix}navigation-default-shadow));

  --#{$vudTokenPrefix}navigation-default-item-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-dark-navigation-default-item-text-color, #{$dark-comp-nav-hor-brand-text-default}));
  --#{$vudTokenPrefix}navigation-default-item-hover-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-hover, var(--custom-dark-navigation-default-item-hover-bg, #{$dark-comp-nav-hor-brand-surface-hover}));
  --#{$vudTokenPrefix}navigation-default-item-focus-border-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-border-focused, var(--custom-dark-navigation-default-item-focus-border-color, #{$dark-comp-nav-hor-brand-border-focused}));
  --#{$vudTokenPrefix}navigation-default-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-selected-moderate, var(--custom-dark-navigation-default-item-active-bg, #{$dark-comp-nav-hor-brand-surface-selected-moderate}));

  --#{$vudTokenPrefix}navigation-default-icon-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-default, var(--custom-dark-navigation-default-icon-bg, #{$dark-comp-nav-hor-brand-icon-default}));
  --#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-inverse, var(--custom-dark-navigation-default-dropdown-open-icon-bg, #{$dark-comp-nav-hor-brand-icon-inverse}));

  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-dark-navigation-default-item-dropdown-open-bg, #{$dark-comp-nav-hor-default-surface-default}));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-inverse, var(--custom-dark-navigation-default-item-dropdown-open-text-color, #{$dark-comp-nav-hor-brand-text-inverse}));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-small-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-dark-navigation-default-item-dropdown-small-text-color, #{$dark-comp-nav-hor-brand-text-default}));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-small-text-color: var(--#{$backupVudTokenPrefix}comp-content-text-secondary, var(--custom-dark-navigation-default-item-dropdown-open-small-text-color, #{$dark-comp-content-text-secondary}));

  --#{$vudTokenPrefix}navigation-default-dropdown-shadow: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-1, var(--custom-dark-navigation-default-dropdown-shadow, #{$dark-ref-shadow-default-elevation-1}));
  --#{$vudTokenPrefix}navigation-default-dropdown-text-color: var(--#{$backupVudTokenPrefix}comp-content-text-default, var(--custom-dark-navigation-default-dropdown-text-color, #{$dark-comp-content-text-default}));
  --#{$vudTokenPrefix}navigation-default-dropdown-divider-color-dotted: var(--#{$backupVudTokenPrefix}comp-separator-strong, var(--custom-dark-navigation-default-dropdown-divider-color-dotted, #{$dark-comp-separator-strong}));
  --#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong: var(--#{$backupVudTokenPrefix}comp-separator-strong, var(--custom-dark-navigation-default-dropdown-divider-color-strong, #{$dark-comp-separator-strong}));

  --#{$vudTokenPrefix}navigation-default-attention-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-dark-navigation-default-attention-bg, #{$dark-comp-situation-funct-icon-warning}));
  --#{$vudTokenPrefix}navigation-default-badge-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-dark-navigation-default-badge-bg, #{$dark-comp-situation-funct-surface-default}));
  --#{$vudTokenPrefix}navigation-default-badge-text-color: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-default, var(--custom-dark-navigation-default-badge-text-color, #{$dark-comp-situation-funct-text-default}));
  --#{$vudTokenPrefix}navigation-default-badge-shadow: var(--custom-dark-navigation-default-badge-shadow, var(--#{$vudTokenPrefix}badge-success-shadow));

  --navigation-default-menudrop-bg: var(--custom-dark-navigation-default-menudrop-bg, var(--#{$vudTokenPrefix}neutral-20));
  --#{$vudTokenPrefix}navigation-default-menudrop-shadow: var(--custom-dark-navigation-default-menudrop-shadow, #{$dark-ref-shadow-default-elevation-2});

  --#{$vudTokenPrefix}navigation-default-hamburger-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-default, var(--custom-dark-navigation-default-hamburger-bg, #{$dark-comp-nav-hor-brand-icon-default}));
  --#{$vudTokenPrefix}navigation-default-hamburger-open-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-inverse, var(--custom-dark-navigation-default-hamburger-open-bg, #{$dark-comp-nav-hor-brand-icon-inverse}));

  // Primary navigation
  --#{$vudTokenPrefix}navigation-primary-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-default, var(--custom-dark-navigation-primary-bg, #{$dark-comp-nav-hor-inverted-surface-default}));

  --#{$vudTokenPrefix}navigation-primary-item-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-text-default, #{$dark-comp-nav-hor-inverted-text-default});
  --#{$vudTokenPrefix}navigation-primary-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-selected-moderate, var(--custom-dark-navigation-primary-item-active-bg, #{$dark-comp-nav-hor-inverted-surface-selected-moderate}));
  --#{$vudTokenPrefix}navigation-primary-item-hover-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-hover, var(--custom-dark-navigation-primary-item-hover-bg, #{$dark-comp-nav-hor-inverted-surface-hover}));
  --#{$vudTokenPrefix}navigation-primary-item-border-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-border-enabled, #{$dark-comp-nav-hor-brand-border-enabled});

  --#{$vudTokenPrefix}navigation-primary-icon-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-icon-default, #{$dark-comp-nav-hor-inverted-icon-default});

  // Secondary navigation
  --#{$vudTokenPrefix}navigation-secondary-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-dark-navigation-secondary-bg, #{$dark-comp-nav-hor-default-surface-default}));
  --#{$vudTokenPrefix}navigation-secondary-shadow: var(--custom-dark-navigation-secondary-shadow, inset 0 rem(-1px) 0 0 var(--#{$vudTokenPrefix}navigation-default-shadow));

  --#{$vudTokenPrefix}navigation-secondary-second-level-shadow: var(--custom-dark-navigation-secondary-second-level-shadow, var(--#{$vudTokenPrefix}navigation-default-second-level-shadow));
  --#{$vudTokenPrefix}navigation-secondary-second-level-divider: var(--custom-dark-navigation-secondary-second-level-divider, var(--#{$vudTokenPrefix}navigation-default-second-level-divider));

  --#{$vudTokenPrefix}navigation-secondary-item-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-dark-navigation-secondary-item-text-color, #{$dark-comp-nav-hor-default-text-default}));
  --#{$vudTokenPrefix}navigation-secondary-item-active-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-emphasis, var(--custom-dark-navigation-secondary-item-active-text-color, #{$dark-comp-nav-hor-default-text-emphasis}));
  --#{$vudTokenPrefix}navigation-secondary-item-active-font-weight: var(--custom-dark-navigation-secondary-item-active-font-weight, #{normal});
  --#{$vudTokenPrefix}navigation-secondary-item-active-shadow: var(--custom-dark-navigation-secondary-item-active-shadow, #{inset 0 -4px 0px} #{$dark-comp-nav-hor-default-border-selected-strong});
  --#{$vudTokenPrefix}navigation-secondary-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-selected-moderate, var(--custom-dark-navigation-secondary-item-active-bg, #{$dark-comp-nav-hor-default-surface-selected-moderate}));
  --#{$vudTokenPrefix}navigation-secondary-item-hover-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-hover, var(--custom-dark-navigation-secondary-item-hover-bg, #{$dark-comp-nav-hor-default-surface-hover}));
  --#{$vudTokenPrefix}navigation-secondary-item-focus-border-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-border-focused, var(--custom-dark-navigation-secondary-item-focus-border-color, #{$dark-comp-nav-hor-default-border-focused}));

  --#{$vudTokenPrefix}navigation-secondary-item-dropdown-small-text-color: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-dark-navigation-secondary-item-dropdown-small-text-color, #{$dark-comp-nav-hor-default-text-default}));

  --#{$vudTokenPrefix}navigation-secondary-icon-bg: var(--custom-dark-navigation-secondary-icon-bg, --#{$vudTokenPrefix}navigation-default-icon-bg);

  --#{$vudTokenPrefix}navigation-secondary-badge-bg: var(--custom-dark-navigation-secondary-badge-bg, var(--#{$vudTokenPrefix}navigation-default-badge-bg));
  --#{$vudTokenPrefix}navigation-secondary-badge-text-color: var(--custom-dark-navigation-secondary-badge-text-color, var(--#{$vudTokenPrefix}navigation-default-badge-text-color));
  --#{$vudTokenPrefix}navigation-secondary-badge-shadow: var(--custom-dark-navigation-secondary-badge-shadow, var(--#{$vudTokenPrefix}navigation-default-badge-shadow));

  --#{$vudTokenPrefix}navigation-secondary-menudrop-icon-bg: var(--#{$backupVudTokenPrefix}comp-nav-hor-default-icon-default, var(--custom-dark-navigation-secondary-menudrop-icon-bg, #{$dark-comp-nav-hor-default-icon-default}));

  --#{$vudTokenPrefix}navigation-secondary-hamburger-bg: var(--custom-dark-navigation-secondary-hamburger-bg, var(--#{$vudTokenPrefix}navigation-default-hamburger-bg));
  --#{$vudTokenPrefix}navigation-secondary-hamburger-open-bg: var(--custom-dark-navigation-secondary-hamburger-open-bg, var(--#{$vudTokenPrefix}navigation-default-hamburger-open-bg));


  //=== i. Vertical navigation
  --#{$vudTokenPrefix}vertical-nav-default-shadow: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-1, var(--custom-dark-vertical-nav-default-shadow, $dark-ref-shadow-default-elevation-1));
  --#{$vudTokenPrefix}vertical-nav-default-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-default, var(--custom-dark-vertical-nav-default-bg, #{$dark-comp-nav-vert-default-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-default-item-hover-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-hover, var(--custom-dark-vertical-nav-default-item-hover-bg, #{$dark-comp-nav-vert-default-surface-hover}));
  --#{$vudTokenPrefix}vertical-nav-default-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-strong, var(--custom-dark-vertical-nav-default-item-active-bg, #{$dark-comp-nav-vert-default-surface-selected-strong}));
  --#{$vudTokenPrefix}vertical-nav-default-item-open-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-weak, var(--custom-dark-vertical-nav-default-item-open-bg, #{$dark-comp-nav-vert-default-surface-selected-weak}));
  --#{$vudTokenPrefix}vertical-nav-default-sublvl-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-strong, var(--custom-dark-vertical-nav-default-sublvl-active-bg, #{$dark-comp-nav-vert-default-surface-selected-strong}));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-dark-vertical-nav-default-collapse-btn-bg, #{$dark-comp-nav-vert-brand-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-hover-bg: var(--custom-dark-vertical-nav-default-collapse-btn-hover-bg, var(--#{$vudTokenPrefix}blue-60));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-shadow: var(--custom-dark-vertical-nav-default-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.1));
  --#{$vudTokenPrefix}vertical-nav-default-icon-color: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-icon-default, var(--custom-dark-vertical-nav-default-icon-color, #{$dark-comp-nav-vert-default-icon-default}));
  --#{$vudTokenPrefix}vertical-nav-default-icon-disabled-color: var(--#{$backupVudTokenPrefix}comp-nav-vert-default-icon-disabled, var(--custom-dark-vertical-nav-default-icon-disabled-color, #{$dark-comp-nav-vert-default-icon-disabled}));
  --#{$vudTokenPrefix}vertical-nav-default-divider-bg: var(--custom-dark-vertical-nav-default-divider-bg, var(--#{$vudTokenPrefix}neutral-50));
  --#{$vudTokenPrefix}vertical-nav-default-badge-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-overlay-default, var(--custom-dark-vertical-nav-default-badge-bg, #{$dark-comp-situation-funct-surface-overlay-default}));

  --#{$vudTokenPrefix}vertical-nav-primary-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-dark-vertical-nav-primary-bg, #{$dark-comp-nav-vert-brand-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-primary-shadow: var(--custom-dark-vertical-nav-primary-shadow, --#{$vudTokenPrefix}vertical-nav-default-shadow);
  --#{$vudTokenPrefix}vertical-nav-primary-item-text: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-text-default, var(--custom-dark-vertical-nav-primary-item-text, #{$dark-comp-nav-vert-brand-text-default}));
  --#{$vudTokenPrefix}vertical-nav-primary-item-hover-bg: var(--custom-dark-vertical-nav-primary-item-hover-bg, var(--module-container-hover-bg-color));
  --#{$vudTokenPrefix}vertical-nav-primary-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-selected-strong, var(--custom-dark-vertical-nav-primary-item-active-bg, #{$dark-comp-nav-vert-brand-surface-selected-strong}));
  --#{$vudTokenPrefix}vertical-nav-primary-item-open-bg: var(--custom-dark-vertical-nav-primary-item-open-bg, var(--#{$vudTokenPrefix}vertical-nav-default-item-open-bg));
  --vertical-nav-primary-item-disabled: var(--custom-dark-vertical-nav-primary-item-disabled, rgba(#{hex-to-rgb($neutral-05)}, 0.5));
  --vertical-nav-primary-collapse-bg: var(--custom-dark-vertical-nav-primary-collapse-bg, var(--module-container-active-bg-color));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-icon-default, var(--custom-dark-vertical-nav-primary-collapse-btn-bg, #{$dark-comp-nav-vert-brand-icon-default}));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-hover-bg: var(--custom-dark-vertical-nav-primary-collapse-btn-hover-bg, var(--#{$vudTokenPrefix}vertical-nav-default-collapse-btn-hover-bg));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-arrow: var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-dark-vertical-nav-primary-collapse-arrow, #{$dark-comp-nav-vert-brand-surface-default}));
  --vertical-nav-primary-collapse-btn-shadow: var(--custom-dark-vertical-nav-primary-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.1));
  --vertical-nav-primary-divider-bg: var(--custom-dark-vertical-nav-primary-divider-bg, rgba(255, 255, 255, 0.12));

  --#{$vudTokenPrefix}vertical-nav-secondary-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-surface-default, var(--custom-dark-vertical-nav-secondary-bg, #{$dark-comp-nav-vert-inverted-surface-default}));
  --#{$vudTokenPrefix}vertical-nav-secondary-border-color: var(--custom-dark-vertical-nav-secondary-border-color, var(--#{$vudTokenPrefix}vertical-nav-secondary-bg));
  --#{$vudTokenPrefix}vertical-nav-secondary-item-text: var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-icon-default, #{$dark-comp-nav-vert-inverted-icon-default});
  --#{$vudTokenPrefix}vertical-nav-secondary-item-active-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-surface-selected-strong, var(--custom-dark-vertical-nav-secondary-item-active-bg, #{$dark-comp-nav-vert-inverted-surface-selected-strong}));
  --#{$vudTokenPrefix}vertical-nav-secondary-item-hover-bg: var(--custom-dark-vertical-nav-secondary-item-hover-bg, var(--module-container-hover-bg-color));
  --#{$vudTokenPrefix}vertical-nav-secondary-item-open-bg: var(--custom-dark-vertical-nav-secondary-item-open-bg, var(--#{$vudTokenPrefix}vertical-nav-default-item-open-bg));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapsed-border-color: var(--custom-dark-vertical-nav-secondary-collapsed-border-color, var(--#{$vudTokenPrefix}blue-70));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-bg: var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-icon-default, var(--custom-dark-vertical-nav-secondary-collapse-btn-bg, #{$dark-comp-nav-vert-inverted-icon-default}));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-hover-bg: var(--custom-dark-vertical-nav-secondary-collapse-btn-hover-bg, var(--#{$vudTokenPrefix}blue-60));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapsed-btn-hover-bg: var(--custom-dark-vertical-nav-secondary-collapsed-btn-hover-bg, var(--#{$vudTokenPrefix}blue-60));


  //=== j. Modals
  --#{$vudTokenPrefix}modal-bg: var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dark-modal-bg, #{$dark-comp-content-surface-enabled}));
  --#{$vudTokenPrefix}modal-shadow: var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-2, var(--custom-dark-modal-shadow, #{$dark-ref-shadow-default-elevation-2}));
  --#{$vudTokenPrefix}modal-overlay-bg: var(--custom-dark-modal-overlay-bg, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.5));


  //=== k. Forms
  // inputs/textarea
  --#{$vudTokenPrefix}input-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-default, var(--custom-dark-input-text-color, #{$dark-comp-inputs-text-default}));
  --#{$vudTokenPrefix}input-placeholder-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-secondary, var(--custom-dark-input-placeholder-color, #{$dark-comp-inputs-text-secondary}));
  --#{$vudTokenPrefix}input-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-enabled, var(--custom-dark-input-border-color, #{$dark-comp-inputs-border-enabled}));
  --#{$vudTokenPrefix}input-bg: var(--#{$backupVudTokenPrefix}comp-inputs-surface-enabled, var(--custom-dark-input-bg, #{$dark-comp-inputs-surface-enabled}));
  --#{$vudTokenPrefix}input-shadow-color: var(--custom-dark-input-shadow-color, transparent);

  // inputs - hover state
  --#{$vudTokenPrefix}input-hover-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-hover, var(--custom-dark-input-hover-border-color, #{$dark-comp-inputs-border-hover}));
  --#{$vudTokenPrefix}input-hover-bg: var(--#{$backupVudTokenPrefix}comp-inputs-surface-hover, var(--custom-dark-input-hover-bg, #{$dark-comp-inputs-surface-hover}));
  --#{$vudTokenPrefix}input-hover-shadow-color: var(--custom-dark-input-hover-shadow-color, #{$dark-ref-shadow-default-elevation-1});

  // inputs - focus state
  --#{$vudTokenPrefix}input-focus-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-focused, var(--custom-dark-input-focus-border-color, #{$dark-comp-inputs-border-focused}));
  --#{$vudTokenPrefix}input-focus-shadow-color: var(--custom-dark-input-focus-shadow-color, #{$dark-ref-shadow-default-elevation-1});

  // inputs - disabled state
  --#{$vudTokenPrefix}input-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-disabled, var(--custom-dark-input-disabled-text-color, #{$dark-comp-inputs-text-disabled}));
  --#{$vudTokenPrefix}input-disabled-bg: var(--#{$backupVudTokenPrefix}comp-inputs-surface-disabled, var(--custom-dark-input-disabled-bg, #{$dark-comp-inputs-surface-disabled}));
  --#{$vudTokenPrefix}input-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-disabled, var(--custom-dark-input-disabled-border-color, #{$dark-comp-inputs-border-disabled}));

  // error inputs
  --#{$vudTokenPrefix}input-error-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-error, var(--custom-dark-input-error-text-color, #{$dark-comp-inputs-text-error}));
  --#{$vudTokenPrefix}input-error-border-color: var(--#{$backupVudTokenPrefix}comp-inputs-border-error, var(--custom-dark-input-error-border-color, #{$dark-comp-inputs-border-error}));

  // labels for inputs
  --#{$vudTokenPrefix}input-label-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-default, var(--custom-dark-input-label-text-color, #{$dark-comp-inputs-text-default}));
  --#{$vudTokenPrefix}input-label-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-inputs-text-disabled, var(--custom-dark-input-label-disabled-text-color, #{$dark-comp-inputs-text-disabled}));

  // inputs - primary
  --#{$vudTokenPrefix}input-primary-border-color: var(--#{$backupVudTokenPrefix}comp-search-brand-border-enabled, var(--custom-dark-input-primary-border-color, #{$dark-comp-search-brand-border-enabled}));
  --#{$vudTokenPrefix}input-primary-shadow-color: var(--custom-dark-input-primary-shadow-color, #{$dark-ref-shadow-brand-elevation-1});

  // inputs - primary - hover
  --#{$vudTokenPrefix}input-primary-hover-border-color: var(--#{$backupVudTokenPrefix}comp-search-brand-border-hover, var(--custom-dark-input-primary-hover-border-color, #{$dark-comp-search-brand-border-hover}));

  // inputs - primary - disabled
  --#{$vudTokenPrefix}search-field-primary-disabled-button: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-disabled, var(--custom-dark-search-field-primary-disabled-button, #{$dark-comp-btn-primary-on-confimatory-surface-disabled}));
  --#{$vudTokenPrefix}search-field-primary-disabled-button-border: var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-disabled, var(--custom-dark-search-field-primary-disabled-button-border, #{$dark-comp-btn-primary-on-confimatory-border-disabled}));


  // switches
  --#{$vudTokenPrefix}switches-text-color: var(--#{$backupVudTokenPrefix}comp-switch-text-default, var(--custom-dark-switches-text-color, #{$dark-comp-switch-text-default}));
  --#{$vudTokenPrefix}switches-border-color: var(--#{$backupVudTokenPrefix}comp-switch-border-enabled, var(--custom-dark-switches-border-color, #{$dark-comp-switch-border-enabled}));
  --#{$vudTokenPrefix}switches-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-enabled, var(--custom-dark-switches-bg, #{$dark-comp-switch-surface-enabled}));
  --#{$vudTokenPrefix}switches-togglemark-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-strong, var(--custom-dark-switches-togglemark-bg, #{$dark-comp-switch-surface-strong}));
  --#{$vudTokenPrefix}switches-togglemark-shadow-color: var(--custom-dark-switches-togglemark-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0));

  // switches - hover
  --#{$vudTokenPrefix}switches-hover-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-hover, var(--custom-dark-switches-hover-bg, #{$dark-comp-switch-surface-hover}));

  // switches - focus
  --#{$vudTokenPrefix}switches-focus-border-color: var(--#{$backupVudTokenPrefix}comp-switch-border-focused, var(--custom-dark-switches-focus-border-color, #{$dark-comp-switch-border-focused}));
  --#{$vudTokenPrefix}switches-focus-inner-border-color: var(--custom-dark-switches-focus-inner-border-color, transparent);

  // switches - disabled
  --#{$vudTokenPrefix}switches-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-switch-text-disabled, var(--custom-dark-switches-disabled-text-color, #{$dark-comp-switch-text-disabled}));
  --#{$vudTokenPrefix}switches-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-switch-border-disabled, #{$dark-comp-switch-border-disabled});
  --#{$vudTokenPrefix}switches-disabled-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-dark-switches-disabled-bg, #{$dark-comp-switch-surface-disabled}));
  --#{$vudTokenPrefix}switches-disabled-togglemark-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-dark-switches-disabled-togglemark-bg, #{$dark-comp-switch-surface-disabled}));

  // switches - checked
  --#{$vudTokenPrefix}switches-checked-text-color: var(--#{$backupVudTokenPrefix}comp-switch-text-inverse, var(--custom-dark-switches-checked-text-color, #{$dark-comp-switch-text-inverse}));
  --#{$vudTokenPrefix}switches-checked-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-selected-strong, var(--custom-dark-switches-checked-bg, #{$dark-comp-switch-surface-selected-strong}));

  // switches - hover - checked
  --#{$vudTokenPrefix}switches-checked-hover-bg: var(--#{$backupVudTokenPrefix}-comp-switch-surface-selected-strong-hover, var(--custom-dark-switches-checked-hover-bg, #{$dark-comp-switch-surface-selected-strong-hover}));

  // switches - disabled - checked
  --#{$vudTokenPrefix}switches-checked-disabled-bg: var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-dark-switches-checked-disabled-bg, #{$dark-comp-switch-surface-disabled}));


  // radio-checkbox
  --#{$vudTokenPrefix}radio-checkbox-text-color: var(--#{$backupVudTokenPrefix}comp-toggle-text-default, var(--custom-dark-radio-checkbox-text-color, #{$dark-comp-toggle-text-default}));
  --#{$vudTokenPrefix}radio-checkbox-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-enabled, var(--custom-dark-radio-checkbox-border-color, #{$dark-comp-toggle-border-enabled}));
  --#{$vudTokenPrefix}radio-checkbox-bg: var(--#{$backupVudTokenPrefix}comp-toggle-surface-enabled, var(--custom-dark-radio-checkbox-bg, #{$dark-comp-toggle-surface-enabled}));
  --#{$vudTokenPrefix}radio-checkbox-checkmark-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-emphasis, var(--custom-dark-radio-checkmark-bg, #{$dark-comp-toggle-icon-emphasis}));
  --#{$vudTokenPrefix}radio-checkbox-dot-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-inverse, var(--custom-dark-radio-checkbox-dot-bg, #{$dark-comp-toggle-icon-inverse}));

  // radio-checkbox - focus
  --#{$vudTokenPrefix}radio-checkbox-focus-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-focused, var(--custom-dark-radio-checkbox-focus-border-color, #{$dark-comp-toggle-border-focused}));
  --#{$vudTokenPrefix}radio-checkbox-error-focus-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-error, var(--custom-dark-radio-checkbox-error-focus-border-color, #{$dark-comp-toggle-border-error}));

  // radio-checkbox - disabled
  --#{$vudTokenPrefix}radio-checkbox-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-toggle-text-disabled, var(--custom-dark-radio-checkbox-disabled-text-color, #{$dark-comp-toggle-text-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-disabled, var(--custom-dark-radio-checkbox-disabled-border-color, #{$dark-comp-toggle-border-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-disabled-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-disabled-bg, #{$dark-comp-toggle-icon-disabled}));

  // radio-checkbox - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-emphasis, var(--custom-dark-radio-checkbox-checked-border-color, #{$dark-comp-toggle-border-emphasis}));
  --#{$vudTokenPrefix}radio-checkbox-checked-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-emphasis, var(--custom-dark-radio-checkbox-checked-bg, #{$dark-comp-toggle-icon-emphasis}));

  // radio-checkbox - disabled - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-border-color: var(--#{$backupVudTokenPrefix}comp-toggle-border-disabled, var(--custom-dark-radio-checkbox-checked-disabled-border-color, #{$dark-comp-toggle-border-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-bg: var(--#{$backupVudTokenPrefix}comp-toggle-surface-disabled, var(--custom-dark-radio-checkbox-checked-disabled-bg, #{$dark-comp-toggle-surface-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-radio-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-checked-disabled-radio-bg, #{$dark-comp-toggle-icon-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-checkmark-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-checked-disabled-checkmark-bg, #{$dark-comp-toggle-icon-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-dot-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-checked-disabled-dot-bg, #{$dark-comp-toggle-icon-disabled}));

  // radio-checkbox - read only - checked
  --#{$vudTokenPrefix}radio-checkbox-read-only-bg: var(--#{$backupVudTokenPrefix}comp-toggle-surface-disabled, var(--custom-dark-radio-checkbox-read-only-bg, #{$dark-comp-toggle-surface-disabled}));
  --#{$vudTokenPrefix}radio-checkbox-read-only-checkmark-bg: var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-read-only-checkmark-bg, #{$dark-comp-toggle-icon-disabled}));


  // selects/dropdowns
  --select-arrow: var(--custom-dark-select-arrow, url(#{$image-path}/arrow-large-dark.svg));
  --select-arrow-disabled: var(--custom-dark-select-arrow-disabled,  url(#{$image-path}/arrow-large-disabled-dark.svg));


  // drop area
  --drop-area-bg: var(--custom-dark-drop-area-bg, var(--#{$vudTokenPrefix}neutral-80));
  --drop-area-border-color: var(--custom-dark-drop-area-border-color, var(--#{$vudTokenPrefix}neutral-50));
  --drop-area-highlight-border-color: var(--custom-dark-drop-area-highlight-border-color, var(--#{$vudTokenPrefix}blue-40));
  --drop-area-highlight-bg: var(--custom-dark-drop-area-highlight-bg, var(--#{$vudTokenPrefix}neutral-70));
  --drop-area-focus-border-color: var(--custom-dark-drop-area-focus-border-color, var(--#{$vudTokenPrefix}neutral-05));
  --drop-area-preview-item-bg: var(--custom-dark-drop-area-preview-item-bg, var(--#{$vudTokenPrefix}neutral-60));
  --drop-area-preview-item-op: var(--custom-dark-drop-area-preview-item-op, 0.5);
  --drop-area-preview-item-border-color: var(--custom-dark-drop-area-preview-item-border-color, var(--#{$vudTokenPrefix}neutral-50));


  //=== l. Images
  --image-no-alt-border-color: var(--custom-dark-image-no-alt-border-color, var(--#{$vudTokenPrefix}primary-orange));
  --thumbnail-border-color: var(--custom-dark-thumbnail-border-color, var(--#{$vudTokenPrefix}neutral-40));


  //=== m. Table
  --#{$vudTokenPrefix}table-border-color: var(--#{$backupVudTokenPrefix}comp-separator-moderate, var(--custom-dark-table-border-color, #{$dark-comp-separator-moderate}));
  --#{$vudTokenPrefix}table-dotted-divider-color: var(--#{$backupVudTokenPrefix}comp-separator-strong, var(--custom-dark-table-dotted-divider-color, #{$dark-comp-separator-strong}));
  --#{$vudTokenPrefix}table-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-enabled, var(--custom-dark-table-bg, #{$dark-comp-table-surface-enabled}));
  --#{$vudTokenPrefix}table-alt-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-muted, var(--custom-dark-table-alt-bg, #{$dark-comp-table-surface-muted}));
  --#{$vudTokenPrefix}table-shadow-color: var(--custom-dark-table-shadow-color, #{$dark-ref-shadow-default-elevation-1});

  // table - cell hover
  --#{$vudTokenPrefix}table-hover-border-color: var(--#{$backupVudTokenPrefix}comp-table-border-hover, var(--custom-dark-table-hover-border-color, #{$dark-comp-table-border-hover}));
  --#{$vudTokenPrefix}table-hover-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-hover, var(--custom-dark-table-hover-bg, #{$dark-comp-table-surface-hover}));

  // table - cell active/pressed state
  --#{$vudTokenPrefix}table-active-border-color: var(--#{$backupVudTokenPrefix}comp-table-border-selected, var(--custom-dark-table-active-border-color, #{$dark-comp-table-border-selected}));
  --#{$vudTokenPrefix}table-active-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-selected, var(--custom-dark-table-active-bg, #{$dark-comp-table-surface-selected}));

  // table - cell active/selected state
  --#{$vudTokenPrefix}table-selected-border-color: var(--#{$backupVudTokenPrefix}comp-table-border-selected, var(--custom-dark-table-selected-border-color, #{$dark-comp-table-border-selected}));
  --#{$vudTokenPrefix}table-selected-bg: var(--#{$backupVudTokenPrefix}comp-table-surface-selected, var(--custom-dark-table-selected-bg, #{$dark-comp-table-surface-selected}));

  // table - error row state
  --#{$vudTokenPrefix}table-error-bg: color-mix(in oklch, #{$black} 32%, var(--#{$backupVudTokenPrefix}comp-table-surface-error-hover, var(--custom-dark-table-error-bg, #{$dark-comp-table-surface-error-hover})));
  --#{$vudTokenPrefix}table-error-text-color: var(--#{$backupVudTokenPrefix}comp-table-text-error-moderate, #{$dark-comp-table-text-error-moderate});

  //=== n. Stepper
  --#{$vudTokenPrefix}stepper-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-disabled, var(--custom-dark-stepper-text-color, #{$dark-comp-nav-stepper-text-disabled}));
  --#{$vudTokenPrefix}stepper-bg: var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-weak, var(--custom-dark-stepper-bg, #{$dark-comp-nav-stepper-surface-weak}));
  --stepper-wrapper-text-color: var(--custom-dark-stepper-wrapper-text-color, var(--#{$vudTokenPrefix}neutral-20));
  --stepper-wrapper-bg: var(--custom-dark-stepper-wrapper-bg, var(--#{$vudTokenPrefix}neutral-70));

  // stepper - active/selected state
  --#{$vudTokenPrefix}stepper-active-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-emphasis, var(--custom-dark-stepper-active-text-color, #{$dark-comp-nav-stepper-text-emphasis}));
  --#{$vudTokenPrefix}stepper-active-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-strong, var(--custom-dark-stepper-active-border-color, #{$dark-comp-nav-stepper-surface-strong}));
  --stepper-active-bg: var(--custom-dark-stepper-active-bg, var(--#{$vudTokenPrefix}neutral-80));
  --stepper-active-shadow-color: var(--custom-dark-stepper-active-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15));

  // stepper - visited state
  --#{$vudTokenPrefix}stepper-visited-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-success, var(--custom-dark-stepper-visited-text-color, #{$dark-comp-nav-stepper-text-success}));
  --#{$vudTokenPrefix}stepper-visited-bg: var(--#{$backupVudTokenPrefix}comp-nav-stepper-icon-success, var(--custom-dark-stepper-visited-bg, #{$dark-comp-nav-stepper-icon-success}));


  //=== o. Progress Bar
  --#{$vudTokenPrefix}progress-bar-label: var(--#{$backupVudTokenPrefix}comp-progress-text-secondary, var(--custom-dark-progress-bar-label, #{$dark-comp-progress-text-secondary}));
  --#{$vudTokenPrefix}progress-bar-wrapper-bg: var(--#{$backupVudTokenPrefix}comp-progress-default-border-weak, var(--custom-dark-progress-bar-wrapper-bg, #{$dark-comp-progress-default-border-weak}));
  --#{$vudTokenPrefix}progress-bar-bg: var(--#{$backupVudTokenPrefix}comp-progress-secondary-border-strong, var(--custom-dark-progress-bar-bg, #{$dark-comp-progress-secondary-border-strong}));
  // --progress-bar-shadow-color: var(--custom-dark-progress-bar-shadow-color, rgba(#{hex-to-rgb($primary-blue)}, 0.25));


  //=== m. Alerts and toasts
  --#{$vudTokenPrefix}alert-success-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-success-weak, var(--custom-dark-alert-success-bg, #{$dark-comp-situation-funct-surface-success-weak}));
  --#{$vudTokenPrefix}alert-success-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-success-weak, var(--custom-dark-alert-success-border, #{$dark-comp-situation-funct-border-success-weak}));
  --#{$vudTokenPrefix}alert-success-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-success-moderate, var(--custom-dark-alert-success-text, #{$dark-comp-situation-funct-text-success-moderate}));
  --#{$vudTokenPrefix}alert-success-shadow: var(--custom-dark-alert-success-shadow, #{$dark-ref-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-info-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-info-weak, var(--custom-dark-alert-info-bg, #{$dark-comp-situation-funct-surface-info-weak}));
  --#{$vudTokenPrefix}alert-info-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-info-weak, var(--custom-dark-alert-info-border, #{$dark-comp-situation-funct-border-info-weak}));
  --#{$vudTokenPrefix}alert-info-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-info-moderate, var(--custom-dark-alert-info-text, #{$dark-comp-situation-funct-text-info-moderate}));
  --#{$vudTokenPrefix}alert-info-shadow: var(--custom-dark-alert-info-shadow, #{$dark-ref-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-warning-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-warning-weak, var(--custom-dark-alert-warning-bg, #{$dark-comp-situation-funct-surface-warning-weak}));
  --#{$vudTokenPrefix}alert-warning-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-warning-weak, var(--custom-dark-alert-warning-border, #{$dark-comp-situation-funct-border-warning-weak}));
  --#{$vudTokenPrefix}alert-warning-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-warning-moderate, var(--custom-dark-alert-warning-text, #{$dark-comp-situation-funct-text-warning-moderate}));
  --#{$vudTokenPrefix}alert-warning-shadow: var(--custom-dark-alert-warning-shadow, #{$dark-ref-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-danger-bg: var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-error-weak, var(--custom-dark-alert-danger-bg, #{$dark-comp-situation-funct-surface-error-weak}));
  --#{$vudTokenPrefix}alert-danger-border: var(--#{$backupVudTokenPrefix}comp-situation-funct-border-error-weak, var(--custom-dark-alert-danger-border, #{$dark-comp-situation-funct-border-error-weak}));
  --#{$vudTokenPrefix}alert-danger-text: var(--#{$backupVudTokenPrefix}comp-situation-funct-text-error-moderate, var(--custom-dark-alert-danger-text, #{$dark-comp-situation-funct-text-error-moderate}));
  --#{$vudTokenPrefix}alert-danger-shadow: var(--custom-dark-alert-danger-shadow, #{$dark-ref-shadow-default-elevation-1});


  //=== n. Popovers and tooltips
  --popover-shadow: var(--custom-dark-popover-shadow, #{shadow(50)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.2));
  --popover-arrow-tip: var(--custom-dark-popover-arrow-tip, url(#{$image-path}/popover-tip-dark.svg));

  //Tooltips
  --tooltip-bg: var(--custom-dark-tooltip-bg, var(--#{$vudTokenPrefix}module-container-bg-color));
  --tooltip-shadow: var(--custom-dark-tooltip-shadow, #{shadow(40)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));
  --tooltip-border-color: var(--custom-dark-tooltip-border-color, var(--neutral-40));
  --tooltip-arrow-tip: var(--custom-dark-tooltip-arrow-tip, url(#{$image-path}/tooltip-tip-dark.svg));

  --tooltip-primary-bg: var(--custom-dark-tooltip-primary-bg, var(--tooltip-bg));
  --tooltip-primary-shadow: var(--custom-dark-tooltip-primary-shadow, var(--tooltip-shadow));
  --tooltip-primary-border-color: var(--custom-dark-tooltip-primary-border-color, var(--tooltip-border-color));
  --tooltip-primary-text: var(--custom-dark-tooltip-primary-text, var(--#{$vudTokenPrefix}body-font-color));
  --tooltip-primary-arrow-tip: var(--custom-dark-tooltip-primary-arrow-tip, var(--tooltip-arrow-tip));

  --tooltip-error-bg: var(--custom-dark-tooltip-error-bg, var(--tooltip-bg));
  --tooltip-error-shadow: var(--custom-dark-tooltip-error-shadow, #{shadow(40)} rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));
  --tooltip-error-border-color: var(--custom-dark-tooltip-error-border-color, var(--#{$vudTokenPrefix}red-40));
  --tooltip-error-text: var(--custom-dark-tooltip-error-text, var(--#{$vudTokenPrefix}red-40));
  --tooltip-error-arrow-tip: var(--custom-dark-tooltip-error-arrow-tip, url(#{$image-path}/tooltip-tip-error-dark.svg));

  --tooltip-secondary-bg: var(--custom-dark-tooltip-secondary-bg, var(--tooltip-bg));
  --tooltip-secondary-shadow: var(--custom-dark-tooltip-secondary-shadow, var(--tooltip-shadow));
  --tooltip-secondary-border-color: var(--custom-dark-tooltip-secondary-border-color, var(--tooltip-border-color));
  --tooltip-secondary-text: var(--custom-dark-tooltip-secondary-text, var(--#{$vudTokenPrefix}body-font-color));
  --tooltip-secondary-arrow-tip: var(--custom-dark-tooltip-secondary-arrow-tip, var(--tooltip-arrow-tip));


  //=== o. Datepicker
  --#{$vudTokenPrefix}datepicker-inline-bg: var(--#{$backupVudTokenPrefix}comp-calendar-surface-enabled, var(--custom-dark-datepicker-inline-bg, #{$dark-comp-calendar-surface-enabled}));
  --datepicker-popup-bg: var(--custom-dark-datepicker-popup-bg, var(--#{$vudTokenPrefix}neutral-60));

  --datepicker-arrow: var(--custom-dark-datepicker-arrow, url(#{$image-path}/arrow-small-blue-dark.svg));
  --#{$vudTokenPrefix}datepicker-border-color: var(--#{$backupVudTokenPrefix}comp-separator-moderate, var(--custom-dark-datepicker-border-color, #{$dark-comp-separator-moderate}));
  --datepicker-nonclickable-text-color: var(--custom-dark-datepicker-nonclickable-text-color, var(--#{$vudTokenPrefix}neutral-20));
  --datepicker-item-text: var(--custom-dark-datepicker-item-text, var(--#{$vudTokenPrefix}neutral-10));
  --datepicker-item-hover-bg: var(--custom-dark-datepicker-item-hover-bg, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.1));
  --datepicker-item-today-border: var(--custom-dark-datepicker-item-today-border, var(--#{$vudTokenPrefix}blue-50));
  --datepicker-item-selected-bg: var(--custom-dark-datepicker-item-selected-bg, var(--#{$vudTokenPrefix}blue-60));
  --datepicker-item-selected-focus-border: var(--custom-dark-datepicker-item-selected-focus-border, var(--#{$vudTokenPrefix}neutral-05));
  --datepicker-item-selected-text: var(--custom-dark-datepicker-item-selected-text, var(--#{$vudTokenPrefix}neutral-05));
  --datepicker-item-selected-range-bg: var(--custom-dark-datepicker-item-selected-range-bg, var(--#{$vudTokenPrefix}neutral-60));
  --datepicker-item-hover-range-bg: var(--custom-dark-datepicker-item-hover-range-bg, var(--#{$vudTokenPrefix}neutral-60));
  --datepicker-item-range-focus-shadow: var(--custom-dark-datepicker-item-range-focus-shadow, var(--#{$vudTokenPrefix}blue-60));
  --datepicker-item-range-focus-border: var(--custom-dark-datepicker-item-range-focus-border, var(--#{$vudTokenPrefix}neutral-05));

  --datepicker-today-btn-border: var(--custom-dark-datepicker-today-btn-border, var(--#{$vudTokenPrefix}neutral-40));
  --datepicker-today-btn-text: var(--custom-dark-datepicker-today-btn-text, var(--#{$vudTokenPrefix}neutral-10));
  --datepicker-clear-btn-border: var(--custom-dark-datepicker-clear-btn-border, var(--#{$vudTokenPrefix}red-40));
  --datepicker-clear-btn-text: var(--custom-dark-datepicker-clear-btn-text, var(--#{$vudTokenPrefix}red-40));


  //=== p. Tabs
  --#{$vudTokenPrefix}tabs-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-default, var(--custom-dark-tabs-text-color, #{$dark-comp-nav-stepper-text-default}));
  --#{$vudTokenPrefix}tabs-focused-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-default, var(--custom-dark-tabs-focused-text-color, #{$dark-comp-nav-stepper-text-default}));
  --#{$vudTokenPrefix}tabs-disabled-text-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-disabled, var(--custom-dark-tabs-disabled-text-color, #{$dark-comp-nav-stepper-text-disabled}));
  --#{$vudTokenPrefix}tabs-content-border-color: var(--#{$backupVudTokenPrefix}comp-separator-extreme, var(--custom-dark-tabs-content-border-color, #{$dark-comp-separator-extreme}));
  --#{$vudTokenPrefix}tabs-content-secondary-border-color: var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-pressed, var(--custom-dark-tabs-content-secondary-border-color, #{$dark-comp-nav-stepper-surface-pressed}));
  --tabs-content-bg: var(--custom-dark-tabs-content-bg, var(--#{$vudTokenPrefix}neutral-60));
  --tabs-content-shadow-color: var(--custom-dark-tabs-content-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.25));

  --tabs-normal-Ypoition:              #{rem(-350px)};
  --tabs-disabled-Ypoition:            #{rem(-400px)};
  --tabs-hover-Ypoition:               #{rem(-450px)};
  --tabs-selected-Ypoition:            #{rem(-499px)};
  --tabs-focus-Ypoition:               #{rem(-549px)};
  --tabs-focus-hover-Ypoition:         #{rem(-599px)};
  --tabs-selected-focused-Ypoition:    #{rem(-650px)};
  --tabs-pressed-Ypoition:             var(--tabs-selected-Ypoition);

  --tabs-dropdown-Ypoition:               #{rem(8px)};
  --tabs-dropdown-normal-Xpoition:     #{rem(-250px)};
  --tabs-dropdown-hover-Xpoition:      #{rem(-300px)};
  --tabs-dropdown-focus-Xpoition:      #{rem(-350px)};
  --tabs-dropdown-pressed-Xpoition:    #{rem(-400px)};
  --tabs-dropdown-disabled-Xpoition:   #{rem(-450px)};


  //=== q. Helper classes 
  //Borders
  --border-color: var(--custom-dark-border-color, var(--#{$vudTokenPrefix}neutral-40));



  //=== r. Login page
  --mask-bg: var(--custom-dark-mask-bg, rgba(#{hex-to-rgb($blue-90)}, 0.67));

  --cookies-alert-bg: var(--custom-dark-cookies-alert-bg, rgba(#{hex-to-rgb($neutral-dark-80)}, 0.5));
  --cookies-alert-secondary-bg: var(--custom-dark-cookies-alert-secondary-bg, var(--cookies-alert-bg));
  --cookies-alert-text: var(--custom-dark-cookies-alert-text, var(--#{$vudTokenPrefix}neutral-20));

  --password-field-icon: var(--custom-dark-password-field-icon, url(#{$image-path}/login/password-icon-dark.svg));
  
  --visma-logo: var(--custom-dark-visma-logo, url(#{$image-path}/visma-logo-dark.svg));


  //=== s. Error pages 
  --error-img-opacity: var(--custom-dark-error-img-opacity, 0.6);


  //=== t. Spinners
  --spinner-default-blue: var(--custom-dark-spinner-default-blue, url(#{$image-path}/spinners/spinner_doughnut_blue_dark.svg));
  --spinner-default-grey: var(--custom-dark-spinner-default-grey, url(#{$image-path}/spinners/spinner_doughnut_grey_dark.svg));
  --spinner-default-green: var(--custom-dark-spinner-default-green, url(#{$image-path}/spinners/spinner_doughnut_green_dark.svg));

  --spinner-primary-blue: var(--custom-dark-spinner-primary-blue, url(#{$image-path}/spinners/spinner_dotted_blue_dark.svg));
  --spinner-primary-grey: var(--custom-dark-spinner-primary-grey, url(#{$image-path}/spinners/spinner_dotted_grey_dark.svg));
  --spinner-primary-green: var(--custom-dark-spinner-primary-green, url(#{$image-path}/spinners/spinner_dotted_green_dark.svg));

  --spinner-secondary-blue: var(--custom-dark-spinner-secondary-blue, url(#{$image-path}/spinners/spinner_snake_blue_dark.svg));
  --spinner-secondary-grey: var(--custom-dark-spinner-secondary-grey, url(#{$image-path}/spinners/spinner_snake_grey_dark.svg));
  --spinner-secondary-green: var(--custom-dark-spinner-secondary-green, url(#{$image-path}/spinners/spinner_snake_green_dark.svg));


  //=== u. Cards list
  --card-list-text-color: var(--custom-dark-card-list-text-color, var(--#{$vudTokenPrefix}neutral-20));
  --card-list-secondary-bg: var(--custom-dark-card-list-secondary-bg, var(--#{$vudTokenPrefix}neutral-60));

  --#{$vudTokenPrefix}hero-card-bg:  var(--#{$backupVudTokenPrefix}comp-content-surface-muted, var(--custom-dark-hero-card-bg, #{$dark-comp-content-surface-muted}));
  --hero-card-border-color: var(--custom-dark-hero-card-border-color, var(--#{$vudTokenPrefix}neutral-70));
  }
