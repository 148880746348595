
// Button group filter type / Segmented controls

//*** scss local variable ***
//*** Button - confirmatory
$segmented-control-bg:                                 var(--#{$vudTokenPrefix}bg-primary);
$segmented-control-divider-bg:                         var(--#{$vudTokenPrefix}divider-color);

$segmented-control-button-hover-border-color:          var(--#{$vudTokenPrefix}button-hover-border-color);
$segmented-control-button-focus-border-color:          var(--#{$vudTokenPrefix}button-focus-border-color);

$segmented-control-button-bg:                          transparent;
$segmented-control-button-hover-bg:                    var(--#{$vudTokenPrefix}button-hover-bg);
$segmented-control-button-pressed-bg:                  var(--#{$vudTokenPrefix}button-bg);
$segmented-control-button-selected-bg:                 var(--#{$vudTokenPrefix}button-bg);

$segmented-control-button-hover-shadow-color:          var(--button-hover-shadow-color);
$segmented-control-button-selected-shadow-color:       var(--button-shadow-color);
$segmented-control-button-selected-focus-shadow-color: var(--button-focus-shadow-color);


//*** element/component code ***
.#{$vudClassPrefix}btn-group {
    &.#{$vudClassPrefix}btn-group-tabs {
        position: inherit;
        margin-bottom: rem(8px);
        padding: rem(4px);

        border-radius: rem(10px);
        background-color: $segmented-control-bg;

        .#{$vudClassPrefix}btn {
            position: relative;
            height: rem(40px);
            min-width: none;
            min-width: unset;
            margin: 0 rem(5px) 0 0;
            padding: rem(3px) rem(30px) rem(4px);

            border-radius: rem(8px);
            border: 0;
            background-color: $segmented-control-button-bg;
            box-shadow: none;

            &::before {
                display: inline-block;
                content: "";
                position: absolute;
                width: rem(1px);
                height: rem(24px);
                top: rem(8px);
                left: rem(-3px);

                background-color: $segmented-control-divider-bg;
                box-shadow: none;
            } // END '.btn-group.btn-group-tabs .btn::before'

            &:first-child {
                &::before { display: none; }
            }

            &:last-child { margin-right: 0; }

            & + .#{$vudClassPrefix}btn {
                box-shadow: none;

                &.active {
                    background-color: $segmented-control-button-selected-bg;
                    // box-shadow: $shadow-20 $segmented-control-button-selected-shadow-color;
                    box-shadow: var(--#{$vudTokenPrefix}shadow-20);

                    &:focus:not(:active) {
                        // box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color, $shadow-20 $segmented-control-button-selected-focus-shadow-color;
                        box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);
                    }
                }

                &:active {
                    &:focus {
                        box-shadow: none;
                    }
                }
            } // END '.btn-group.btn-group-tabs .btn + .btn'

            &:hover {
                background-color: $segmented-control-button-hover-bg;
                background-image: none;
                // box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, $shadow-20 $segmented-control-button-hover-shadow-color;
                box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);

                + .#{$vudClassPrefix}btn.disabled,
                + .#{$vudClassPrefix}btn:disabled { box-shadow: none; }
            } // END '.btn-group.btn-group-tabs .btn:hover'

            &:focus:not(:active) {
                box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color;

                + .#{$vudClassPrefix}btn:hover,
                + .#{$vudClassPrefix}btn.disabled,
                + .#{$vudClassPrefix}btn:disabled { box-shadow: none; }
            } // END '.btn-group.btn-group-tabs .btn:focus'

            &.active, &:active {
                background-color: $segmented-control-button-selected-bg;
                // box-shadow: $shadow-20 $segmented-control-button-selected-shadow-color;
                box-shadow: var(--#{$vudTokenPrefix}shadow-20);

                &::before {
                    display: none;
                }

                &:hover {
                    background-color: $segmented-control-button-hover-bg;
                    // box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, $shadow-20 $segmented-control-button-hover-shadow-color;
                    box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);
                }

                &:focus:not(:active) {
                    // box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-hover-border-color, $shadow-20 $segmented-control-button-hover-shadow-color;
                    box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);
                }

                + .#{$vudClassPrefix}btn {
                    &::before { display: none; }

                    // &:hover { box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, $shadow-20 $segmented-control-button-hover-shadow-color; }
                    &:hover { box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, var(--#{$vudTokenPrefix}shadow-20); }

                    &:focus:not(:active) {
                        box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color;
                    }

                    &:active { box-shadow: none; }

                    &.disabled,
                    &:disabled { box-shadow: none; }
                } // END '.btn-group.btn-group-tabs .btn.active +.btn'
            } // END '.btn-group.btn-group-tabs .btn.active'

            &:active {
                &:hover {
                    background-color: $segmented-control-button-pressed-bg;
                    box-shadow: none;
                }

                &:not(:hover) {
                    box-shadow: none;
                }
            }

            &.disabled,
            &:disabled {
                + .#{$vudClassPrefix}btn:hover { box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color; }

                + .#{$vudClassPrefix}btn:focus { box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color; }

                + .#{$vudClassPrefix}btn:active { box-shadow: none; }
            }

            .vismaicon-sm {
                top: rem(1px);
                margin-right: 0;
            }

        } // END '.btn-group.btn-group-tabs .btn'


        &.#{$vudClassPrefix}btn-group-tabs-sm {
            height: rem(32px);
            margin-left: rem(12px);

            border-radius: rem(8px);

            .#{$vudClassPrefix}btn {
                height: rem(24px);
                padding: rem(3px) rem(16px) rem(4px);

                font-size: rem(13px);
                line-height: 1.38462em;

                border-radius: rem(4px);

                &::before {
                    height: rem(14px);
                    top: rem(5px);
                }
            }
        }

        &.#{$vudClassPrefix}btn-group-animation {
            &::before {
                display: block;
                position: absolute;
                content: '';
                height: rem(40px);
                min-width: 0;
                width: var(--btn-grp-animation-width, 0);

                border-radius: rem(10px);
                background-color: $segmented-control-button-selected-bg;
                // box-shadow: $shadow-20 $segmented-control-button-selected-shadow-color;
                box-shadow: var(--#{$vudTokenPrefix}shadow-20);

                transform: translateX(var(--btn-grp-animation-offset, 0));
                transition: transform .3s ease-in-out, width .3s ease-in-out;
            }

            &.#{$vudClassPrefix}btn-group-tabs-sm {
                &::before {
                    height: rem(24px);

                    border-radius: rem(4px);
                }
            }

            .#{$vudClassPrefix}btn {
                &.active, &:active {
                    background-color: transparent;
                    box-shadow: none;
                }

                &.active:hover {
                    background-color: $segmented-control-button-hover-bg;
                    // box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, $shadow-20 $segmented-control-button-hover-shadow-color;
                    box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);
                }

                &.active:focus:not(:active) {
                    box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color;
                }
            }
        }
    } // END '.btn-group.btn-group-tabs'
} // END '.btn-group'
