
// Split buttons

//*** scss local variable ***
$btn-split-icon-color:                       var(--#{$vudTokenPrefix}dropdown-arrow-bg);
$btn-split-icon-disabled-color:              var(--dropdown-arrow-disabled-bg);

$btn-split-disabled-text-color:              var(--#{$vudTokenPrefix}button-disabled-text-color);

$btn-split-border-color:                     var(--#{$vudTokenPrefix}button-border-color);
$btn-split-inner-border-color:               var(--button-inner-border-color);
$btn-split-hover-border-color:               var(--#{$vudTokenPrefix}button-hover-border-color);
$btn-split-focus-border-color:               var(--#{$vudTokenPrefix}button-focus-border-color);
$btn-split-disabled-border-color:            var(--#{$vudTokenPrefix}button-disabled-border-color);

$btn-split-selected-hover-bg:                var(--#{$vudTokenPrefix}button-hover-bg);
$btn-split-selected-bg:                      var(--#{$vudTokenPrefix}button-active-bg);
$btn-split-disabled-bg:                      var(--#{$vudTokenPrefix}button-disabled-bg);

$btn-split-shadow-color:                     var(--button-shadow-color);


$btn-split-primary-icon-color:               var(--#{$vudTokenPrefix}dropdown-primary-arrow-bg);

$btn-split-primary-text-color:               var(--#{$vudTokenPrefix}button-primary-text-color);

$btn-split-primary-border-color:             var(--button-primary-border-color);
$btn-split-primary-inner-border-color:       var(--button-primary-inner-border-color);
$btn-split-primary-hover-border-color:       var(--#{$vudTokenPrefix}button-primary-hover-border-color);
$btn-split-primary-focus-border-color:       var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$btn-split-primary-focus-inner-border-color: var(--button-primary-focus-inner-shadow-color);
$btn-split-primary-selected-border-color:    var(--#{$vudTokenPrefix}button-primary-active-border-color);
$btn-split-primary-disabled-border-color:    var(--button-primary-disabled-border-color);

$btn-split-primary-bg:                       var(--#{$vudTokenPrefix}button-primary-bg);
$btn-split-primary-hover-bg:                 var(--#{$vudTokenPrefix}button-primary-hover-bg);
$btn-split-primary-tint-hover-bg:            linear-gradient(0deg, rgba($black, 0.12), rgba($black, 0.12));
$btn-split-primary-selected-bg:              var(--#{$vudTokenPrefix}button-primary-active-bg);
$btn-split-primary-disabled-bg:              var(--button-primary-disabled-bg);

$btn-split-primary-shadow-color:             var(--button-primary-shadow-color);
$btn-split-primary-focus-shadow-color:       var(--button-primary-focus-shadow-color);


//*** element/component code ***
.#{$vudClassPrefix}btn-group {
    &.#{$vudClassPrefix}btn-split {
        position: relative;
        margin: 0 $spacing-8 $spacing-8 0;

        > .#{$vudClassPrefix}btn {
            margin: 0;
            text-decoration: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            text-align: left;
            justify-content: flex-start;
            // box-shadow: $shadow-20 $btn-split-shadow-color;
            box-shadow: var(--#{$vudTokenPrefix}shadow-20);
            z-index: 1;

            &:focus:not(:active) {
                box-shadow: inset 0 0 0 rem(1px) $btn-split-focus-border-color;
                z-index: 2;
            }

            &:not(.#{$vudClassPrefix}dropdown-toggle) {
                min-width: rem(130px);
                @media (max-width: $screen-md-min) { min-width: rem(90px); }
                @media (max-width: $screen-sm-min) { min-width: rem(70px); }
            }
        }

        //# Dropdown trigger style.
        .#{$vudClassPrefix}dropdown-toggle {
            min-width: $input-btn-height;
            padding: $spacing-4;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            > .#{$vudClassPrefix}caret { display: none; }

            //## drodown arrow style
            &::before {
                right: rem(11px);
            }

            &, &.#{$vudClassPrefix}btn-primary {
                &::before {
                    background-color: $btn-split-icon-color;

                    .#{$vudClassPrefix}btn-toolbar-primary & {
                        background-color: $btn-split-primary-icon-color;
                    }
                }

                //## drodown arrow style for disabled state
                &.disabled, &:disabled {
                    &::before {
                        background-color: $btn-split-icon-disabled-color;

                        .#{$vudClassPrefix}btn-toolbar-primary & {
                            background-color: $btn-split-icon-disabled-color;
                        }
                    }
                }
            }
        }

        &.open {
            z-index: 2;

            .#{$vudClassPrefix}dropdown-toggle {
                background-color: $btn-split-selected-bg;
                box-shadow: rem(-1px) 0 0 0 $btn-split-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color;

                &:hover:not(:active) {
                    background-color: $btn-split-selected-hover-bg;
                    box-shadow: rem(-1px) 0 0 0 $btn-split-hover-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color;
                }

                &:focus:not(:active) {
                    box-shadow: rem(-1px) 0 0 0 $btn-split-focus-border-color, inset 0 0 0 rem(1px) $btn-split-focus-border-color;
                }

                &.disabled, &:disabled {
                    ~ .dropdown-menu { display: none; }
                }
            }
        }

        .disabled &, :disabled &,
        &.disabled, &:disabled {
            &, &.open {
                .#{$vudClassPrefix}dropdown-toggle {
                    &, &.#{$vudClassPrefix}btn-primary {
                        &::before {
                            background-color: $btn-split-icon-disabled-color;

                            .#{$vudClassPrefix}btn-toolbar-primary & {
                                background-color: $btn-split-icon-disabled-color;
                            }
                        }
                    }
                }

                .#{$vudClassPrefix}dropdown-menu {
                    display: none;
                }
            }

            > .#{$vudClassPrefix}btn {
                box-shadow: none;
            }
        }

        &.#{$vudClassPrefix}btn-group-lg {

            > .#{$vudClassPrefix}btn:not(.#{$vudClassPrefix}dropdown-toggle) {
                min-width: rem(114px);
                @media (max-width: $screen-md-min) { min-width: rem(72px); }
                @media (max-width: $screen-sm-min) { min-width: rem(52px); }
            }

            .#{$vudClassPrefix}dropdown-toggle {
                min-width: $input-btn-height-lg;

                &::before { 
                    top: rem(22px); 
                    right: rem(18px);
                }
            }
        }

        // alternative style for split buttons when is not in 'btn-toolbar'
        :not(.#{$vudClassPrefix}btn-toolbar) & {
            // primary sheme color
            &.#{$vudClassPrefix}btn-split-primary {
                .#{$vudClassPrefix}btn {
                    color: $btn-split-primary-text-color;

                    border-color: $btn-split-primary-border-color;
                    background-color: $btn-split-primary-bg;
                    // box-shadow: $shadow-20 $btn-split-primary-shadow-color;
                    box-shadow: var(--#{$vudTokenPrefix}shadow-20);

                    &.#{$vudClassPrefix}dropdown-toggle {
                        &::before {
                            background-color: $btn-split-primary-icon-color;
                        }

                        &.disabled, &:disabled {
                            &::before {
                                background-color: $btn-split-icon-disabled-color;
                            }
                        }
                    }

                    &:hover {
                        border-color: $btn-split-primary-hover-border-color;
                        background-color: $btn-split-primary-hover-bg;
                        background-image: $btn-split-primary-tint-hover-bg;
                    }

                    &:focus {
                        &:not(:active) {
                            border-color: $btn-split-primary-focus-border-color;
                            // box-shadow: inset 0 0 0 rem(1px) $btn-split-primary-focus-border-color, inset 0 0 0 rem(2px) $btn-split-primary-focus-inner-border-color, $shadow-20 $btn-split-primary-focus-shadow-color;
                            box-shadow: inset 0 0 0 rem(1px) $btn-split-primary-focus-border-color, inset 0 0 0 rem(2px) $btn-split-primary-focus-inner-border-color, var(--#{$vudTokenPrefix}shadow-20);

                            +.#{$vudClassPrefix}btn {
                                // box-shadow: rem(-1px) 0 0 0 $btn-split-focus-border-color, $shadow-20 $btn-split-primary-focus-shadow-color;
                                box-shadow: rem(-1px) 0 0 0 $btn-split-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);

                                &.disabled, &:disabled {
                                    box-shadow: rem(-1px) 0 0 0 $btn-split-focus-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color;
                                }
                            }
                        }
                    }

                    &:active {
                        border-color: $btn-split-primary-selected-border-color;
                        background-color: $btn-split-primary-selected-bg;
                        background-image: none;
                    }

                    &.disabled, &:disabled {
                        color: $btn-split-disabled-text-color;

                        &, &:focus {
                            border-color: $btn-split-disabled-border-color;
                            background-color: $btn-split-disabled-bg;
                            box-shadow: none;

                            + .#{$vudClassPrefix}btn {
                                // box-shadow: rem(-1px) 0 0 0 $btn-split-border-color, rem(-6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color, $shadow-20 $btn-split-shadow-color;
                                box-shadow: rem(-1px) 0 0 0 $btn-split-border-color, rem(-6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                                &.disabled, &:disabled {
                                    &, &:focus:not(:active) {
                                        box-shadow: rem(-1px) 0 0 0 $btn-split-inner-border-color;
                                    }
                                }
                            }
                        }
                    }

                    + .#{$vudClassPrefix}btn {
                        // box-shadow: rem(-1px) 0 0 0 $btn-split-primary-inner-border-color, $shadow-20 $btn-split-primary-focus-shadow-color;
                        box-shadow: rem(-1px) 0 0 0 $btn-split-primary-inner-border-color, var(--#{$vudTokenPrefix}shadow-20);

                        &:focus:not(:active) {
                            // box-shadow: rem(-1px) 0 0 0 $btn-split-primary-focus-border-color, inset 0 0 0 rem(1px) $btn-split-primary-focus-border-color, inset 0 0 0 rem(2px) $btn-split-primary-focus-inner-border-color, $shadow-20 $btn-split-primary-focus-shadow-color;
                            box-shadow: rem(-1px) 0 0 0 $btn-split-primary-focus-border-color, inset 0 0 0 rem(1px) $btn-split-primary-focus-border-color, inset 0 0 0 rem(2px) $btn-split-primary-focus-inner-border-color, var(--#{$vudTokenPrefix}shadow-20);

                            &.disabled, &:disabled {
                                box-shadow: rem(-1px) 0 0 0 $btn-split-inner-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color;
                            }
                        }

                        &.disabled, &:disabled {
                            box-shadow: rem(-1px) 0 0 0 $btn-split-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color;
                        }
                    }
                }

                // split buttons style for opend dropdown menu
                &.open {
                    .#{$vudClassPrefix}btn.#{$vudClassPrefix}dropdown-toggle {
                        border-color: $btn-split-primary-selected-border-color;
                        background-color: $btn-split-primary-selected-bg;
                        box-shadow: rem(-1px) 0 0 0 $btn-split-primary-inner-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color;

                        &:hover:not(:active) {
                            border-color: $btn-split-primary-hover-border-color;
                            background-color: $btn-split-primary-hover-bg;
                            background-image: $btn-split-primary-tint-hover-bg;
                            box-shadow: rem(-1px) 0 0 0 $green-70, inset rem(6px) 0 rem(4px) rem(-4px) $btn-split-shadow-color;
                        }

                        &:focus:not(:active) {
                            border-color: $btn-split-primary-focus-border-color;
                            box-shadow: rem(-1px) 0 0 0 $btn-split-primary-focus-border-color, inset 0 0 0 rem(1px) $btn-split-primary-focus-border-color, inset 0 0 0 rem(2px) $btn-split-primary-focus-inner-border-color;
                        }

                        &.disabled, &:disabled {
                            &, &:focus {
                                border-color: $btn-split-primary-disabled-border-color;
                                background-color: $btn-split-primary-disabled-bg;
                            }
                        }
                    }
                }
            }
        }
    }
}

// split primary color style for disabled state
.disabled:not(.#{$vudClassPrefix}btn-toolbar) .#{$vudClassPrefix}btn-group.#{$vudClassPrefix}btn-split.#{$vudClassPrefix}btn-split-primary,
.disabled:not(.#{$vudClassPrefix}btn-toolbar) .#{$vudClassPrefix}btn-group.#{$vudClassPrefix}btn-split.#{$vudClassPrefix}btn-split-primary.open,
:not(.#{$vudClassPrefix}btn-toolbar):disabled .#{$vudClassPrefix}btn-group.#{$vudClassPrefix}btn-split.#{$vudClassPrefix}btn-split-primary,
:not(.#{$vudClassPrefix}btn-toolbar):disabled .#{$vudClassPrefix}btn-group.#{$vudClassPrefix}btn-split.#{$vudClassPrefix}btn-split-primary.open,
:not(.#{$vudClassPrefix}btn-toolbar) .#{$vudClassPrefix}btn-group.#{$vudClassPrefix}btn-split.#{$vudClassPrefix}btn-split-primary.disabled,
:not(.#{$vudClassPrefix}btn-toolbar) .#{$vudClassPrefix}btn-group.#{$vudClassPrefix}btn-split.#{$vudClassPrefix}btn-split-primary.disabled.open,
:not(.#{$vudClassPrefix}btn-toolbar) .#{$vudClassPrefix}btn-group.#{$vudClassPrefix}btn-split.#{$vudClassPrefix}btn-split-primary:disabled,
:not(.#{$vudClassPrefix}btn-toolbar) .#{$vudClassPrefix}btn-group.#{$vudClassPrefix}btn-split.#{$vudClassPrefix}btn-split-primary.open:disabled {
    .#{$vudClassPrefix}btn {
        &.#{$vudClassPrefix}dropdown-toggle {
            &::before {
                background-color: $btn-split-icon-disabled-color;
            }
        }

        &, &:focus {
            color: $btn-split-disabled-text-color;

            border-color: $btn-split-disabled-border-color;
            background-color: $btn-split-disabled-bg;
            box-shadow: none;

            + .#{$vudClassPrefix}btn {
                &, &:focus {
                    box-shadow: rem(-1px) 0 0 0 $btn-split-inner-border-color;
                }
            }
        }
    }
}
