
// Button Group

//*** scss local variable ***
$button-group-border-color:                     var(--#{$vudTokenPrefix}button-border-color);
$button-group-hover-border-color:               var(--#{$vudTokenPrefix}button-hover-border-color);
$button-group-focus-border-color:               var(--#{$vudTokenPrefix}button-focus-border-color);
$button-group-focus-inner-border-color:         var(--button-inner-border-color); // vertical border betwin two buttons
$button-group-pressed-border-color:             var(--#{$vudTokenPrefix}button-pressed-border-color);
$button-group-selected-border-color:            var(--#{$vudTokenPrefix}button-active-border-color);
$button-group-disabled-border-color:            var(--#{$vudTokenPrefix}button-disabled-border-color);

$button-group-bg:                               var(--#{$vudTokenPrefix}button-bg);
$button-group-hover-bg:                         var(--#{$vudTokenPrefix}button-hover-bg);
$button-group-pressed-bg:                       var(--#{$vudTokenPrefix}button-pressed-bg);
$button-group-selected-bg:                      var(--#{$vudTokenPrefix}button-selected-bg);
$button-group-disabled-bg:                      var(--#{$vudTokenPrefix}button-disabled-bg);

$button-group-shadow-color:                     var(--button-shadow-color);
$button-group-hover-shadow-color:               var(--button-hover-shadow-color);
$button-group-focus-shadow-color:               var(--button-focus-shadow-color);

$button-group-text-color:                       var(--#{$vudTokenPrefix}button-text-color);
$button-group-disabled-text-color:              var(--#{$vudTokenPrefix}button-disabled-text-color);

$button-group-primary-inner-border-color:       var(--button-primary-inner-border-color); // vertical border betwin two buttons
$button-group-primary-hover-border-color:       var(--#{$vudTokenPrefix}button-primary-hover-border-color);
$button-group-primary-focus-border-color:       var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$button-group-primary-pressed-border-color:     var(--#{$vudTokenPrefix}button-primary-active-border-color);
$button-group-primary-selected-border-color:    var(--button-primary-border-color);

$button-group-primary-hover-bg:                 var(--#{$vudTokenPrefix}button-primary-hover-bg);
$button-group-primary-tint-hover-bg:            linear-gradient(0deg, rgba($black, 0.12), rgba($black, 0.12));
$button-group-primary-selected-bg:              var(--#{$vudTokenPrefix}button-primary-bg);

$button-group-primary-focus-inner-shadow-color: var(--button-primary-focus-inner-shadow-color);

$button-group-primary-text-color:               var(--#{$vudTokenPrefix}button-primary-text-color);

//*** element/component code ***
// Group a series of buttons together on a single line with the button group
.#{$vudClassPrefix}btn-group {
    display: inline-flex;
    vertical-align: middle;

    //# Defining/overwiting the button defaults inside button groups
    .#{$vudClassPrefix}btn {
        color: $button-group-text-color;

        border-color: $button-group-border-color;
        background-color: $button-group-bg;
        // box-shadow: $shadow-20 $button-group-shadow-color;
        box-shadow: var(--#{$vudTokenPrefix}shadow-20);

        //## Removing left border-radius and margins, exept if it's the only button or is the last button
        &:not(:last-child) {
            margin-right: 0;

            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        //### button active/selected state in group buttons
        &.active {
            border-color: $button-group-border-color;
            background-color: $button-group-selected-bg;
            box-shadow: none;

            // selected button on hoverd
            &:hover {
                box-shadow: none;

                // Button style for one that precede a 'selected hoverd' button
                + .#{$vudClassPrefix}btn {
                    &.active {
                        box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color;

                        &:focus { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, inset 0 0 0 rem(1px) $button-group-focus-border-color; }

                        &:active { box-shadow: rem(-1px) 0 0 0 $button-group-selected-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                    }
                }
            } // END '.btn-group .btn.active:hover'

            // selected button on focused
            &:focus {
                box-shadow: inset 0 0 0 rem(1px) $button-group-focus-border-color;

                // Button style for one that precede a 'selected focused' button
                + .#{$vudClassPrefix}btn {
                    &.active {
                        &, &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color; }
                    }
                }
            } // END '.btn-group .btn:focus'

            // pressed selected button
            &:active {
                box-shadow: none;

                + .#{$vudClassPrefix}btn {
                    &.active {
                        &, &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                    }
                }
            } // END '.btn-group .btn:active'

            // Base style for a button that precede a 'selected' button
            + .#{$vudClassPrefix}btn {
                box-shadow: rem(-1px) 0 0 0 $button-group-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                // hoverd state for a button that precede a 'selected' button
                &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, var(--#{$vudTokenPrefix}shadow-20); }

                // focused state for a button that precede a 'selected' button
                &:focus { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, inset 0 0 0 rem(1px) $button-group-focus-border-color, var(--#{$vudTokenPrefix}shadow-20); }

                // pressed state for a button that precede a 'selected' button
                &:active { box-shadow: rem(-1px) 0 0 0 $button-group-selected-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }

                // selected state for a button that precede a 'selected' button
                &.active {
                    box-shadow: rem(-2px) 0 0 rem(-1px) $button-group-focus-inner-border-color;

                    &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color; }

                    &:active { box-shadow: rem(-1px) 0 0 0 $button-group-selected-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                } // END '.btn-group .btn + .btn.active'
            } // END '.btn-group .btn + .btn'
        } // END '.btn-group .btn.active'

        //### button hover state
        &:hover {
            border-color: $button-group-hover-border-color;
            background-color: $button-group-hover-bg;
            // box-shadow: $shadow-20 $button-group-hover-shadow-color;
            box-shadow: var(--#{$vudTokenPrefix}shadow-20);

            // Button style for one that precede a 'hoverd' button
            + .#{$vudClassPrefix}btn {
                box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);

                &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
            } // END '.btn-group .btn:hover + .btn'
        } // END '.btn-group .btn:hover'


        //### button focus state
        &:focus {
            border-color: $button-group-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $button-group-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);

            // Button style for one that precede a 'focused' button
            + .#{$vudClassPrefix}btn {
                box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);

                &.active:hover, &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color; }

                &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, var(--#{$vudTokenPrefix}shadow-20); }
            } // END '.btn-group .btn:focus + .btn'
        } // END '.btn-group .btn:focus'


        //### button active/pressed state
        &:active {
            border-color: $button-group-pressed-border-color;
            background-color: $button-group-pressed-bg;
            box-shadow: none;

            // Button style for one that precede a 'pressed' button
            + .#{$vudClassPrefix}btn {
                &, &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-pressed-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, var(--#{$vudTokenPrefix}shadow-20); }

                &.active, &.active:hover, &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-group-pressed-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
            } // END '.btn-group .btn:active + .btn'
        } // END '.btn-group .btn:active'


        //### button disabled state
        &:disabled, &.disabled {
            color: $button-group-disabled-text-color;

            border-color: $button-group-disabled-border-color;
            background-color: $button-group-disabled-bg;
            box-shadow: none;

            // Button style for one that precede a 'disabled' button
            + .#{$vudClassPrefix}btn {
                box-shadow: rem(-1px) 0 0 0 $button-group-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                &.active {
                    box-shadow: rem(-1px) 0 0 0 $button-group-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color;

                    &:hover {  box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                }

                &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, var(--#{$vudTokenPrefix}shadow-20); }

                &:focus {
                    box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, inset 0 0 0 rem(1px) $button-group-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);
                }

                &:active {
                    box-shadow: rem(-1px) 0 0 0 $button-group-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color;
                }
    
                &:disabled, &.disabled { box-shadow: none; }
            } // END '.btn-group .btn:disabled + .btn'
        } // END '.btn-group .btn:disabled'


        //## Restyling the second and up button in the group
        + .#{$vudClassPrefix}btn {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            box-shadow: rem(-2px) 0 0 rem(-1px) $button-group-focus-inner-border-color, var(--#{$vudTokenPrefix}shadow-20);

            // selected state
            &.active {
                box-shadow: inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color;

                &:focus {
                    &, &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, inset 0 0 0 rem(1px) $button-group-focus-border-color; }

                    &:active { box-shadow: rem(-1px) 0 0 0 $button-group-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                } // END '.btn-group .btn + .btn.active:focus'
            } // END '.btn-group .btn + .btn.active'

            // hover state
            &:hover {
                box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);

                &.active { box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color; }
            } // END '.btn-group .btn + .btn:hover'

            // focus state
            &:focus {
                box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, inset 0 0 0 rem(1px) $button-group-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);
            } // END '.btn-group .btn + .btn:focus'

            // pressed state
            &:active {
                &, &.active { box-shadow: rem(-1px) 0 0 0 $button-group-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
            } // END '.btn-group .btn + .btn:active'

            // disabled state
            &:disabled, &.disabled {
                box-shadow: rem(-1px) 0 0 0 $button-group-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color;
            } // END '.btn-group .btn + .btn.disabled'
        } // END '.btn-group .btn + .btn'
    } // END '.btn-group .btn'


    //# Style for buttons in 'disabled group'
    :disabled &, .disabled &,
    &:disabled,  &.disabled {
        .#{$vudClassPrefix}btn {
            color: $button-group-disabled-text-color;

            border-color: $button-group-disabled-border-color;
            background-color: $button-group-disabled-bg;
            box-shadow: none;

            //## Selected state for a button in 'disabled group'
            &.active {
                background-color: $button-group-selected-bg;
                box-shadow: none;

                //### Button style for one that precede a 'selected' button in 'disabled group'
                + .#{$vudClassPrefix}btn {
                    box-shadow: rem(-1px) 0 0 0 $button-group-focus-inner-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color;

                    &.active { box-shadow: rem(-1px) 0 0 0 $button-group-focus-inner-border-color; }
                }
            } // END '.disabled .btn-group .btn.active' or '.btn-group.disabled .btn.active'

            + .#{$vudClassPrefix}btn {
                box-shadow: rem(-1px) 0 0 0 $button-group-focus-inner-border-color;

                &.active {
                    &, &:disabled,  &.disabled { box-shadow: rem(-1px) 0 0 0 $button-group-focus-inner-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                }
            } // END '.disabled .btn-group .btn + .btn' or '.btn-group.disabled .btn + .btn'
        } // END '.disabled .btn-group .btn' or '.btn-group.disabled .btn'
    } // END '.disabled .btn-group' or '.btn-group.disabled'

    > .#{$vudClassPrefix}btn-split, > .#{$vudClassPrefix}dropdown, > .#{$vudClassPrefix}dropup {
        //### 'Button group' inside a button group that is not the first child
        &:not(:first-child) {
            .#{$vudClassPrefix}btn {
                &:first-child {
                    border-left: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &:last-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &:last-child { 
            .#{$vudClassPrefix}btn.#{$vudClassPrefix}dropdown-toggle { 
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }

        //### 'Button group' inside a button group that is not the last child
        &:not(:last-child) {
            margin-right: 0;
        }

        //### Button style for one that precede a 'button group', 'split button group' or 'dropdown button'
        + .#{$vudClassPrefix}btn {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        } // END '.btn-group > .btn-group + .btn' or '.btn-group > .dropdown + .btn'
    } // END '.btn-group > .btn-group' or '.btn-group > .dropdown'

    > .#{$vudClassPrefix}btn + .#{$vudClassPrefix}btn-group:not(.#{$vudClassPrefix}btn-split) {
        margin-left: rem(6px);
    }

    > .#{$vudClassPrefix}btn-group:not(.#{$vudClassPrefix}btn-split) {
        margin-right: rem(6px);
    }

    // group of buttons stretch at equal sizes to span the entire width of its parent
    &.#{$vudClassPrefix}btn-group-justified {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        // width: 100%;
        // table-layout: fixed;
        // border-collapse: separate;

        > .#{$vudClassPrefix}btn, > .#{$vudClassPrefix}btn-group, > div {
            // display: table-cell;
            float: none;
            width: 100%;
            min-width: unset;
            margin-bottom: 0;
        } // END '.btn-group.btn-group-justified > .btn'

        .#{$vudClassPrefix}btn.#{$vudClassPrefix}dropdown-toggle {
            min-width: 100%;
        }
    } // END '.btn-group.btn-group-justified'

    //# Alternative styling for group buttons
    &.#{$vudClassPrefix}btn-group-primary {
        
        //## Defind the states for selected or pressed button in the alternative styling
        .#{$vudClassPrefix}btn {

            //### Selected state
            &.active {
                color: $button-group-primary-text-color;

                border-color: $button-group-primary-selected-border-color;
                background-color: $button-group-primary-selected-bg;

                // selected and hovered state
                &:hover {
                    border-color: $button-group-primary-hover-border-color;
                    background-color: $button-group-primary-hover-bg;
                    background-image: $button-group-primary-tint-hover-bg;

                    // button style for a button that precede a 'selected and hoverd' button
                    + .#{$vudClassPrefix}btn {
                        box-shadow: rem(-1px) 0 0 0 $button-group-primary-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);

                        &.active {  box-shadow: rem(-1px) 0 0 0 $button-group-primary-hover-border-color; }
                    }
                } // END '.btn-group.btn-group-primary .btn.active:hover'

                // selected and focused state
                &:focus {
                    border-color: $button-group-primary-focus-border-color;
                    box-shadow: inset 0 0 0 rem(1px) $button-group-primary-focus-border-color, inset 0 0 0 rem(2px) $button-group-primary-focus-inner-shadow-color;

                    // button style for a button that precede a 'selected and focused' button
                    + .#{$vudClassPrefix}btn {
                        box-shadow: rem(-1px) 0 0 0 $button-group-primary-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);

                        &.active {
                            box-shadow: rem(-1px) 0 0 0 $button-group-primary-focus-border-color;

                            &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-primary-focus-border-color; }
                        }

                        &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-primary-focus-border-color, var(--#{$vudTokenPrefix}shadow-20); }
                    }
                } // END '.btn-group.btn-group-primary .btn.active:focus'

                // selected and pressed state
                &:active {
                    border-color: $button-group-primary-pressed-border-color;
                    background-color: $button-group-primary-selected-bg;
                    background-image: none;
                    box-shadow: none;

                    // button style for a button that precede a 'selected and pressed' button
                    + .#{$vudClassPrefix}btn {
                        box-shadow: rem(-1px) 0 0 0 $button-group-primary-inner-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                        &.active {
                            &, &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-primary-inner-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                        }

                        &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-primary-inner-border-color, var(--#{$vudTokenPrefix}shadow-20); }
                    }
                } // END '.btn-group.btn-group-primary .btn.active:active'

                // selected and disabled state
                :disabled &, .disabled &,
                &:disabled,  &.disabled {
                    color: $button-group-disabled-text-color;

                    border-color: $button-group-disabled-border-color;
                    background-color: $button-group-disabled-bg;

                    // button style for a button that precede a 'selected and disabled' button
                    + .#{$vudClassPrefix}btn { box-shadow: rem(-1px) 0 0 0 $button-group-focus-inner-border-color, rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, var(--#{$vudTokenPrefix}shadow-20); }
                } // END '.btn-group.btn-group-primary .btn.active.disabled'

                // button style for a button that precede a 'selected' button
                + .#{$vudClassPrefix}btn {
                    box-shadow: rem(-1px) 0 0 0 $button-group-primary-selected-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                    &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, var(--#{$vudTokenPrefix}shadow-20); }

                    &:focus { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, inset 0 0 0 rem(1px) $button-group-focus-border-color, var(--#{$vudTokenPrefix}shadow-20); }

                    &:active { box-shadow: rem(-1px) 0 0 0 $button-group-selected-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                } // END '.btn-group.btn-group-primary .btn.active + .btn'
            } // END '.btn-group.btn-group-primary .btn.active'

            //### Button hoverd state
            &:hover {
                // button style for a button that precede a 'hoverd' button
                + .#{$vudClassPrefix}btn {
                    &.active {
                        &:focus { box-shadow: rem(-1px) 0 0 0 $button-group-primary-focus-border-color, inset 0 0 0 rem(1px) $button-group-primary-focus-border-color, inset 0 0 0 rem(2px) $button-group-primary-focus-inner-shadow-color; }

                        &:active { box-shadow: rem(-1px) 0 0 0 $button-group-primary-pressed-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }

                        &, &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                    }
                }
            } // END '.btn-group.btn-group-primary .btn:hover'

            //### Button focus state
            &:focus {
                // button style for a button that precede a 'focused' button
                + .#{$vudClassPrefix}btn {
                    &.active {
                        &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color; }

                        &, &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                    }
                }
            } // END '.btn-group.btn-group-primary .btn:focus'

            //### Button pressed state
            &:active {
                // button style for a button that precede a 'pressed' button
                + .#{$vudClassPrefix}btn {
                    &.active {
                        box-shadow: rem(-1px) 0 0 0 $button-group-border-color, rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color;

                        &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }

                        &:disabled, &.disabled { box-shadow: rem(-6px) 0 rem(4px) rem(-4px) $button-group-shadow-color, rem(-2px) 0 0 rem(-1px) $button-group-disabled-border-color; }
                    }
                }
            } // END '.btn-group.btn-group-primary .btn:active'


            //### Restyling the second and up button in the group
            + .#{$vudClassPrefix}btn {
                &.active {
                    box-shadow: rem(-1px) 0 0 0 $button-group-primary-selected-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color;

                    &:hover { box-shadow: rem(-1px) 0 0 0 $button-group-primary-hover-border-color; }

                    &:focus { box-shadow: rem(-1px) 0 0 0 $button-group-primary-focus-border-color, inset 0 0 0 rem(1px) $button-group-primary-focus-border-color, inset 0 0 0 rem(2px) $button-group-primary-focus-inner-shadow-color; }

                    &:active { box-shadow: rem(-1px) 0 0 0 $button-group-primary-inner-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }

                    &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-group-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-group-shadow-color; }
                } // END '.btn-group.btn-group-primary .btn + .btn.active'
            } // END '.btn-group.btn-group-primary .btn + .btn'
        } // END '.btn-group.btn-group-primary .btn'
    } // END '.btn-group.btn-group-primary'
} // END '.btn-group'
