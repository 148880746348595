
// Directional buttons style declaration

//*** scss local variable ***
/// Button Directional Default Theme
$button-directional-border-color:                     var(--#{$vudTokenPrefix}button-border-color);
$button-directional-hover-border-color:               var(--#{$vudTokenPrefix}button-hover-border-color);
$button-directional-focus-border-color:               var(--#{$vudTokenPrefix}button-focus-border-color);
$button-directional-pressed-border-color:             var(--#{$vudTokenPrefix}button-pressed-border-color);
$button-directional-active-border-color:              var(--#{$vudTokenPrefix}button-active-border-color);
$button-directional-disabled-border-color:            var(--#{$vudTokenPrefix}button-disabled-border-color);

$button-directional-bg:                               var(--#{$vudTokenPrefix}button-bg);
$button-directional-hover-bg:                         var(--#{$vudTokenPrefix}button-hover-bg);
$button-directional-pressed-bg:                       var(--#{$vudTokenPrefix}button-pressed-bg);
$button-directional-active-bg:                        var(--#{$vudTokenPrefix}button-active-bg);
$button-directional-disabled-bg:                      var(--#{$vudTokenPrefix}button-disabled-bg);

$button-directional-hover-shadow-color:               var(--button-hover-shadow-color);
$button-directional-focus-shadow-color:               var(--button-focus-shadow-color);

/// Button Directional Primary Theme
$button-directional-primary-hover-border-color:       var(--#{$vudTokenPrefix}button-primary-hover-border-color);
$button-directional-primary-focus-border-color:       var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$button-directional-primary-active-border-color:      var(--#{$vudTokenPrefix}button-primary-active-border-color);

$button-directional-primary-bg:                       var(--#{$vudTokenPrefix}button-primary-bg);
$button-directional-primary-hover-bg:                 var(--#{$vudTokenPrefix}button-primary-hover-bg);
$button-directional-primary-tint-hover-bg:            linear-gradient(0deg, rgba($black, 0.12), rgba($black, 0.12));
$button-directional-primary-active-bg:                var(--#{$vudTokenPrefix}button-primary-active-bg);

$button-directional-primary-hover-shadow-color:       var(--button-primary-hover-shadow-color);
$button-directional-primary-focus-shadow-color:       var(--button-primary-focus-shadow-color);
$button-directional-primary-focus-inner-shadow-color: var(--button-primary-focus-inner-shadow-color);


//*** element/component code ***
.#{$vudClassPrefix}btn {
    //# General styles
    &.left, &.right {
        position: relative;
        min-width: rem(120px);

        //### Defining the tip/arrow of the button, on normal dimension (height: 30px)
        &::before {
            display: block;
            position: absolute;
            content: '';

            width: rem(18px);
            height: rem(18px);
            top: $spacing-6;

            background-color: $button-directional-bg;

            transform: rotate(45deg) skew(11deg, 11deg);
        } // END '.btn.right::before' and '.btn.left::before'

        // hover state
        &:hover {
            &::before { background-color: $button-directional-hover-bg; }
        }

        // pressed state
        &:active, &.active:active {
            background-color: $button-directional-pressed-bg;

            &::before { background-color: $button-directional-pressed-bg; }
        }

        // active/selected state
        &.active {
            border-color: $button-directional-active-border-color;

            &::before { background-color: $button-directional-active-bg; }

            &:hover {
                border-color: $button-directional-hover-border-color;
                background-color: $button-directional-hover-bg;
                // box-shadow: $shadow-20 $button-directional-hover-shadow-color;
                box-shadow: var(--#{$vudTokenPrefix}shadow-20);

                &:before {
                    background-color: $button-directional-hover-bg;
                }
            }

            &:focus {
                border-color: $button-directional-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $button-directional-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);
            }

            &:active {
                border-color: $button-directional-pressed-border-color;
                background-color: $button-directional-pressed-bg;
                box-shadow: none;

                &:before {
                    background-color: $button-directional-pressed-bg;
                }
            }
        }

        // disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            border-color: $button-directional-disabled-border-color;

            &::before { background-color: $button-directional-disabled-bg; }
        }

        //### and, on big buttons (height: rem(48px))
        &.#{$vudClassPrefix}btn-lg {
            &::before {
                width: rem(28px);
                height: rem(28px);
                top: rem(9px);

                transform: rotate(45deg) skew(11deg, 11deg);
            }
        } // END '.btn.btn-lg.right' and '.btn.btn-lg.left'


        //## General style for Directional button primary theme
        &.#{$vudClassPrefix}btn-primary {
            &::before {
                background-color: $button-directional-primary-bg;
                box-shadow: none;
            }

            // hover state
            &:hover:not(:active) {
                &::before {
                    background-color: $button-directional-primary-hover-bg;
                    background-image: $button-directional-primary-tint-hover-bg;
                    box-shadow: none;
                }
            }

            // pressed state
            &:active, &.active:active {
                border-color: $button-directional-primary-active-border-color;
                background-color: $button-directional-primary-active-bg;
                background-image: none;

                &::before {
                    background-color: $button-directional-primary-active-bg;
                    box-shadow: none;
                }
            }

            // active/selected state
            &.active {
                border-color: $button-directional-primary-active-border-color;

                &::before { background-color: $button-directional-primary-active-bg; }

                &:hover {
                    border-color: $button-directional-primary-hover-border-color;
                    background-color: $button-directional-primary-hover-bg;
                    background-image: $button-directional-primary-tint-hover-bg;
                    // box-shadow: $shadow-20 $button-directional-primary-hover-shadow-color;
                    box-shadow: var(--#{$vudTokenPrefix}shadow-20);
    
                    &:before {
                        background-color: $button-directional-primary-hover-bg;
                        background-image: $button-directional-primary-tint-hover-bg;
                    }
                }

                &:focus {
                    border-color: $button-directional-primary-focus-border-color;
                    box-shadow: inset 0 0 0 rem(1px) $button-directional-primary-focus-border-color, inset 0 0 0 rem(2px) $button-directional-primary-focus-inner-shadow-color, 0 rem(2px) rem(4px) 0 $button-directional-primary-focus-shadow-color;
                }

                &:active {
                    border-color: $button-directional-primary-active-border-color;
                    background-color: $button-directional-primary-active-bg;
                    background-image: none;
                    box-shadow: none;

                    &:before {
                        background-color: $button-directional-primary-active-bg;
                        background-image: none;
                        box-shadow: none;
                    }
                }
            }

            // disabled state
            &:disabled, &.disabled,
            :disabled &, .disabled & {
                &::before { background-color: $button-directional-disabled-bg; }
            }
        } // END '.btn.btn-primary.right' and '.btn.btn-primary.left'
    } // END '.btn.right' and '.btn.left'

    //# Defining the button tip/arrow for different state of the button
    //## Left button
    &.left {
        border-top-left-radius: $spacing-4;
        border-bottom-left-radius: $spacing-4;
        margin-left: $spacing-8;
        
        &::before {
            left: rem(-9px);

            border-left-color: $button-directional-border-color;
            border-bottom-color: $button-directional-border-color;
            box-shadow: inset rem(2px) rem(-2px) 0 rem(-1px) $button-directional-border-color;
        }

        // hover state
        &:hover, &.active:hover {
            &::before { box-shadow: inset rem(2px) rem(-2px) 0 rem(-1px) $button-directional-hover-border-color; }
        }

        // focus state
        &:focus, &.active:focus {
            &::before { box-shadow: inset rem(3px) rem(-3px) 0 rem(-1px) $button-directional-focus-border-color; }
        }

        // pressed state
        &:active, &.active:active {
            &::before { box-shadow: inset rem(2px) rem(-2px) 0 rem(-1px) $button-directional-pressed-border-color; }
        }

        // active/selected state
        &.active {
            &::before { box-shadow: inset rem(2px) rem(-2px) 0 rem(-1px) $button-directional-active-border-color; }
        }

        // disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            &::before { box-shadow: inset rem(2px) rem(-2px) 0 rem(-1px) $button-directional-disabled-border-color; }
        }

        // large dimensions
        &.#{$vudClassPrefix}btn-lg {
            border-top-left-radius: rem(3px);
            border-bottom-left-radius: rem(3px);
            margin-left: $spacing-16;

            &::before { left: rem(-14px); }
        } // END '.btn.btn-lg.left'


        //### Left button - primary theme
        &.#{$vudClassPrefix}btn-primary {
            &.active:not(:focus) {
                &::before { box-shadow: none; }
            }

            &:focus:not(:active) {
                &::before { box-shadow: inset rem(3px) rem(-3px) 0 rem(-1px) $button-directional-focus-border-color, inset rem(4px) rem(-4px) 0 rem(-1px) $button-directional-primary-focus-inner-shadow-color; }
            }

            // disabled state
            &:disabled, &.disabled,
            :disabled &, .disabled & {
                &::before { box-shadow: inset rem(2px) rem(-2px) 0 rem(-1px) $button-directional-disabled-border-color; }
            }
        } // END '.btn.btn-primary.left'
    } // END '.btn.left'


    //## Right buton
    &.right {
        border-top-right-radius: rem(4px);
        border-bottom-right-radius: rem(4px);
        margin-right: $spacing-12;
        
        &::before {
            right: rem(-9px);

            border-right-color: $button-directional-border-color;
            border-top-color: $button-directional-border-color;
            box-shadow: inset rem(-2px) rem(2px) 0 rem(-1px) $button-directional-border-color;
        }

        // hover state
        &:hover {
            &::before { box-shadow: inset rem(-2px) rem(2px) 0 rem(-1px) $button-directional-hover-border-color; }
        }

        // focus state
        &:focus {
            &::before { box-shadow: inset rem(-3px) rem(3px) 0 rem(-1px) $button-directional-focus-border-color; }
        }

        // pressed state
        &:active {
            &::before { box-shadow: inset rem(-2px) rem(2px) 0 rem(-1px) $button-directional-pressed-border-color; }
        }

        // active/selected state
        &.active {
            &::before { box-shadow: inset rem(-2px) rem(2px) 0 rem(-1px) $button-directional-active-border-color; }

            &:hover {
                &::before { box-shadow: inset rem(-2px) rem(2px) 0 rem(-1px) $button-directional-hover-border-color; }
            }

            &:focus {
                &::before { box-shadow: inset rem(-3px) rem(3px) 0 rem(-1px) $button-directional-focus-border-color; }
            }

            &:active {
                &::before { box-shadow: inset rem(-2px) rem(2px) 0 rem(-1px) $button-directional-pressed-border-color; }
            }
        }

        // disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            &::before { box-shadow: inset rem(-2px) rem(2px) 0 rem(-1px) $button-directional-disabled-border-color; }
        }

        // large dimensions
        &.#{$vudClassPrefix}btn-lg {
            border-top-right-radius: rem(3px);
            border-bottom-right-radius: rem(3px);
            margin-right: $spacing-16;

            &::before { right: rem(-14px); }
        } // END '.btn.btn-lg.right'


        //### Right button - primary theme
        &.#{$vudClassPrefix}btn-primary {
            &:focus:not(:active) {
                &::before { box-shadow: inset rem(-3px) rem(3px) 0 rem(-1px) $button-directional-primary-focus-border-color, inset rem(-4px) rem(4px) 0 rem(-1px) $button-directional-primary-focus-inner-shadow-color; }
            }

            &.active:not(:focus) {
                &::before { box-shadow: none; }
            }
  
            // disabled state
            &:disabled, &.disabled,
            :disabled &, .disabled & {
                &::before { box-shadow: inset rem(-2px) rem(2px) 0 rem(-1px) $button-directional-disabled-border-color; }
            }
        } // END '.btn.btn-primary.right'
    } // END '.btn.right'
} // END '.btn'
