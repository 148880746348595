
/*
 * Badges/Labels
 */

.#{$vudClassPrefix}badge {
    position: relative;
    min-width: rem(30px);
    top: 0;
    padding: rem(2px) rem(8px);
    margin-right: rem(1px);

    text-align: center;
    font-size: rem(12px);
    font-weight: $bold;
    line-height: rem(16px);

    border-radius: rem(20px);

    &.#{$vudClassPrefix}badge-sm {
        text-indent: -9999px;
        display: block;
        width: rem(9px);
        min-width: 1%;
        height: rem(9px);
        border-radius: rem(4px);
        position: absolute;
        right: rem(-2px);
        top: rem(-2px);
        z-index: 1;
        padding: 0;
        margin: 0;

        &, &.#{$vudClassPrefix}badge-primary {
            background-color: var(--#{$vudTokenPrefix}badge-success-sm-bg);
        }
    
        &.#{$vudClassPrefix}badge-secondary,
        &.#{$vudClassPrefix}badge-success {
            background-color: var(--#{$vudTokenPrefix}badge-success-sm-bg);
        }
    
        &.#{$vudClassPrefix}badge-info {
            background-color: var(--#{$vudTokenPrefix}badge-info-sm-bg);
        }
    
        &.#{$vudClassPrefix}badge-warning {
            background-color: var(--#{$vudTokenPrefix}badge-warning-sm-bg);
        }
    
        &.#{$vudClassPrefix}badge-danger {
            background-color: var(--#{$vudTokenPrefix}badge-danger-sm-bg);
        }
    }

    &,
    &.#{$vudClassPrefix}badge-primary {
        color: var(--#{$vudTokenPrefix}badge-text-color);
        background-color: var(--#{$vudTokenPrefix}badge-bg);
        box-shadow: var(--#{$vudTokenPrefix}badge-shadow);
    }

    &.#{$vudClassPrefix}badge-secondary,
    &.#{$vudClassPrefix}badge-success {
        color:  var(--#{$vudTokenPrefix}badge-success-text);
        background-color: var(--#{$vudTokenPrefix}badge-success-bg);
        box-shadow: var(--#{$vudTokenPrefix}badge-success-shadow);
    }

    &.#{$vudClassPrefix}badge-info {
        color: $neutral-05;
        background-color: var(--#{$vudTokenPrefix}badge-info-bg);
    }

    &.#{$vudClassPrefix}badge-warning {
        color: $neutral-05;
        background-color: var(--#{$vudTokenPrefix}badge-warning-bg);
    }

    &.#{$vudClassPrefix}badge-danger {
        color: $neutral-05;
        background-color: var(--#{$vudTokenPrefix}badge-danger-bg);
        box-shadow: var(--#{$vudTokenPrefix}badge-danger-shadow);
    }

    a & {
        display: inline-block;
        margin-left: rem(6px);
        text-decoration: none;
    }

    .#{$vudClassPrefix}btn &, .#{$vudClassPrefix}nav-pills & {
        margin-left: rem(6px);
        box-shadow: none;
    }

    .#{$vudClassPrefix}btn:not(.#{$vudClassPrefix}btn-primary) &, .#{$vudClassPrefix}nav-pills & {
        color: $neutral-05;

        &:not(.#{$vudClassPrefix}badge-secondary):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger):not(.#{$vudClassPrefix}badge-sm) {
            background-color: $primary-green;
        }
    }

    .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary &,
    .#{$vudClassPrefix}nav-pills.#{$vudClassPrefix}nav-pills-primary .active & {
        background-color: $neutral-05;

        &:not(.#{$vudClassPrefix}badge-secondary):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger):not(.#{$vudClassPrefix}badge-sm) {
            background-color: $neutral-05;
        }

        &.#{$vudClassPrefix}badge-success, &.#{$vudClassPrefix}badge-secondary {
            color: $primary-green;
        }

        &.#{$vudClassPrefix}badge-info {
            color: $primary-blue;
        }

        &.#{$vudClassPrefix}badge-warning {
            color: $primary-orange;
        }

        &.#{$vudClassPrefix}badge-danger {
            color: $primary-red;
        }
    }

    .#{$vudClassPrefix}nav-pills.#{$vudClassPrefix}nav-pills-primary .active & {
        &:not(.#{$vudClassPrefix}badge-secondary):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger):not(.#{$vudClassPrefix}badge-sm) {
            color: $primary-neutral;
        }
    }
}

.#{$vudClassPrefix}badge {
    .disabled &, :disabled & {
        color: $neutral-60;

        &.#{$vudClassPrefix}badge-success, &.#{$vudClassPrefix}badge-secondary {
            color: $green-20;
            background-color: $green-40;
        }

        &.#{$vudClassPrefix}badge-info {
            color: $blue-10;
            background-color: $blue-20;
        }

        &.#{$vudClassPrefix}badge-warning {
            color: $orange-20;
            background-color: $orange-40;
        }

        &.#{$vudClassPrefix}badge-danger {
            color: $red-20;
            background-color: $red-30;
        }
    }

    .disabled .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary &,
    :disabled .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary &,
    .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary:disabled &,
    .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary.disabled & {
        color: $neutral-60;
        background-color: $neutral-05;

        &.#{$vudClassPrefix}badge-success, &.#{$vudClassPrefix}badge-secondary {
            color: $green-40;
        }

        &.#{$vudClassPrefix}badge-info {
            color: $blue-20;
        }

        &.#{$vudClassPrefix}badge-warning {
            color: $orange-40;
        }

        &.#{$vudClassPrefix}badge-danger {
            color: $red-30;
        }
    }
}

.#{$vudClassPrefix}badge {
    :disabled .#{$vudClassPrefix}btn:not(.#{$vudClassPrefix}btn-primary) &,
    .disabled .#{$vudClassPrefix}btn:not(.#{$vudClassPrefix}btn-primary) &,
    .#{$vudClassPrefix}btn:not(.#{$vudClassPrefix}btn-primary):disabled &,
    .#{$vudClassPrefix}btn:not(.#{$vudClassPrefix}btn-primary).disabled &,
    :disabled .#{$vudClassPrefix}nav-pills &,
    .disabled .#{$vudClassPrefix}nav-pills &,
    .#{$vudClassPrefix}nav-pills:disabled &,
    .#{$vudClassPrefix}nav-pills.disabled &,
    .#{$vudClassPrefix}nav-pills :disabled &,
    .#{$vudClassPrefix}nav-pills .disabled & {
        &:not(.#{$vudClassPrefix}badge-secondary):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger):not(.#{$vudClassPrefix}badge-sm) {
            color: $green-20;
            background-color: $green-40;
        }
    }
}

.#{$vudClassPrefix}label {
    position: relative;
    margin: 0;
    padding: rem(3px) rem(12px) rem(4px);

    color: var(--#{$vudTokenPrefix}text-default);
    font-size: rem(12px);
    font-weight: $normal;
    line-height: rem(16px);

    border-radius: rem(4px);
    border: 0;
    background-color: var(--#{$vudTokenPrefix}button-bg);
    box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}button-border-color);

    &.#{$vudClassPrefix}label-secondary,
    &.#{$vudClassPrefix}label-success {
        color:  var(--#{$vudTokenPrefix}alert-success-text);
        background-color: var(--#{$vudTokenPrefix}alert-success-bg);
        box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}alert-success-border);
    }

    &.#{$vudClassPrefix}label-primary,
    &.#{$vudClassPrefix}label-info {
        color: var(--#{$vudTokenPrefix}alert-info-text);
        background-color: var(--#{$vudTokenPrefix}alert-info-bg);
        box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}alert-info-border);
    }

    &.#{$vudClassPrefix}label-warning {
        color: var(--#{$vudTokenPrefix}alert-warning-text);
        background-color: var(--#{$vudTokenPrefix}alert-warning-bg);
        box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}alert-warning-border);
    }

    &.#{$vudClassPrefix}label-danger {
        color: var(--#{$vudTokenPrefix}alert-danger-text);
        background-color: var(--#{$vudTokenPrefix}alert-danger-bg);
        box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}alert-danger-border);
    }
}
