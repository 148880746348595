
// CSS Custom Properties / CSS Variables
//
// Light Mode Variables
//


/* Setup a few global scope properties */
:root, :host {
  @include light-mode();
}
