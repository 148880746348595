// Stepper compressed - base styling

.#{$vudClassPrefix}stepper.#{$vudClassPrefix}stepper-sm {
    @media (min-width: ($screen-md-min + 1px)) {
        max-width: rem(800px);
        > ul {
            padding-left: 0;

            > li {
                > * {
                    font-size: $font-size-base; 

                    &:after {
                        height: rem(2px);
                        bottom: rem(11px);
                    }

                    &:before {
                        width: rem(16px);
                        height: rem(16px);
                        border-radius: rem(16px);
                    }
                }

                // &:first-child {}

                &.active {
                    > * {
                        font-size: $font-size-base;

                        &:before {
                            width: rem(24px);
                            height: rem(24px);
                            border-radius: rem(24px);
                            border: rem(10px) solid var(--#{$vudTokenPrefix}stepper-active-border-color);
                            bottom: rem(0);
                        }
                    }
                }

                &.passed {
                    > * {
                        &:before { background-image: none; }
                    }
                }
            }
        }
    }
} // END '.stepper'