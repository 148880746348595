
//=== Basic vertical nav style

//*** scss local variable ***
$vertical-nav-width: rem(304px);
$vertical-nav-collapse-width: rem(104px);
$vertical-nav-hidden-width: rem(0px);
$vertical-nav-onelvl-mainnav-top-offset: rem(64px);
$vertical-nav-twolvl-mainnav-top-offset: rem(110px);

$vertical-nav-border-width: rem(4px);
$vertical-nav-font-weight: 400;

$vertical-nav-mainlvl-height: rem(48px);
$vertical-nav-mainlvl-inner-height: 1.5; // rem(24px);  // line-height
$vertical-nav-mainlvl-inner-gap: rem(12px);
$vertical-nav-mainlvl-inner-stretch-space: rem(12px);
$vertical-nav-mainlvl-border-radius: rem(12px);
$vertical-nav-mainlvl-font-family: $font-family-secondary;
$vertical-nav-mainlvl-font-size: rem(16px);

$vertical-nav-sublvl-height: rem(32px);
$vertical-nav-sublvl-inner-height: 1.42857 ; // rem(20px);  // line-height
$vertical-nav-sublvl-inner-stretch-space: rem(6px) rem(16px);
$vertical-nav-sublvl-border-radius: rem(8px);
$vertical-nav-sublvl-font-family: $font-family-base;
$vertical-nav-sublvl-font-size: rem(14px);

$vertical-nav-shownav-mask: url(#{$image-path}/vertical-nav-collapse-btn.svg);
$vertical-nav-shownav-focus-btn-mask: url(#{$image-path}/vertical-nav-collapse-btn-focus.svg);
$vertical-nav-shownav-arrow-mask: url(#{$image-path}/vertical-nav-collapse-arrow.svg);
$vertical-nav-shownav-width: rem(30px);
$vertical-nav-shownav-height: rem(65px);
$vertical-nav-shownav-primary-width: rem(30px);
$vertical-nav-shownav-primary-height: rem(30px);
$vertical-nav-shownav-bottom-offset: rem(16px);
$vertical-nav-shownav-primary-bottom-offset: rem(32px);

$vertical-nav-badge-info-color: '';
$vertical-nav-badge-info-bg: '';
$vertical-nav-badge-success-color: '';
$vertical-nav-badge-success-bg: '';
$vertical-nav-badge-warning-color: '';
$vertical-nav-badge-warning-bg: '';
$vertical-nav-badge-danger-color: '';
$vertical-nav-badge-danger-bg: '';

//*** element/component code ***
//- component wrapper -
.page-holder {
    padding-left: $vertical-nav-width;
    transition: padding-left 0.2s ease-in-out;

    @media (max-width: $screen-md-min) {
        padding-left: $vertical-nav-hidden-width;

        &.page-holder-nav-collapse {
            padding-left: $vertical-nav-collapse-width;

            + footer { padding-left: $vertical-nav-collapse-width; }
        }
    }

    &.hidden-vert-menu {
        padding-left: $vertical-nav-hidden-width;
        transition: padding-left 0.2s ease-in-out;

        &.page-holder-nav-collapse { padding-left: $vertical-nav-collapse-width; }

        + footer { padding-left: $vertical-nav-hidden-width; }
    }

    + footer {
        padding-left: $vertical-nav-width;
        padding-right: rem(30px);
        transition: padding 0.2s ease-in-out;

        @media (max-width: $screen-md-min) {
            padding-left: $vertical-nav-hidden-width;
        }
    }
}

//- component code -
.#{$vudClassPrefix}vertical-nav {
    overflow: visible;
    position: fixed;
    height: calc(#{'100vh - ' $vertical-nav-onelvl-mainnav-top-offset}); // When top navigation has only one level
    width: $vertical-nav-width;
    padding: rem(24px) 0;
    top: $vertical-nav-onelvl-mainnav-top-offset; // When top navigation has only one level
    left: 0;

    box-shadow: 0 rem(2px) rem(4px) rgba(27, 56, 76, 0.12);
    z-index: 1;
    transition: left 0.2s ease-in-out;

    &.#{$vudClassPrefix}vertical-nav-collapse { transition: width 0.3s ease-in-out; }

    &.#{$vudClassPrefix}vertical-nav-icons {
        ul, .nav {
            ul, .nav {
                li, .menu-item { padding-left: rem(32px); }

                a { padding-left: rem(16px); }

                .has-icon {
                    a {
                        padding-left: rem(48px);

                        .vismaicon { top: rem(4px); }
                    }
                }
            }
        }

        a {
            position: relative;
            padding-left: rem(48px);

            .vismaicon {
                position: absolute;
                margin: 0;
                top: rem(12px);
                left: rem(16px);
            }
        }
    }

    &.hidden-menu {
        left: calc( -1 * ($vertical-nav-width - $vertical-nav-border-width) );

        &.#{$vudClassPrefix}vertical-nav-collapse {
            left: 0;
            width: $vertical-nav-collapse-width;

            border-right: 0;

            &.#{$vudClassPrefix}vertical-nav-icons {
                ul, .nav {
                    ul, .nav{
                        li, .menu-item { padding: 0 rem(8px); }
                    }
                }

                a {
                    .#{$vudClassPrefix}badge { right: rem(19px); }
                }

                .has-children {
                    a {
                        &::after { right: rem(18rem); }
                    }

                    li, .menu-item {
                        a {
                            .#{$vudClassPrefix}badge { right: rem(11px); }
                        }
                    }
                }
            }

            ul, .nav {
                ul, .nav {
                    a {
                        padding: rem(6px) rem(4px);

                        .#{$vudClassPrefix}badge {
                            top: rem(4px);
                            right: rem(5px);
                        }
                    }
                }
            }

            li, .menu-item { padding: 0 rem(8px); }

            a {
                padding: rem(12px) rem(16px);

                .#{$vudClassPrefix}badge {
                    display: block;
                    position: absolute;
                    overflow: hidden;
                    min-width: auto;
                    min-width: unset;
                    width: rem(8px);
                    height: rem(8px);
                    margin: 0;
                    padding: 0;
                    top: rem(8px);
                    right: rem(13px);
        
                    color: transparent;
                    text-indent: 100%;
                    border-radius: rem(8px);
                    border: 0;
                    box-shadow: none;
                }
            }

            .has-children {
                a {
                    padding: rem(12px) rem(36px) rem(12px) rem(16px);

                    &::after { right: rem(12rem); }
                }
            }

            .#{$vudClassPrefix}label { padding: 0 rem(24px); }

            .show-nav {
                right: rem(-30px);

                &, a { padding: 0; }
            }
        }

        &.#{$vudClassPrefix}vertical-nav-icons {
            ul, .nav {
                ul, .nav {
                    a {
                        padding: rem(6px) rem(4px);
                        text: {
                            indent: 0;
                            align: center;
                        }
                    }

                    .has-icon {
                        a {
                            padding-right: 0;
                            padding-left: rem(72px);
                        }

                        .vismaicon { left: rem(18px); }
                    }
                }
            }

            a {
                text-indent: rem(90px);
                
                .vismaicon { left: rem(26px); }
            }

            .has-children {
                a {
                    &::after { right: rem(16px); }
                }
            }
        }

        .show-nav {
            padding: 0;
            right: rem(-34px);
            z-index: 1;
            
            a {
                right: rem(0px);
                transform: rotateY(180deg);
            }
        }
    }

    ul, .nav {
        display: block;
        overflow-y: auto;
        height: 100%;
        padding: 0;
        margin: 0;

        list-style: none;

        ul, .nav {
            display: none;
            height: auto;
            padding: rem(8px) 0;

            border-radius: 0 0 $vertical-nav-mainlvl-border-radius $vertical-nav-mainlvl-border-radius;

            li, .menu-item {
                padding: 0 rem(8px);
                margin-bottom: rem(4px);
            }

            a {
                height: $vertical-nav-sublvl-height;
                padding: $vertical-nav-sublvl-inner-stretch-space;

                font: {
                    family: $vertical-nav-sublvl-font-family;
                    size: $vertical-nav-sublvl-font-size;
                    weight: $vertical-nav-font-weight;
                }
                line-height: $vertical-nav-sublvl-inner-height;

                border-radius: $vertical-nav-sublvl-border-radius;
            }
        }
    }

    li, .menu-item {
        display: block;
        margin: 0 0 rem(2px) 0;
        padding: 0 rem(16px);

        &.has-children {
            &.active, &.show, &.open {
                > a {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;

                    font-weight: $normal;

                    &::after { transform: rotate(180deg); }
                }

                ul, .nav {
                    display: block;

                    a {
                        .#{$vudClassPrefix}badge {
                            top: 0;
                        }
                    }
                }
            }

            > a {
                padding-right: rem(40px);

                &::before {
                    display: none;
                    pointer-events: none;
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
    
                    border-radius: $vertical-nav-mainlvl-border-radius;
                    background-color: transparent;
                }
    
                &::after {
                    display: flex;
                    content: '';
                    position: absolute;
                    top: calc(50% - rem(3px));
                    right: rem(24px);

                    @include caret('lg', 'default');
                    transition: transform .25s;
                }
    
                &:focus {
                    outline: 0;
    
                    &::before {
                        display: block;
                        outline-offset: rem(-2px);
                        outline: 0.2rem solid var(--#{$vudTokenPrefix}anchor-focus-outline);
                    }
                }
            }
        }
    
        &.active, &.show, &.open {
            ul, .nav { display: block; }
        }

        &.active,
        &.active-child {
            > a {
                font-weight: $bold;

                &:hover { box-shadow: none; }
            }
        }

        &.disabled {
            a {
                pointer-events: none;

                &:hover { cursor: not-allowed; }
                &:focus { outline: none; }
            }
        }

        a {
            display: block;
            position: relative;
            overflow: hidden;
            align-items: center;
            width: 100%;
            height: $vertical-nav-mainlvl-height;
            padding: $vertical-nav-mainlvl-inner-stretch-space;

            font: {
                family: $vertical-nav-mainlvl-font-family;
                size: $vertical-nav-mainlvl-font-size;
                weight: $vertical-nav-font-weight;
            }
            line-height: $vertical-nav-mainlvl-inner-height;
            white-space: nowrap;
            text: {
                decoration: none;
                overflow: ellipsis;
            }

            border-radius: $vertical-nav-mainlvl-border-radius;

            box-sizing: border-box;

            &:focus { outline-offset: rem(-2px); }

            .#{$vudClassPrefix}badge {
                position: relative;
                margin-left: $vertical-nav-mainlvl-inner-gap;
                top: rem(-2px);

                font: {
                    family: $font-family-base;
                }
                border-radius: $vertical-nav-sublvl-border-radius;
                box-shadow: none;
            }
        }
        
        &.has-icon {
            a {
                text-overflow: inherit; 
            }
        }
    }

    .show-nav {
        position: absolute;
        right: 0;
        bottom: $vertical-nav-shownav-bottom-offset;
        padding: 0;

        a {
            display: block;
            width: $vertical-nav-shownav-width;
            height: $vertical-nav-shownav-height;
            padding: 0;
            text-overflow: inherit;

            text-indent: -10000%;
            border-radius: 0;

            -webkit-mask: $vertical-nav-shownav-mask;
            mask: $vertical-nav-shownav-mask;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: cover;
            mask-size: cover;

            &:after {
                display: flex;
                content: '';
                position: absolute;
                height: rem(14px);
                width: rem(14px);
                top: rem(26px);
                right: rem(8px);

                -webkit-mask: $vertical-nav-shownav-arrow-mask;
                mask: $vertical-nav-shownav-arrow-mask;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: cover;
                mask-size: cover;

                transition: width 0.2s ease-in-out;
            }

            &:focus {
                outline: none;
                -webkit-mask: $vertical-nav-shownav-focus-btn-mask;
                mask: $vertical-nav-shownav-focus-btn-mask;
            }
        }

        &.show-nav-primary {
            a {
                width: $vertical-nav-shownav-primary-width;
                height: $vertical-nav-shownav-primary-height;
                bottom: $vertical-nav-shownav-primary-bottom-offset;

                border-radius: rem(5px) 0 0 rem(5px);
                mask: none;
                -webkit-mask: none;

                &:after { top: rem(9px); }

                &:focus { outline-offset: rem(2px); }
            }
        }
    }

    .divider {
        height: 0;
        margin: rem(24rem) 0;
        border-bottom: rem(1px) solid transparent;
    }

    .label {
        display: block;
        overflow: hidden;
        width: 100%;
        padding-left: rem(28px);
        margin: 0;

        font-size: rem(14px);
        line-height: $vertical-nav-mainlvl-inner-height;
        text-overflow: ellipsis;
        background-color: transparent;
        box-shadow: none;
    }
}


/* When top navigation/main nav has 2 levels */
.nav-space {
    .#{$vudClassPrefix}vertical-nav {
        // When top navigation has two levels
        @media (min-width: $screen-sm-min) {
            height: calc(#{'100vh - ' $vertical-nav-twolvl-mainnav-top-offset});
            top: $vertical-nav-twolvl-mainnav-top-offset;
        }
    }
}
