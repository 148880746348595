//=== Pagination

//*** scss local variable ***
$pagination-icon-img:            url(#{$image-path}/pagination-arrow.svg);

$pagination-icon-bg:             var(--#{$vudTokenPrefix}caret-default-bg);
$pagination-disabled-icon-bg:    var(--#{$vudTokenPrefix}caret-disabled-bg);

$pagination-disabled-text-color: var(--#{$vudTokenPrefix}text-disabled);


// Mixins
@import '../../utilities/carets';

//*** element/component code ***
.#{$vudClassPrefix}pagination {
    padding: 0;
    margin: 0;
    display: flex;

    &.#{$vudClassPrefix}pagination-round {
        > * {
            > a,
            > button,
            > span {
                &, &.#{$vudClassPrefix}btn {
                    border-radius: 50%;
                }
            }
        }
    }

    > * {
        list-style: none;
        text-align: center;
        margin-right: $spacing-8;
        margin-bottom: 0;

        > a,
        > b, em,
        > label,
        > button,
        > span {
            line-height: rem(30px);
            display: inline-flex;
            text-decoration: none;
        }

        > a,
        > button {
            &.#{$vudClassPrefix}btn {
                width: $input-btn-height;
                height: $input-btn-height;
                min-width: 1%;
                text-indent: -9999px;
                overflow: hidden;
                padding: 0;
                display: block;
                position: relative;

                &:before {
                    content:'';
                    display: block;
                    background-color: $pagination-icon-bg;
                    position: absolute;
                }

                > span { display: none; }

                &.go2first,
                &.go2last {
                    &:before {
                        width: $spacing-10;
                        height: $spacing-10;
                        top: calc(#{'50% - ' rem(5px)});
                        left: calc(#{'50% - ' rem(5px)});
                    }
                }

                &.prev,
                &.next {
                    &:before {
                        top: $spacing-12;
                        left: rem(11px);
                    }
                }

                &.prev {
                    &:before {
                        left: $spacing-10;
                    }
                }

                &.go2first:before {
                    mask-image: $pagination-icon-img;
                    -webkit-mask-image: $pagination-icon-img;
                }

                &.prev:before {
                    @include caret('lg', 'default');
                    transform: rotate(90deg);
                }

                &.go2last:before {
                    mask-image: $pagination-icon-img;
                    -webkit-mask-image: $pagination-icon-img;
                    transform: rotate(180deg);
                }

                &.next:before {
                    @include caret('lg', 'default');
                    transform: rotate(-90deg)
                }
            }
        }
    }

    &.disabled > * {
        > span,
        > button,
        > a {
            color: $pagination-disabled-text-color;

            &:before {
                background-color: $pagination-disabled-icon-bg;
            }
        }
    }

    > * > a.#{$vudClassPrefix}btn.disabled,
    > * > button.#{$vudClassPrefix}btn.disabled,
    > * > button.#{$vudClassPrefix}btn[disabled] {
        color: $pagination-disabled-text-color;

        &:before {
            background-color: $pagination-disabled-icon-bg;
        }
    }

    .quantity {
        min-width: rem(84px);
        padding-right: rem(40px);
        background-position: right rem(16px) top rem(13px);
    }
}
