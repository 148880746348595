
// Button toolbars

//*** scss local variable ***
$button-toolbar-border-color:                      var(--#{$vudTokenPrefix}button-border-color);
$button-toolbar-hover-border-color:                var(--#{$vudTokenPrefix}button-hover-border-color);
$button-toolbar-focus-border-color:                var(--#{$vudTokenPrefix}button-focus-border-color);
$button-toolbar-focus-inner-border-color:          var(--button-inner-border-color); // vertical border betwin two buttons
$button-toolbar-selected-border-color:             var(--#{$vudTokenPrefix}button-primary-selected-border-color);
$button-toolbar-selected-inner-border-color:       var(--button-primary-inner-border-color); // vertical border betwin two buttons
$button-toolbar-selected-hover-border-color:       var(--#{$vudTokenPrefix}button-primary-hover-border-color);
$button-toolbar-selected-focus-border-color:       var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$button-toolbar-selected-pressed-border-color:     var(--#{$vudTokenPrefix}button-primary-active-border-color);
$button-toolbar-disabled-border-color:             var(--#{$vudTokenPrefix}button-disabled-border-color);

$button-toolbar-selected-bg:                       var(--#{$vudTokenPrefix}button-primary-bg);
$button-toolbar-selected-hover-bg:                 var(--#{$vudTokenPrefix}button-primary-hover-bg);
$button-toolbar-selected-tint-hover-bg:            linear-gradient(0deg, rgba($black, 0.12), rgba($black, 0.12));
$button-toolbar-selected-pressed-bg:               var(--#{$vudTokenPrefix}button-primary-active-bg);
$button-toolbar-disabled-bg:                       var(--#{$vudTokenPrefix}button-disabled-bg);

$button-toolbar-shadow-color:                      var(--button-shadow-color);
$button-toolbar-hover-shadow-color:                var(--button-hover-shadow-color);
$button-toolbar-focus-shadow-color:                var(--button-focus-shadow-color);
$button-toolbar-selected-focus-inner-shadow-color: var(--button-primary-focus-inner-shadow-color);

$button-toolbar-selected-text-color:               var(--#{$vudTokenPrefix}button-primary-text-color);
$button-toolbar-disabled-text-color:               var(--#{$vudTokenPrefix}button-disabled-text-color);


//*** element/component code ***
// Combine sets of button groups into button toolbars for more complex components
.#{$vudClassPrefix}btn-toolbar {
    //# Alternative styling for group buttons
    &.#{$vudClassPrefix}btn-toolbar-primary {
        .#{$vudClassPrefix}btn-group {

            //## Defind the states for selected or pressed button in the alternative styling
            .#{$vudClassPrefix}btn {

                //### Selected state
                &.active {
                    color: $button-toolbar-selected-text-color;

                    border-color: $button-toolbar-selected-border-color;
                    background-color: $button-toolbar-selected-bg;

                    // selected and hovered state
                    &:hover {
                        border-color: $button-toolbar-selected-hover-border-color;
                        background-color: $button-toolbar-selected-hover-bg;
                        background-image: $button-toolbar-selected-tint-hover-bg;

                        // button style for a button that precede a 'selected and hoverd' button
                        + .#{$vudClassPrefix}btn {
                            box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);

                            &.active {  box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-hover-border-color; }
                        }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active:hover'

                    // selected and focused state
                    &:focus {
                        border-color: $button-toolbar-selected-focus-border-color;
                        box-shadow: inset 0 0 0 rem(1px) $button-toolbar-selected-focus-border-color, inset 0 0 0 rem(2px) $button-toolbar-selected-focus-inner-shadow-color;

                        // button style for a button that precede a 'selected and focused' button
                        + .#{$vudClassPrefix}btn {
                            box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);

                            &.active {
                                box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-focus-border-color;

                                &:hover { box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-focus-border-color; }
                            }

                            &:hover { box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-focus-border-color, var(--#{$vudTokenPrefix}shadow-20); }
                        }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active:focus'

                    // selected and pressed state
                    &:active {
                        border-color: $button-toolbar-selected-pressed-border-color;
                        background-color: $button-toolbar-selected-pressed-bg;
                        background-image: none;
                        box-shadow: none;

                        // button style for a button that precede a 'selected and pressed' button
                        + .#{$vudClassPrefix}btn {
                            box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-inner-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                            &.active {
                                &, &:hover { box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-inner-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }
                            }

                            &:hover { box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-inner-border-color, var(--#{$vudTokenPrefix}shadow-20); }
                        }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active:active'

                    // selected and disabled state
                    &:disabled, &.disabled {
                        color: $button-toolbar-disabled-text-color;

                        border-color: $button-toolbar-disabled-border-color;
                        background-color: $button-toolbar-disabled-bg;

                        // button style for a button that precede a 'selected and disabled' button
                        + .#{$vudClassPrefix}btn { box-shadow: rem(-1px) 0 0 0 $button-toolbar-border-color, rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color, var(--#{$vudTokenPrefix}shadow-20); }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active.disabled'

                    // button style for a button that precede a 'selected' button
                    + .#{$vudClassPrefix}btn {
                        box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                        &:hover { box-shadow: rem(-1px) 0 0 0 $button-toolbar-hover-border-color, var(--#{$vudTokenPrefix}shadow-20); }

                        &:focus { box-shadow: rem(-1px) 0 0 0 $button-toolbar-focus-border-color, inset 0 0 0 rem(1px) $button-toolbar-focus-border-color, var(--#{$vudTokenPrefix}shadow-20); }

                        &:active { box-shadow: rem(-1px) 0 0 0 $button-toolbar-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active + .btn'
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active'

                //### Button hoverd state
                &:hover {
                    // button style for a button that precede a 'hoverd' button
                    + .#{$vudClassPrefix}btn {
                        &.active {
                            &:focus { box-shadow: rem(-1px) 0 0 0 $button-toolbar-focus-border-color, inset 0 0 0 rem(1px) $button-toolbar-focus-border-color, inset 0 0 0 rem(2px) $button-toolbar-selected-focus-inner-shadow-color; }

                            &:active { box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-inner-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }

                            &, &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-toolbar-hover-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }
                        }
                    }
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn:hover'

                //### Button focus state
                &:focus {
                    // button style for a button that precede a 'focused' button
                    + .#{$vudClassPrefix}btn {
                        &.active {
                            &:hover { box-shadow: rem(-1px) 0 0 0 $button-toolbar-focus-border-color; }

                            &, &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-toolbar-focus-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }
                        }
                    }
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn:focus'

                //### Button pressed state
                &:active {
                    // button style for a button that precede a 'pressed' button
                    + .#{$vudClassPrefix}btn {
                        &.active {
                            box-shadow: rem(-1px) 0 0 0 $button-toolbar-border-color, rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color;

                            &:hover { box-shadow: rem(-1px) 0 0 0 $button-toolbar-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }

                            &:disabled, &.disabled { box-shadow: rem(-6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color, rem(-2px) 0 0 rem(-1px) $neutral-40; }
                        }
                    }
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn:active'


                //### Restyling the second and up button in the group
                + .#{$vudClassPrefix}btn {
                    &.active {
                        box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color;

                        &:hover { box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-inner-border-color; }

                        &:focus { box-shadow: rem(-1px) 0 0 0 $button-toolbar-focus-border-color, inset 0 0 0 rem(1px) $button-toolbar-focus-border-color, inset 0 0 0 rem(2px) $button-toolbar-selected-focus-inner-shadow-color; }

                        &:active { box-shadow: rem(-1px) 0 0 0 $button-toolbar-selected-inner-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }

                        &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 $button-toolbar-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn + .btn.active'
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn + .btn'
            } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn'
        } // END '.btn-toolbar.btn-toolbar-primary .btn-group'
    } // END '.btn-toolbar.btn-toolbar-primary'
} // END '.btn-toolbar'


// The default 'Disabled state' for the button in alternative styling group
:disabled .#{$vudClassPrefix}btn-toolbar-primary .#{$vudClassPrefix}btn-group .#{$vudClassPrefix}btn,
.disabled .#{$vudClassPrefix}btn-toolbar-primary .#{$vudClassPrefix}btn-group .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-toolbar-primary:disabled .#{$vudClassPrefix}btn-group .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-toolbar-primary.disabled .#{$vudClassPrefix}btn-group .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-toolbar-primary .#{$vudClassPrefix}btn-group:disabled .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-toolbar-primary .#{$vudClassPrefix}btn-group.disabled .#{$vudClassPrefix}btn {
    //# Selected state for a button in a disabled group
    &.active {
        color: $button-toolbar-disabled-text-color;

        border-color: $button-toolbar-disabled-border-color;
        background-color: $button-toolbar-disabled-bg;

        //### Removing 'focus state' for a 'selected' button in a disabled group
        &:focus {
            border-color: $button-toolbar-disabled-border-color;
            box-shadow: none;

            + .#{$vudClassPrefix}btn {
                box-shadow: rem(-1px) 0 0 0 $button-toolbar-focus-inner-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color;

                &.active { box-shadow: rem(-1px) 0 0 0 $button-toolbar-disabled-border-color; }
            }
        } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn.active:focus'

        + .#{$vudClassPrefix}btn {
            &, &:focus {
                box-shadow: rem(-1px) 0 0 0 $button-toolbar-disabled-border-color, rem(-6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color;

                &.active { box-shadow: rem(-1px) 0 0 0 $button-toolbar-disabled-border-color; }
            }
        } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn.active + .btn'
    } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn.active'

    //# Removing 'focus state' for a button in a disabled group
    &:focus {
        + .#{$vudClassPrefix}btn {
            &.active { box-shadow: $button-toolbar-focus-inner-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }
        }
    } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn:focus'

    //## Second and up button for a button in 'disabled group'
    + .#{$vudClassPrefix}btn {
        &.active {
            &, &:focus { box-shadow: rem(-1px) 0 0 0 $button-toolbar-disabled-border-color, inset rem(6px) 0 rem(4px) rem(-4px) $button-toolbar-shadow-color; }
        }
    } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn + .btn'
} // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn' or '.btn-toolbar.btn-toolbar-primary .btn-group.disabled .btn'
