// Stepper - horizontal (default)
body.nav-space {
    @media (max-width: $screen-md-min) {
        .#{$vudClassPrefix}stepper:not(.#{$vudClassPrefix}stepper-vertical) {
            top: rem(115px);

            + .container { margin-top: rem(115px); }
        }
    }
} // this applies for the case where the top navigation has 2 levels


body {
    @media (max-width: $screen-sm-min) {
        .#{$vudClassPrefix}stepper:not(.#{$vudClassPrefix}stepper-vertical) {
            top: rem(67px);

            + .container { margin-top: rem(70px); }
        }
    }
}

.#{$vudClassPrefix}stepper:not(.#{$vudClassPrefix}stepper-vertical) {
    @include make-container();
    @include make-container-max-widths();
    margin: 0 auto rem(50px);

    @media (max-width: $screen-md-min) {
        max-width: 100%;
    }

    > ul {
        flex-direction: row;

        > li {
            > * {
                text-align: center;
                min-height: rem(75px);

                &:before {
                    right: 0;
                    left: auto;
                    bottom: rem(4px);
                }

                > span {
                    position: absolute;
                    text-align: center;
                    left: auto;
                    right: calc(#{'-50% + ' rem(20px)});
                    padding: 0 rem(5px);
                }
            }

            /* 2 items in the stepper */
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ li {
                &:first-child,
                &.passed:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -95.6%; }
                }

                &.active:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -94.2%; }
                }
            }

            /* 3 items in the stepper */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ li {
                &:first-child,
                &.passed:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -46.7%; }
                }

                &.active:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -45.7%; }
                }
            }

            /* 4 items in the stepper */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ li {
                &:first-child,
                &.passed:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -30.4%; }
                }

                &.active:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -29.4%; }
                }
            }

            /* 5 items in the stepper */
            &:first-child:nth-last-child(5),
            &:first-child:nth-last-child(5) ~ li {
                &:first-child,
                &.passed:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -22.3%; }
                }

                &.active:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -21.3%; }
                }
            }

            /* 6 items in the stepper */
            &:first-child:nth-last-child(6),
            &:first-child:nth-last-child(6) ~ li {
                &:first-child,
                &.passed:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -17.3%; }
                }

                &.active:first-child { 
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -16.5%; }
                }
            }

            /* 7 items in the stepper */
            &:first-child:nth-last-child(7),
            &:first-child:nth-last-child(7) ~ li {
                &:first-child,
                &.passed:first-child { 
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -14.1%; }
                }

                &.active:first-child {
                    @media (min-width: ($screen-md-min + 1px)) { margin-left: -13.3%; }
                }
            }
        }
    }

    &.no-ellipsis {
        > ul > li > * > span.step {
            //bottom: rem(50px);
            //top: auto;
        }
    } // END .no-ellipsis
} // END '.stepper'