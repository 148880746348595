
// Spinners
.#{$vudClassPrefix}spinner {
    width: rem(48px);
    height: rem(48px);
    display: inline-block;
    text-indent: -9999px;
    background-size: 100%;

    &-sm {
        width: rem(24px);
        height: rem(24px);
    }

    &-xs {
        width: rem(16px);
        height: rem(16px);
    }

    &.#{$vudClassPrefix}spinner-default-blue { background-image: var(--spinner-default-blue); }
    &.#{$vudClassPrefix}spinner-default-green { background-image: var(--spinner-default-green); } 
    &.#{$vudClassPrefix}spinner-default-grey { background-image: var(--spinner-default-grey); }

    &.#{$vudClassPrefix}spinner-primary-blue { background-image: var(--spinner-primary-blue); }
    &.#{$vudClassPrefix}spinner-primary-green { background-image: var(--spinner-primary-green); }
    &.#{$vudClassPrefix}spinner-primary-grey { background-image: var(--spinner-primary-grey); }

    &.#{$vudClassPrefix}spinner-secondary-blue { background-image: var(--spinner-secondary-blue); }
    &.#{$vudClassPrefix}spinner-secondary-green { background-image: var(--spinner-secondary-green);  }
    &.#{$vudClassPrefix}spinner-secondary-grey { background-image: var(--spinner-secondary-grey);  }
}
