
// Panel wrapper
.#{$vudClassPrefix}panel, .#{$vudClassPrefix}panel-group {
    margin-bottom: rem(12px);
    padding: 0;

    background-color: var(--#{$vudTokenPrefix}module-container-bg-color);
    box-shadow: var(--module-container-shadow);

    // panel default inner spaceing
    .#{$vudClassPrefix}panel-heading {
        padding: $spacing-32 $spacing-32 $spacing-12;

        &:only-child { padding-bottom: $spacing-32; }
    }

    .#{$vudClassPrefix}panel-body {
        padding: $spacing-12 $spacing-32 $spacing-32;

        &:first-child { padding-top: $spacing-32; }

        > p:only-child { margin-bottom: 0; }
    }

    // Panel heading and title styleing
    .#{$vudClassPrefix}panel-heading {
        &, .#{$vudClassPrefix}panel-title {
            margin: 0;

            color: var(--#{$vudTokenPrefix}titles-font-color);
            line-height: rem(24px);
        }

        .#{$vudClassPrefix}panel-title {
            font: {
                size: rem(16px);
                weight: $bold;
            }
        }
    }

    // reset spacing for the depper inner pannel
    .#{$vudClassPrefix}panel-body {
        .#{$vudClassPrefix}panel {
            .#{$vudClassPrefix}panel-heading,
            .#{$vudClassPrefix}panel-body,
            .#{$vudClassPrefix}panel-footer {
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;
            }
        }
    }

    // pannel footer styling
    .#{$vudClassPrefix}panel-footer {
        padding: rem(24px) rem(30px);

        border-top: rem(1px) solid var(--module-container-divider);

        @include clearfix();

        > .#{$vudClassPrefix}btn, > .#{$vudClassPrefix}btn-group {
            margin-bottom: 0;
        }
    }

    // remove margin bottom for the last paragraph in panel body
    p:last-child {
        margin-bottom: 0;
    }

    // reset styling for list inside panels
    .#{$vudClassPrefix}list-group {
        border-radius: 0;
        box-shadow: none;

        > .#{$vudClassPrefix}list-group-item:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    // reset inner spacing for table in panels
    .#{$vudClassPrefix}table {
        box-shadow: 0 rem(1px) rem(1px) 0 rgba($blue-90, 0.1), 0 rem(-1px) 0 0 var(--module-container-divider);

        > thead > tr > th,
        > tbody > tr > td,
        > tbody > tr > th {
            &:first-child {
                padding-left: rem(30px);
            }
            &:last-child {
                padding-right: rem(30px);
            }
        }
    }
}

// Panel groups style
.#{$vudClassPrefix}panel-group {

    // Anchors in panel title
    .#{$vudClassPrefix}panel-title {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .#{$vudClassPrefix}panel-heading {
        padding-bottom: 0;
    }

    .#{$vudClassPrefix}panel {
        margin-bottom: 0;
        box-shadow: none;

        .#{$vudClassPrefix}panel {
            .#{$vudClassPrefix}panel-title {
                a {
                    color: var(--#{$vudTokenPrefix}titles-font-color);

                    &:hover {
                        color: var(--#{$vudTokenPrefix}anchor-hover-color);
                    }
                }
            }
        }
    }
}

