// Month and year picker

//*** scss local variable ***
$datepicker-months-cell-hover-border-color:          var(--#{$vudTokenPrefix}button-hover-border-color);
$datepicker-months-cell-focus-border-color:          var(--#{$vudTokenPrefix}anchor-focus-outline);
$datepicker-months-cell-focus-selected-border-color: var(--button-primary-focus-inner-shadow-color);

$datepicker-months-cell-bg:                          transparent;
$datepicker-months-cell-hover-bg:                    var(--datepicker-item-hover-bg);
$datepicker-months-cell-pressed-bg:                  transparent;
$datepicker-months-cell-selected-bg:                 var(--datepicker-item-selected-bg);

$datepicker-months-cell-text-color:                  var(--datepicker-item-text);
$datepicker-months-cell-selected-text-color:         var(--datepicker-item-selected-text);

//*** element/component code ***
.#{$vudClassPrefix}month-picker {
    .#{$vudClassPrefix}datepicker {
        .#{$vudClassPrefix}dropdown-menu {
            max-width: rem(320px);
        }

        .#{$vudClassPrefix}dropdown-item {
            padding: 0;
        }

        .#{$vudClassPrefix}datepicker-table {
            min-width: auto;
            min-width: unset;
        }
    }
}

.#{$vudClassPrefix}datepicker-months {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    gap: $spacing-32;

    .#{$vudClassPrefix}datepicker-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(64px);
        height: rem(32px);

        color: $datepicker-months-cell-text-color;
        background-color: $datepicker-months-cell-bg;
        box-shadow: none;

        &:hover {
            background-color: $datepicker-months-cell-hover-bg;
            box-shadow: inset 0 0 0 rem(1px) $datepicker-months-cell-hover-border-color;
        }

        &:active {
            background-color: $datepicker-months-cell-bg;
            box-shadow: none;
        }

        &:focus {
            box-shadow: inset 0 0 0 rem(2px) $datepicker-months-cell-focus-border-color;
        }

        &.is-selected {
            color: $datepicker-months-cell-selected-text-color;
            background-color: $datepicker-months-cell-selected-bg;

            &:focus:not(:active) {
                box-shadow: inset 0 0 0 rem(1px) $datepicker-months-cell-focus-border-color, inset 0 0 0 rem(3px) $datepicker-months-cell-focus-selected-border-color;
            }

            &:active {
                color: $datepicker-months-cell-text-color;
            }
        }
    }
}
