
// Dropdown buttons style declaration

//*** scss local variable ***
$dropdown-icon-color:                          var(--#{$vudTokenPrefix}dropdown-arrow-bg);
$dropdown-primary-icon-color:                  var(--#{$vudTokenPrefix}dropdown-primary-arrow-bg);
$dropdown-icon-disabled-color:                 var(--#{$vudTokenPrefix}dropdown-arrow-disabled-bg);

$dropdown-selected-border-color:               var(--#{$vudTokenPrefix}button-active-border-color);
$dropdown-primary-selected-border-color:       var(--#{$vudTokenPrefix}button-primary-active-border-color);
$dropdown-selected-focus-border-color:         var(--#{$vudTokenPrefix}button-active-border-color);
$dropdown-primary-focus-border-color:          var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$dropdown-primary-focus-inner-border-color:    var(--button-primary-focus-inner-shadow-color);

$dropdown-selected-bg:                         var(--#{$vudTokenPrefix}button-active-bg);
$dropdown-selected-hover-bg:                   var(--#{$vudTokenPrefix}button-hover-bg);
$dropdown-primary-selected-bg:                 var(--#{$vudTokenPrefix}button-primary-active-bg);
$dropdown-primary-selected-hover-bg:           var(--#{$vudTokenPrefix}button-primary-hover-bg);
$dropdown-primary-selected-tint-hover-bg:      linear-gradient(0deg, rgba($black, 0.12), rgba($black, 0.12));

$dropdown-selected-focus-shadow-color:         var(--button-focus-shadow-color);

$dropdown-menu-shadow-color:                   var(--#{$vudTokenPrefix}dropdown-menu-shadow-color);

$dropdown-item-text-color:                     var(--#{$vudTokenPrefix}dropdown-menu-text-color);
$dropdown-item-disabled-text-color:            var(--#{$vudTokenPrefix}button-disabled-text-color);

$dropdown-item-hover-border-color:             var(--#{$vudTokenPrefix}button-hover-border-color);
$dropdown-item-focus-border-color:             var(--#{$vudTokenPrefix}button-focus-border-color);
$dropdown-item-pressed-border-color:           var(--#{$vudTokenPrefix}button-pressed-border-color);
$dropdown-item-selected-border-color:          var(--#{$vudTokenPrefix}button-active-border-color);

$dropdown-item-bg:                             var(--#{$vudTokenPrefix}dropdown-menu-bg);
$dropdown-item-hover-bg:                       var(--#{$vudTokenPrefix}dropdown-menu-hover-bg);
$dropdown-item-pressed-bg:                     var(--#{$vudTokenPrefix}button-pressed-bg);
$dropdown-item-selected-bg:                    var(--#{$vudTokenPrefix}button-active-bg);

$dropdown-divider-bg:                          var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong);


//*** element/component code ***
//# Dropdown/dropup wrapper
.#{$vudClassPrefix}dropdown,
.#{$vudClassPrefix}dropup {
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    margin: 0 $spacing-8 $spacing-8 0;

    &.open {
        z-index: 2;
    }
}


//# Dropdown/dropup the trigger/button style.
.#{$vudClassPrefix}dropdown-toggle {
    position: relative;
    justify-content: flex-start;
    margin: 0;
    padding-right: $input-btn-dropdown-padding-x;
    // padding: $input-btn-padding-y-t $input-btn-dropdown-padding-x $input-btn-padding-y-b $input-btn-padding-x;

    text-align: left;
    z-index: 1;

    //## drodown arrow style
    &::before {
        display: block;
        content: '';
        position: absolute;
        top: rem(13px);
        right: rem(20px);

        @include caret('sm', 'default');
        transition: transform .25s;

        .#{$vudClassPrefix}dropup & {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            top: rem(13px);
        }
    }

    // removing shadow for open dropdown/dropup
    .open & {
        background-color: $dropdown-selected-bg;
        box-shadow: none;

        &::before {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        &:not(.#{$vudClassPrefix}btn-primary) {
            &:not(:active) {
                &:hover {
                    background-color: $dropdown-selected-hover-bg;
                }

                &:focus {
                    box-shadow: inset 0 0 0 0.1rem var(--#{$vudTokenPrefix}button-focus-border-color);
                }
            }
        }
    }

    .#{$vudClassPrefix}dropup.open & {
        &::before {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    // alternative style for the dropdown trigger/button
    &.#{$vudClassPrefix}btn-primary {
        &::before {
            background-color: $dropdown-primary-icon-color;
        }

        // removing shadow for open dropdown/dropup
        .open & {
            background-color: $dropdown-primary-selected-bg;

            &:hover {
                background-color: $dropdown-primary-selected-hover-bg;
                background-image: $dropdown-primary-selected-tint-hover-bg;
            }

            &:active { background-image: none; }
        }
    }

    // drodown arrow style for large buttons
    &.#{$vudClassPrefix}btn-lg {
        padding-right: $input-btn-dropdown-padding-x;

        &::before {
            top: rem(21px);
        }
    }

    // disable state for drodown arrow
    .disabled &, :disabled &,
    &.disabled, &:disabled {
        &::before {
            background-color: $dropdown-icon-disabled-color;
        }
    }
}


//#  Dropdown/Dropup content list
.#{$vudClassPrefix}dropdown-menu {
    display: none;
    position: absolute;
    width: 100%;
    margin: 0;
    padding: rem(22px) $spacing-12 $spacing-16;
    top: calc(#{'100% - ' $spacing-6});

    list-style: none;

    background-color: $dropdown-item-bg;
    // box-shadow: $shadow-20 $dropdown-menu-shadow-color;
    box-shadow: var(--#{$vudTokenPrefix}shadow-20);

    // dropup menu list position
    .#{$vudClassPrefix}dropup & {
        top: unset;
        bottom: calc(#{'100% - ' $spacing-6});

        // box-shadow: $shadow-20 $dropdown-menu-shadow-color;
        box-shadow: var(--#{$vudTokenPrefix}shadow-20);
    }

    .open > & {
        display: block;
    }

    // dropdown disable state
    .disabled &, :disabled & {
        display: none;
    }

    // cta elements
    a, button {
        display: block;
        overflow: hidden;
        box-sizing: border-box;
        align-items: center;

        padding: $spacing-8 $spacing-16;

        color: inherit;
        line-height: 1.14286;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;

        border-radius: $spacing-8;

        // hover state for content items
        &:hover {
            background-color: $dropdown-item-hover-bg;
            box-shadow: inset 0 0 0 0.1rem $dropdown-item-hover-border-color;
        }

        // focus state for content items
        &:focus {
            outline: 0;
            box-shadow: inset 0 0 0 rem(2px) $dropdown-item-focus-border-color;
        }

        // pressed state for content items
        &:active {
            background-color: $dropdown-item-pressed-bg;
            box-shadow: inset 0 0 0 0.1rem $dropdown-item-pressed-border-color;
        }
    }

    // active/selected state for content items
    .active {
        > a, > button {
            background-color: $dropdown-item-selected-bg;
            box-shadow: inset 0 0 0 0.1rem $dropdown-item-selected-border-color;

            // hover state for active/selected items
            &:hover:not(:active) {
                background-color: $dropdown-item-hover-bg;
                box-shadow: inset 0 0 0 0.1rem $dropdown-item-hover-border-color;
            }

            // focus state for active/selected items
            &:focus {
                box-shadow: inset 0 0 0 rem(2px) $dropdown-item-focus-border-color;
            }

            // pressed state for active/selected items
            &:active {
                background-color: $dropdown-item-pressed-bg;
                box-shadow: inset 0 0 0 0.1rem $dropdown-item-pressed-border-color;
            }
        }
    }
}


//# Dropdown/dropup content items styling
.#{$vudClassPrefix}dropdown-item,
.#{$vudClassPrefix}dropdown-menu > li {
    display: block;
    overflow: hidden;
    box-sizing: border-box;

    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: $spacing-2 0;

    color: $dropdown-item-text-color;

    text-align: left;
    font-size: $font-size-sm;
    line-height: 1.7;
    text-overflow: ellipsis;
    white-space: nowrap;

    border: 0;
    background-color: $dropdown-item-bg;
    box-shadow: none;

    // divider style
    &.divider {
        &:after {
            background: $dropdown-divider-bg;
            content: "";
            display: block;
            height: rem(1px);
            width: 100%;
            margin: 0;
        }
    }

    // disabled state for content items
    &.disabled, &:disabled {
        &, a, button {
            pointer-events: none;
    
            color: $dropdown-item-disabled-text-color;
            box-shadow: none;
        }
    }
}


//# Dropdown/dropup styling in button groups
.#{$vudClassPrefix}btn-group {
    &.open {
        position: relative;
        z-index: 2;
    }

    &.#{$vudClassPrefix}btn-group-lg {
        > .#{$vudClassPrefix}dropdown, > .#{$vudClassPrefix}dropup {
            .#{$vudClassPrefix}dropdown-toggle:before {
                top: rem(21px);
            }
        }
    }
}
