
// Tabs graphics variables
$tabs-corner-bg:                    $tabs-graph-path + '/tab-corner.svg';
$tabs-corner-bg-flip:               $tabs-graph-path + '/tab-corner-flip.svg';
$tabs-middle-bg:                    $tabs-graph-path + '/tab-middle.svg';
$tabs-dropdown-trigger-bg:          $tabs-graph-path + '/more-tabs-btn.svg';

$tabs-height:                       rem(41px);
$tabs-background-size:              rem(25px) rem(692px); 
$tabs-background-size-corner:       rem(26px) rem(692px);
$tabs-overflow-icon-background-size: rem(490px) rem(24px);

$tabs-normal-Ypoition: var(--tabs-normal-Ypoition); // #{rem(0px)};
$tabs-disabled-Ypoition: var(--tabs-disabled-Ypoition); // #{rem(-50px)};
$tabs-hover-Ypoition: var(--tabs-hover-Ypoition); // #{rem(-100px)};
$tabs-pressed-Ypoition: var(--tabs-pressed-Ypoition); // #{rem(-150px)};
$tabs-focus-Ypoition: var(--tabs-focus-Ypoition); // #{rem(-200px)};
$tabs-focus-hover-Ypoition: var(--tabs-focus-hover-Ypoition); // #{rem(-250px)};
$tabs-selected-Ypoition: var(--tabs-selected-Ypoition); // #{rem(-250px)};
$tabs-selected-focused-Ypoition:  var(--tabs-selected-focused-Ypoition); // #{rem(-350px)};

$tabs-dropdown-Ypoition: var(--tabs-dropdown-Ypoition); // #{rem(4px)};
$tabs-dropdown-normal-Xpoition: var(--tabs-dropdown-normal-Xpoition); // #{rem(4px)};
$tabs-dropdown-hover-Xpoition: var(--tabs-dropdown-hover-Xpoition); // #{rem(-45px)};
$tabs-dropdown-focus-Xpoition: var(--tabs-dropdown-focus-Xpoition); // #{rem(-94px)};
$tabs-dropdown-pressed-Xpoition: var(--tabs-dropdown-pressed-Xpoition); // #{rem(-143px)};
$tabs-dropdown-disabled-Xpoition: var(--tabs-dropdown-disabled-Xpoition); // #{rem(-192px)};


.#{$vudClassPrefix}tab-content {
    border-top: rem(2px) solid var(--#{$vudTokenPrefix}tabs-content-border-color);
}


// Tabs general style
.#{$vudClassPrefix}nav-tabs:not(.#{$vudClassPrefix}navbar-nav) {
    height: $tabs-height;

    .#{$vudClassPrefix}nav-item {
        margin-right: rem(-13px);
       
        a {
            display: inline-flex;
            align-items: center;
            height: $tabs-height;
            padding: rem(4) rem(10px) rem(4px);
            border-bottom: 2px solid transparent;
           
            background: {
                color: transparent;
                image: url(#{$tabs-middle-bg});
                position: 0 $tabs-normal-Ypoition;
                repeat: repeat-x;
                size: $tabs-background-size;
            };

            // tabs items corners graphics
            &::before, &::after {
                display: block;
                content: '';
                position: absolute;
                width: rem(22px);
                height: $tabs-height;
                top: 0;

                background: {
                    color: transparent;
                    image: url(#{$tabs-corner-bg});
                    position: 0 $tabs-normal-Ypoition;
                    repeat: no-repeat;
                    size: $tabs-background-size-corner;
                };
            }

            &::before {
                left: rem(-22px);
            }

            &::after {
                right: rem(-22px);
                background: {
                    image: url(#{$tabs-corner-bg-flip});
                    //size: $tabs-background-size-corner;
                };
            }

            // tabs items on hover
            &:hover, &.hover {
                &, &::before, &::after { 
                    background-position-y: $tabs-hover-Ypoition; 
                }
            }

            // tabs items on focus
            &:focus:not(:active), &.focus:not(:active) {
                outline: rem(2px) solid var(--#{$vudTokenPrefix}anchor-focus-outline);
                outline-offset: rem(-9px);

                // &, &::before, &::after { background-position-y: $tabs-focus-Ypoition; }
                &, &::before, &::after { background-position-y: $tabs-normal-Ypoition; }

                // tabs items on focus and hover
                &:hover, &.hover {
                    // &, &::before, &::after { background-position-y: $tabs-focus-hover-Ypoition; }
                    &, &::before, &::after { background-position-y: $tabs-hover-Ypoition; }
                }
            }

            // tabs items on pressed state
            &.pressed, &:active {
                &, &::before, &::after { background-position-y: $tabs-pressed-Ypoition; }
            }
        }

        // tabs items selected
        &.active {
            a {
                &, &::before, &::after { background-position-y: $tabs-selected-Ypoition; }
            }

            // tabs items selected and focused
            &.focus, a:focus, a.focus {
                //&, &::before, &::after { background-position-y: $tabs-selected-focused-Ypoition; }
                &, &::before, &::after { background-position-y: var(--tabs-selected-Ypoition); }
            }
        }

        &.pressed, &:active:not(.disabled) {
            a { 
                &, &::after, &::before {
                    border-bottom: rem(2px) solid var(--#{$vudTokenPrefix}tabs-content-border-color);
                }
            }
            
        }

        // tabs items on diusabled state
        &.disabled a, &:disabled a,
        a.disabled, a:disabled {
            &, &:focus {
                &, &::before, &::after { background-position-y: $tabs-disabled-Ypoition; }
            }
        }
    }


    // tabs overflow dropdown
    .tabdrop {
        // tabs overflow dropdown trigger
        .#{$vudClassPrefix}dropdown-toggle {
            background: {
                color: transparent;
                image: url(#{$tabs-dropdown-trigger-bg});
                position: $tabs-dropdown-normal-Xpoition $tabs-dropdown-Ypoition;
                repeat: no-repeat;
                size: $tabs-overflow-icon-background-size;
            };

            // tabs overflow dropdown trigger on hover
            &:hover { background-position-x: $tabs-dropdown-hover-Xpoition; }

            // tabs overflow dropdown trigger on focus
            &:focus {
                outline: 0;

                &:not(:active) {
                    background-position-x: $tabs-dropdown-focus-Xpoition;
                    background-position-y: $tabs-dropdown-Ypoition;
                    box-shadow: none;
                }
            }

            // tabs overflow dropdown trigger on pressed
            &:active { background-position-x: $tabs-dropdown-pressed-Xpoition; }
        }

        &.disabled .#{$vudClassPrefix}dropdown-toggle,
        &:disabled .#{$vudClassPrefix}dropdown-toggle,
        .#{$vudClassPrefix}dropdown-toggle.disabled,
        .#{$vudClassPrefix}dropdown-toggle:disabled {
            background-position-x: $tabs-dropdown-disabled-Xpoition;
        }
    }
}
