
// Top navigation base styles

//*** scss local variable ***
// $dropdown-icon-color:                          var(--#{$vudTokenPrefix}dropdown-arrow-bg);
// $dropdown-primary-icon-color:                  var(--#{$vudTokenPrefix}dropdown-primary-arrow-bg);
// $dropdown-icon-disabled-color:                 var(--#{$vudTokenPrefix}dropdown-arrow-disabled-bg);

// $dropdown-selected-border-color:               var(--#{$vudTokenPrefix}button-active-border-color);
// $dropdown-primary-selected-border-color:       var(--#{$vudTokenPrefix}button-primary-active-border-color);
// $dropdown-selected-focus-border-color:         var(--#{$vudTokenPrefix}button-active-border-color);
// $dropdown-primary-focus-border-color:          var(--#{$vudTokenPrefix}button-primary-focus-border-color);
// $dropdown-primary-focus-inner-border-color:    var(--button-primary-focus-inner-shadow-color);

// $dropdown-selected-bg:                         var(--#{$vudTokenPrefix}button-active-bg);
// $dropdown-selected-hover-bg:                   var(--#{$vudTokenPrefix}button-hover-bg);
// $dropdown-primary-selected-bg:                 var(--#{$vudTokenPrefix}button-primary-active-bg);

// $dropdown-selected-focus-shadow-color:         var(--button-focus-shadow-color);

$navigation-dropdown-menu-shadow:                   var(--#{$vudTokenPrefix}navigation-default-dropdown-shadow);

// $dropdown-item-text-color:                     var(--#{$vudTokenPrefix}dropdown-menu-text-color);
// $dropdown-item-disabled-text-color:            var(--#{$vudTokenPrefix}button-disabled-text-color);

// $dropdown-item-hover-border-color:             var(--#{$vudTokenPrefix}button-hover-border-color);
// $dropdown-item-focus-border-color:             var(--#{$vudTokenPrefix}button-focus-border-color);
// $dropdown-item-pressed-border-color:           var(--#{$vudTokenPrefix}button-pressed-border-color);
// $dropdown-item-selected-border-color:          var(--#{$vudTokenPrefix}button-active-border-color);

// $dropdown-item-bg:                             var(--#{$vudTokenPrefix}dropdown-menu-bg);
// $dropdown-item-hover-bg:                       var(--#{$vudTokenPrefix}dropdown-menu-hover-bg);
// $dropdown-item-pressed-bg:                     var(--#{$vudTokenPrefix}button-pressed-bg);
// $dropdown-item-selected-bg:                    var(--#{$vudTokenPrefix}button-active-bg);

// $dropdown-divider-bg:                          var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong);


//*** element/component code ***
body {
    padding-top: rem(105px); /* When top navigation/main nav has only one level */

    /* When top navigation/main nav has 2 levels */
    &.nav-space {
      padding-top: rem(165px);

      @media (max-width: $screen-sm-min) { padding-top: rem(105px); }
    }
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.active-on-mobile { display: none; }

.#{$vudClassPrefix}navbar {
    height: auto;
    margin-bottom: rem(15px);
    min-height: rem(64px);
    min-width: rem(320px);
    padding: 0;
    position: relative;
    z-index: $zindex-navbar;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    .#{$vudClassPrefix}dropdown {
        > .#{$vudClassPrefix}dropdown-toggle {
            .#{$vudClassPrefix}caret {
                position: absolute;
                @include caret('lg', 'default');
            }

            &::before { display: none; }
        }

        &.open {
            > .#{$vudClassPrefix}dropdown-toggle {
                .#{$vudClassPrefix}caret {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .#{$vudClassPrefix}dropdown-menu {
        box-shadow: $navigation-dropdown-menu-shadow;
        top: 100%;
        z-index: 1;
    }

    .has-children { position: relative; }

    a:focus{
        outline: none;
        box-shadow: none;
    }
}


/* Product dropdown area */
.#{$vudClassPrefix}navbar-default,
.#{$vudClassPrefix}navbar-primary,
.#{$vudClassPrefix}navbar-secondary {
    .#{$vudClassPrefix}navbar-header {
        float: left;
        position: relative;

        .#{$vudClassPrefix}navbar-brand {
            font-family: $font-family-secondary;
            font-size: $font-size-md;
            line-height: 3;
            margin: 0;
            padding: 0;
            display: block;
            border-right: 0;

            > a {
                display: block;
                text-decoration: none;
                padding: rem(10px) $spacing-40 rem(10px) $spacing-16;
                min-width: rem(300px);
                @include text-overflow;

                &.#{$vudClassPrefix}dropdown-toggle {
                    .#{$vudClassPrefix}caret {
                        top: rem(32px);
                        margin-left: rem(26px);
                    }

                    &::before { display: none; }
                }
            }

            > a:not(.#{$vudClassPrefix}dropdown-toggle) {
                > .#{$vudClassPrefix}caret { display: none; }
            }

            &.open {
                .#{$vudClassPrefix}dropdown-toggle { 
                    border-color: transparent; 
                }
            }
        }
    }
}



/* navbar */
.#{$vudClassPrefix}navbar-default,
.#{$vudClassPrefix}navbar-primary,
.#{$vudClassPrefix}navbar-secondary {
    .#{$vudClassPrefix}navbar-collapse {
        position: relative;
        width: 100%;

        .nav {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                margin: 0;
                padding: 0;
                position: static;

                &:hover {
                    background-color: transparent;
                }

                > a {
                    display: block;
                    font: $font-md;
                    line-height: 1.5;
                    text-decoration: none;
                    padding: rem(20px) $spacing-16;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }

            .#{$vudClassPrefix}dropdown-menu {
                > li {
                    white-space: initial;

                    > a {
                        color: var(--#{$vudTokenPrefix}navigation-default-dropdown-text-color);
                        border-radius: 0;

                        &:hover{
                            background: var(--#{$vudTokenPrefix}vertical-nav-default-item-hover-bg);
                            box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}anchor-focus-outline);
                        }

                        &:focus {
                            box-shadow: none;
                            outline: rem(2px) solid var(--navigation-secondary-item-focus-border-color);
                            outline-offset: rem(-2px);
                        }
                    }
                }

                > .disabled {
                    > a {
                        color: var(--#{$vudTokenPrefix}button-disabled-text-color);
                    }
                }
            }

            & > li { float: left; }
        }

        .first-level.#{$vudClassPrefix}navbar-right {
            float: right;
            position: relative;

            .#{$vudClassPrefix}navbar-form {
                &.#{$vudClassPrefix}navbar-form-shortened {
                    input.#{$vudClassPrefix}form-control {
                        max-width: rem(150px);
                    }
                }

                input.#{$vudClassPrefix}form-control {
                    padding-right: rem(40px);
                }
            }

            .#{$vudClassPrefix}dropdown {
                margin: 0;

                &.open {
                    position: relative;

                    .#{$vudClassPrefix}dropdown-toggle {
                        color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-text-color); 
                        background-color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg);

                        &::after, &::before { display: none; }

                        &:hover {
                            background-color: var(--module-container-hover-bg-color);
                            box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}button-hover-border-color);
                        }

                        .vismaicon {
                            &::before { background-color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-small-text-color); }
                        }

                        .#{$vudClassPrefix}badge {
                            color: var(--#{$vudTokenPrefix}badge-success-text);
                            background: var(--#{$vudTokenPrefix}badge-success-bg);
                        }
                    }
                }

                &.user-dropdown {
                    &.open, &.show {
                        .#{$vudClassPrefix}dropdown-toggle {
                            small { color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-small-text-color); }
                        }
                    }

                    &.icon {
                        .#{$vudClassPrefix}dropdown-toggle {
                            width: rem(48px);
                            min-width: unset;
                            padding: rem(12px);

                            .vismaicon { display: inline-block; }
                        }

                        &.open, &.show {
                            .#{$vudClassPrefix}dropdown-toggle {
                                margin: 0;
                                padding: rem(20px) rem(40px) rem(20px) rem(16px);
                            }
                        }
                    }

                    .#{$vudClassPrefix}dropdown-toggle {
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        min-width: rem(300px);
                        padding: rem(5px) rem(50px) rem(7px) rem(30px);

                        line-height: 2.2;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        > small {
                            display: block;
                            overflow: hidden;
                            max-width: rem(220px);
                            height: rem(16px);
                            margin-top: rem(-7px);
                            margin-bottom: rem(8px);

                            font: $font-sm;
                            line-height: 1;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .vismaicon { display: none; }
                    }

                    .#{$vudClassPrefix}dropdown-menu {
                        top: 100%;
                        margin-top: rem(1px);
                        padding: $spacing-16 0;
                        z-index: 1;

                        > li {
                            padding: 0 $spacing-16;
                            margin-bottom: $spacing-2;
    
                            > * {
                                padding: 0 $spacing-16;
                            }
    
                            > p {
                                > a {
                                    color: var(--#{$vudTokenPrefix}anchor-color);
                                    padding: 0;
                                    display: inline;
                                    white-space: normal;
    
                                    &:hover {
                                        background-color:  transparent;
                                        text-decoration: underline;
                                    }
                                }
                            }
    
                            > a {
                                padding: $spacing-8 $spacing-16;
                                font: $font-sm;
                                line-height: 1.4285714;
                                border-radius: rem(8px);
    
                                &.log-out-link {
                                    padding-left: rem(58px);
                                    position: relative;
                                    top: 0;
    
                                    &::before {
                                        content: "";
                                        width: rem(16px);
                                        height: rem(16px);
                                        display: block;
                                        position: absolute;
                                        left: $spacing-32;
                                        top: rem(10px);
                                    }
                                }
                            }

                            .light-dark-toggle.switch.switch-label {
                                padding: $spacing-8 $spacing-16;
    
                                .togglemark {
                                    margin: 0 rem(12px);
    
                                    &, &:hover {
                                        &::after { background: linear-gradient(to bottom, #122938 0%, #142836 100%);}
                                    }
                                }
    
                                input {
                                    &:checked, &.checked {
                                        ~ .togglemark {
                                            background-image: linear-gradient(to bottom, #112838 0%, #182D3B 100%);
                                        }
                                    }
                                }
                            }
                        }

                        .#{$vudClassPrefix}btn {
                            display: flex;
                            width: calc(100% - $spacing-32);
                            margin: 0 $spacing-16;
                        }
    
                        .divider {
                            padding: $spacing-16 $spacing-32;
                            cursor: default;
                            pointer-events: none;
    
                            &::after {
                                content: "";
                                display: block;
                                height: rem(1px);
                                width: 100%;
                                margin: 0;
                                background-position: bottom left;
                                background-repeat: repeat-x;
                                background-size: rem(6px) rem(1px);
                                background-color: transparent;
                                background-image: linear-gradient(to right, var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-dotted) 10%, rgba(0,0,0,0) 0%);
                            }
    
                            &.divider-strong {
                                &::after {
                                    background: var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong);
                                }
                            }
                        }
                    }

                    .company-selection-area {
                        &.one-context {
                            display: none;
    
                            +.divider { display: none; }
                        }
                    }
    
                    .user-details-area {
                        cursor: default;
    
                        > div {
                            display: flex;
                            align-items: center;
                            padding: 0 rem(16px);
                            @include text-overflow;
    
                            .user-img {
                                width: rem(48px);
                                height: rem(48px);
                                flex: 0 0 rem(48px);
                            }
    
                            .user-text {
                                padding-left: rem(12px);
    
                                span {
                                    display: block;
                                    width: 92%;
                                    @include text-overflow;
                                }
                            }
                        }
                    }
                }

                &.shopping-cart {
                    .#{$vudClassPrefix}dropdown-toggle {
                        .vismaicon {
                            margin-right: $spacing-32;

                            + b { margin-right: $spacing-4; }
                        }
                    }

                    &:not(.icon) {
                        .#{$vudClassPrefix}badge {
                            position: absolute;
                            top: rem(4px);
                            left: rem(18px);
                        }
                    }

                    &.icon {
                        .vismaicon { margin-right: 0; }
                    }
                }

                &.icon-dropdown {
                    &.open, &.show {
                        .#{$vudClassPrefix}dropdown-menu {
                            display: flex;
                            justify-content: flex-end;

                            .icon {
                                width: rem(64px);

                                > a, > button {
                                    &:hover {
                                        background-color: transparent;
                                        box-shadow: none;
    
                                        &::after {
                                            display: block;
                                            background-color: var(--#{$vudTokenPrefix}vertical-nav-default-item-hover-bg);
                                        }

                                        &::before {
                                            display: block;
                                            background-color: var(--#{$vudTokenPrefix}anchor-focus-outline);
                                        }
                                    }

                                    &:focus {
                                        &::before {
                                            display: block;
                                            background-color: var(--#{$vudTokenPrefix}anchor-focus-outline);
                                        }
                                    }

                                    .vismaicon {
                                        &::before {
                                            background-color: var(--#{$vudTokenPrefix}navigation-secondary-icon-bg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .#{$vudClassPrefix}dropdown-toggle {
                    display: flex;
                    width: auto;
                    height: rem(64px);
                    margin: 0;
                    padding: rem(20px) rem(52px) rem(20px) $spacing-16;

                    .vismaicon {
                        &::before { background-color: var(--navigation-default-icon-bg); }
                    }

                    .#{$vudClassPrefix}badge {
                        align-self: center;
                        padding: rem(2px) rem(9px) rem(1px);
                    }
                }

                .#{$vudClassPrefix}caret {
                    top: rem(30px);
                    right: rem(20px);
                }

                .#{$vudClassPrefix}dropdown-menu {
                    position: absolute;
                    overflow-y: auto;
                    max-height: calc( 100vh - rem(72px) );
                    min-width: rem(300px);
                    right: 0;
                    left: auto;
                    margin-top: rem(1px);
                    padding: $spacing-10 0 $spacing-8 0;
                }
            }

            .icon {
                &:last-child:not(.user-dropdown) { margin-right: rem(20px); }

                &.attention {
                    position: relative; 

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: rem(15px);
                        right: rem(11px);
                        width: rem(8px);
                        height: rem(8px);

                        border-radius: rem(4px);
                        background-color: var(--#{$vudTokenPrefix}navigation-default-attention-bg);

                        z-index: 1;
                    }
                }

                &.open, &.show {
                    > .#{$vudClassPrefix}dropdown-toggle {
                        height: rem(64px);
                        margin: 0;
                        padding: rem(20px) rem(40px) rem(20px) rem(16px);

                        color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-text-color);
                        background-color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg);

                        .#{$vudClassPrefix}badge {
                            top: rem(4px);
                            left: rem(24px);
                        }
                    }
                }

                &.active:not(.open):not(.show) {
                    > a, > button {
                        &::after { display: block; }
                    }
                }

                > a, > button {
                    position: relative;
                    cursor: pointer;
                    width: rem(48px);
                    height: rem(48px);
                    margin: $spacing-8 $spacing-4;
                    padding: rem(12px);

                    border: 0;
                    background-color: transparent;
                    box-shadow: none;

                    &::before, &::after {
                        display: none;
                        position: absolute;
                        content:'';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;

                        -webkit-mask-size: cover;
                        mask-size: cover;
                        -webkit-mask-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        z-index: -1;
                    }

                    &:before {
                        -webkit-mask-image: url(#{$image-path}/squerkle-border-thick.svg);
                        mask-image: url(#{$image-path}/squerkle-border-thick.svg);
                        z-index: 0;
                    }

                    &:after {
                        -webkit-mask-image: url(#{$image-path}/squerkle-mask.svg);
                        mask-image: url(#{$image-path}/squerkle-mask.svg);
                    }

                    &:focus {
                        outline: none;

                        &::before { display: block; }
                    }

                    &:hover {
                        &::after { display: block; }
                    }

                    &.#{$vudClassPrefix}dropdown-toggle {
                        position: relative;
                        width: rem(48px);
                        min-width: 1%;
                        height: rem(48px);
                        margin: $spacing-8 $spacing-4;
                        padding: rem(12px);

                        font-size: rem(0px);
                        text-indent: rem(-9999px);
    
                        > .#{$vudClassPrefix}caret { display: none; }

                        &:focus {
                            outline: none;
                        }
                    }

                    .#{$vudClassPrefix}badge {
                        position: absolute;
                        overflow: hidden;
                        width: rem(32px);
                        height: rem(20px);
                        top: -$spacing-4;
                        left: rem(20px);
                        margin: 0;
                        padding: rem(2px) rem(9px) rem(1px); // 0.2rem 0.9rem 0.15rem;

                        font-family: $font-family-base;
                        text-indent: 0;
                        border-radius: $border-radius-20;
                        box-shadow: none;
                        z-index: 1;
                    }

                    .vismaicon {
                        display: inline-block;
                        margin-right: 0;
                        top: 0;
                    }

                }
            }
        }

        .first-level {
            float: left;
            max-height: rem(64px);
            position: static;

            &:not(.#{$vudClassPrefix}navbar-right) {
                .fl-menu-item.has-children {
                    .#{$vudClassPrefix}caret {
                        display: none;
                    }
                }
            }

            > li {
                > a {
                    height: rem(64px); /*(IE Edge bug)*/
                    font-family: $font-family-secondary;
                    font-weight: $normal;
                }

                &.#{$vudClassPrefix}dropdown {
                    &.open {
                        > .#{$vudClassPrefix}dropdown-toggle  { 
                            border-color: transparent;

                            &:hover {
                                background-color: var(--module-container-hover-bg-color);
                                box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}button-hover-border-color);
                            }

                            &:focus {
                                outline-color: var(--navigation-secondary-item-focus-border-color);
                            }
                        }
                    }
                }
            }

            > .active {
                ul.second-level{
                    display: block;
                    margin: 0;
                    padding: 0;
                }
            }

            > .disabled {
                > a {
                    pointer-events: none;
                }
            }

            .#{$vudClassPrefix}navbar-form {
                padding: rem(16px) rem(12px);

                .search-group { margin-bottom: 0; }
            }

            .menudrop {
                position: relative;
                height: rem(64px);

                > .#{$vudClassPrefix}dropdown-toggle {
                    display: inline-block;
                    width: rem(90px);
                    height: rem(64px);

                    &::before{ display: none; }

                    .icon-align-justify {
                        border-radius: rem(5px);
                        width: rem(5px);
                        height: rem(5px);
                        position: absolute;
                        top: rem(33px);
                        left: rem(42px);

                        &::before, &::after {
                            content: "";
                            display: block;
                            border-radius: rem(5px);
                            width: rem(5px);
                            height: rem(5px);
                            position: absolute;
                            top: 0;
                            left: rem(-12px);
                        }

                        &::after{ left: rem(12px); }
                    }
                }

                &.open {
                    .#{$vudClassPrefix}dropdown-toggle {
                        background-color: var(--#{$vudTokenPrefix}dropdown-menu-bg);
                        box-shadow: var(--#{$vudTokenPrefix}navigation-default-menudrop-shadow);

                        .icon-align-justify {
                            &,
                            &::before,
                            &::after{ background-color: var(--#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg); }
                        }
                    }
                }

                > .#{$vudClassPrefix}dropdown-menu {
                    overflow-y: auto;
                    width: auto;
                    min-width: rem(220px);
                    min-height: 1%;
                    max-height: calc(#{'100vh - ' rem(180px)});
                    top: 100%;
                    right: 0;
                    padding: rem(8px) 0;
                    z-index: 1;

                    @media (min-height: 980px) { max-height: 800px; }

                    > li {
                        float: none;
                        display: list-item;

                        a {
                            padding: rem(4px) rem(16px); // 0.365rem 1.5625rem;
                            border-radius: 0;
                        }

                        &.active {
                            > a {
                                background: var(--module-container-active-bg-color);
                            }
                        }
                    }
                }

                .second-level-children {
                    > a {
                        > .#{$vudClassPrefix}caret {
                            display: inline-block;
                            position: relative;
                            top: rem(-2px);
                            right: 0;
                            margin-left: $spacing-8;

                            @include caret('sm', 'default');
                        }
                    }

                    &.is-open {
                        > a {
                            .#{$vudClassPrefix}caret { transform: rotate(180deg); }
                        }

                        ul.second-level {
                            box-shadow: none;
                            display: block;
                            margin: 0;
                            padding: 0;
                            position: static;

                            li {
                                display: list-item;

                                &.open {
                                    .third-level {
                                        box-shadow: none;
                                        position: static;
                                        padding: 0;
                                        margin: 0;

                                        li {
                                            a { padding-left: rem(38px); } // 3.75rem;

                                            &.disabled {
                                                > a {
                                                    pointer-events: none;
                                                }
                                            }
                                        }

                                        .active {
                                            a {
                                                // color: var(--#{$vudTokenPrefix}anchor-color);
                                                font-weight: $bold;
                                            }
                                        }
                                    }
                                }

                                &.disabled {
                                    > a {
                                        pointer-events: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        ul.second-level {
            display: none;
            background: var(--#{$vudTokenPrefix}navigation-default-second-level-bg);
            box-shadow: var(--#{$vudTokenPrefix}navigation-default-second-level-shadow);
            position: absolute;
            right: auto;
            bottom: auto;
            left: 0;
            width: 100%;

            & > li {
                display: inline-block;
                vertical-align: middle;
                padding: rem(12px) 0;

                &.disabled {
                    > a {
                        pointer-events: none;
                        color: var(--#{$vudTokenPrefix}button-disabled-text-color);
                    }
                }

                a {
                    color: var(--#{$vudTokenPrefix}navigation-default-dropdown-text-color);
                    font: $font-sm;
                    padding: 0 $spacing-16;
                    line-height: 1.5;
                    background-color: transparent;

                    &.#{$vudClassPrefix}dropdown-toggle {
                        > .#{$vudClassPrefix}caret {
                            display: inline-block;
                            position: relative;
                            top: rem(-2px);
                            right: 0;
                            margin-left: rem(12px);
                            @include caret('sm', 'default');
                        }

                        &:hover {
                            background: none;
                        }
                    }

                    &:hover {
                        text-decoration: underline;
                        color: var(--#{$vudTokenPrefix}blue-60);
                        background-color: transparent;

                        &.#{$vudClassPrefix}dropdown-toggle {
                            > .#{$vudClassPrefix}caret {
                                background-color: var(--#{$vudTokenPrefix}blue-60);
                            }
                        }
                    }

                    &:focus {
                        outline: rem(2px) solid var(--navigation-secondary-item-focus-border-color);
                        outline-offset: rem(0px);
                    }
                }
            }

            & > .active > a { font-weight: $bold; }

            & > .open {
                .third-level {
                    overflow-y: auto;
                    width: auto;
                    min-width: rem(150px);
                    min-height: rem(92px);
                    max-height: calc(#{'100vh - ' rem(180px)});
                    top: 100%;
                    margin-top: 0;
                    padding: 0;

                    @media (min-height: 980px) { max-height: 800px; }


                    > li {
                        padding: $spacing-2 $spacing-12;

                        > a {
                            padding: rem(10px) rem(22px);
                            border-radius: rem(8px);

                            &:hover {
                                text-decoration: none;
                                color: var(--#{$vudTokenPrefix}body-font-color);
                                background-color: var(--module-container-hover-bg-color);
                            }
                        }

                        &.active > a { font-weight: $bold; }

                        &.disabled {
                            > a {
                                pointer-events: none;
                                color: var(--#{$vudTokenPrefix}button-disabled-text-color);
                            }
                        }
                    }
                }
            }
        }
    }
}

.#{$vudClassPrefix}navbar-default,
.#{$vudClassPrefix}navbar-primary,
.#{$vudClassPrefix}navbar-secondary {
    &.#{$vudClassPrefix}navbar.is-mobile {
        .#{$vudClassPrefix}navbar-collapse {
            .first-level {
                .menudrop {
                    position: static;

                    > .#{$vudClassPrefix}dropdown-toggle {
                        width: auto;
                        padding-left: rem(60px);
                        font: $font-lg;
                        line-height: 1.2;

                        &::before { display: none; }
                    }

                    .fl-menu-item.has-children {
                        .#{$vudClassPrefix}caret {
                            display: inline-block;
                        }
                    }

                    .icon-align-justify {
                        &,
                        &::before,
                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: rem(24px);
                            height: rem(2px);
                            left: rem(24px);
                            top: rem(32px);
                            border-radius: $border-radius;
                            background: var(--#{$vudTokenPrefix}navigation-default-hamburger-bg);
                            transition: transform 0.3s ease-in-out;
                        }

                        &::before {
                            top: rem(-8px);
                            left: 0;
                        }

                        &::after {
                            bottom: rem(-8px);
                            top: auto;
                            left: 0;
                        }
                    }

                    &.open {
                        .icon-align-justify {
                            & { background-color: transparent; }

                            &::before,
                            &::after { background: var(--#{$vudTokenPrefix}navigation-default-hamburger-open-bg); }

                            &::before {
                                transform: rotate(45deg);
                                top: -1px;
                                left: 0px;
                            }

                            &::after { 
                                transform: rotate(-45deg);
                                bottom: 0;
                                left: 0px;
                            }
                        }

                        > .#{$vudClassPrefix}dropdown-toggle {
                            color: var(--#{$vudTokenPrefix}navigation-default-dropdown-text-color);

                            &::before, &::after { border-color: var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong); }
                        }

                        > .#{$vudClassPrefix}dropdown-menu {
                            width: 100%;
                            padding: 0;
                            top: rem(64px);

                            .#{$vudClassPrefix}navbar-brand {
                                > a:not(.#{$vudClassPrefix}dropdown-toggle) {
                                    .#{$vudClassPrefix}caret { display: none; }
                                }
                            }

                            li {
                                padding: 0;

                                a {
                                    padding: rem(12px) rem(16px);
                                    line-height: rem(24px);
                                    border-radius: 0;
    
                                    &.#{$vudClassPrefix}dropdown-toggle {
                                        box-shadow: none;
                                    }

                                    &:hover {
                                        color: var(--#{$vudTokenPrefix}navigation-default-dropdown-text-color);
                                        background-color: var(--module-container-hover-bg-color);
                                        box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}anchor-focus-outline);

                                        .#{$vudClassPrefix}caret {
                                            background-color: var(--#{$vudTokenPrefix}caret-default-bg);
                                        }
                                    }

                                    &:focus:not(:active) {
                                        box-shadow: inset 0 0 0 rem(2px) var(--#{$vudTokenPrefix}anchor-focus-outline);
                                    }
                                }
                            }

                            > .active {
                                > a {
                                    background-color: var(--module-container-active-bg-color);
                                    position: relative;

                                    &::before {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        width: rem(6px);
                                        height: 100%;
                                        left: 0;
                                        top: 0;
                                        background-color: var(--module-container-active-before-color);
                                    }
                                }
                            }
                        }
                    }
                }

                .#{$vudClassPrefix}navbar-brand {
                    .#{$vudClassPrefix}dropdown-toggle {
                        display: flex;
                        align-items: center;
                        padding-left: rem(32px) !important;

                        .#{$vudClassPrefix}caret {
                            top: rem(20px) !important;
                            left: rem(16px) !important;

                            background-color: var(--#{$vudTokenPrefix}navigation-default-dropdown-text-color);
                            transform: rotate(90deg);
                        }
                    }

                    &.is-open {
                        .#{$vudClassPrefix}dropdown-toggle {
                            padding: rem(12px) rem(16px) !important;

                            .#{$vudClassPrefix}caret {
                                position: static;
                                margin-left: rem(8px);
                                transform: rotate(-90deg);
                            }
                        }

                        .#{$vudClassPrefix}dropdown-menu {
                            display: block;
                            top: 0;
                            padding: 0;
                            position: relative;

                            .app-item {
                                display: flex;
                                align-items: center;
                                height: rem(64px);
                                padding: rem(8px) rem(24px) rem(8px) rem(16px);

                                img { margin-right: rem(16px); }
                            }
                        }
                    }
                }
            }

            .second-level {
                border: 0;

                > li {
                    a {
                        &:focus {
                            outline-offset: rem(-2px);
                        }
                    }
                }

                .active {
                    > a { box-shadow: none; }
                }
            }

            .first-level.#{$vudClassPrefix}navbar-right {
                position: static;

                .user-dropdown .company-selection {
                    max-width: none;
                    width: 100%;
                    top: rem(64px);
                }
            }
        }
    }
}

