$tabs-height: rem(41px);

// Tabs definition
.#{$vudClassPrefix}tab-content {
    display: block;
    content: '';
    background-color: var(--tabs-content-bg);
    // box-shadow: $shadow-10 var(--tabs-content-shadow-color);
    box-shadow: var(--#{$vudTokenPrefix}shadow-10);
}


// Tabs general style
.#{$vudClassPrefix}nav-tabs:not(.#{$vudClassPrefix}navbar-nav) {
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;

    // height: $tabs-height;
    padding: 0 rem(54px) 0 rem(4px);
    margin: 0;
    top: rem(2px);

    border: 0;
    background: transparent;
    box-shadow: none;

    list-style: none;
    z-index: 1;

    // tabs items basic style
    .#{$vudClassPrefix}nav-item {
        position: relative;
        margin: 0;
        padding: 0 rem(18px);
        float: left;

        a {
            cursor: pointer;
            position: relative;
            line-height: 1;

            color: var(--#{$vudTokenPrefix}tabs-text-color);
            white-space: nowrap;
            text-align: center;
            text-decoration: none;

            outline: 0;
           
            // tabs items on hover
            &:hover, &.hover {
                color: var(--#{$vudTokenPrefix}tabs-focused-text-color);
            }

            // tabs items on focus
            &:focus:not(:active), &.focus:not(:active) {
                color: var(--#{$vudTokenPrefix}tabs-focused-text-color);
            }
        }

        // tabs items on layer order
        &:nth-last-child(24) { z-index: 24; }
        &:nth-last-child(23) { z-index: 23; }
        &:nth-last-child(22) { z-index: 22; }
        &:nth-last-child(21) { z-index: 21; }
        &:nth-last-child(20) { z-index: 20; }
        &:nth-last-child(19) { z-index: 19; }
        &:nth-last-child(18) { z-index: 18; }
        &:nth-last-child(17) { z-index: 17; }
        &:nth-last-child(16) { z-index: 16; }
        &:nth-last-child(15) { z-index: 15; }
        &:nth-last-child(14) { z-index: 14; }
        &:nth-last-child(13) { z-index: 13; }
        &:nth-last-child(12) { z-index: 12; }
        &:nth-last-child(11) { z-index: 11; }
        &:nth-last-child(10) { z-index: 10; }
        &:nth-last-child( 9) { z-index:  9; }
        &:nth-last-child( 8) { z-index:  8; }
        &:nth-last-child( 7) { z-index:  7; }
        &:nth-last-child( 6) { z-index:  6; }
        &:nth-last-child( 5) { z-index:  5; }
        &:nth-last-child( 4) { z-index:  4; }
        &:nth-last-child( 3) { z-index:  3; }
        &:nth-last-child( 2) { z-index:  2; }
        &:last-child         { z-index:  1; }

        // tabs items selected
        &.active {
            cursor: default;
            pointer-events: none;
            z-index: 29;
        }

        // &.pressed, &:active:not(.disabled) {
        //     a { }
            
        // }

        // tabs items on diusabled state
        &.disabled a, &:disabled a,
        a.disabled, a:disabled {
            &, &:focus {
                cursor: default;
                pointer-events: none;

                color: var(--#{$vudTokenPrefix}tabs-disabled-text-color);
            }
        }
    }


    // tabs overflow dropdown
    .tabdrop {
        overflow: visible;
        position: absolute;
        right: rem(2px);
        margin: 0;
        padding: 0;

        border-right: 0;
        box-shadow: none;
        z-index: 30;

        &.hide {
            display: none;
        }

        .#{$vudClassPrefix}caret { display: none; }

        // tabs overflow dropdown trigger
        .#{$vudClassPrefix}dropdown-toggle {
            display: block;
            content: '';
            overflow: hidden;
            padding: 0;
            width: rem(40px);
            height: $tabs-height;
            text-indent: 9999px;

            &::before, &::after { display: none; }

            &:hover { background-color: transparent; }

            .icon-align-justify { display: none; }
        }

        // tabs overflow dropdown menu
        .#{$vudClassPrefix}dropdown-menu {
            width: auto;
            width: unset;
            min-width: rem(120px);
            top: rem(30px);
            right: rem(0);
            left: auto;
            margin: 0;
            padding: rem(8px) 0;

            // tabs overflow dropdown menu items
            li {
                height: rem(36px);
                padding: 0;

                a {
                    display: flex;
                    height: rem(36px);
                    padding: rem(8px) rem(18px);
                    line-height: $line-height-base;

                    text-align: left;
                    background: transparent;
                    border: 0;

                    &:hover {
                        background-color: var(--#{$vudTokenPrefix}dropdown-menu-hover-bg);
                    }

                    &:focus {
                        outline: none;
                    }

                    &:active {
                        border: 0;
                        color: var(--#{$vudTokenPrefix}dropdown-menu-selected-text-color);
                    }
                }

                &.active, &:active {
                    background-color: transparent;
                    
                    a {
                        color: var(--#{$vudTokenPrefix}dropdown-menu-selected-text-color);
                    }
                }
            }
        }
    }

// /* Justified tabs */
    &.#{$vudClassPrefix}nav-justified {
        width: 100%;

        &.hide-tabdrop { padding-right: rem(17px); }

        // tabs items style for justify tabs
        .#{$vudClassPrefix}nav-item {
            flex: 1;

            // tabs overflow for justify tabs
            &.tabdrop {
                right: 0;

                > a {
                    width: rem(44px);
                    height: $tabs-height;
                }
            }

            a { 
                width: 100%; 
                justify-content: center;
            }
        }
    }
}