
// Imports
@import '../../utilities/carets';


//=== Screen Reader only - element visible but only for the accessibility tools.
.sr-only {
    overflow: hidden !important;
    position: absolute !important;

    width: rem(1px) !important;
    height: rem(1px) !important;
    margin: rem(-1px) !important;
    padding: 0 !important;

    border: 0 !important;
    clip: rect(0,0,0,0) !important;
}


//=== Width and height
.w-0 { width: 0 !important; }
.w-100 { width: 100% !important; }

.h-0 { height: 0 !important; }
.h-100 { height: 100% !important; }

.max-w-0 { max-width: 0 !important; }
.max-w-100, .mw-100 { max-width: 100% !important; }
.max-w-max-content { max-width: max-content !important; }
.max-w-min-content { max-width: min-content !important; }

.min-w-min-content { min-width: min-content !important; }
.min-w-max-content { min-width: max-content !important; }
.min-w-100 { min-width: 100% !important; }
.min-w-0 { min-width: 0 !important; }

.max-h-0 { max-height: 0 !important; }
.max-h-100, .mh-100 { max-height: 100% !important; }
.max-h-screen { max-height: 100vh !important; }

.min-h-screen { min-height: 100vh !important; }
.min-h-100 { min-height: 100% !important; }
.min-h-0 { min-height: 0 !important; }



//=== Close button
.#{$vudClassPrefix}close {
  display: inline-block;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: rem(16px);
  height: rem(16px);
  padding: 0;

  text-indent: -9999px;
  border: 0;
  background: transparent;

  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: var(--#{$vudTokenPrefix}close-icon-bg);
    mask: $close-icon no-repeat center;
    -webkit-mask: $close-icon no-repeat center;
    mask-size: rem(10px);
    -webkit-mask-size: rem(10px);
  }

  &.#{$vudClassPrefix}close-lg {
    &::after {
      mask: $close-icon-lg no-repeat center;
      -webkit-mask: $close-icon-lg no-repeat center;
      mask-size: cover;
      -webkit-mask-size: cover;
    }
  }

  &:focus { outline: rem(2px) solid var(--#{$vudTokenPrefix}anchor-focus-outline); }

  > span { display: none; }
}



//==== Text
//=== Alignment
.text-nowrap { white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.text-truncate-2,
.text-truncate-3,
.text-truncate-4 {
  display: -webkit-box;
  // max-width: 200px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-2 { -webkit-line-clamp: 2; }
.text-truncate-3 { -webkit-line-clamp: 3; }
.text-truncate-4 { -webkit-line-clamp: 4; }

//=== Responsive alignment
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
    .text#{$infix}-justify { text-align: justify !important; }
  }
}

//=== Transformation
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-lowercase { text-transform: lowercase !important; }
    .text#{$infix}-uppercase { text-transform: uppercase !important; }
    .text#{$infix}-capitalize { text-transform: capitalize !important; }
    .text#{$infix}-normal-case	{ text-transform: none !important; }

    .text-numeric#{$infix}-normal { font-variant-numeric: normal !important; }

    .text-numeric#{$infix}-ordinal {
      font: {
        family: monospace;
        size: 1.2em;
        variant-numeric: ordinal !important;
      }
    }

    .text-numeric#{$infix}-diagonal-fractions {
      font: {
        family: sans-serif;
        size: 1.25em;
        variant-numeric: diagonal-fractions !important;
      }
    }
  }
}

//=== Decoration
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-underline { text-decoration: underline !important; }
    .text#{$infix}-line-through { text-decoration: line-through !important; }
    .text#{$infix}-no-underline { text-decoration: none !important; }
  }
}

//=== Weight
.font-weight-light, .light  { font-weight: $thin !important; }
.font-weight-normal, .normal { font-weight: $normal !important; }
.font-weight-bold, .bold   { font-weight: $bold !important; }

//=== Family
.font-family-open-sans { 
  font-family: $font-family-base !important; 
}

.font-family-ubuntu { 
  font-family: $font-family-secondary !important; 
}

.font-family-ubuntu-light { 
  font-family: $font-family-secondary-light !important; 
  font-weight: 300 !important;
}

.font-family-ubuntu-semibold { 
  font-family: $font-family-secondary-semibold !important; 
  font-weight: 500 !important;
} 

.font-family-ubuntu-bold { 
  font-family: $font-family-secondary-bold !important; 
  font-weight: 700 !important;
}

//=== Size
.font-48 { font-size: rem(48px) !important; }
.font-32 { font-size: rem(32px) !important; }
.font-24 { font-size: $font-size-xl !important; }
.font-20 { font-size: $font-size-lg !important; }
.font-16 { font-size: $font-size-md !important; }
.font-14 { font-size: $font-size-sm !important; }
.font-13 { font-size: $font-size-xs !important; }


//==== Contextual colors
//=== Text
.#{$vudClassPrefix}text-default { color: var(--#{$vudTokenPrefix}text-default) !important; }

.#{$vudClassPrefix}text-primary,
.#{$vudClassPrefix}text-blue { color: var(--#{$vudTokenPrefix}text-primary) !important; }

.#{$vudClassPrefix}text-disabled,
.#{$vudClassPrefix}text-grey { 
  &, &:hover, & a {
    color: var(--#{$vudTokenPrefix}text-disabled) !important; 
    cursor: default;
    pointer-events: none;
  }
}

.#{$vudClassPrefix}text-error,
.#{$vudClassPrefix}text-red { color: var(--#{$vudTokenPrefix}text-error) !important; }

.#{$vudClassPrefix}text-orange,
.#{$vudClassPrefix}text-warning { color: var(--#{$vudTokenPrefix}text-warning) !important; }

.#{$vudClassPrefix}text-success,
.#{$vudClassPrefix}text-green { color: var(--#{$vudTokenPrefix}text-success) !important; }

.#{$vudClassPrefix}text-secondary,
.#{$vudClassPrefix}text-dark-grey { color: var(--#{$vudTokenPrefix}text-secondary) !important; }


//=== Backgrounds (and the before is for the icons)
.#{$vudClassPrefix}bg-default {
  background-color: var(--#{$vudTokenPrefix}bg-default) !important;

  &.#{$vudClassPrefix}vismaicon {
    background-color: transparent !important;

    &:before {
      background-color: var(--#{$vudTokenPrefix}bg-default) !important;
    }
  }
}

.#{$vudClassPrefix}bg-primary {
  background-color: var(--#{$vudTokenPrefix}bg-primary) !important;

  &.#{$vudClassPrefix}vismaicon {
    background-color: transparent !important;
    
    &:before {
      background-color: var(--#{$vudTokenPrefix}bg-primary) !important;
    }
  }
}

.#{$vudClassPrefix}bg-secondary {
  background-color: var(--#{$vudTokenPrefix}bg-secondary) !important;

  &.#{$vudClassPrefix}vismaicon {
    background-color: transparent !important;
    
    &:before {
      background-color: var(--#{$vudTokenPrefix}bg-secondary) !important;
    }
  }
} 

.#{$vudClassPrefix}bg-transparent {
  background-color: transparent !important;

  &.#{$vudClassPrefix}vismaicon {
    background-color: transparent !important;

    &:before {
      background-color: transparent !important;
    }
  }
}

.vismaicon {
  &.icon-neutral {
    &:before {
      background-color: var(--#{$vudTokenPrefix}caret-default-bg) !important;
    }
  }

  &.icon-info {
    &:before {
      background-color: var(--#{$vudTokenPrefix}icon-informative-info-bg) !important;
    }
  }

  &.icon-success {
    &:before {
      background-color: var(--#{$vudTokenPrefix}icon-informative-success-bg) !important;
    }
  }

  &.icon-warning {
    &:before {
      background-color: var(--#{$vudTokenPrefix}icon-informative-warning-bg) !important;
    }
  }

  &.icon-error {
    &:before {
      background-color: var(--#{$vudTokenPrefix}icon-informative-error-bg) !important;
    }
  }
}


//=== Shadows
.#{$vudClassPrefix}shadow-50 { box-shadow: var(--#{$vudTokenPrefix}shadow-50) !important; }
.#{$vudClassPrefix}shadow-40 { box-shadow: var(--#{$vudTokenPrefix}shadow-40) !important; }
.#{$vudClassPrefix}shadow-30 { box-shadow: var(--#{$vudTokenPrefix}shadow-30) !important; }
.#{$vudClassPrefix}shadow-20 { box-shadow: var(--#{$vudTokenPrefix}shadow-20) !important; }
.#{$vudClassPrefix}shadow-10 { box-shadow: var(--#{$vudTokenPrefix}shadow-10) !important; }
.#{$vudClassPrefix}shadow-0, .#{$vudClassPrefix}no-shadow { box-shadow: none !important; }


//=== Positions and float
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      .float#{$infix}-left  { float: left; }
      .float#{$infix}-right { float: right; }
      .float#{$infix}-none  { float: none; }
  
      .position#{$infix}-static   { position: static ; }
      .position#{$infix}-fixed { position: fixed; }
      .position#{$infix}-absolute  { position: absolute; }
      .position#{$infix}-relative  { position: relative; }
      .position#{$infix}-sticky  { position: sticky; }
    }
  }


//=== List decoration
.#{$vudClassPrefix}no-bullet {
  padding-left: rem(30px);
  list-style-type: none;
}

.#{$vudClassPrefix}list-none {
  padding-left: 0;
  list-style-type: none;
}

.#{$vudClassPrefix}list-disc {
  padding-left: rem(30px);
  list-style-type: disc;
}

.#{$vudClassPrefix}list-circle {
  padding-left: rem(30px);
  list-style-type: circle;
}

.#{$vudClassPrefix}list-decimal {
  padding-left: rem(30px);
  list-style-type: decimal;
}



//=== Border
.#{$vudClassPrefix}border         { border: rem(1px) solid var(--border-color) !important; }
.#{$vudClassPrefix}border-top     { border-top: rem(1px) solid var(--border-color) !important; }
.#{$vudClassPrefix}border-right   { border-right: rem(1px) solid var(--border-color) !important; }
.#{$vudClassPrefix}border-bottom  { border-bottom: rem(1px) solid var(--border-color) !important; }
.#{$vudClassPrefix}border-left    { border-left: rem(1px) solid var(--border-color) !important; }

.#{$vudClassPrefix}border-0        { border: 0 !important; }
.#{$vudClassPrefix}border-top-0    { border-top: 0 !important; }
.#{$vudClassPrefix}border-right-0  { border-right: 0 !important; }
.#{$vudClassPrefix}border-bottom-0 { border-bottom: 0 !important; }
.#{$vudClassPrefix}border-left-0   { border-left: 0 !important; }

.#{$vudClassPrefix}border-white { border-color: $white !important; }


//=== Border-radius
.#{$vudClassPrefix}rounded { border-radius: $border-radius !important; } 
.#{$vudClassPrefix}rounded-4 { border-radius: $border-radius-4 !important; } 
.#{$vudClassPrefix}rounded-5 { border-radius: $border-radius-5 !important; } 
.#{$vudClassPrefix}rounded-8 { border-radius: $border-radius-8 !important; } 
.#{$vudClassPrefix}rounded-12 { border-radius: $border-radius-12 !important; } 
.#{$vudClassPrefix}rounded-24 { border-radius: $border-radius-24 !important; } 
.#{$vudClassPrefix}rounded-10 { border-radius: $border-radius-10 !important; } // DEPRECATED
.#{$vudClassPrefix}rounded-20 { border-radius: $border-radius-20 !important; } // DEPRECATED
.#{$vudClassPrefix}rounded-0 { border-radius: 0 !important; }
.#{$vudClassPrefix}rounded-circle { border-radius: 50% !important; }

.#{$vudClassPrefix}rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}

.#{$vudClassPrefix}rounded-top-left {
  border-top-left-radius: $border-radius !important;
}

.#{$vudClassPrefix}rounded-top-right {
  border-top-right-radius: $border-radius !important;
}

.#{$vudClassPrefix}rounded-right {
    border-top-right-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
    }

.#{$vudClassPrefix}rounded-bottom {
    border-bottom-right-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important; 
    }

.#{$vudClassPrefix}rounded-bottom-left {
  border-bottom-left-radius: $border-radius !important; 
}

.#{$vudClassPrefix}rounded-bottom-right {
  border-bottom-right-radius: $border-radius !important; 
}

.#{$vudClassPrefix}rounded-left {
    border-top-left-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
    }



//=== Show on light mode/dark mode
.show-light,
.show-on-light {
  display: block;

  html[data-theme='dark'] &, .dark-theme & { display: none; }
  html[data-theme='light'] &, .light-theme & { display: block; }

  @media (prefers-color-scheme: dark) {
      display: none;

      html[data-theme='light'] &, .light-theme & {
          display: block;
      }
  }
}

.show-dark,
.show-on-dark {
  display: none;

  html[data-theme='dark'] &, .dark-theme & { display: block; }
  html[data-theme='light'] &, .light-theme & { display: none; }

  @media (prefers-color-scheme: dark) {
      display: block;

      html[data-theme='light'] &, .light-theme & {
          display: none;
      }
  }
}



//=== Display
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .d#{$infix}-none         { display: none !important; }
      .d#{$infix}-inline       { display: inline !important; }
      .d#{$infix}-inline-block { display: inline-block !important; }
      .d#{$infix}-block        { display: block !important; }
      .d#{$infix}-flex         { display: flex !important; }
      .d#{$infix}-grid         { display: grid !important; }
      .d#{$infix}-inline-flex  { display: inline-flex !important; }
    }
  }

//=== "Display" in print
@media print {
    .d-print-none         { display: none !important; }
    .d-print-inline       { display: inline !important; }
    .d-print-inline-block { display: inline-block !important; }
    .d-print-block        { display: block !important; }
    .d-print-flex         { display: flex !important; }
    .d-print-inline-flex  { display: inline-flex !important; }
}



//=== Caret <span class="caret caret-sm"></span>
.#{$vudClassPrefix}caret {
  display: inline-block;
  @include caret();
  transition: transform .25s;

  &.#{$vudClassPrefix}caret-link { transform: rotate(-90deg); }

  &.#{$vudClassPrefix}caret-sm, &.#{$vudClassPrefix}caret-small {
    @include caret-mask-img('sm');
  }

  &.#{$vudClassPrefix}caret-lg, &.#{$vudClassPrefix}caret-large {
    @include caret-mask-img('lg');
  }

  &.caret-small-black,
  &.caret-small-default { @include caret-mask-color('default'); }

  &.caret-small-blue,
  &.caret-small-primary,
  &.#{$vudClassPrefix}caret-primary {
    @include caret-mask-color('primary');
  }

  &.caret-small-white,
  &.caret-small-secondary,
  &.#{$vudClassPrefix}caret-secondary {
    @include caret-mask-color('secondary');
  }

  &.caret-small-grey,
  &.caret-small-disabled,
  &.#{$vudClassPrefix}caret-disabled {
    @include caret-mask-color('disabled');
  }

  // Large carets
  // Medium carets are deprecated - will be removed soon - they are replaced by caret-large-*
  &.caret-medium-black,
  &.caret-large-black,
  &.caret-medium-default,
  &.caret-large-default { @include caret('lg', 'default'); }

  &.caret-medium-blue,
  &.caret-large-blue,
  &.caret-medium-primary,
  &.caret-large-primary { @include caret('lg', 'primary'); }

  &.caret-medium-white,
  &.caret-large-white,
  &.caret-medium-secondary,
  &.caret-large-secondary { @include caret('lg', 'secondary'); }

  &.caret-medium-grey,
  &.caret-large-grey,
  &.caret-medium-disabled,
  &.caret-large-disabled { @include caret('lg', 'disabled'); }
}

.open {
  &.#{$vudClassPrefix}caret,
  > .#{$vudClassPrefix}caret {
    transform: rotate(180deg);

    &.#{$vudClassPrefix}caret-link { transform: rotate(90deg); }
  }
}


//==== Layout
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    //=== Overflow
    .overflow#{$infix}-auto      { overflow: auto !important; }
    .overflow#{$infix}-hidden    { overflow: hidden !important; }
    .overflow#{$infix}-visible   { overflow: visible !important; }
    .overflow#{$infix}-scroll    { overflow: scroll !important; }

    .overflow-x#{$infix}-auto    { overflow-x: auto !important; }
    .overflow-x#{$infix}-hidden  { overflow-x: hidden !important; }
    .overflow-x#{$infix}-visible { overflow-x: visible !important; }
    .overflow-x#{$infix}-scroll  { overflow-x: scroll !important; }

    .overflow-y#{$infix}-auto    { overflow-y: auto !important; }
    .overflow-y#{$infix}-hidden  { overflow-y: hidden !important; }
    .overflow-y#{$infix}-visible { overflow-y: visible !important; }
    .overflow-y#{$infix}-scroll  { overflow-y: scroll !important; }


    //=== Visibility
  .visible#{$infix} { visibility: visible; }
  .invisible#{$infix} { visibility: hidden; }

  }
}

//=== Center content blocks
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  float: none;
}


//=== Clearfix
.clear,
.clearfix {
  display: block;
  clear: both;
}

.clear-left { clear: left; }
.clear-right { clear: right; }
.clear-none { clear: none; }


//=== Object fit
.object-contain	{ object-fit: contain;}
.object-cover	{ object-fit: cover; }
.object-fill	{ object-fit: fill; }
.object-none	{ object-fit: none; }
.object-scale-down	{ object-fit: scale-down; }


//=== Object position
.object-bottom	{object-position: bottom;}
.object-center	{object-position: center};
.object-left	{object-position: left;}
.object-left-bottom	{object-position: left bottom;}
.object-left-top	{object-position: left top;}
.object-right	{object-position: right;}
.object-right-bottom	{object-position: right bottom;}
.object-right-top	{object-position: right top;}
.object-top	{object-position: top;}


//=== Z-index
.z-index-0	{z-index: 0;}
.z-index-1	{z-index: 1;}
.z-index-2	{z-index: 2;}
.z-index-3	{z-index: 3;}
.z-index-4	{z-index: 4;}
.z-index-5	{z-index: 5;}
.z-index-10	{z-index: 10;}
.z-index-20	{z-index: 20;}
.z-index-50	{z-index: 50;}
.z-index-auto	{z-index: auto;}


//=== Hide/Show
.hide { display: none; }
.show { display: block; }
