
// Top navigation - primary styles - GRAPHITE VERSION

.#{$vudClassPrefix}navbar {
    &.#{$vudClassPrefix}navbar-primary {
        background: var(--#{$vudTokenPrefix}navigation-primary-bg);

        .#{$vudClassPrefix}dropdown {
            > .#{$vudClassPrefix}dropdown-toggle {
                .#{$vudClassPrefix}caret { background-color: var(--caret-navigation-bg); }

                &:hover { background-color: var(--#{$vudTokenPrefix}navigation-primary-item-hover-bg); }
            }

            &.open {
                > .#{$vudClassPrefix}dropdown-toggle {
                    .#{$vudClassPrefix}caret { background-color: var(--#{$vudTokenPrefix}caret-default-bg); }
                }
            }
        }
    }
}

/* Product dropdown area */
.#{$vudClassPrefix}navbar-primary {
    .#{$vudClassPrefix}navbar-header {
        .#{$vudClassPrefix}navbar-brand {
            > a,
            > .#{$vudClassPrefix}dropdown-toggle {
                color: var(--#{$vudTokenPrefix}navigation-primary-item-text-color);

                &:hover { background-color: var(--#{$vudTokenPrefix}navigation-primary-item-hover-bg); }

                &:focus {
                    outline: rem(2px) solid var(--#{$vudTokenPrefix}navigation-default-item-focus-border-color);
                    outline-offset: rem(-2px);
                }
            }

            &.open {
                > .#{$vudClassPrefix}dropdown-toggle {
                    &:focus { outline-color: var(--#{$vudTokenPrefix}navigation-secondary-item-focus-border-color); }
                }
            }
        }
    }
}

/* navbar */
.#{$vudClassPrefix}navbar-primary {
    .#{$vudClassPrefix}navbar-collapse {
        .nav {
            li {
                a {
                    &:hover{ background: var(--#{$vudTokenPrefix}navigation-primary-item-hover-bg); }
                }
            }
        }

        .first-level.#{$vudClassPrefix}navbar-right {
            > .icon {
                & > a {

                    &:hover {
                        &:after { background-color: var(--#{$vudTokenPrefix}navigation-primary-item-hover-bg); }
                    }

                    &:focus {
                        &:before { background-color: var(--#{$vudTokenPrefix}navigation-default-item-focus-border-color); }
                    }

                    .#{$vudClassPrefix}badge {
                        color:  var(--#{$vudTokenPrefix}navigation-default-badge-text-color);
                        background-color: var(--#{$vudTokenPrefix}navigation-default-badge-bg);
                        box-shadow: var(--#{$vudTokenPrefix}navigation-default-badge-shadow);
                    }

                    .vismaicon::before { background-color: var(--#{$vudTokenPrefix}navigation-default-item-text-color); }
                }

                &:not(.#{$vudClassPrefix}dropdown) > a {
                    border-radius: rem(14px);

                    &:hover { background: none; }
                }

                &.active {
                    > a {
                        background-color: transparent;

                        // &:after { background-color: var(--#{$vudTokenPrefix}navigation-primary-item-active-bg); }
                        &:after { background-color: rgba(255, 255, 255, 0.12); }
                    }

                    > .vismaicon:not(.vismaicon-filled)::before { background-color: var(--navigation-primary-icon-bg); }
                }

                &.open > a {
                    .#{$vudClassPrefix}badge {
                        background: var(--#{$vudTokenPrefix}badge-success-bg);
                        color: var(--#{$vudTokenPrefix}badge-success-text);
                    }
                }

                &.#{$vudClassPrefix}dropdown {
                    &.open {
                        > a.#{$vudClassPrefix}dropdown-toggle {
                            > .vismaicon:before { background-color: var(--#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg); }

                            &:focus { outline: rem(2px) solid var(--#{$vudTokenPrefix}navigation-secondary-item-focus-border-color); }
                        }
                    }
                }

                .vismaicon.vismaicon-menu {
                    &:before { background-color: var(--navigation-primary-icon-bg); }
                }
            }

            .user-dropdown {
                > .#{$vudClassPrefix}dropdown-toggle {
                    > small { color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-small-text-color); }
                }

                &.icon {
                    &.open {
                        .#{$vudClassPrefix}dropdown-toggle {
                            .vismaicon::before { background-color: var(--#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg); }
                        }
                    }
                }

                &.open {
                    > .#{$vudClassPrefix}dropdown-toggle {
                        &:focus { outline-color: var(--#{$vudTokenPrefix}navigation-secondary-item-focus-border-color); }
                    }
                }
            }
        }

        .first-level {
            > li > a {
                color: var(--#{$vudTokenPrefix}navigation-primary-item-text-color);

                &:focus {
                    outline: rem(2px) solid var(--#{$vudTokenPrefix}navigation-default-item-focus-border-color);
                    outline-offset: rem(-2px);
                }
            }

            > .active {
                > a { background-color: var( --#{$vudTokenPrefix}navigation-primary-item-active-bg); }
            }

            > .disabled {
                > a { opacity: 0.55; }

                &.icon {
                    .vismaicon::before { background-color: var(--#{$vudTokenPrefix}navigation-default-item-text-color) !important; }
                }
            }

            .menudrop {
                > .#{$vudClassPrefix}dropdown-toggle {
                    .icon-align-justify {
                        background: var(--navigation-primary-icon-bg);

                        &::before, &::after { background: var(--navigation-primary-icon-bg); }
                    }
                }
            }

            .search-group {
                &, &.search-group-primary {
                    input {
                        &.#{$vudClassPrefix}form-control {
                            &[type=text], &[type=search] {
                                border-color: var(--#{$vudTokenPrefix}navigation-primary-item-border-color);
                                background-color: var(--#{$vudTokenPrefix}neutral-90);
                                box-shadow: none;

                                &:hover, &:focus {
                                    border-color: var(--#{$vudTokenPrefix}navigation-default-item-focus-border-color);
                                    background-color: transparent;
                                }

                                &:focus { box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}navigation-default-item-focus-border-color); }

                                &, &::placeholder { color: var(--#{$vudTokenPrefix}navigation-default-item-text-color); }

                                &:-webkit-autofill  {
                                    &, &:hover, &:focus {
                                        -webkit-text-fill-color: var(--#{$vudTokenPrefix}navigation-default-item-text-color);
                                        box-shadow: inset 0 0 0 rem(1px) var(--#{$vudTokenPrefix}navigation-default-item-focus-border-color), 0 0 0 rem(1000px) #25262600 inset !important;
                                    }
                                }
                            }
                        }
                    }

                    .search-icon, .clear-search {
                        &::before { background-color: var(--navigation-primary-icon-bg); }
                    }
                }
            }
        }

        ul.second-level {
            border-top: rem(1px) solid var(--#{$vudTokenPrefix}navigation-default-second-level-divider);
            box-shadow: var(--#{$vudTokenPrefix}navigation-default-second-level-shadow);
        }
    }
}


/* Mobile */
.#{$vudClassPrefix}navbar-primary {
    &.#{$vudClassPrefix}navbar.is-mobile {
        .#{$vudClassPrefix}navbar-collapse{
            .first-level {
                .menudrop {
                    .icon-align-justify {
                        &,
                        &::before,
                        &::after { background: var(--navigation-primary-icon-bg); }
                    }
                }
            }
        }
    }
}
