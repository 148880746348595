
// Tabs graphics variables
$tabs-primary-height:  rem(82px);

// Tabs primary style
.#{$vudClassPrefix}nav-tabs:not(.#{$vudClassPrefix}navbar-nav) {
    &.#{$vudClassPrefix}nav-tabs-primary {
        background-color: var(--tabs-content-bg);
        border-radius: $border-radius-8 $border-radius-8 0 0;
        border-bottom: rem(1px) solid var(--module-container-divider);
        padding-left: rem(16px);
        top: 0;
        width: 100%;
        height: $tabs-primary-height;
        
        .#{$vudClassPrefix}nav-item {
            margin: 0;
            padding: 0;
            display: inline-flex;

            a {
                background-image: none;
                background-image: unset;
                display: flex;
                position: relative;
                align-items: center;
                height: $tabs-primary-height;
                padding: rem(16px);
                justify-content: center;
                border-bottom: 0;
 
                &::before, &::after {
                    display: none;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width:  calc(#{'100% - ' rem(32px)});
                    height: rem(4px);
                    top: calc(#{$tabs-primary-height - rem(4px)});
                    left: rem(16px);
                    background: var(--#{$vudTokenPrefix}tabs-content-border-color);
                    top: auto;
                    bottom: 0;
                }

                &:hover, &.hover {
                    color: var(--#{$vudTokenPrefix}anchor-color);
                }

                &:focus:not(:active), &.focus:not(:active) {
                    outline: 0;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: rem(30px);
                        top: calc(50% - rem(15px));
                        border: rem(2px) solid var(--#{$vudTokenPrefix}anchor-focus-outline);
                        background-image: none;
                        left: 0;
                    }
                }

                &:active:focus {
                    &:after { display: none; }
                }
            }

            // tabs items on pressed state
            &.active, &.pressed, &:active:not(.disabled) {
                a {
                    color: var(--#{$vudTokenPrefix}tabs-text-color);

                    &::before {
                        display: block;
                    }
                }
            }

            &.pressed, &:active:not(.disabled) {
                a { 
                    &, &::after, &::before {
                        border-bottom: 0;
                    }
                }
                
            }

            // tabs items on diusabled state
            &.disabled a, &:disabled a,
            a.disabled, a:disabled {
                &, &:focus {
                    color: var(--#{$vudTokenPrefix}tabs-disabled-text-color);
                }
            }
        }

        .#{$vudClassPrefix}dropdown-menu {
            a {
                justify-content: start;
                border-radius: 0;
                box-shadow: none;

                &:focus:not(:active), &.focus:not(:active) {
                    box-shadow: inset 0 0 0 rem(2px) var(--#{$vudTokenPrefix}button-focus-border-color);
                }
            }
        }

        .#{$vudClassPrefix}dropdown {
            .#{$vudClassPrefix}dropdown-menu {
                top: rem(56px);

                .#{$vudClassPrefix}nav-item {
                    display: block;

                    &.active, &:active {
                        a::before { display: none; }
                    }

                    a {
                        &:focus:not(:active), &.focus:not(:active) {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        & + .#{$vudClassPrefix}tab-content {
            border-radius: 0 0 $border-radius-8 $border-radius-8;
            border-top: rem(1px) solid var(--tabs-content-primary-border-color);
        }


        // tabs overflow dropdown
        .tabdrop {
            position: relative;
            right: auto;

            // tabs overflow dropdown trigger
            .#{$vudClassPrefix}dropdown-toggle {
                background: none;
                top: rem(21px);
                width: rem(58px);
                padding: rem(16px);

                .icon-align-justify {
                    &, &::before, &::after {
                        content:'';
                        width: rem(5px);
                        height: rem(5px);
                        background-color: var(--#{$vudTokenPrefix}tabs-text-color);
                        border-radius: $border-radius-circle;
                        display: flex;
                        text-indent: 9999px;
                        position: absolute;
                        top: calc(50% - rem(2.5px));
                    }

                    & {
                        left: rem(16px);
                    }

                    &:before {
                        left: rem(10px);
                    }

                    &:after {
                        left: rem(20px);
                    }
                }

                
                &:hover {
                    .icon-align-justify {
                        &, &:after, &:before {
                            background-color: var(--#{$vudTokenPrefix}anchor-color);
                        }
                    }
                }

                &:focus:not(:active), &.focus:not(:active) {
                    outline: 0;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: rem(30px);
                        top: calc(50% - rem(15px));
                        border: rem(2px) solid var(--#{$vudTokenPrefix}anchor-focus-outline);
                        background-image: none;
                        left: 0;
                    }
                }

                // tabs overflow dropdown trigger on pressed
                //&:active { background-position-x: $tabs-dropdown-pressed-Xpoition; }
            }

            &.disabled .#{$vudClassPrefix}dropdown-toggle,
            &:disabled .#{$vudClassPrefix}dropdown-toggle,
            .#{$vudClassPrefix}dropdown-toggle.disabled,
            .#{$vudClassPrefix}dropdown-toggle:disabled {
                .icon-align-justify {
                    &, &:after, &:before {
                        background-color: var(--#{$vudTokenPrefix}tabs-disabled-text-color);
                    }
                }
            }
        }
    }
}
