// Base style

//*** scss local variable ***
$datepicker-dropdown-bg:                       var(--#{$vudTokenPrefix}dropdown-menu-bg);
// $datepicker-dropdown-shadow-color:             var(--#{$vudTokenPrefix}dropdown-menu-shadow-color);
// $datepicker-dropdown-shadow-color:             $shadow-30 $datepicker-dropdown-shadow-color;
$datepicker-dropdown-shadow-color:             var(--#{$vudTokenPrefix}shadow-30);

$datepicker-select-caret-icon:                 var(--datepicker-arrow);

$datepicker-pagination-arrow-bg:               var(--#{$vudTokenPrefix}caret-primary-bg);
$datepicker-pagination-arrow-hover-bg:         var(--#{$vudTokenPrefix}icon-hover-bg);
$datepicker-pagination-arrow-disabled-bg:      var(--#{$vudTokenPrefix}caret-disabled-bg);



$datepicker-cell-divider-border-color:         var(--#{$vudTokenPrefix}datepicker-border-color);
$datepicker-cell-hover-border-color:           var(--#{$vudTokenPrefix}button-hover-border-color);
$datepicker-cell-focus-border-color:           var(--#{$vudTokenPrefix}anchor-focus-outline);
$datepicker-cell-focus-selected-border-color:  var(--button-primary-focus-inner-shadow-color);
$datepicker-cell-pressed-border-color:         var(--#{$vudTokenPrefix}button-pressed-border-color);
$datepicker-cell-selected-border-color:        var(--#{$vudTokenPrefix}button-pressed-border-color);
$datepicker-cell-today-border-color:           var(--datepicker-item-today-border);
$datepicker-stroke-cell-selected-border-color: var(--#{$vudTokenPrefix}button-active-border-color);

$datepicker-cell-bg:                           transparent;
$datepicker-cell-hover-bg:                     var(--datepicker-item-hover-bg);
$datepicker-cell-pressed-bg:                   transparent;
$datepicker-cell-selected-bg:                  var(--datepicker-item-selected-bg);
$datepicker-cell-selected-range-bg:            var(--datepicker-item-selected-range-bg);
$datepicker-cell-selected-range-hover-bg:      var(--datepicker-item-hover-bg);
$datepicker-stroke-cell-selected-bg:           var(--#{$vudTokenPrefix}bg-primary);

$datepicker-cell-text-color:                   var(--datepicker-item-text);
$datepicker-cell-selected-text-color:          var(--datepicker-item-selected-text);
$datepicker-cell-nonclickable-text-color:      var(--datepicker-nonclickable-text-color);



$datepicker-cta-border-color:                  transparent;
$datepicker-cta-hover-border-color:            var(--#{$vudTokenPrefix}button-hover-border-color);
$datepicker-cta-focus-border-color:            var(--#{$vudTokenPrefix}anchor-focus-outline);
$datepicker-cta-pressed-border-color:          var(--#{$vudTokenPrefix}button-pressed-border-color);

$datepicker-cta-bg:                            transparent;
$datepicker-cta-hover-bg:                      var(--#{$vudTokenPrefix}button-hover-bg);
$datepicker-cta-pressed-bg:                    var(--#{$vudTokenPrefix}button-pressed-bg);



$datepicker-btn-default-border-color:          var(--#{$vudTokenPrefix}button-focus-border-color);
$datepicker-btn-default-focus-border-color:    var(--#{$vudTokenPrefix}anchor-focus-outline);
$datepicker-btn-default-disabled-border-color: var(--#{$vudTokenPrefix}button-disabled-border-color);
$datepicker-btn-default-bg:                    transparent;
$datepicker-btn-default-disabled-bg:           var(--#{$vudTokenPrefix}button-disabled-bg);
$datepicker-btn-default-text-color:            var(--datepicker-today-btn-text);
$datepicker-btn-default-disabled-text-color:   var(--#{$vudTokenPrefix}button-disabled-text-color);

$datepicker-btn-destructive-border-color:      var(--#{$vudTokenPrefix}button-destructive-border-color);
$datepicker-btn-destructive-bg:                transparent;
$datepicker-btn-destructive-hover-bg:          var(--#{$vudTokenPrefix}button-destructive-hover-bg);
$datepicker-btn-destructive-text-color:        var(--#{$vudTokenPrefix}button-destructive-text-color);


//*** element/component code ***
// datepicker main container/wrapper
.#{$vudClassPrefix}datepicker {
    //
    .#{$vudClassPrefix}datepicker-square &, &.#{$vudClassPrefix}datepicker-square {
        .#{$vudClassPrefix}datepicker-cell {
            span {
                border-radius: $border-radius;
            }
        }
    }

    .#{$vudClassPrefix}datepicker-stroke &, &.#{$vudClassPrefix}datepicker-stroke {
        .#{$vudClassPrefix}datepicker-row {
            .#{$vudClassPrefix}datepicker-cell {
                &.is-selectable {
                    &.is-selected:not(.is-within-selected):not(.is-within-hovered),
                    &.is-first-hovered.is-within-hovered-range:not(.is-within-hovered),
                    &.is-last-hovered.is-within-hovered-range:not(.is-within-hovered){
                        color: $datepicker-cell-text-color;

                        span {
                            background: $datepicker-stroke-cell-selected-bg;
                            box-shadow: inset 0 0 0 rem(1px) $datepicker-stroke-cell-selected-border-color
                        }

                        &:focus {
                            span {
                                box-shadow: inset 0 0 0 rem(2px) $datepicker-cell-focus-border-color;
                            }
                        }
                    }
                }
            }
        }
    }

    // datepicker main inner container
    .#{$vudClassPrefix}dropdown {
        margin:0;

        @media(max-width: $screen-xs-min) {
            width: 100%;
        }
    }

    // datepicker content wrapper
    .#{$vudClassPrefix}dropdown-menu {
        width: rem(400px);
        padding: $spacing-32;

        background-color: $datepicker-dropdown-bg;
        box-shadow: $datepicker-dropdown-shadow-color;

        @media(max-width: $screen-xs-min) {
            width: 100%;
            min-width: rem(265px);
            padding: $spacing-16;
        }
    }

    // datepicker main content container
    .#{$vudClassPrefix}dropdown-content {
        padding: 0;

        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
    }

    // datepicker inner content container
    .#{$vudClassPrefix}dropdown-item {
        &,
        &:hover,
        &:active,
        a:active,
        button:active { background-color: transparent; }

        &:focus { box-shadow: none; }
    }

    // *** datepicker header block
    .#{$vudClassPrefix}datepicker-header {
        display: flex;
        cursor: default;

        .select {
            display: inline-block;
            position: relative;
            vertical-align: top;
            max-width: 100%;

            select {
                min-width: 1%;
                padding-right: $spacing-24;

                border: 0;
                background: {
                    color: $datepicker-cta-bg;
                    image: $datepicker-select-caret-icon;
                    position: right rem(11px) top rem(14px);
                    repeat: no-repeat;
                }
                box-shadow: none;

                // &:hover {
                //     background-color: $datepicker-cta-hover-bg;
                //     box-shadow: inset 0 0 0 rem(1px) $datepicker-cta-hover-border-color;
                // }

                &:focus {
                    outline: 0;
                    box-shadow: inset 0 0 0 rem(2px) $datepicker-cta-focus-border-color;
                }

                // &:active {
                //     background-color: transparent;
                //     box-shadow: inset 0 0 0 rem(1px) $datepicker-cta-pressed-border-color;
                // }
            }
        }
    }

    // *** datepicker header elements
    // datepicker header control elements container
    .#{$vudClassPrefix}pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: $spacing-16;

        text-align: center;

        &.is-centered {
            .#{$vudClassPrefix}pagination-list {
                justify-content: center;
                order: 2;
            }
        }
    }

    // datepicker header select components main container
    .#{$vudClassPrefix}pagination-list {
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        order: 1;
        flex-grow: 1;
        flex-shrink: 2;
        width: 100%;
        margin: 0;

        text-align: center;
    }

    // datepicker header select components inner container
    .field-body {
        display: flex;
        justify-content: center;
        flex-basis: 0;
        flex-grow: 5;
        flex-shrink: 1;

        .field.has-addons {
            display: flex;
            justify-content: flex-start;
        }
    }

    // datepicker header cta/arrow pagination elements
    .#{$vudClassPrefix}pagination-next,
    .#{$vudClassPrefix}pagination-previous {
        flex-shrink: 0;
        width: $input-btn-height;
        height: $input-btn-height;
        padding: 0;
        margin: 0;

        &:hover:not(:active) {
            span {
                background-color: $datepicker-pagination-arrow-hover-bg;
            }
        }

        &:hover, &:active {
            background-color: transparent;
            box-shadow: none;
        }

        &:focus {
            box-shadow: inset 0 0 0 rem(2px) $datepicker-cta-focus-border-color;
        }

        &.is-disabled, &.disabled, &:disabled {
            pointer-events: none;
            cursor: default;

            box-shadow: none;

            span {
                background-color: $datepicker-pagination-arrow-disabled-bg;
            }
        }

        span {
            display: block;
            position: relative;
            top: $spacing-12;

            @include caret('lg', 'primary');
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
        }
    }

    // datepicker header cta/arrow 'previous' element
    .#{$vudClassPrefix}pagination-previous {
        order: 1;

        span {
            left: rem(10px);
        }
    }

    // datepicker header cta/arrow 'next' element
    .#{$vudClassPrefix}pagination-next {
        order: 3;

        span {
            left: rem(11px);

            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
        }
    }



    // *** datepicker content block
    .#{$vudClassPrefix}datepicker-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-8;
        min-width: rem(330px);

        .#{$vudClassPrefix}datepicker-header {
            display: flex;
            gap: $spacing-8;

            .#{$vudClassPrefix}datepicker-cell {
                cursor: default;

                color: $datepicker-cell-nonclickable-text-color;
                font-size: rem(13px);

                &:first-child {
                    position: relative;

                    span {

                        &::before {
                            position: absolute;
                            content: 'Wk';
                            width: auto;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            width: rem(1px);
                            height: calc(#{'100% + ' rem(8px)});
                            top: rem(-4px);
                            right: rem(-4px);

                            background-color: $datepicker-cell-divider-border-color;
                        }
                    }
                }

                span {
                    font-weight: 400;
                }
            }
        }

        .#{$vudClassPrefix}datepicker-body {
            display: flex;
            flex-direction: column;
            gap: $spacing-8;
        }
    }

    .#{$vudClassPrefix}datepicker-cell {
        overflow: visible;
        width: $input-btn-height;
        padding: 0;

        text-align: center;
        color: $datepicker-cell-text-color;
        background-color: $datepicker-cell-bg;
        box-shadow: none;

        &:hover, &:focus {
            background-color: $datepicker-cell-bg;
            box-shadow: none;
        }

        &.is-week-number {
            pointer-events: none;
            cursor: default;
            margin-right: $spacing-8;
            border-radius: 0;
        }

        &.is-unselectable {

            &:hover {
                &, > span {
                    pointer-events: none;
                    cursor: default;
                }
            }
        }

        &.is-nearby, &.is-unselectable, &.is-week-number {
            color: $datepicker-cell-nonclickable-text-color;

            span {
                font-weight: 400;
            }
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $input-btn-height;
            height: $input-btn-height;
            margin: 0 auto;

            font-weight: 600;
            border-radius: $border-radius-circle;
            border: rem(1px) solid transparent;
        }
    }

    .#{$vudClassPrefix}datepicker-row {
        display: flex;
        cursor: default;
        gap: $spacing-8;

        .#{$vudClassPrefix}datepicker-cell {
            position: relative;

            span {
                position: relative;
                overflow: visible;
                margin: 0;

                border: 0;

                &:before,
                &:after {
                    display: block;
                    content:'';
                    position: absolute;
                    width: calc(#{'50% + ' rem(4px)});
                    height: $input-btn-height;

                    z-index: -1;
                }

                &::before {
                    left: rem(-4px);
                }

                &::after {
                    right: rem(-4px);
                }
            }

            &:hover {
                span {
                    background: $datepicker-cell-hover-bg;
                    box-shadow: inset 0 0 0 rem(1px) $datepicker-cell-hover-border-color;
                }
            }

            &:focus {
                span {
                    box-shadow: inset 0 0 0 rem(2px) $datepicker-cell-focus-border-color;
                }
            }

            &:active {
                span {
                    background-color: $datepicker-cell-pressed-bg;
                    box-shadow: inset 0 0 0 rem(1px) $datepicker-cell-pressed-border-color;
                }
            }

            &.is-week-number {
                span {
                    &::before { display: none;}
                    &::after {
                        width: rem(1px);
                        height: calc(#{'100% + ' rem(8px)});
                        top: rem(-4px);
                        right: rem(-8px);

                        background-color: $datepicker-cell-divider-border-color;
                    }
                }
            }

            &.is-week-number, &.is-unselectable {
                &:hover {
                    span {
                        background: $datepicker-cell-bg;
                        box-shadow: none;
                    }
                }
            }

            &.is-today {
                span {
                    box-shadow: inset 0 0 0 rem(2px) $datepicker-cell-today-border-color;
                }

                &.is-within-selected,
                &.is-within-hovered-range {
                    span { box-shadow: none; }
                }
            }

            &.is-selectable {
                &.is-selected:not(.is-within-selected):not(.is-within-hovered),
                &.is-first-hovered.is-within-hovered-range:not(.is-within-hovered),
                &.is-last-hovered.is-within-hovered-range:not(.is-within-hovered){
                    color: $datepicker-cell-selected-text-color;

                    span {
                        background: $datepicker-cell-selected-bg;
                        box-shadow: none;
                    }

                    &:focus {
                        span {
                            box-shadow: inset 0 0 0 rem(1px) $datepicker-cell-focus-border-color, inset 0 0 0 rem(3px) $datepicker-cell-focus-selected-border-color;
                        }
                    }
                }
            }

            &.is-selected {
                &.is-first-selected {
                    span {
                        &::after {
                            background-color: $datepicker-cell-selected-range-bg;
                        }
                    }
                }

                &.is-last-selected {
                    span {
                        &::before {
                            background-color: $datepicker-cell-selected-range-bg;
                        }
                    }
                }

                &.is-first-selected.is-last-selected:not(.is-within-hovered) {
                    span {
                        &::before, &::after {
                            background-color: $datepicker-cell-bg;
                        }
                    }
                }
            }

            &.is-within-selected {
                span {
                    &::before, &::after {
                        background-color: $datepicker-cell-selected-range-bg;
                    }
                }
            }

            &.is-within-hovered-range {
                &.is-within-hovered {
                    span {
                        &::before, &::after {
                            background-color: $datepicker-cell-selected-range-hover-bg;
                        }
                    }
                }

                &.is-first-hovered:not(.is-last-hovered) {
                    span {
                        &::after {
                            background-color: $datepicker-cell-selected-range-hover-bg;
                        }
                    }
                }

                &.is-last-hovered:not(.is-first-hovered) {
                    span {
                        &::before {
                            background-color: $datepicker-cell-selected-range-hover-bg;
                        }
                    }
                }
            }
        }
    }

    // *** datepicker footer block
    .#{$vudClassPrefix}datepicker-footer {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: auto;
        width: auto;
        padding: $spacing-24 0 0 0;
        bottom: auto;
    }

    // footer cta elements
    .#{$vudClassPrefix}btn {
        width: auto;
        min-width: rem(40px);
        padding: $spacing-6 $spacing-16;

        border-radius: $border-radius-16;
        border: rem(2px) solid transparent;
        background-color: $datepicker-btn-default-bg;
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }

        &.btn-today {
            border-color: $datepicker-btn-default-border-color;
            color: $datepicker-btn-default-text-color;
            box-shadow: none;

            &:focus:not(:active) {
                box-shadow: inset 0 0 0 rem(1px) $datepicker-cta-focus-border-color;
            }
        }

        &.btn-clear {
            border-color: $datepicker-btn-destructive-border-color;
            color: $datepicker-btn-destructive-text-color;

            &:hover:not(:active) {
                background-color: $datepicker-btn-destructive-hover-bg;
            }

            &:focus {
                box-shadow: inset 0 0 0 rem(1px) $datepicker-btn-destructive-border-color;
            }
        }

        //### '.btn-destructive' disabled state
        &.btn-today, &.btn-clear {
            &:disabled, &.disabled,
            :disabled &, .disabled & {
                &, &:focus {
                    color: $datepicker-btn-default-disabled-text-color;
    
                    border-color: $datepicker-btn-default-disabled-border-color;
                    background-color: $datepicker-btn-default-disabled-bg;
                    box-shadow: none;
                }
            }
        }
    }
}
