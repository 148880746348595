/*
 * Login page - default
 */

.login-page {
    padding-top: rem(32px);
    padding-bottom: 0;

    background: {
        color: transparent;
        image: url('#{$image-path}/login/login-background-default-1.jpg');
        repeat: no-repeat;
        size: cover;
    }

    &.login-page-default-bg-1 { background-image: url('#{$image-path}/login/login-background-default-1.jpg'); }
    &.login-page-default-bg-2 { background-image: url('#{$image-path}/login/login-background-default-2.jpg'); }
    &.login-page-default-bg-3 { background-image: url('#{$image-path}/login/login-background-default-3.jpg'); }
    &.login-page-default-bg-4 { background-image: url('#{$image-path}/login/login-background-default-4.jpg'); }
    &.login-page-default-bg-5 { background-image: url('#{$image-path}/login/login-background-default-5.jpg'); }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--mask-bg);
    }

    .cookies-alert { 
        justify-content: center; 
        top: 0;
        margin-bottom: rem(24px);
    } 

    .login-footer-alert { 
        justify-content: right; 
        bottom: 0;
        margin-top: rem(24px);

        @media (max-width: $screen-md-min) {
            justify-content: center;
        }
    }

    .login-box { box-shadow: var(--#{$vudTokenPrefix}modal-shadow); }
}