// Stepper - base styling

.#{$vudClassPrefix}stepper {
    padding: 0 rem(32px);

    > ul {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        padding: 0;

        > li {
            position: relative;
            cursor: pointer;
            flex: 1;
            margin: 0;
            padding: 0;
            display: inline-block;
            height: 100%;
            flex: 1 100%;
            text-align: center;
            cursor: default;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.visited > *,
            > * {
                font: $font-md;
                text-decoration: none;
                outline: 0;
                color: var(--#{$vudTokenPrefix}stepper-text-color);
                position: relative;
                display: block;
                width: 100%;
                cursor: pointer;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    margin: 0 auto;
                    position: absolute;
                    top: auto;
                    background: var(--#{$vudTokenPrefix}stepper-bg);
                }

                &:after {
                    width: 100%;
                    height: rem(3px);
                    right: auto;
                    left: auto;
                    bottom: rem(17px);
                    width: 100%;
                    background-color: var(--#{$vudTokenPrefix}stepper-bg);
                }

                &:before {
                    width: rem(32px);
                    height: rem(32px);
                    border-radius: rem(32px);
                    z-index: 20;
                }

                > span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;
                }

                > span:not(.step) {
                    display: block;
                    margin: 0;
                    line-height: 1.5;
                }

                > span.step {
                    display: none;
                    font-size: $font-size-xs;
                    color: var(--#{$vudTokenPrefix}stepper-text-color);
                }

            }

            &:first-child {
                > * {
                    &:after { opacity: 0 }
                }
            }

            &.active {
                > * {
                    color: var(--#{$vudTokenPrefix}stepper-active-text-color);
                    font-weight: $bold;

                    &:before {
                        width: rem(48px);
                        height: rem(48px);
                        border-radius: rem(48px);
                        border: rem(20px) solid var(--#{$vudTokenPrefix}stepper-active-border-color);
                        background: var(--stepper-active-bg);
                        bottom: rem(-5px);
                        // box-shadow: $shadow-30 var(--stepper-active-shadow-color);
                        box-shadow: var(--#{$vudTokenPrefix}shadow-30);
                    }
                }
            }

            &.passed {
                > * {
                    color: var(--#{$vudTokenPrefix}stepper-visited-text-color);
                    font-weight: $semibold;

                    &:after { background: var(--#{$vudTokenPrefix}stepper-visited-bg); }

                    &:before {
                        background-color: var(--#{$vudTokenPrefix}stepper-visited-bg);
                        background-size: rem(12px) rem(10px);
                        background-repeat: no-repeat;
                        background-position: center; 
                    }
                }

                + .active {
                    > * {
                        &:after {
                            background: linear-gradient(90deg, var(--#{$vudTokenPrefix}stepper-visited-bg) 0%, var(--#{$vudTokenPrefix}stepper-active-border-color) 50%, var(--#{$vudTokenPrefix}stepper-bg) 50%, var(--#{$vudTokenPrefix}stepper-bg) 100%);
                            background-size: 200% auto;
                            background-position-x: 0%;
                        }
                    }
                }
            }

            &.visited {
                + .passed,
                + .active {
                    > * {
                        &:after {
                            background-color: var(--#{$vudTokenPrefix}stepper-bg);
                        }
                    }
                }
            }

            &.disabled {
                > * {
                    cursor: default;
                    pointer-events: none;
                }
            }

        }
    }


    &.no-ellipsis {
        > ul > li > * > span:not(.step) {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.3;
            align-items: center;
            justify-content: center;

            @media (min-width: $screen-md-max) {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: rem(46px);
                top: rem(-12px);
                white-space: normal;
            }
        }
    } // END .no-ellipsis

} // END '.stepper'