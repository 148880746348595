

// Stepper transitions
.#{$vudClassPrefix}stepper {
    &.transition {
        > ul {
            > li.active {
                @media (min-width: $screen-md-min) {
                    > *:not(.#{$vudClassPrefix}panel) {
                        animation: active-font 0.1s linear;
                        -webkit-animation: active-font 0.1s linear;

                        &::after {
                            animation: progress-bar 0.75s linear;
                            -webkit-animation: progress-bar 0.75s linear;
                        }

                        &::before {
                            animation: bullet 0.1s linear;
                            -webkit-animation: bullet 0.1s linear;
                        }
                    }

                    > .#{$vudClassPrefix}panel {
                        > .#{$vudClassPrefix}panel-body {
                            animation: content-panel 0.3s linear;
                            -webkit-animation: content-panel 0.3s linear;
                            animation: content-panel-opacity 0.1s linear;
                            -webkit-animation: content-panel-opacity 0.1s linear;
                        }
                    }
                }
            }
        }

    }
} 


// Transitions
@keyframes progress-bar {
    0% { background-position: 100% 100%; }
    100% { background-position: 0% 0%; }
}

@keyframes bullet {
    0% { border-width: 0; }
    100% { border-width: 15px; }
}

@keyframes active-font {
    0% { font-weight: $normal; }
    100% { font-weight: $bold; }
}

@keyframes content-panel {
    0% {
        max-height: 0;
        padding-bottom: 0;
    }
    100% {
        max-height: rem(1000px);
        padding-bottom: rem(32px);
    }
}

@keyframes content-panel-opacity {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}
