//# Table

//*** scss local variable ***
$table-condensed-size:        rem(32px);
$table-default-size:          rem(40px);
$table-regular-size:          rem(48px);
$table-relaxed-size:          rem(56px);
$table-row-line-height:       rem(20px);
$table-default-height:        rem(32px);
$table-border-width:          rem(1px);

$table-default-padding-x:     $spacing-16;
$table-condensed-padding-y:   calc(($table-condensed-size - $table-default-height) / 2);
$table-default-padding-y:     calc(($table-default-size - $table-default-height) / 2);
$table-regular-padding-y:     calc(($table-regular-size - $table-default-height) / 2);
$table-relaxed-padding-y:     calc(($table-relaxed-size - $table-default-height) / 2);


$table-border-color:          var(--#{$vudTokenPrefix}table-border-color);
$table-hover-border-color:    var(--#{$vudTokenPrefix}table-hover-border-color);
$table-pressed-border-color:  var(--#{$vudTokenPrefix}table-active-border-color);
$table-selected-border-color: var(--#{$vudTokenPrefix}table-selected-border-color);

$table-inner-divider:         linear-gradient(to bottom, var(--#{$vudTokenPrefix}table-dotted-divider-color) 10%, rgba(255, 255, 255, 0) 0%);

$table-bg:                    var(--#{$vudTokenPrefix}table-bg);
$table-alt-bg:                var(--#{$vudTokenPrefix}table-alt-bg);
$table-hover-bg:              var(--#{$vudTokenPrefix}table-hover-bg);
$table-pressed-bg:            var(--table-active-bg);
$table-selected-bg:           var(--#{$vudTokenPrefix}table-selected-bg);
$table-error-bg:              var(--#{$vudTokenPrefix}table-error-bg);
$table-error-text-color:      var(--#{$vudTokenPrefix}table-error-text-color);

$table-shadow-color:          var(--#{$vudTokenPrefix}table-shadow-color);



//*** element/component code ***
.#{$vudClassPrefix}table {
    width: 100%;
    max-width: 100%;
    margin-bottom: $spacing-16;

    border-spacing: 0;
    border-collapse: separate;

    background: $table-bg;
    // box-shadow: $shadow-10 $table-shadow-color;
    box-shadow: var(--#{$vudTokenPrefix}shadow-10);

    // caption basic style
    caption {
        padding: $spacing-8 $spacing-16;
        text-align: left;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // cell main lateral padding
    thead, tbody, tfoot {
        th, td {
            padding-right: $table-default-padding-x;
            padding-left: $table-default-padding-x;

            vertical-align: middle;
            background-color: $table-bg;

            .checkbox { top: rem(2px); }
        }
    }

    // thead cell - the main style
    thead {
        th, td {
            height: $table-regular-size;
            padding-top: $table-regular-padding-y;
            padding-bottom: $table-regular-padding-y;

            font-weight: $semibold;

            border: $table-border-width solid transparent;
            border-bottom-color: $table-border-color;
            // box-shadow: 0 $table-border-width 0 0 $table-border-color;
        }
    }

    // tbody cell - the main style
    tbody {
        th, td {
            position: relative;
            height: $table-default-size;
            padding-top: $table-default-padding-y;
            padding-bottom: $table-default-padding-y;

            font-weight: $normal;

            border-top: $table-border-width solid $table-border-color;
            border-bottom: $table-border-width solid transparent;

            &:first-child {
                border-left: $table-border-width solid transparent;

                .indent-error {
                    position: absolute;
                    width: rem(32px);
                    height: rem(32px);
                    left: rem(-32px);

                    justify-content: center;

                    .vismaicon {
                        top: rem(3px);
                    }
                }
            }

            &:last-child { border-right: $table-border-width solid transparent; }

            &.no-border {
                border-color: transparent;
            }

            .indent-error {
                display: inline-flex;
            }
        }

        tr {
            &:first-child {
                th, td { border-top-color: transparent; }
            }

            &.has-error {
                &:hover {
                    th, td {
                        color: $table-error-text-color;
                        background-color: $table-error-bg;
                    }
                }
                th, td {
                    .vismaicon {
                        position: inherit;
                        top: rem(3px);
                        right: unset;
                        transform: none;
                    }
                }

                .checkbox:not(.has-error):not(:focus-within) {
                    outline: 0;
                }
            }
        }
    }

    // tfoot cell - the main style
    tfoot {
        th, td {
            padding-top: $spacing-16;
            padding-bottom: $spacing-16;

            font-weight: normal;

            border-top: $table-border-width solid $table-selected-bg;

            p, .btn { margin-bottom: 0; }
        }
    }

    &.#{$vudClassPrefix}table-header-sticky {
        thead {
            th, td {
                position: sticky;
                top: 0;
                z-index: 2;
            }
        }
    }

    &.#{$vudClassPrefix}table-first-column-sticky {
        thead {
            th, td {
                &:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 3;
                }
            }
        }

        tbody {
            th, td {
                &:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 1;
                }
            }
        }
    }

    &.#{$vudClassPrefix}table-last-column-sticky {
        thead {
            th, td {
                &:last-child {
                    position: sticky;
                    right: 0;
                    z-index: 3;
                }
            }
        }

        tbody {
            th, td {
                &:last-child {
                    position: sticky;
                    right: 0;
                    z-index: 1;
                }
            }
        }
    }


    //## '.table-bordered' - add border on the tables in case the tables will be added on a white background
    &.#{$vudClassPrefix}table-bordered {
        border: $table-border-width solid $table-border-color;
        border-bottom: 0;
    }


    //## '.table-stripe' - variant table design - zebra design
    &.#{$vudClassPrefix}table-stripe {
        // thead and tbody - vertical dotted divider
        thead, tbody {
            th, td {
                background: {
                    image: $table-inner-divider;
                    position: right;
                    size: rem(1px) rem(6px);
                    repeat: repeat-y;
                }

                &:last-child { background-image: none; }
            }
        }

        // zebra background style declaration
        tbody {
            tr {
                &:nth-child(odd) > th,
                &:nth-child(odd) > td { background-color: $table-alt-bg; }

                th, td { border-color: transparent; }
            }
        }
    }


    //## '.table-zebra' - variant table design - zebra design
    &.#{$vudClassPrefix}table-plain {
        tbody {
            th, td {
                border-color: transparent;
            }
        }
    }


    //## '.table-hover' - variant table design - hover state for table fields (this applied to .table-active too)
    &.#{$vudClassPrefix}table-active,
    &.#{$vudClassPrefix}table-hover {
        thead, tbody {
            th, td { cursor: pointer; }
        }

        thead {
            th, td {
                &:hover:not(.no-hover) {
                    border-color: $table-hover-border-color;
                    background-color: $table-hover-bg;
                }
            }
        }

        tbody {
            tr {
                &:hover {
                    th, td {
                        border-color: $table-hover-border-color;
                        background-color: $table-hover-bg;
                    }

                    + tr {
                        th, td {
                            border-top-color: transparent;
                        }
                    }
                }

                &.has-error {
                    &, &.active {
                        &:hover,
                        &:hover:not(:active) {
                            th, td {
                                color: $table-error-text-color;
                                border-color: $table-border-color transparent $table-border-color transparent;
                                background-color: $table-error-bg;
                            }
                        }
                    }
                }
            }
        }
    }


    //## '.table-active' - variant table design - active state for table fields
    &.#{$vudClassPrefix}table-active {
        thead, tbody {
            th, td { cursor: pointer; }
        }

        thead {
            th, td {
                // pressed state for thead cells
                &:active:not(.no-hover) {
                    border-color: $table-pressed-border-color;
                    background-color: $table-pressed-bg;
                }
            }
        }

        tbody {
            tr {
                &:active, &.active {
                    th, td {
                        border-color: $table-pressed-border-color;
                        background-color: $table-selected-bg;
                    }

                    + tr {
                        th, td {
                            border-top-color: transparent;
                        }
                    }
                }

                &:active {
                    th, td {
                        background-color: $table-pressed-bg;
                    }
                }

                &.active {
                    &:hover {
                        th, td {
                            border-color: $table-hover-border-color;
                            background-color: $table-hover-bg;
                        }
                    }
                }
            }
        }
    }


    //## '.table-condensed' - variant table design - make tables more compact
    &.#{$vudClassPrefix}table-condensed {
        tbody {
            th, td {
                height: $table-condensed-size;
                padding-top: $table-condensed-padding-y;
                padding-bottom: $table-condensed-padding-y;
            }
        }
    }


    //## '.table-regular' - variant table design
    &.#{$vudClassPrefix}table-regular {
        tbody {
            th, td {
                height: $table-regular-size;
                padding-top: $table-regular-padding-y;
                padding-bottom: $table-regular-padding-y;
            }
        }
    }


    //## '.table-relaxed' - variant table design - make tables more compact
    &.#{$vudClassPrefix}table-relaxed {
        tbody {
            th, td {
                height: $table-relaxed-size;
                padding-top: $table-relaxed-padding-y;
                padding-bottom: $table-relaxed-padding-y;
            }
        }
    }
}



//# Responsive tables
.#{$vudClassPrefix}table-responsive {
    min-height: .01%;
    overflow: auto;

    @media(max-width: $screen-md-min) {
        width: 100%;
        margin-bottom: rem(18px);
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        // box-shadow: $shadow-10 $table-shadow-color;
        box-shadow: var(--#{$vudTokenPrefix}shadow-10);

        > .#{$vudClassPrefix}table { 
            margin: 0;
            box-shadow: none;
        }
    }
}
