// Datepicker Popup

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.#{$vudClassPrefix}datepicker {
    input[type="date"],
    input[type="text"] {
        padding-right: $input-btn-dropdown-padding-x;

        + .icon {
            display: block;
            width: rem(16px);
            height: rem(16px);
            position: absolute;
            right: $spacing-8;
            top: $spacing-8;
            mask: url(#{$image-path}/datepicker.svg);
            -webkit-mask: url(#{$image-path}/datepicker.svg);
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            mask-position: right 0 top 0;
            -webkit-mask-position: right 0 top 0;
            background-color: var(--#{$vudTokenPrefix}icon-bg);
            cursor: pointer;
        }
    }

    .control {
        position: relative;

        input[type="date"],
        input[type="text"] {
            width: 100%;
        }
    }

    .#{$vudClassPrefix}dropdown {
        .#{$vudClassPrefix}dropdown-menu {
            display: block;
            top: 100%;
            z-index: 2;
        }
    }
}

// Label
.#{$vudClassPrefix}field {
    label.#{$vudClassPrefix}label {
        background-color: transparent;
        font-weight: normal;
        font: $font-sm;
        min-width: 1%;
        text-align: left;
        margin: 0;
        top: auto;
        border-radius: 0;
        border: 0;
        box-shadow: none;
    }
}