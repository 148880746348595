
// Button Group

//*** scss local variable ***
$button-group-vertical-border-color:             var(--#{$vudTokenPrefix}button-border-color);
$button-group-vertical-hover-border-color:       var(--#{$vudTokenPrefix}button-hover-border-color);
$button-group-vertical-focus-border-color:       var(--#{$vudTokenPrefix}button-focus-border-color);
$button-group-vertical-focus-inner-border-color: var(--button-inner-border-color); // vertical border betwin two buttons
$button-group-vertical-pressed-border-color:     var(--#{$vudTokenPrefix}button-pressed-border-color);
$button-group-vertical-disabled-border-color:    var(--#{$vudTokenPrefix}button-disabled-border-color);

$button-group-vertical-bg:                       var(--#{$vudTokenPrefix}button-bg);
$button-group-vertical-hover-bg:                 var(--#{$vudTokenPrefix}button-hover-bg);
$button-group-vertical-pressed-bg:               var(--#{$vudTokenPrefix}button-pressed-bg);
$button-group-vertical-selected-bg:              var(--#{$vudTokenPrefix}button-selected-bg);
$button-group-vertical-disabled-bg:              var(--#{$vudTokenPrefix}button-disabled-bg);

$button-group-vertical-shadow-color:             var(--button-shadow-color);
$button-group-hover-shadow-color:                var(--button-hover-shadow-color);

$button-group-vertical-text-color:               var(--#{$vudTokenPrefix}button-text-color);
$button-group-vertical-disabled-text-color:      var(--#{$vudTokenPrefix}button-disabled-text-color);


//*** element/component code ***
// Vertically stacked group buttons
.#{$vudClassPrefix}btn-group-vertical {
    display: inline-block;
    vertical-align: top;

    //# Button base style
    .#{$vudClassPrefix}btn {
        display: block;
        width: 100%;
        max-width: 100%;

        color: $button-group-vertical-text-color;

        border-color: $button-group-vertical-border-color;
        background-color: $button-group-vertical-bg;
        // box-shadow: $shadow-20 $button-group-vertical-shadow-color;
        box-shadow: var(--#{$vudTokenPrefix}shadow-20);

        &:not(:first-child):not(:last-child) { border-radius: 0; }

        &:not(:last-child) {
            margin-bottom: 0;

            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:first-child) {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }


        //## Button style for 'selected state'
        &.active {
            border-color: $button-group-vertical-border-color;
            background-color: $button-group-vertical-selected-bg;
            box-shadow: none;

            //### Button style for one that precede a 'selected' button
            + .#{$vudClassPrefix}btn {
                box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, 0 rem(-4px) rem(4px) rem(-2px) $button-group-vertical-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                // Button style for a 'selected' button that precede a 'selected' button
                &.active {
                    &:hover { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-hover-border-color; }

                    &:focus { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-border-color, inset 0 0 0 rem(1px) $button-group-vertical-focus-border-color; }

                    &, &:active { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color; }
                } // END '.btn-group-vertical .btn.active + .btn.active'
            } // END '.btn-group-vertical .btn.active + .btn'
        } // END '.btn-group-vertical .btn.active'


        //## Button style for 'hoverd state'
        &:hover:not(:active) {
            border-color: $button-group-vertical-hover-border-color;
            background-color: $button-group-vertical-hover-bg;
            // box-shadow: $shadow-20 $button-group-hover-shadow-color;
            box-shadow: var(--#{$vudTokenPrefix}shadow-20);

            //### Button style for one that precede a 'hoverd' button
            + .#{$vudClassPrefix}btn {
                box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-hover-border-color, var(--#{$vudTokenPrefix}shadow-20);

                // Button style for a 'selected' button that precede a 'hoverd' button
                &.active {
                    &, &:disabled, &.disabled { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-hover-border-color, inset 0 rem(4px) rem(4px) rem(-2px) $button-group-vertical-shadow-color; }
                }

                // Button style for a 'disabled' button that precede a 'hoverd' button
                &:disabled, &.disabled { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-hover-border-color; }
            } // END '.btn-group-vertical .btn:hover + .btn'
        } // END '.btn-group-vertical .btn:hover'


        //## Button style for 'focus state'
        &:focus:not(:active) {
            border-color: $button-group-vertical-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $button-group-vertical-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);

            //### Button style for one that precede a 'focused' button
            + .#{$vudClassPrefix}btn {
                &, &:hover { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-border-color, var(--#{$vudTokenPrefix}shadow-20); }

                // Button style for a 'selected' button that precede a 'focused' button
                &.active {
                    &:disabled, &.disabled { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-border-color, inset 0 rem(4px) rem(4px) rem(-2px) $button-group-vertical-shadow-color; }
                }

                // Button style for a 'disabled' button that precede a 'focused' button
                &:disabled, &.disabled { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-border-color; }
            } // END '.btn-group-vertical .btn:focus + .btn'
        } // END '.btn-group-vertical .btn:focus'


        //## Button style for 'pressed state'
        &:active {
            border-color: $button-group-vertical-pressed-border-color;
            background-color: $button-group-vertical-pressed-bg;
            box-shadow: none;

            //### Button style for one that precede a 'pressed' button
            + .#{$vudClassPrefix}btn {
                &, &:hover { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, 0 rem(-6px) rem(4px) rem(-4px) $button-group-vertical-shadow-color, var(--#{$vudTokenPrefix}shadow-20); }

                // Button style for a 'disabled selected' button that precede a 'pressed' button
                &.active {
                    &:disabled, &.disabled { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, inset 0 rem(4px) rem(4px) rem(-2px) $button-group-vertical-shadow-color; }
                }
            } // END '.btn-group-vertical .btn:active + .btn'
        } // END '.btn-group-vertical .btn:active'


        //# Button base style for the second and up button in the group
        + .#{$vudClassPrefix}btn {
            border-top: 0;
            box-shadow: 0 rem(-2px) 0 rem(-1px) $button-group-vertical-focus-inner-border-color, var(--#{$vudTokenPrefix}shadow-20);

            // selected state
            &.active { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, inset 0 rem(4px) rem(4px) rem(-2px) $button-group-vertical-shadow-color; }

            // hover state
            &:hover { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-hover-border-color, var(--#{$vudTokenPrefix}shadow-20); }

            // focus state
            &:focus {
                box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-border-color, inset 0 0 0 rem(1px) $button-group-vertical-focus-border-color, var(--#{$vudTokenPrefix}shadow-20);

                // Button style for a 'pressed' button that precede a 'focused' button
                + .#{$vudClassPrefix}btn {
                    &.active { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-border-color, inset 0 rem(4px) rem(4px) rem(-2px) $button-group-vertical-shadow-color; }
                }
            } // END '.btn-group-vertical .btn + .btn:focus'

            // pressed state
            &:active {
                box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, inset 0 rem(6px) rem(4px) rem(-4px) $button-group-vertical-shadow-color;

                // Button style for a button that precede a 'pressed' button
                + .#{$vudClassPrefix}btn {
                    box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, 0 rem(-6px) rem(4px) rem(-4px) $button-group-vertical-shadow-color, var(--#{$vudTokenPrefix}shadow-20);

                    // Button style for a 'selected', 'disabled' or 'selected disabled' button that precede a 'pressed' button
                    &.active, &:disabled, &.disabled {
                        &, &.active { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, 0 rem(-6px) rem(4px) rem(-4px) $button-group-vertical-shadow-color; }
                    }
                } // END '.btn-group-vertical .btn + .btn:active + .btn'
            } // END '.btn-group-vertical .btn + .btn:active'
        } // END '.btn-group-vertical .btn + .btn'
    } // END '.btn-group-vertical .btn'
} // END '.btn-group-vertical'


// The default 'Disabled state' for the button in buttons group
:disabled .#{$vudClassPrefix}btn-group-vertical .#{$vudClassPrefix}btn,
.disabled .#{$vudClassPrefix}btn-group-vertical .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-group-vertical:disabled .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-group-vertical.disabled .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-group-vertical .#{$vudClassPrefix}btn:disabled,
.#{$vudClassPrefix}btn-group-vertical .#{$vudClassPrefix}btn.disabled {
    color: $button-group-vertical-disabled-text-color;

    border-color: $button-group-vertical-disabled-border-color;
    box-shadow: none;

    //# Button base style for 'disabled state'
    &, &.#{$vudClassPrefix}btn-primary {
        background-color: $button-group-vertical-disabled-bg;

        //## 'sellected disabled' button
        &.active {
            color: $button-group-vertical-disabled-text-color;

            background-color: $button-group-vertical-selected-bg;

            // Button style for one that precede a 'selected disabled' button
            + .#{$vudClassPrefix}btn {
                &.active {
                    &:hover { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-hover-border-color; }

                    &:focus { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-border-color, inset 0 0 0 rem(1px) $button-group-vertical-focus-border-color; }

                    &, &:active { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color; }
                }
            }
        }
    }

    //# Button base style for the second and up button in the 'disabled' group or pressed a 'disabled' button
    + .#{$vudClassPrefix}btn {
        box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, var(--#{$vudTokenPrefix}shadow-20);

        &.active { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, inset 0 rem(4px) rem(4px) rem(-2px) $button-group-vertical-shadow-color; }

        &:hover { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-hover-border-color, var(--#{$vudTokenPrefix}shadow-20); }

        &:focus { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-border-color, inset 0 0 0 rem(1px) $button-group-vertical-focus-border-color, var(--#{$vudTokenPrefix}shadow-20); }

        &:active { box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-border-color, inset 0 rem(6px) rem(4px) rem(-4px) $button-group-vertical-shadow-color; }
    }
}


:disabled .#{$vudClassPrefix}btn-group-vertical .#{$vudClassPrefix}btn,
.disabled .#{$vudClassPrefix}btn-group-vertical .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-group-vertical:disabled .#{$vudClassPrefix}btn,
.#{$vudClassPrefix}btn-group-vertical.disabled .#{$vudClassPrefix}btn {
    //# Button base style for a 'selected' button in the 'disabled' group
    &.active {
        + .#{$vudClassPrefix}btn {
            box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-inner-border-color, 0 rem(-6px) rem(4px) rem(-4px) $button-group-vertical-shadow-color;

            &.active {
                &, &:focus { box-shadow: 0 rem(-2px) 0 rem(-1px) $button-group-vertical-focus-inner-border-color; }
            }
        }
    }

    //# Button base style for the second and up button in the 'disabled' group
    + .#{$vudClassPrefix}btn {
        &, &:focus { box-shadow: 0 rem(-2px) 0 rem(-1px) $button-group-vertical-focus-inner-border-color; }

        &.active {
            &, &:focus{ box-shadow: 0 rem(-1px) 0 0 $button-group-vertical-focus-inner-border-color, inset 0 rem(6px) rem(4px) rem(-4px) $button-group-vertical-shadow-color; }
        }
    }
}
